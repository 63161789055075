@import "../../styleConstant";

.gasless-manager-container {
  display: grid;
}



.menu {
  background-color: #000; /* Black background color */
  color: #fff; /* White text color */
  padding: 20px;
  flex: 0.5;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  cursor: pointer;
  padding: 20px 0;
  text-align: center;
  font-size: 16px;
}

.menu li:hover {
  background-color: #333; /* Darker background color on hover */
}

.menu .selected {
  background-color: transparent;
  border-bottom: 2px solid #9359ef !important;
  font-weight: bold;
}

.content {
  align-items: end;
  flex: 2;
  padding: 20px;

}



//-------------------- create gasless subscription

.column-container {
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 500;
  line-height: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .label-text {
    letter-spacing: 0.02em;
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #f2ecfb;

    .anticon {
      margin-left: 15px;
    }
  }

  .input-container, .create-collection-drop {
    color: @color-d5;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-radius: 6px;
    padding: 10px;
    background: #02020f;
    border: 1px solid #676767;
    border-radius: 6px;
    &::placeholder {
      color: #e0caef82;
    }
  }
  .budget-slider{
    color: #fff;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
  }

  .ant-radio-wrapper {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: white !important;
  }

  .services-list{
    margin-left: 80px;
    width: 60%;

    .ant-form-item{
      margin-bottom: 10px;
    }
  }

  .service-text{
    .ant-form-item-control{
      align-items: center;
    }
  }

  .or-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px 0;
    font-size: 18px;
    color: #fff;
    max-width: 750px;
  }

}

//-------------------- create gasless subscription Ends
.header-text{
 color: #fff;
 font-family: 'Poppins';
 font-size: 24px;
 font-weight:400;
 line-height: 35px;
  margin-bottom: 20px;
}
.header-text-inner{
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  line-height: 35px;
  text-align: left;
   margin-bottom: 20px;
   font-family: 'Poppins';
 }

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.gasless-header-section{
display:flex;
align-items: center;
}
.gasless-filter{
  margin-left:auto;

  .createBtn{
    font-family: 'Poppins' !important;
    width: 219px !important;
  }

  .search-box-container,.input-box {
    width: 293px !important;
  }
}

.gasless-footer-container{
 margin-top: 30px;
.gasless-footer-container{
         max-width: 100%;
         display: flex;
         z-index:1;
         flex-direction: row;
          //gap: 10px;
          .project-footer-col{
           width: 25%;
          }
  }

  .tiles {
     display: grid;
     grid-template-columns: repeat(4, 1fr);
     -moz-column-gap: 1rem;
          column-gap: 1rem;
     row-gap: 1rem;
     margin-top: 1.25rem;
   }
   @media (max-width: 700px) {
     .tiles {
       grid-template-columns: repeat(1, 1fr);
     }
   }

   .tile {
     padding: 1rem;
     border-radius: 8px;
     /* background-color: #e3ffa8; */
     background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
     color: #000000;
     min-height: 200px;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     position: relative;
     transition: 0.25s ease;

     &:hover {
             a .icon-button {
                 transform: rotateZ(-30deg);
             }
         }
   }
   .tile:hover {
     transform: translateY(-5px);
   }

   .tile:focus-within {
     box-shadow: 0 0 0 2px #1f1f1f, 0 0 0 4px #e3ffa8;
   }
   .tile:nth-child(2) {
     background-color: #45ffbc;
   }
   .tile:nth-child(2):focus-within {
     box-shadow: 0 0 0 2px #1f1f1f, 0 0 0 4px #45ffbc;
   }
   .tile:nth-child(3) {
     background-color: #bdbbb7;
   }
   .tile:nth-child(3):focus-within {
     box-shadow: 0 0 0 2px #1f1f1f, 0 0 0 4px #bdbbb7;
   }
   .tile a {
     text-decoration: none;
     display: flex;
     align-items: center;
     justify-content: space-between;
     font-weight: 600;
   }
   .tile a .icon-button {
     color: inherit;
     border-color: inherit;
   }
   .tile a span svg,img {
        height: 35px;
   }

   .tile a .icon-button:hover, .tile a .icon-button:focus {
     background-color: transparent;
   }
   .tile a .icon-button:hover i, .tile a .icon-button:focus i {
     transform: none;
   }
   .tile a:focus {
     box-shadow: none;
   }
   .tile a:after {
     content: "";
     display: block;
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
   }

   .tile-header {
     display: flex;
     align-items: center;
   }
   .tile-header i {
     font-size: 2.5em;
   }
   .tile-header h3 {
     display: flex;
     flex-direction: column;
     line-height: 1.375;
     margin-left: 0.5rem;
   }
   .tile-header h3 span:first-child {
     font-weight: 600;
   }
   .tile-header h3 span:last-child {
     font-size: 0.825em;
     font-weight: 200;
   }
   .tile-small {
        min-height: 30px !important;
   }
   .tile_div{
        background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
        border-radius: 8px;
   }
   .tile_div:hover {
        transform: translateY(-5px);
   }
   }

 .gasless-input-container {
     color:#f8f2ff;
     font-size: 16px;
     width: 100%;
     border: 1px solid #464646;
     background:#2e2e2e ;
     border-radius: 8px;
     padding: 10px;
     &::placeholder {
       color:gray;
     }

   }
 .td-text{
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  font-family: 'Poppins';

 }
 .td-text1{
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  line-height: 35px;
  text-align: left;
  padding-left: 20px;
  font-family: 'Poppins';

 }
 .td-text2{
  color: gray;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  padding-left: 20px;
  font-family: 'Poppins';

 }
.gasless-body td{
padding:20px;
text-align: start;
width: 20%;
}
.gasless-form{
padding:20px;
// background:linear-gradient(90deg, #4a464b -4.52%, #2f475e 120.27%);
background-color: #333333;
border-radius:15px;
}


//-----------------gasless model form --------------------//
/* .ant-modal {
  margin-left: auto !important;
  margin-right: 40% !important;
  .ant-modal-content {
    width: 800px !important;
  }
} */

//table corrections

.gasless-table{

  /* Target the specific columns by their index */
  .ant-table-content .ant-table-thead th:nth-child(4),
  .ant-table-content .ant-table-thead th:nth-child(3) {
    // width: 150px !important;
  }

}


.gassless-dropdown-filter {
         color: rgba(183, 183, 183, 0.65);
         width: 300px;
         padding: 8px;
         height: 50px;
         border-color: #464646;
         border-radius: 10px;
         outline: none;
         margin-right: 10px;
         font-size: 17px;
         font-weight: 400;
         background: #2e2e2e;
         &::placeholder {
                color: rgba(183, 183, 183, 0.65);
                padding:10px;
              }
     }

.gasless-filter {
    display: flex;
    align-items: center;
}

.actions-container {
    display: flex;
    align-items: center;
}

.search-container-gasless {
    position: relative;
    display: flex;
    align-items: center;
    transition: width 0.3s ease;
}

.search-expand-icon {
    cursor: pointer;
    width: 41px;
    height: 41px;
    margin-right: 10px;
    transition: opacity 0.3s ease;
}

.search-container.expanded .search-icon {
    display: none;
}

.search-box-wrapper {
    display: flex;
    align-items: center;
}
.search-container-gasless.expanded .search-expand-icon {
    display: none;
}

.close-search-btn {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
}

.createBtn3 {
    width: 220px;
    height: 49px;
    border-radius: 20px;
    background: white;
     font-family: Poppins !important;
     font-weight: 600;
     font-size: 20px;
     line-height: 35px;
     text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    color: transparent; /* Hide the original text */
    border: none;
}

.createBtn3::before {
    content: "Whitelist Contract";
    background: linear-gradient(90deg, #F81F49 0%, #A219BC 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
}



@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;