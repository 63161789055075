@import "../../styleConstant.less";

.data-section1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
.project-info-container {
  /* .gradient-border(); */
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 1.76599px 27.3729px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: auto;
 /*  margin-bottom:10px; */
  background: #222222;

  .api-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #cdcdcd;
    padding-bottom: 20px;
  }
  .account-label {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: #cdcdcd;
      padding-bottom: 20px;
  }

  .apiKey-section {
    background: #242424;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 3.49493px;
    margin-bottom: 30px;
    z-index: 1;

    .ant-input-affix-wrapper {
      background: #242424;
      padding: 0px;
      padding-right: 10px;
      box-sizing: border-box;
      border: none;
      cursor: default;
    }

    .ant-input {
      color: #adadad;
      background-color: transparent;
    }

    .anticon {
      color: #cdcdcd;
      &:hover {
        color: #cdcdcd;
      }
    }
    svg {
      cursor: pointer;
    }
  }

  .reset-btn-container {
    margin-bottom: 50px;
    .reset-btn {
      font-size: 14px;
      line-height: 17px;
      color: #c5c5c5;
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
      background-color: transparent;
      .gradient-border();
      outline: none;
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
  }

  .create-project-btn {
    cursor: pointer;
    width: 150px;
    height: 40px;
    background-color: transparent;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    border: 1.66px solid #464646;
    outline: none;
  }

  .tab-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .tab {
      background-color: transparent;
      border: none;
      font-style: normal;
      font-family: 'Poppins' ;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      display: flex;
      align-items: center;
      color: rgba(205, 205, 205, 0.5);
      padding-bottom: 10px;
      margin-right:32px;
    }

    .tab.active-tab {
      color: #efefef;
        border-bottom: 3px solid #095FDB;
        background: #181818;
        padding: 12px 50px 12px 30px;
        border-top-right-radius: 8px;
        margin-top: -24px;
        border-top-left-radius: 8px;
        margin-left: -20px;
    }
  }

  .tab-section {
    .tab-item {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      line-height: 27px;
      color: #cdcdcd;
    }
  }

  .carousel-items {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    .hide {
      visibility: hidden;
    }

    .prev-btn,
    .next-btn {
      width: 30px;
      height: 30px;

      svg {
        fill: #e0e0e0;
        cursor: pointer;
      }
    }

    .prev-btn.disabled,
    .next-btn.disabled {
      opacity: 0.5;
      visibility: hidden;
      svg {
        cursor: not-allowed;
      }
    }

    .content-section {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 0px 10px;
      width: calc(100% - 60px);
      overflow: hidden;

      .item-section {
        padding-right: 5px;
        text-align: center;
        min-width: calc(100% / 3);
        box-sizing: border-box;

        .column {
          display: inline-flex;
          align-items: center;

          .item-text {
            padding-left: 5px;
            font-size: 14px;
            line-height: 17px;
            color: red;
          }
        }
      }
      .hide {
        display: none;
      }
    }
  }
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 5px !important;
}

.tooltip {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .tooltip-text {
    padding-right: 5px;
  }
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: transparent !important;
}
 .text-containerrs {
    font-weight: 500;
    font-size: 14px;
    color: #e3e3e3;
    padding-top: 10px;
  }

 .project-column-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    max-width: 100%;
    //grid-gap: 30px;
    //grid-template-columns: 650px calc(100% - 680px);

    .project-info {
      width: 50%;
      .info-column-container{
        max-width: 100%;
        display: flex;
        z-index:1;
        flex-direction: row;
        margin-top: 20px;
        margin-left: 30px;
         //gap: 10px;
         .info-col{
          width: 50%;
         }
      }
    }
  }

  .endpoint-column-container{
    max-width: 95%;
    display: flex;
    flex-direction: row;
    overflow:auto;
      .endpoint-col{
         white-space: nowrap;
         width: 20%;
         padding: 8px;
      }
      .endpoint-col-url{
               white-space: nowrap;
               width: 35%;
               padding: 8px;
            }
      .endpoint-copy-col{
               width: 5%;
               margin-left:30px;
               padding: 8px;
               margin-top: 20px;
            }
      .endpoint-text{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #cdcdcd;
        padding-bottom: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        }
        .endpoint-sub-text{
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #cdcdcd;
                padding-bottom: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-top: -16px;
                }
   }
.link-btn {
   cursor: pointer;
   color: white;
   width: fit-content;
   height: 60px;
   background-color: transparent;
   border-radius: 8px;
   font-size: 14px;
   font-weight: 600;
   border: 1.66px solid #464646;
   outline: none;
   padding:20px;
   text-align: center;
    .gradient-border();
  }

 .proj-footer-container{
 margin-top: 30px;
 .project-footer-container{
         max-width: 100%;
         display: flex;
         z-index:1;
         flex-direction: row;
          //gap: 10px;
          .project-footer-col{
           width: 25%;
          }
  }
 }

 .service-tab-container {
   .gradient-border();
   display: flex;
   flex-direction: column;
   padding: 20px;
   box-sizing: border-box;
   box-shadow: 0px 1.76599px 27.3729px rgba(0, 0, 0, 0.08);
   border-radius: 8px;
   height: auto;
   margin-bottom:10px;

  .service-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #cdcdcd;
    /* padding-bottom: 20px; */
  }
  .service-column-container{
          max-width: 100%;
          /* display: flex; */
          z-index:1;
          flex-direction: row;
          /* margin-top: 50px; */
          /* margin-left: 30px; */
           //gap: 10px;
           .service-col{
            width: 50%;
           }
        }
 }
 .service-not-available-label {
     font-weight: 600;
     font-size: 20px;
     line-height: 20px;
     color: #cdcdcd;
     padding-bottom: 20px;
     text-align: center;
     margin-top: 50px;
   }


   // New design

   .icon-button {
     width: 32px;
     height: 32px;
     border-radius: 50%;
     border: 0;
     background-color: transparent;
     border: 1px solid #969593;
     color: #ffffff;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     transition: 0.25s ease;
     flex-shrink: 0;
   }
   .icon-button.large {
     width: 42px;
     height: 42px;
     font-size: 1.25em;
   }
   .icon-button i {
     transition: 0.25s ease;
   }
   .icon-button span{
    color: #000 !important;
   }
   .icon-button:hover, .icon-button:focus {
     background-color: #313131;
     box-shadow: 0 0 0 4px #1f1f1f, 0 0 0 5px #969593;
   }

   .tiles {
     display: grid;
       grid-template-columns: repeat(2, 1fr);
       column-gap: 2rem;
       row-gap: 1rem;
       margin-top: 0.25rem;

       .header-3{
        padding-bottom: 14px;
          padding-top: 20px;
       }
       .doc-div{
        width: 70%;
       }
   }
   @media (max-width: 700px) {
     .tiles {
       grid-template-columns: repeat(1, 1fr);
     }
   }

   .tile {
     padding: 1rem;
       border-radius: 8px;

       color: #fff;
       opacity: 0.8;
       height: 185px;
       min-width: 450px;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       position: relative;
       transition: 0.25s ease;
       text-align: center;
         align-items: center;
background: #222222;
   border: 1px solid #303030;
   &:hover {
                border: 3px solid #095FDB !important;
            }
             img{
                        width: 100px;
                        height: auto;
                        bottom: 0;
                        position: absolute;
                     }

            a{
                display: contents
            }
   }
   .tile:hover {
     transform: translateY(-5px);
   }
   .tile-inner{
   background: #222222;
   border: 1px solid #303030;
   &:hover {
                border: 1px solid #095FDB !important;
            }
             img{
                        width: 100px;
                        height: auto;
                        bottom: 0;
                        position: absolute;
                     }
   }

  /*  .tile:focus-within {
     box-shadow: 0 0 0 2px #1f1f1f, 0 0 0 4px #e3ffa8;
   } */
   /* .tile:nth-child(2) {
     background-color: #45ffbc;
   }
   .tile:nth-child(2):focus-within {
     box-shadow: 0 0 0 2px #1f1f1f, 0 0 0 4px #45ffbc;
   } */
   /* .tile:nth-child(3) {
     background-color: #bdbbb7;
   }
   .tile:nth-child(3):focus-within {
     box-shadow: 0 0 0 2px #1f1f1f, 0 0 0 4px #bdbbb7;
   } */
   .tile-inner a {
    display: contents;
   }
   /* .tile a {
     text-decoration: none;
     display: flex;
     align-items: center;
     justify-content: space-between;
     font-weight: 600;
   }
   .tile a .icon-button {
     color: inherit;
     border-color: inherit;
   }
   .tile a span svg,img {
        height: 35px;
   } */

   /* .tile a .icon-button:hover, .tile a .icon-button:focus {
     background-color: transparent;
   }
   .tile a .icon-button:hover i, .tile a .icon-button:focus i {
     transform: none;
   }
   .tile a:focus {
     box-shadow: none;
   }
   .tile a:after {
     content: "";
     display: block;
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
   } */

   .tile-header {
     display: flex;
     align-items: center;
   }
   .tile-header i {
     font-size: 2.5em;
   }
   .tile-header h3 {
     display: flex;
     flex-direction: column;
     line-height: 1.375;
     margin-left: 0.5rem;
   }
   .tile-header h3 span:first-child {
     font-weight: 600;
   }
   .tile-header h3 span:last-child {
     font-size: 0.825em;
     font-weight: 200;
   }
   .tile-small {
        min-height: 30px !important;

   }
   .tile_div{
        background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
        border-radius: 8px;
   }
   .tile_div:hover {
        transform: translateY(-5px);
   }

.project-column-container1 .project-info {
  width: 50%;
}

.project-info-container1 {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 1.76599px 27.3729px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 250px;
  margin-bottom: 10px;
  background-color: #1f262c;
                   padding: 30px;
                   border-radius: 6px;
                   color: #fff;
}
.project-info-container1 .btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-info-container1 .btn-container .view-details {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: white;
  width: calc((100% - 10px) / 2);
  background-color: black;
  border: none;
  position: relative;
  outline: none;
  height: 39px;
}

.filter_button {
  grid-column-gap: .25rem;
  grid-row-gap: .25rem;
  color: #0057da;
  margin-top: auto;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 700;
  display: flex;

  div {
    font-family: 'Poppins' ;
    font-size: 1rem;
    font-weight: 700;
  }
  div span svg {
    font-size: 14px;
    margin-top: -4px;
    color: #0057da;
  }
}
.w-color-blue{
    color: #0057da;
}
.w-color-white{
    color: #fff;
}
.w-color-black{
    color: #000;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.static_div{
background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
border-radius: 8px;
}
.static-element_card {
  width: 100%;
  min-height: 24rem;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.25), rgba(151, 151, 151, 0.75), 70%, rgba(255, 255, 255, 0.25)) !important;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px) !important;
  border-radius: 8px;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-content: center;
  justify-content: center;
  /* align-items: flex-start; */
  justify-items: start;
  padding: 2rem 5.5rem 2rem 2rem;
  display: flex;

  .cms-filters_static-cta-text {
   font-family: 'Poppins' ;
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-right: auto;
    align-items: center;
    text-align: center;
    color: #e8fff6;

    text-align: left;
  }
  a div span svg {
      color: #000;
    }

}
.div-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.gradient-button-info {
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #090a2c;
  width: 35%;
  height: 47px;
  box-sizing: border-box;
  margin-left: 5px;
  color: white;
  /* background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%); */
  background: #000;
  border-radius: 6px;
  border: 0.5px solid black;
  margin-bottom: 60px;
}
.f-40 {
    font-family: 'Poppins' ;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 40px !important;
        line-height: 60px !important;
}
.wh-100-100{
    width: 100px !important;
    height: 100px !important;
    font-size: 40px !important;
}
.ncbutton-container {
  display: flex;
  justify-content: space-between;
}

.ncbutton {
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      border-radius:8px;
      color: white;
      background-color: black;
      .gradient-border();
      outline: none;
      height: 30px;
      cursor: pointer;
}

.left-ncbuttons {
  display: flex;
   gap:10px;
}

.right-ncbutton {
  margin-left: auto;
}
.nc-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    width: 254px;
    height: 47px;
    box-sizing: border-box;
    background: linear-gradient(90deg, #B447EB 0%, #2797FF 100%);
    &:focus {
      outline: none;
    }
  }
.nc-button1 {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    background: linear-gradient(90deg, #B447EB 0%, #2797FF 100%);
    &:focus {
      outline: none;
    }
  }

.export-popup-text p{
      font-size: 16px;
      font-weight: 400;
}
.ncwallet_table td{
width:20%;
text-align:start;
}
.ncwallet_table b{
font-size:18px;
font-weight:600;
}

 .service-create-Endpoint-btn {
    background: black !important;
    box-shadow: 0px 2px 14px rgba(167, 167, 167, 0.25);
    border-radius: 6px;
    font-family: @font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    width: auto;
    height: 40px;

  }
   .service-create-submit {
      background: transparent;
      box-shadow: 0px 2px 14px rgba(167, 167, 167, 0.25);
      border-radius: 6px;
      font-family: @font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: white;
      width: auto;
      height: 40px;
      .gradient-border();

    }
    .service-submit{
     display: flex;
         flex-direction: row;
         flex-wrap: nowrap;
         align-content: center;
         justify-content: center;
         align-items: center;
    }
.enable-service{
    font-weight: 550;
        font-size: 20px;
        color: #cdcdcd;
   border:1px solid #b447eb;
   width:35%;
   padding:5px;
   margin:auto;
   gap:10px;
   display: flex;
       flex-wrap: nowrap;
       justify-content: center;
       align-content: center;
       align-items: center;
}

.service1-text {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #289bc7;
    display: contents;
}
.disable-btn {
  cursor: not-allowed;
}
.ant-switch{
background-color:rgb(209 233 230 / 25%) !important;
}
.ant-switch-checked {
    background-color: #5496a5 !important;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
}
.manage-details {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: white;
  width: calc((100% - 10px) / 2);
  background-color: black;
  border: none;
  position: relative;
  outline: none;
  cursor:pointer;
  .gradient-border()
}
.manage-details-notallowed {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: white;
  width: calc((100% - 10px) / 2);
  border: none;
  position: relative;
  outline: none;
  border-radius:5px;
  cursor:not-allowed !important;
}
  .warning-content {
              font-weight: normal;
              font-size: 12px !important;
              font-style: italic;
              color:#faad14;

            }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;