@import "../../../styleConstant.less";

.transaction-page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .transaction-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-section {
      display: inline-flex;
      align-items: center !important;
      flex-direction:row !important;

      .text {
        font-weight: 600;
        font-size: 16px !important;
        line-height: 20px;
        color: #e0e0e0;
        padding-right: 20px;
      }

    }

  }
   .warning-content {
              font-weight: normal;
              font-size: 12px !important;
              font-style: italic;
              color:#faad14;

            }
      .search-box-container {
                  .input-box {
                    width: 248px;
                  }
              .input-box::placeholder {
                text-indent: 20px;
              }
        }
}



@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;