/* styles.css */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 64px;
}

.loader div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #A076F9;
  animation: loaderAnimation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loaderAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.1);
  }
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;