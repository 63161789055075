@import "../../../styleConstant.less";

.transaction-page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .transaction-search-section{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .filter-container{
        gap:10px;
      }

      .ant-picker-range .ant-picker-input > input {
        color: white;
      }
      .ant-picker-panel-container{
        background:black !important;
      }

      .ant-picker-range{
         width: 260px;
         height: 42px;
         border: 1px solid rgba(255, 255, 255, 0.3);
         border-radius: 8px;
         background-color: transparent;
         color: #fff;
      }

    .search-box-container {
              position: relative;
              display: inline-flex;
              align-items: center;
              color: #fff;

                  .anticon {
                       position: absolute;
                       left: 230px;
                       color: #d9d9d9;
                       font-size: 16px;
                       cursor:pointer;
                     }
              .input-box {
                width: 260px;
                height: 42px;
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-radius: 8px;
                background-color: transparent;
                color: #fff;
                padding-left: 10px;
              }
    }

  }

  .url-container {
    display: inline-flex;
    align-items: center;
    gap:20px;
  }

.url-container-btn1 {
  background-color:#0b0b0b;
  font-family: 'Poppins';
    cursor:pointer;
        width: 191px;
        height: 52px;
        border-radius: 15px 15px 15px 15px;
        border: 1px 0px 0px 0px;
       // opacity: 0px;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        font-size: 15px;
        font-weight: 400;
  }

  .url-container-btn2 {
      background-color:#FFFFFF;
      cursor:pointer;
      font-family: 'Poppins';
          width: 191px;
          height: 52px;
          border-radius: 15px 15px 15px 15px;
          border: 1px 0px 0px 0px;
          //opacity: 0px;
          color: #4C59E3;
          border: 1px solid #FFFFFF;
          font-size: 18px;
          font-weight: 400;
    }

  .dropdown-filter {
    background-color:transparent;
    cursor:pointer;
        width: 191px;
        height: 52px;
        border-radius: 15px 15px 15px 15px;
        border: 1px 0px 0px 0px;
        //opacity: 0px;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        font-size: 14px;
        font-weight: 400;
  }

  .transaction-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 71%;

      .text {
        font-weight: 400;
        font-size: 24px !important;
        line-height: 35px;
        color: #ffffff;
        padding-right: 20px;
      }
    }

    .dbInfo{
      margin-top:20px;
      .text{
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 18px;
     }
   }
  }

  .notice-board{
    color:#e0e0e0;
    padding-left:5px;
    font-size: 12px;
  }

  .setup-condition-btn{
    display: flex;
    justify-content: space-around;
  }


}

// popup css
.custom-modal custom-modal{
    background-color:red;
    border-bottom: none;
    border-radius:10px
}

.custom-modal .ant-modal-title {
    color: white;

}

// block details wrapeer
.block-details-wrapper{
    width: 100%;
    maxHeight: 60vh;
    overflow: auto ;

}

.refresh-collections {
  width: 131px;
  height: 47px;
  background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
  border: 1px solid #403f3f;
  border-radius: 7.94305px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #c4c4c4;
  cursor: pointer;
  margin-right: 25px;
}
.support-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    color: black;
    background: white;
    border-radius: 18px;
    border: 0.5px solid black;
  }
.gradient-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();
    &:focus {
      outline: none;
    }
  }
  .gradient-button1 {
    border-radius: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #4C59E3;
    width: 150px;
    margin-right: 20px;
    height: 52px;
    border: none;
    box-sizing: border-box;
    // .button-background();
    // &:focus {
    //   outline: none;
    // }
  }

.view-details1 {

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: white;
  width: calc((100% - 10px) / 2);
  background-color: black;
  .gradient-border();
  outline: none;
  height: 39px;
  cursor: pointer;
}

//   Table css

.key-value-display td {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 15px;
}

// block details ---- new

h2 {
 font-size: 18px !important;
  margin-top: 0px !important;
  text-align: left !important;
  color: #e3e3e3 !important;

}

.ant-input[disabled] {
    color: white !important;
}



//------------------------------------------------
/* styles.css (or styles.less, as mentioned in the component) */



table {
    border: none !important;
}

/* BlockDetails component container */
.block-details {
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #1c1b1c;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #fff;
}

/* BlockDetails component title */
.block-details h2 {
  margin-bottom: 20px;
}

/* Block section table */
.block-section table {
  width: 100%;
  border-collapse: collapse;
}

/* Block section table cells */
.block-section td {
  padding: 10px;
  border: 1px solid #444;
  transition: background-color 0.3s ease;
  font-size: 14px !important;
}

/* Block section table header */
.block-section th {
  padding: 10px;
  background-color: #111;
  border: 1px solid #444;
  text-align: left;
}

/* Styling for label cell */
.block-section td.label {
  font-weight: bold;
  color: #e3e3e3 !important;
}

/* Styling for key-value pairs */
.block-section td:not(.label) {
  color: #ccc !important;
}

/* Block section table rows alternate background */
.block-section tbody tr:nth-child(even) {
  background-color: #222;
}
.datalake-input-container {
    color: #bfbfbf;
    font-size: 16px;
    width: 550px !important;
//     max-width: 550px !important;
    border: 1px;
    border-style: solid;
    border-color: #464646;
    background: #2e2e2e;
    border-radius: 12px;
    padding: 5px;
    font-weight: 400;
//     margin-bottom: 30px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
    height: 50px;
    margin-bottom: 20px;
    padding-left: 10px;
  }
.datalake-popup-header{
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    margin-bottom: 20px;
 }
 .datalake-save-button-container{
         padding-top: 20px;
         display: flex;
         justify-content: center;
         padding-bottom: 30px;
     }
 .datalake-save-button{
     background: #fff !important;
 }
 .datalake-save-button-text{
     color: #4C59E3 !important;
     }
 .datalake-url-input-container{
      color: #bfbfbf;
         font-size: 16px;
         width: 350px !important;
         border: 1px;
         border-style: solid;
         border-color: #464646;
         background: #2e2e2e;
         border-radius: 15px;
         padding: 5px;
         font-weight: 400;
         &::placeholder {
           color: rgba(183, 183, 183, 0.65);
         }
         height: 55px;
         padding-left: 10px;
     }
 .datalake-url-validate-button{
    height: 35px;
    width: 90px;
    position: absolute;
    right: 5px;
    top: 18%;
    background: white;
     border-radius:20px;
     }
 .datalake-url-validate-disabled-button{
     height: 35px;
     width: 90px;
     position: absolute;
     right: 5px;
     top: 18%;
     background: gray;
     border-radius:20px;
      }
  .validate-text{
      fontSize: 12px;
      font-weight:700;
      background: linear-gradient(90.07deg, #AE1AAB 0.06%, #480B47 44.97%);
      color: white;
       background-clip: text;
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
  }

  .ant-input[disabled] {
      color: white !important;
      border-color: #2e2e2e;
  }

  .ant-input[disabled]:hover {
      border-color: #3c548c;
      border-right-width: 1px;

  }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;