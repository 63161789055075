@import "../../styleConstant.less";

.project-admin-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .project-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-text {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #e0e0e0;
    }
  }
}

//column-add-project-container
.column-add-project-container {
  font-family: @font-family;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .label-text {
    font-size: 20px;
    color: @color-d5;
    letter-spacing: @letter-space;
    display: flex;
    align-items: center;
    text-align: left;

    .anticon {
      margin-left: 15px;
    }
  }

  .input-container {
    color: @color-d5;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-radius: 6px;
    border-color: #464646;
    background: #02020f;
    padding: 10px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }
}

.popup-request-container {
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: black;
    // background-image: url("../../assets/icons");
    border-color: #233c80;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(240, 240, 240, 0.35);
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(240, 240, 240, 0.35);
  }
}
.icon-wrapper {
  .ant-slider-handle {
    background-color: #000000;
    margin-top: 0px;
    width: 25px;
    height: 25px;
    top: -2px;
  }
  .ant-slider-rail {
    border-radius: 12px;
    height: 10px;
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-slider-track {
    border-radius: 12px;
    height: 10px;
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
  }
  .ant-slider-dot {
    display: none;
  }
  .desc-text {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: rgba(240, 240, 240, 0.35);
  }
}

.table_Link{
  color: #e3e3e3;
}
.table_Link:hover{
  font-weight: bold;
  text-decoration: underline;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;