


/*.Assign-Project::placeholder {*/
/*    font-family: 'Poppins', sans-serif;*/
/*    font-size: 18px;*/
/*    font-weight: 400;*/
/*    line-height: 35px;*/
/*    text-align: left;*/
/*    font-color: #FFFFFF;*/
/*    caret-color: red;*/
/*    text-indent: 10px;*/
/*}*/
/*.assign-project:focus {*/
/*    outline: none; !* Remove the outline border *!*/
/*    !* Increase the font size when input is focused *!*/
/*}*/

/*.assign-project::selection {*/
/*    background-color: #4169E1; !* Change the background color of the selected text *!*/
/*    !* Change the text color of the selected text *!*/
/*}*/

/*.assign-project::-moz-selection {*/
/*    background: transparent; !* Change the background color of the selected text (for Firefox) *!*/
/*    color: #ffffff; !* Change the text color of the selected text (for Firefox) *!*/
/*}*/
/*!*.assign-button {*!*/
/*!*    margin-top: 70px;*!*/
/*!*    margin-left: 150px;*!*/



/*!*}*!*/
/*!*.assign-button {*!*/
/*!*    display: flex;*!*/
/*!*    align-items: center;*!*/
/*!*    justify-content: center;*!*/
/*!*    gap: 8px;*!*/
/*!*    margin-top: 70px;*!*/
/*!*    margin-left: 150px;*!*/

/*!*}*!*/

/*!*.spinner {*!*/
/*!*    margin-left: auto; !* This will push the spinner to the right *!*!*/
/*    .assign-button {*/
/*        !*display: flex;*!*/
/*        !*align-items: center;*!*/
/*        !*justify-content: center;*!*/
/*        !*gap: 8px;*!*/
/*        !*position: relative; !* Add position relative *!*!*/
/*        margin-top: 70px;*/
/*        margin-left: 150px;*/
/*    }*/

/*    !*.spinner {*!*/
/*    !*    !*position: absolute; !* Add position absolute *!*!*!*/
/*    !*    right: 8px; !* Adjust as needed *!*!*/
/*    !*}*!*/

/*!*}*!*/


/*!*.ant-modal-body button {*!*/
/*!*    font-family: 'Poppins';*!*/
/*!*    text-align: left;*!*/
/*!*    padding-left: 15px;*!*/
/*!*    padding-bottom: 5px;*!*/
/*!*    font-size: 16px;*!*/
/*!*    font-weight: 700;*!*/
/*!*    background: #A219BC;*!*/
/*!*    margin-top: 70px;*!*/
/*!*    margin-left: 150px;*!*/
/*!*}*!*/
.assign-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 70px;
    margin-left: 150px;
    background-color: #4169E1; /* Set the background color */
    border-color: #4169E1; /* Set the border color */
    color: #ffffff; /* Set the text color */
}

.assign-button:hover,
.assign-button:focus,
.assign-button:active {
    background-color: #4169E1; /* Set the background color on hover, focus, and active states */
    border-color: #4169E1; /* Set the border color on hover, focus, and active states */
    color: #ffffff; /* Set the text color on hover, focus, and active states */
}
