.element {
   
    /* padding-top: 7%; */
  padding-left: 12%;
   padding-right: 12%;
  background: url('../../assets/icons/Group811548.svg');
  background-attachment: fixed;
  background-size: cover;
     /* background-color: #4d0c5a; */

     /* background-size: 100% auto; */

   /* background-repeat: no-repeat !important; */
  }
  .mobileview-infra{
    display: none;
  }

  /* .element1{
    display: flex;
    height: 12rem;
    align-items: center;
    justify-content: center;
  
  } */
  /* .element2{
    font-weight: 600; 

  text-transform: uppercase; 

  color: #6B7280; 
  } */
  .element3{
    position: relative;
  height: 300vh;
  display: flex;
  width: 100%;
  background-color: transparent;
  }
  .element4{
    position: sticky;
  top: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  overflow: hidden;
  }
  .element5{
    display: flex;
    flex-direction: column;
    gap:500px;
    /* overflow-y: scroll; */
  }
  .element6{
    position: relative;
  height: 450px;
  width: 800px;
  /* overflow: hidden; */
  /* background-color: #E5E7EB;  */
  }

  .element7 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    transition-property: transform;
    transition-duration: 300ms;
  }
  
  .element7:hover {
    transform: scale(1.1);
  }

  .element8 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* z-index: 10; */
    display: grid;
    place-content: center;
  }

  .element9{
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  padding: 2rem;
  position: relative;
  font-size: 4rem;
  font-family: 'Poppins';
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  backdrop-filter: blur(8px);
  }
  


  .light-theme-scroll-section{
    box-sizing: border-box;
    display: block;
  }
  .light-theme-scroll-inner1{
    box-sizing: border-box;
    position: sticky;
      top: 0;
      height: auto;
      background-color: #9cf !important;
      display: flex;
  }
  .light-theme-left-scroll1{
    width: 40%;
    /* padding-left: 12%; */
    background: url('../../assets/icons/Group811548.svg');
  background-attachment: fixed;
     /* background-color: #4d0c5a; */

     /* background-size: 100% auto; */

   /* background-repeat: no-repeat !important; */
       background-color: black;
      /* border-radius: 0 60px 60px 0;
     box-shadow: 0 2px 71.44px 4.56px rgba(0,0,0,0.14); */
      display: flex;
      justify-content: center;
      bottom: 0;
      height: 100vh;
      flex-direction: column;
      gap:40px;
      align-items: start;
      /* padding-left: 150px; */
      position: absolute;
      z-index: 1;
      background-size: cover;
  }
  .light-theme-image-box1 {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    transform: translateX(100%);
     position: absolute;
    transition: .5s;
    margin-bottom: 100px;
    /* padding: 120px; */
  }
  .light-theme-right-scroll1{
    width: 65%;
      display: flex;
      flex-direction: column;
       gap:40px;
      position: relative;
       overflow: scroll;
      padding: 80px 0px;
  }
  .light-theme-left-scroll-inner{
    max-width: 500px;
      width: 100%;
  }
  .light-theme-right-side-header-tabs1{
    font-family: 'Poppins';
    font-size: 38px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    color: white;
    cursor: pointer;
    opacity: 50%;
    margin-bottom: 0px;
  }
  .light-theme-right-side-header-tabs-active1{
    /* border: 0.5px solid #4c59e3; */
    border-radius: 20px;
    padding: 15px 200px 15px 30px;
    opacity: 100%;
    background: linear-gradient(90deg,#A0A0A066 40%, #73737370 70%);

  }
  .light-theme-right-side-header-tabs-active2{
    position: relative;
    display: inline-block;
    /* margin-bottom: 40px; */
  }
  .light-theme-right-side-header-tabs-active2 ::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 1px; /* control the border thickness */
    background: linear-gradient(to right, #4c59e3, #ae1aab, #ff7070);
    background-color: linear-gradient(90deg, rgba(160, 160, 160, 0.4) 0%, rgba(115, 115, 115, 0.44) 100%);
  
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    pointer-events: none;
  }
  
  
  
  .light-theme-right-para{
    font-family: 'Poppins';
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      text-align: left;
      color: white;
    margin-left: 30px;
  
  }

  @media (max-width:1540px){
    .light-theme-right-side-header-tabs1{
      font-size: 28px;
      line-height: 48px;
    }
    .element6{
      position: relative;
    height: 450px;
    width: 600px;
    /* overflow: hidden; */
    /* background-color: #E5E7EB;  */
    }
    .light-theme-right-para{
        font-size: 16px;
        line-height: 30px;
    }
  }
  
  @media (max-width:1440px){
    .element {
    /* padding-left: 80px; */
     padding-right: 40px;
  
    }
    .light-theme-left-scroll1{
      padding-left: 40px;
      
    }
    .element6 {
      position: relative;
      height: 450px;
      width: 600px;
      /* overflow: hidden; */
      /* background-color: #E5E7EB; */
  }
  }
  @media (max-width: 767px) {
    .light-theme-left-scroll1{
      display: none;
    }
    .element4{
      position: sticky;
    top: 0;
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    }
    .element3{
      display: none;
    }
  }  

  @media (max-width: 1200px) {
    .element{
      padding-right: 20px;
      padding-left: 20px;
    }
    .element3{
      display: none;
    }
    .mobileview-infra{
      display: flex;
      flex-direction: column;
      padding-top: 50px;
    }
    .light-theme-image-box1 {
      width: 100%;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      transform:none;
       position: static;
      transition: .5s;
      margin-bottom: 100px;
      /* padding: 120px; */
    }
    .mobileview-infra-header{
      font-family: 'Poppins';
      color: white !important;
      font-size: 32px !important;
      font-weight: 600 !important;
      line-height: 50px !important;
      text-align: center !important;

    }
    .light-theme-right-para{
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 20px;
  
  }
  .light-theme-infrastructure-img{
    height: 280px;
    width: 250px;
  }
  }