@import "../../styleConstant.less";

.apexcharts-xaxis-label {
  fill: #fff !important;
}

.apexcharts-tooltip,
.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  background: #1e1e1e !important;
  color: #dcdcdc !important;
  border: none !important;
  margin-bottom: 0 !important;
  font-family: @font-family !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color: #dcdcdc !important;
}

.apexcharts-tooltip-series-group {
  padding: 0px 5px !important;
}

.apexcharts-tooltip-marker {
  margin-right: 5px !important;
}

.apexcharts-legend-text {
  font-family: @font-family !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #d6d6d6 !important;
}

.chart-hoc-container {
  .gradient-border();
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;


  .select-box-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    .ant-select {
      font-size: 12px;
      color: #ffffff;

      .ant-select-selector {
        border: 0.882995px solid #9f9f9f;
        border-radius: 7.06396px;
        background-color: transparent;
      }
    }

    .ant-select-arrow {
      font-size: 12px;
      color: #ffffff;
    }
  }
}

.container-text {
  display: inline-flex;
  align-items: center;

  .text {
    font-weight: 600;
    font-size: 20px;
    color: #d9d9d9;
    padding-right: 10px;
  }
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;