@import "../../styleConstant.less";

.table_button_revoke{
    border:1px solid #9933ff;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius:20px
}
.project-page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .project-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-text {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #e0e0e0;
    }
  }

  .data-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}
.view-details1 {
  
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: white;
    width: calc((100% - 10px) / 2);
    background-color: black;
    .gradient-border();
    outline: none;
    height: 39px;
    cursor: pointer;
  }
  .view-details1-did {

      padding-left: 10px;
      padding-right: 10px;
      padding-top: 3px;
      padding-bottom: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      color: white;
      width: calc((100% - 10px) / 2);
      background-color: black;
      .gradient-border();
      outline: none;
      height: 39px;
      cursor: pointer;
    }

  .text-right{
  float: right;
  padding-bottom: 3px;
  }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;