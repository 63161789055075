.modal-title{
 font-size: 24px;
 font-weight: 700;
 text-align: center;
 padding-bottom:30px;
 color: white;
}
.create-dapp-container{
//     display: flex;
//     margin: 20px 30px 20px 70px;

        margin: -15px -8px 0px 16px;
}
.input-container {
    color: #bfbfbf;
    font-size: 16px;
    width: 90% !important;
    max-width: 550px !important;
    border: 1px;
    border-style: solid;
    border-color: #464646;
    background: transparent;
    border-radius: 12px;
    padding: 5px;
    font-weight: 400px;
//     margin-bottom: 30px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }
.select-dropdown-filter {
     color: rgba(183, 183, 183, 0.65);
     background-color: #2e2e2e;
     font-size: 16px;
     width: 90%;
     max-width: 550px;
     border: 1.8px solid #716F6F;
     border-radius: 15px;
     margin-left: 0px;
//      margin-bottom: 30px;
     padding: 10px;
     font-weight: 400;
     height: 50px;
     &::placeholder {
       color: rgba(183, 183, 183, 0.65);
     }
}
.ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 700px;
    height: 32px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px !important;
    margin-top: 30px !important;
}
 .form-label{
     position: relative;
     display: inline-flex;
     align-items: center;
     max-width: 100%;
     height: 32px;
     color: #fff;
     font-size: 16px;
     font-weight: 400;
     margin-bottom: 20px;
     margin-top: 30px;
 }
 .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
     display: none;

}
.ant-form-item-label > label::after{
    display: none;

}
form-submit-button{
    width: 184px;
    height: 63px;
    border-radius: 20px;
    background: #4C59E3;
}


.rectangle-143 {
    width: 638px;
    height: 55px;
    position: relative;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    justify-content: center; /* Aligns items to the left and right edges */
    align-items: center;

}

.rectangle-146 {
    width: 175px;
    height: 55px;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, #A219BC 0%, #A219BC 72.57%);
    box-shadow: 129px 19px 52px rgba(0, 0, 0, 0.01), 73px 11px 44px rgba(0, 0, 0, 0.05), 32px 5px 33px rgba(0, 0, 0, 0.09), 8px 1px 18px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.text-inside-rectangle {
//     font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding-left: 20px;
    //margin-right: 100px;
//     line-height: 35px;
//     text-align: left;
}

.copy-icon {
    width: 22px;
    height: 22px;
    position: absolute;
    left: 592px;
    cursor: pointer;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;