

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.body-404 {
       height: 100vh;
       display: flex;
       justify-content: center;
       align-items: center;
       background: var(--background-Color);
       color: #e1e1e1;
       -webkit-font-smoothing: antialiased;
       overflow: hidden;
}

:root {
    --Text-Color: #ffffff;
    --background-Color: #080808;
    --square-color: #26c245;
}

/* ----- Background ----- */
.Background {
    position: absolute;
    width: 110%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    overflow: hidden;
    -webkit-animation: hue_rotate 60s linear both infinite;
    -o-animation: hue_rotate 60s linear both infinite;
    animation: hue_rotate 60s linear both infinite;
}

.Background .Square {
    width: 60px;
    height: 60px;
    border: rgb(20, 20, 20) 1px solid;
    transition: 2s linear;
}

.Background .Square:hover, .Background .Square.active {
    background-color: var(--square-color);
    border: var(--square-color) 1px solid;
    transition: 0ms linear;
}

/* ----- Glass Box ----- */
.glass-Box {
    width: 400px;
    height: 400px;
    padding: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.03em;
    text-align: center;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #222222;
    -webkit-backdrop-filter: blur(16px) saturate(100%);
    backdrop-filter: blur(16px) saturate(100%);
    box-shadow: 0 0 16px 0 rgba(100, 100, 100, 0.22);
    border: 1px rgba(255, 255, 255, 0.08) solid;
    border-radius: 10px;
    align-items: center;
}

.button {
    /* display: block;
    height: 2.75rem;
    line-height: 2.75rem;
    border: 2px rgb(60, 60, 60) solid;
    border-radius: 1rem;
    color: var(--Text-Color);
    opacity: 0.8;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.1rem;
    text-decoration: none;
    transition: 0.2s linear;
    -webkit-animation: hue_rotate 60s linear both infinite;
    -o-animation: hue_rotate 60s linear both infinite;
    animation: hue_rotate 60s linear both infinite; */

    border-radius: 8px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #090a2c;
            width: 100%;
            height: 47px;
            box-sizing: border-box;
            margin-left: 5px;
            color: white;
            background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
            border-radius: 6px;
            border: 0.5px solid black;
}

.button:hover {
    background-color: var(--square-color);
   /*  border: 1px var(--square-color) solid; */
    color: var(--background-Color);
}

.glass-Box h1 {
    font-size: 6rem;
}

.glass-Box p {
    font-size: 1.625rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

@keyframes hue_rotate {
    0% {
        filter: hue-rotate(360deg);
    }
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;