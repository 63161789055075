.ant-dropdown-menu{
 background: #454545;
 width: 270px;
 border-radius: 15px;
 margin-top: 10px;
}
.ant-dropdown-menu-item{
 font-size: 13px;
 font-weight: 700;
 color: #FFFFFF;
 margin: 10px 20px 10px 20px;
 padding-left: 20px;
 border-radius: 10px;
//  margin : 10px;
//  border-bottom: 1px solid #fff;
}
.ant-dropdown-menu-item:hover{
 background:#999999;
 border-radius: 10px;
}
.profile-text{
 font-size: 14px;
 font-weight: 400;
 line-height: 35px;
 text-align: left;
 color: #fff;
}
.profile-header{
 font-size: 24px;
 font-weight: 700;
 line-height: 35px;
 text-align: left;
 color: #fff;
}
.subscription-table{
 display: block;
 background: #000000;
 border-radius: 20px 20px 0px 0px;
 height: auto;
}
.subscription-table th {
  font-size: 18px;
   font-weight: 700;
   line-height: 35px;
   text-align: left;
   width: 100%;
}
.subscription-table th,
.subscription-table td {
  width: 600px;
  flex-grow: 1;
  text-align: center;
  padding: 10px;
}
.subscription-table th{
  border-bottom: 1.5px solid #ddd;
}
.purchase-credit-button{
  border-radius: 12px;
  opacity: 0px;
  border: 1px solid gray;
  color: black;
  background: #D9D9D9;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
}
.view-plan-popup-width .ant-modal-body{
    max-width: 500px;
    left: 35%;
    right: 30%;
    position: relative;
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;