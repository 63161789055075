.new-landing-page-ui{
  background-color: white!important;
  width: 100%;
  height: 100vh;
  /* background: url('./Icons/Header-Blur-bg.svg');
  background-position: center;
background-repeat: no-repeat;
background-size: cover; */
overflow-x: scroll;

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}


.animate {
-webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
}

/*=== Optional Delays, change values here  ===*/

@-webkit-keyframes fadeInUp1 {
from {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}
@keyframes fadeInUp1 {
from {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}

.fadeInUp1 {
-webkit-animation-name: fadeInUp;
animation-name: fadeInUp;
}


// .four {
//   -webkit-animation-delay: 0.1s;
//   -moz-animation-delay: 0.1s;
//   animation-delay: 0.1s;
// }


@-webkit-keyframes fadeInUp {
from {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}
@keyframes fadeInUp {
from {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}

.fadeInUp {
-webkit-animation-name: fadeInUp;
animation-name: fadeInUp;
}

/*=== FADE IN DOWN ===*/
.fadeInDown {
-webkit-animation-name: fadeInDown;
animation-name: fadeInDown;
}
/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
from {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}
@keyframes fadeInRight {
from {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}

.fadeInRight {
-webkit-animation-name: fadeInRight;
animation-name: fadeInRight;
}

@-webkit-keyframes fadeInLeft {
from {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}
@keyframes fadeInLeft {
from {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}

.fadeInLeft {
-webkit-animation-name: fadeInLeft;
animation-name: fadeInLeft;
}


.banner-bg{
      background: url('./Icons/Header-Blur-bg.svg');
      background-color: rgba(0, 0, 0, 0);
      background-position-x: 0%;
      background-position-y: 0%;
      background-repeat: repeat;
      background-size: auto;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.navbar-nav li div span{
  color: #121212;
  font-size:14px;
  cursor: pointer;
  font-weight: 500;
}
.text-line{
  font-size: 18px;
      border-left: 2px solid #99989b;
      color: #99989b !important;
}
}

.header-wrap {
background:transparent;
position: inherit;
z-index: 100;
top: 0;
 width: 100%;
}

.navbar {
padding: 16px 45px 16px 43px;
display: flex;
align-items: center;
justify-content: space-between;
}


.navbar-brand img.logo {
height: 29px; /* Adjust the height as needed */
width: 129px;
}



.navbar-toggler {
border: none;
background-color: transparent;
display:flex; /* Hide the toggler for screens wider than 768px */
}

.nav-item{
display: flex;
align-items: center;
}

.nav-item .nav-link{
cursor: pointer;
margin-left: 70px;
font-size:14px;
color: #121212;
cursor:pointer;
  font-weight: 500;
}
.nav-item .nav-link1{
cursor: pointer;
margin-left: 35px;
font-size:14px;
color: #121212;
cursor:pointer;
  font-weight: 500;
}
.navbar-nav .active-link {
color: #d988ddcc; /* Set color for the active link */
font-weight: bold; /* Example: Make the active link bold */
font-size:14px;
  cursor:pointer;
}
.nav-item .nav-link:hover{
color:#b447eb;
}
.navbar-logo-division {
  display: flex;
  align-items: center;
}
.menu-right .header-signupbtn {
padding: 10px 16px;
border-radius: 50px;
color: white !important;
}
.header-signupbtn {
    font-family: Inter,sans-serif;
       font-style: normal;
       font-weight: 500;
       font-size: 14px;
       line-height: 20px;
       padding: 17px 26px 16px;
      border-radius: 0.875rem;
      background: #494AF2;
       color: white;
       border:none;

 }
 .text-white-sign{
  font-size:14px;
  color: black !important;;
  cursor:pointer;
  font-weight: 500;
  }
.text-white{
font-size:14px;
color: black !important;;
cursor:pointer;
font-weight: 500;
}
.text-whiteR{
font-size:14px;
color: black !important;;
cursor:pointer;
font-weight: 500;
}
.text-white1{
font-size: 14px;
  border-left: 2px solid #99989b;
  color: #99989b !important;
  font-weight: 500;
}

.expandResource{
position: absolute;
top:0;
background-color: white;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
z-index: 1000;
width: 100%;
height: auto;
color: black!important;
min-height: 15vh;
box-shadow: 0 2px 400px rgba(0, 0, 0, 0.3);
//  border: 2px solid #E4E5EB;
}
.headerExpand{
display: flex;
flex-direction: column;
}
.headerExpandImg{
width: 20%;
}

.dapplandingpage-header{
width: 100%;
height: 250px;
background-color: #E9E6E6A6;
display: flex;
border-radius:16px;
margin-left: 10px;
padding: 30px;
flex-direction: column;
justify-content: end;
}

.dapplandingpage-header1{
width: 100%;
border: 1px solid gray;
height: 250px;
display: flex;
border-radius: 16px;
margin-left: 10px;
padding: 30px;
flex-direction: column;
justify-content: space-between;
}
.dapplandingpage-header2{
width: 100%;
background-color: transparent;
height: 250px;
display: flex;
margin-left: 10px;
border-left: 1px solid #99989b;
padding: 30px;
padding-left: 10%;
flex-direction: column;
justify-content: end;
}
.banner_btn_docs-header {
font-family: Inter,sans-serif;
font-style: normal;
font-weight: 500;
font-size: 1rem;
line-height: 18px;
padding: 10px 16px 10px 16px;
width:max-content;
margin-top: 10px;
border-radius: 50px;
background-color:#F81F49;
color: white !important;
border:none;
}

.dapplandingpage-header3{
width: 100%;
background-color: #0A77FF;
height: 250px;
display: flex;
margin-left: 10px;
border-radius:16px;
padding: 30px;
flex-direction: column;
justify-content: space-between;
}


.mobile_responsive_header{

}

@media (max-width: 1200px) {
.expandResource{
  display: none !important;
}
.mobile_responsive_header{
  display: flex !important;
  align-items: center;
  text-align: left !important;
  margin-left: 35px !important;
  width: fit-content !important;
}
.nav-item .nav-link{
  cursor: pointer;
  margin-left: 35px !important;
  padding-left: 0px !important;
}
.text-whiteR{
  display: none !important;
  }
  .text-white1{
    display: none;
  }
.nav-item {
  display: flex; /* Hide the navigation items */
}

.navbar-toggler {
  display: block; /* Show the toggler for screens up to 1200px */
}
.navbar-logo-division {
  margin-right  : 17%;
}
.navbar-collapse {
  display:none;
  background-color: white;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  z-index:111;
  font-size:20px;
}

.navbar-nav {

  text-align: end;
  display: flex !important;
  flex-direction: row !important;
}

.navbar-collapse.show {

  display: block !important;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
       background:white !important;
        font-size:20px;
        padding:10px;
}

.navbar-collapse li {
  text-align: center;
  margin: 10px 0;
  font-size:18px;
}

.menu-right {
  margin-top: 10px;
}
}
@media (max-width: 767px) {

@-webkit-keyframes fadeInUp1 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp1 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp1 {
  -webkit-animation-name: fadeInRight !important;
animation-name: fadeInRight !important;
}


.text-white-sign{

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding: 10px 20px 10px 20px;
  background: #fff;
  border-radius: 50px;
  color: white;
  border: 1px solid #e5e5e7;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  }
.mobile_responsive_header{
  display: flex !important;
  justify-content: center;
  // flex-direction: column !important;
  text-align: left !important;
  // align-items: flex-start;
  margin-left: 30px !important;
  width: fit-content !important;
}
.navbar-nav {

  flex-direction: column !important;
}
.text-whiteR{
  display: none !important;
  }
  .text-white1{
    display: none;
  }

.nav-item {
      display: grid; /* Hide the navigation items */
}
.navbar-logo-division {
    margin-right  : 0% !important;
  }

.navbar-toggler {
  display: block; /* Show the toggler for screens up to 360px */
}

.navbar-collapse {
  display:none;
  background: transparent;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  font-size:18px;

}

.navbar-nav {
  flex-direction: column;
}

.navbar-collapse.show {
  display: flex; /* Show the navbar items when toggler is clicked */
   display: block !important;
          align-content: center;
          justify-content: center;
          align-items: center;
          justify-items: center;
          background:transparent;
          padding:10px;
}

.navbar-collapse li {
  text-align: left;
  margin: 10px 0;
  font-size:18px;
}

.menu-right {
  margin-top: 10px;
}


.menu-right .header-signupbtn {
  font-size: 14px; /* Adjust the font size for smaller screens */
}
}

/* Basic styles for the banner section */
.Bannersection-wrap {
text-align: center;
position: relative;
width: 100%;
display: flex;
justify-content: space-around;
padding: 0px 43px 40px 43px;
/* background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #080322; */
backdrop-filter: blur(25px);
background-color: transparent;
}

// .Bannersection-wrap::before {
//     content: "";
//     opacity: 0.9;
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
.banner-head-container {
padding: 0px 15px;
 width: 100%;
 display: flex;
 flex-direction: column;
/* max-width:1200px; */
  /*background: url(../../../assets/images/banner-bcg.avif)no-repeat;
      background-position: center;
      background-size: contain;*/



}

.banner-heading {
font-style: normal;
font-weight: 500;
font-size: 54px;
line-height: normal;
color: #ececec;
text-align: start;
// margin-top: 80px;
/* margin-left: 230px; */
 padding: 20px 0;

}
.banner-highlight {

/* background: linear-gradient(86.32deg, #33354A 8.78%, #A219BC 37.32%, #F81F49 65.87%, #FA522B 95.26%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-family: Inter;
  font-size: 54px;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
  background: none;
}


.banner-para {
font-size: 18px;
line-height: 1.5;
color: rgba(53, 53, 53, 0.90);
margin-top: 30px;
text-align: left;
font-weight: 500;
line-height: 30px;
}

.banner_btn {
  font-family: Inter,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding: 12px 20px 12px 20px;
  width:max-content;
  border-radius: 50px;
  background: #0000FF;
  color: #FFFFFF;
  border:none;
}
.docs_btn{
font-family: Inter, sans-serif;
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 22px;
padding: 12px 20px 12px 20px;
background: #fff;
border-radius: 50px;
color: white;
border: 1px solid #e5e5e7;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
.banner-button-section{
width: 100%;
display: flex;
/* justify-content:center;
align-items: center; */
gap:0.7rem;
padding-top: 30px;
}
.banner-text{
      /* background: linear-gradient(to right, #33001a, #ff0000);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
      background: linear-gradient(86deg, #33354A 8.78%, #A219BC 37.32%, #F81F49 65.87%, #FA522B 95.26%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;
      margin-top: 130px;
}
.banner-docs{
display:inline-flex;
gap:1rem;
color: black;
}
// .container{
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
// }

.BannerRightSection{
width: 92%;
/* display: flex;
justify-content: start;
align-items: center; */
text-align: right;
background-color: transparent;
}
.newlandingpage-rectangle{
max-width: 100%;
  width: 100%;
  margin-right: -25px;
}


/* Responsive styles for screens up to 1200px */
@media (max-width: 1200px) {
.banner-head-container {
  padding: 0 0px;
  width: 100%;
}
.banner-button-section{
  width: 100%;
}


.banner-heading {
  font-size: 3.3rem;
    line-height: normal;
    margin-left: 40px;
}

.banner-para {
  font-size: 18px;
  margin-bottom: 40px;
}

.theme-btn {
  padding: 10px 20px;
  font-size: 14px;
}
.Bannersection-wrap {
  margin-top: 0px !important;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
}
.BannerRightSection{
  display: flex;
  width: 100% !important;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.newlandingpage-rectangle{
  max-width: 100%;
      width: 100%;

}
}

/* Responsive styles for screens up to 768px (tablet and mobile) */
@media (max-width: 768px) {
.navbar {
  padding: 16px 20px !important;
}
.headerExpand{
  flex-direction: column;
  align-items: center;
}
.headerExpandImg{
  width: 40%;
}
.banner-heading {
  font-size: 45px;
  line-height: 105%;
  margin:20px;
}
.newlandingpage-rectangle{
  max-width: 100%;
      width: 100%;
      margin-right: 0px;
}
.BannerRightSection{
  width: 100%;
  /* justify-content: center; */
  margin-bottom: 30px;
  margin-top: 30px;
  display:none;
}
.banner-button-section{
  padding-top: 0px !important;
}
.docs_btn{
  display: flex;
  justify-content: center;
  // width: 100%;
}


.banner-docs{
  // width: 100%;
  justify-content: center;
}
.banner-para {
    margin-top: 20px;
  font-size: 17px;
  margin-bottom:30px;
}

.theme-btn {
  padding: 8px 16px;
  font-size: 12px;
}

.banner_btn {
    font-family: Inter,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    background: #0000FF;
    color: #FFFFFF;
}

.banner-button-section{
  width: 100%;
display: flex;
}


.marquee-content:hover {
  -moz-animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
 }
 .banner-highlight{
  font-size: 34px !important;
  font-family: Inter;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;

 }
 .banner-text{
      margin-top: 60px;
 }

}
/* Basic styles for the client logo section */
.clientlogo-wrap {
width: 100%;
overflow-x: hidden;
background-color:#FFFFFF;
padding: 10px 0;
margin-top:50px;
padding-bottom:80px;
}
.clientlogowidth{
width: 120px;
height: 50px;
}
.text-trust{
font-size:20px;
color:white;
display:flex;
font-weight:700;
padding-top:100px;
justify-content: center;
background:#080322;

}

.marquee-container {
overflow: hidden;
}

.marquee-content {
display: flex;
animation: marquee-left 50s linear infinite;
}

@keyframes marquee-left {
0% {
  transform: translateX(100%);
}
100% {
  transform: translateX(-100%);
}
}

.marquee-content:hover {
-moz-animation-play-state: paused;
-webkit-animation-play-state: paused;
animation-play-state: paused;
}

.plandetails-sec2-image-container_1 {
display: flex;
align-items: center;
justify-content: center;
gap:2rem;

}




/* Responsive styles for screens up to 1200px */
@media (max-width: 1200px) {
.clientlogo-item {
  margin: 0 10px; /* Reduce the margin between logo items */
}
.clientlogowidth{
  width: 120px;
}
.tenx-card-container-new {

  display: flex;
  justify-content: space-between;
  margin: 70px auto !important;

}
.faster-content{
  width: 100%;
  display: flex;
  justify-content: center;
}

.startK-card-container{
  padding: 50px !important;
  flex-direction: column;
  align-items: center;
}
.startK-card-left{
  width: 100% !important;
  flex-direction: column !important;
  padding: 20px 20px 50px 20px;
  height: auto !important;

}

.startK-card-right{
  width: 100% !important;
  padding: 20px;
  min-height: 400px;

}
.startK-card-right1{
  width: 100% !important;
  padding: 20px;
  min-height: 400px;

}
}

.services-padding{
padding: 50px 70px 50px 70px;
}

.platformvalue{
font-family: Inter;
font-size: 44px;
font-weight: 600;
color: #000000;
margin-bottom: 20px;
}
/* Responsive styles for screens up to 768px (tablet and mobile) */
@media (max-width: 1200px) {
.startK-card{
  .startK-header{
   font-size: 28px !important;
   color: #000 !important;

  }
  .startK-subheader{
    color: #000 !important;
  }

}
.services-bg-img{
display: none !important;


}
.platform-bg-image{
display: none !important;
}
.faster-content img{
width: 75% !important;
margin-top: 0 !important;

}
}
@media (min-width: 767px) {
.banner-highlight {
  /* background: linear-gradient(86.32deg, #33354A 8.78%, #A219BC 37.32%, #F81F49 65.87%, #FA522B 95.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    font-family: Inter;
    font-size: 54px !important;
    font-weight: 500;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: left !important;
    background: none;
  }
  .left-header{

    color: #fff !important;
    font-size: 38px !important;
  
  }
  .right-header{
    color: #ffffff !important;
    font-size: 38px !important;
  
  }
}
@media (min-width: 1200px) {

.startK-card{
  .startK-header{
   font-size: 28px !important;
   color: #000 !important;

  }
  .startK-subheader{
    color: #000 !important;
  }
}
.platform-header-two{
font-family: Inter;
font-size: 42px !important;
color: #000 !important;
font-weight: 500;
line-height: 56px;
letter-spacing: 0em;
text-align: left !important;
  margin-bottom: 36px;
}
.startK-heading{
padding-top: 80px !important;
padding-bottom: 30px !important;
  h2{
       font-size: 42px !important;
       font-weight: 500;
       line-height: 56px;
          opacity: 0.8;
          color: #000 !important;
          text-align: center !important;

  }
  p{
      font-size: 16px !important;
          margin-top: -10px !important;
          color: #494949;
          opacity: 0.8 !important;
          margin-bottom: 10px !important;
          line-height: 24px !important;
          text-align: center;
  }
}
}
@media (max-width: 768px) {


.platformvalue{
font-size: 20px;
font-weight: 500;
margin-bottom: 5px;

}
.platform-bg-image{
  display: none !important;
}
.services-bg-img{
display: none;

}

.services-padding{
  padding: 30px;
}
.pricingplan-wrapper {
  padding: 20px !important;
  padding-bottom: 50px !important;
}

.NewLandingEnterpriceN-wrapper{
      padding: 20px !important;
}
.new-footer-wrapper{
      padding: 20px !important;
}
.mt-7{
  margin-top: 3rem !important;
}
.startK-container-new .startK-heading h2{
  font-size: 24px !important;
  line-height: 32px !important;
}
.platform-header-two{
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center !important;
  font-size: 24px !important;
  line-height: 32px !important;
}
.faster-content img{
  width: 75% !important;
  margin-top: 0 !important;

}
.start-card-rightbox{
margin-top: 40px !important;
margin-right: 0 !important;
border-radius: 16px !important;
margin-bottom: 0px !important;

}
.krypcoreimgnew{
  margin-top: 20px;
  width: 100% !important;
}
.marquee-container-left {
   /* Enable horizontal scrolling on smaller screens */
}


.plandetails-sec2-image-container_1 {
 display: flex;
 align-items: center;
 justify-content: center;
 margin-bottom:40px;
}
// .marquee-content-left {
//   display: flex;
//   animation: marquee-left 20s linear infinite;
// }

.clientlogo-item {
display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;

}
.clientlogo-wrap {
  margin-top:0 !important;
  margin-bottom:0 !important;
  padding-bottom:0px;
}
.text-trust{
padding-top:50px;
}

.faster-section-new{
display: none !important;
padding: 35px !important;
flex-direction: column;
}
.faster-section-new1{
  background-size: cover;
  background-position: bottom;
  display: flex !important;
  background-color: #FFFFFF;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 50px;
  padding: 30px;
}
.platform-subheader{
display: flex;
  width: 100%;
  justify-content: center;
  text-align: center !important;
font-size: 17px !important;
padding-top: 20px !important;
line-height: 24px !important;
padding-left: 10% !important;
padding-right: 10px !important;
text-align: left;
}

.tenx-card-container-new {
display: flex;
justify-content: space-between;
margin: 20px auto !important;

.tenx-card-h2{
  display: flex;
  justify-content: center !important;
  text-align: center !important;
  font-size: 34px !important;
  line-height: 45px !important;
  margin-bottom: 12px !important;

}
.tenx-card-p{
  display: flex;
  justify-content: center !important;
  font-size: 12px !important;
  line-height: 16px !important;
}
}
.startK-card-container{
padding: 40px !important;
flex-direction: column;
align-items: center;
}
.startK-card-left{
width: 100% !important;
flex-direction: column !important;
padding: 40px !important;
height: auto !important;

}

.startK-card-right{
width: 100% !important;
padding: 40px !important;
min-height: 350px;
height: auto;
margin-top: 5px !important;
margin-bottom: 30px !important;
}

.startK-card-right1{
width: 100% !important;
padding: 40px !important;
min-height: 350px;
height: auto;
margin-top: -23px !important;
margin-bottom: 30px !important;
}

.startK-card{
  .startK-header{
   font-size: 22px !important;
   color: #000 !important;

  }
  .startK-subheader{
    font-size: 15px !important;
    color: #000 !important;
  }
}
.startK-footer{
flex-direction: column !important;
gap: 10px !important;
width: 100%;
}
.buildK-field{
width: 100% !important;
}
.buildK-text-card{
width: 100% !important;
}
.left-header{

font-size: 24px !important;
line-height: 32px !important;

}
.right-header{

font-size: 24px !important;
line-height: 32px !important;

}
.card-box{
margin-top:3rem !important;
margin-bottom: 20px !important;

}
.plan-credit-num{
font-size: 24px !important;
}


}

.faster-content img{
margin-top: -50px;

}
.services-bg-img{
background: url('./Icons/Lines-Frame.svg');
width: 100%;
margin-top: -250px;
height: 280px;
      background-position-x: 0%;
      background-position-y: 0%;
      background-repeat: repeat;
      background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


// .clientlogo-item img {
//   max-width: 134px;
//   height: auto;
// }
.platform-platform-section{
  padding-top: 80px;
}
.platform-header-two{
font-family: Inter;
font-size: 42px;
color: #000 !important;
font-weight: 500;
line-height: 56px;
letter-spacing: 0em;
text-align: left;
  margin-bottom: 36px;
}
.platform-subheader{
  font-size: 18px ;
  font-weight: 400;
  margin-top: -10px !important;
  color: #353535;
  opacity: 0.8 !important;
  margin-bottom: 40px ;
  line-height: 30px ;
  text-align: left;
}
.faster-section-new{

  background-size: cover;
  background-position: bottom;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 70px 50px 50px 70px;
}
.faster-section-new1{
display: none;
}
.platform-bg-image{
margin-top: -330px;
padding: 10px 0px 10px 0px;
background: url('./Icons/Lines-Frame.svg');
width: 100%;
height: 280px;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.tenx-card-container-new {
  display: flex;
  justify-content: space-between;
  margin: 70px auto;



  .tenx-card {
    background-color: transparent;
    border-radius: 10px;
    width: calc(34.33% - 20px);
    transition: all 0.3s ease;
    position: relative;
  }
}
.tenx-card-h2{
font-style: normal;
  font-weight: 400;
  font-size: 65px !important;
  line-height: 78px;
  margin-bottom: 24px;
  color: #000000 !important;
  text-align: left;
}
.tenx-card-p{
font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  color: #353535 !important;
  text-align: left;
}


.startK-container-new{
  margin-top: 80px !important;
  overflow: hidden;
  display: inline;
  height: auto !important;

  .startK-heading{
    padding-top: 80px !important;
    padding-bottom: 30px !important;
      h2{
           font-size: 42px;
           font-weight: 500;
           line-height: 56px;
              opacity: 0.8;
              color: #000 !important;
              text-align: center !important;

      }
      p{
          font-size: 16px !important;
              margin-top: -10px !important;
              color: #494949;
              opacity: 0.8 !important;
              margin-bottom: 10px !important;
              line-height: 24px !important;
              text-align: center;
      }
  }
  .column-header{
      color: #121212 !important;
      line-height: 24px;
      font-size: 24px !important;
      font-weight: 500;
  }
  .column-subheader{
      color: #353535 !important;
      line-height: 24px;
      font-size: 18px !important;
      font-weight: 400;
  }
  .startK-card-container{
      height: auto;
      padding: 50px;
      display: flex;
      gap: 30px;
  }
  .startK-card-left{
      display: flex;
      justify-content: space-between !important;
      width: 100%;
      height: 754px;
      padding: 40px 40px 40px 50px;
      border-radius: 16px;
      -webkit-backdrop-filter: brightness(5);
      backdrop-filter: brightness(5);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      background: #121316;

      .left-header{
          color: #ffffff;
          font-family: Inter;
          font-size: 38px;
          font-weight: 600;
          margin-bottom: 10px;
          line-height: 56px;
          letter-spacing: 0em;


      }
      .sub-header{
          color: #D2D3DA;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
      }
      .card-box p{
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: #D2D3DA;
      }

        .cad-docs_btn{
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          padding: 13px 20px 13px 20px;
          border-radius: 25px;
          color: white;
          border: 0;
          background: linear-gradient(96.75deg, #F81F49 3.56%, #FA522B 55.29%);
          width: max-content;

        }
        .start-card-rightbox{
          background: #1C1D21;
            border-top-right-radius: 16px;
            margin-top: -40px;
            padding: 26px 26px 0 26px;
            margin-right: -40px;
            margin-bottom: 50px;
            border-bottom-left-radius: 16px;
        }
        .mt-7{
          margin-top: 7rem;
        }
  }
  .startK-card-right{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 330px !important;
            padding: 30px 40px 40px 41px;
            height: 100%;
            border-radius: 16px;
            -webkit-backdrop-filter: brightness(5);
            backdrop-filter: brightness(5);
            background: #BBD6FF;

            .right-header{
                  color: #212020 !important;
                  font-size: 38px;
                  font-weight: 600;
                  line-height: 56px;
                  margin-bottom: 10px;
            }
            .sub-header{
                  font-size: 16px ;
                  font-weight: 400;
                  color: #373943;
                  line-height: 26px;
            }

            .startK-footer{
              display: flex;
              flex-direction: column;
              // align-items: center;
              justify-content: center;
              .buildK-field{

                  .buildK-text-card{
                      border-radius: 25px;
                        background: #fff;
                        color: #000;
                        width: 100%;
                        padding: 10px 22px  10px 22px;
                        font-size: 12px;
                        display: inline-flex;
                        justify-content: space-between;
                        align-items: center;
                        transition: all 0.3s ease;
                        height: 46px;
                  }
              }
              .docs_btn{
                font-family: Inter, sans-serif;
                  font-style: normal;
                  font-weight: 500;
                  margin-top: 10px;
                  width: fit-content;
                  font-size: 15px;
                  line-height: 22px;
                  padding: 12px 20px 12px 20px;
                  background: transparent;
                  border-radius: 50px;
                  color: white;
                  border: 0;
                  background: #0A77FF;
              }
            }
            .buildK-view-btn{
              color: #4C59E3;
              font-size: 16px;
              font-weight: 400;
              line-height: 26px;
            }
      }
      .startK-card-right1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 400px !important;
        padding: 30px 40px 40px 41px;
        height: 100%;
        border-radius: 16px;
        -webkit-backdrop-filter: brightness(5);
        backdrop-filter: brightness(5);
        background: #BBD6FF;

        .right-header{
              color: #212020 !important;
              font-size: 38px;
              font-weight: 600;
              line-height: 56px;
              margin-bottom: 10px;
        }
        .sub-header{
              font-size: 16px ;
              font-weight: 400;
              color: #373943;
              line-height: 26px;
        }

        .startK-footer{
          display: flex;
          flex-direction: column;
          // align-items: center;
          justify-content: center;
          .buildK-field{

              .buildK-text-card{
                  border-radius: 25px;
                    background: #fff;
                    color: #000;
                    width: 100%;
                    padding: 10px 22px  10px 22px;
                    font-size: 12px;
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    transition: all 0.3s ease;
                    height: 46px;
              }
          }
          .docs_btn{
            font-family: Inter, sans-serif;
              font-style: normal;
              font-weight: 500;
              margin-top: 10px;
              width: fit-content;
              font-size: 15px;
              line-height: 22px;
              padding: 12px 20px 12px 20px;
              background: transparent;
              border-radius: 50px;
              color: white;
              border: 0;
              background: #0A77FF;
          }
        }
        .buildK-view-btn{
          color: #4C59E3;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
        }
  }

  // .startK-card-container-dblock{
  //     display: block;
  //     padding: 55px;
  // }
  .startK-card{
    margin-bottom: 30px;
      .startK-header{
       font-size: 28px ;
        opacity: 0.8;
        color: #000 !important;
        text-align: left !important;
        margin-bottom: 20px;
        min-width: 150px;
      }
      .startK-subheader{
        font-size: 16px !important;
        margin-top: -10px !important;
        color: #000000;
        opacity: 0.8 !important;
        margin-bottom: 10px !important;
        line-height: 24px !important;
        text-align: left;
      }
  }
}
.outlined-checkbox{
  outline: 1px solid #fff;
    border-radius: 50px;
    padding: 6px;
    opacity: 0.8;
}
.card-box{
  margin-top: 7.563rem;
  margin-bottom: 48px;
  p{
      display: flex;
        gap: 15px;
  }
}

.docs_btn1{
font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-top: 16px;
  width: fit-content;
  font-size: 15px;
  line-height: 22px;
  padding: 10px 20px 10px 20px;
  background: transparent;
  border-radius: 50px;
  color: white;
  border: 0;
  background: #0A77FF;
}
.docs_btn1:hover{
color: white !important;
}

.pricingplan-wrapper {
background-color: #121316;
padding: 115px 50px 50px 50px;
text-align: center;
}


.theme-heading {
margin-top:0px;
display: flex;
// padding-left: 20px;
padding-right: 20px;
}
.theme-heading1 {
margin-bottom:40px;
display: flex;
padding-right: 20px;

}

.theme-heading h3 {
color: white !important;
font-size: 42px;
line-height: 56px;
margin-bottom: 0px;
font-weight:500px;
}
.theme-heading1 p {
color:#FFFFFFB2;
font-size: 18px;
font-weight:500px;
}
.plan-highlight {
   background: linear-gradient(165deg, #AA0AFC 0%, #D5D3F3 54.14%, #007AEB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.plan-credit-num{
  color: white !important;
  font-size: 36px;
  font-weight: 600;
}
.plan-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }

.plandetail-card {
border-radius: 16px;
border: 2px solid #3A3C44;
color: white;
width:100%;
height:520px;
display: grid;
align-content: center;
align-items: start;
justify-items: start;
padding: 40px;
background: #1C1D21;
}
.plan-card-container {
width: calc(25% - 40px);
min-width: 300px;
height:522px;
margin: 20px;
padding: 0px;
background-color: #1C1D21 !important;
color:#fff;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
border:1px solid #3A3C44;
border-radius:15px;

}
.most-popular-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Align items horizontally in the center */
  width: 100%;
  margin: -50px auto;
}
.pro-plandetail-card {
width:100%;
height:520px;
border-radius: 16px;
opacity: 0.9;
border: 2px solid #3A3C44;
color: white;
background: #1C1D21;
display: grid;
align-content: center;
  align-items: start;
  justify-items: start;
  padding: 43px 40px 43px 40px;
}
.pro-card-container{
width: calc(25% - 40px);
height:522px;
min-width: 300px;
 margin: 20px;
 padding: 0px;
 color:#fff;
 background: #1C1D21;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 border: 1px solid #3A3C44;
 border-radius:15px;
 }
.plandetail-card h3 {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:20px;
    font-weight:600;
    color:white;
    text-align:start;

  }
.plandetail-card h5 {
     font-size: 14px;
     margin-bottom: 20px;
     color:white;
     text-align:start;

   }
.plandetail-card p {
    font-size: 25px;
    margin-bottom: 20px;
    color:white;
    text-align:start;
  }
.pro-text{
background: linear-gradient(to left, #fdc830, #f37335);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.pro-sub-text{
background:linear-gradient(to left, #fc4a1a, #fc4a1a);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.pro-plandetail-card h3 {
font-size: 30px;
margin-bottom: 20px;
margin-top:20px;
font-weight:600;
text-align:center;
 color:white;
}
.pro-plandetail-card h5 {
 font-size: 14px;
 margin-bottom: 20px;
 text-align:-webkit-auto;
 color:white;
}
.pro-plandetail-card p {
font-size: 25px;
margin-bottom: 20px;
text-align:center;
color:white;
}
  .Free-plan-basic-ul{
  list-style-type: none;
  text-align:start;
        font-size: 14px;
        margin-bottom: 33px;
        color:white;

  }
  .Starter-plan-basic-ul{
      list-style-type: none;
      text-align:start;
            font-size: 14px;
            margin-bottom: 12px;
            color:white;
            line-height: 30px;
            padding-top: 20px;
      }
.Pro-plan-basic-ul{
          list-style-type: none;
          text-align:start;
                font-size: 14px;
                margin-bottom: 12px;
                color:white;
                line-height: 30px;
                padding-top: 20px;

}
.Enterprise-plan-basic-ul{
              list-style-type: none;
              text-align:start;
                    font-size: 14px;
                   margin-bottom:12px;
                   color:white;
                   line-height: 30px;
                   padding-top: 20px;
}
// .Free-plan-basic-ul li {
//   position: relative;
//   padding-left: 30px;
//   line-height: 2;
// }
// .Free-plan-basic-ul li::before {
//   content: '\2713';
//   position: absolute;
//   color:#3331D2;
//   left: 0;
// }
// .Starter-plan-basic-ul li{
//  position: relative;
//   padding-left: 30px;
//   line-height: 2;
// }
// .Starter-plan-basic-ul li::before {
//   content: '\2713';
//   position: absolute;
//   color:#3331D2;
//   left: 0;
// }
// .Enterprise-plan-basic-ul li{
//  position: relative;
//   padding-left: 30px;
//   line-height: 2;
// }
// .Enterprise-plan-basic-ul  li::before {
//   content: '\2713';
//   position: absolute;
//   color:#3331D2;
//   left: 0;
// }
// .Pro-plan-basic-ul li{
//  position: relative;
//   padding-left: 30px;
//   line-height: 2;
// }
// .Pro-plan-basic-ul  li::before {
//   content: '\2713';
//   position: absolute;
//   color:#3331D2;
//   left: 0;
// }
.contact-us-button {
     border-radius: 4px;
     font-weight: 600;
     font-size: 18px;
     line-height: 17px;
     text-align: center;
     border:1px solid #B2B6C0;
     color: #000;
     width: 100%;
     height: 47px;
     box-sizing: border-box;
     background:#B2B6C0;
     margin-bottom:20px;

   }
.contact-us-button-popular {
      border-radius: 25px;
      font-weight: 600;
      font-size: 15px;
      line-height: 17px;
      text-align: center;
      border:none;
      width: 100%;
      color: #FFFFFF;
      width: 100%;
      height: 47px;
      box-sizing: border-box;
      background: #0000FF;
      margin-top:40px;

    }
    .contact-us-button-popular1 {
      border-radius: 25px;
      font-weight: 600;
      font-size: 15px;
      line-height: 17px;
      text-align: center;
      border:none;
      color: #FFFFFF;
      width: 100%;
      height: 47px;
      box-sizing: border-box;
      background: linear-gradient(96.75deg, #F81F49 3.56%, #FA522B 55.29%);
      margin-top:40px;

    }
.most-popular {
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        border:none;
        color: #fff;
        width: 50%;
        height: 30px;
        box-sizing: border-box;
        background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
//           margin: -50px auto;
        position:relative;
      }
.plan-button {
    border-radius: 0.875rem;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    margin-bottom:20px;
    color: #000;
    width: 100%;
    height: 47px;
    border:1px solid #B2B6C0;
    box-sizing: border-box;
    background:#B2B6C0;
  }
.planList{
 display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     align-content: center;
     justify-content: center;
     align-items: center;
.getstart-button{
              border-radius: 4px;
              font-weight: 500;
              font-size: 18px;
              line-height: 17px;
              cursor:pointer;
               text-align: center;
              color: #090a2c;
               width: 250px;
               z-index:1;
             height: 50px;
              box-sizing: border-box;

                &:focus {
                 outline: none;
}
}
}
@media (max-width: 1200px) {
.laptopViewEnterprise{
  display: flex;
  flex-direction: column;
}

.dapplandingpagecol{
  width: 100% !important;
  margin-top: 30px !important;
  flex-direction: row !important;
  margin-left: 0px !important;
  gap: 30px !important;
}


.dapplandingpage2{

  margin-left: 0px !important;
  height: 300px !important;

}


.enterpriseimg12{
  width: 100% !important;
}

.NewLandingEnterpriceN{
  height: auto !important;
}
.NewLandingEnterprice{
  height: auto !important;
}
.most-popular {
               border-radius: 4px;
               font-weight: 500;
               font-size: 14px;
               line-height: 17px;
               text-align: center;
               border:none;
               color: #fff;
               width: 50%;
               height: 30px;
               box-sizing: border-box;
               background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
//                  margin:-50px auto;
               position:relative;
             }

             .theme-heading{
              // padding-left: 20px;
              padding-right: 20px;
             }
             .theme-heading1{
              // padding-left: 20px;
              padding-right: 20px;
             }

}

/* Responsive styles for screens up to 768px (tablet and mobile) */
@media (min-width: 768px) {
.mobileViewEnterprise{
  display: none;
}
}
@media (max-width: 768px) {
.theme-heading-input {
  margin-top: 10px;
  margin-bottom:20px;
  display: flex;
  flex-direction: column;
  width: 100% !important;



}
.new_footer{

  padding: 30px !important;

  }
.hiddenbreaktag{
  display: block !important;
}
.hiddenbreaktag1{
  display: none !important;
}
.dapplandingpagecol{
  width: 100% !important;
  margin-top: 30px !important;
  margin-left: 0px !important;
  flex-direction: column !important;
}

.dapplandingpage2{

  margin-left: 0px !important;
  margin-top: 10px !important;

}
.landingpageenterprise{
   flex-direction: column !important;
   padding: 10px 26px!important;
}
.NewLandingEnterprice {
  padding: 0px !important;
}
.theme-heading{
  width: 100% !important;
  justify-content: center;
  padding-left: 1px;
  padding-right: 1px;
 }
 .theme-heading1{
  width: 100% !important;
  justify-content: center;
  padding-left: 1px;
  padding-right: 1px;
 }
 .theme-heading h3 {
  font-size: 24px !important;
  font-weight:600px;
}
.theme-heading1 p {
  text-align: center !important;
  font-size: 16px !important;
}
.enterpriseimg11{
  width: 100%;
  margin-top: -35px !important;
}
.NewLandingEnterpriceN{
  height: auto !important;
}
.NewLandingEnterprice{
  height: auto !important;
}
.mobileViewEnterprise{
  margin-top: 30px !important;
}
.laptopViewEnterprise{
  display: none !important;
}
 .most-popular {
                border-radius: 4px;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                border:none;
                color: #fff;
                width: 50%;
                height: 30px;
                box-sizing: border-box;
                background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
//                   margin:-25px auto;
                position:relative;
              }
              .most-popular-container {
                  display: flex;
                  align-items: center; /* Align items vertically in the center */
                  justify-content: center; /* Align items horizontally in the center */
                  width: 100%;
                  margin: -20px auto;
              }
.plan-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction:column;
      gap:1rem;
    }
.plan-card-container {
 width:100%;
 margin: auto;

}
.pro-card-container{
  width:100%;
    margin: auto;

}
.plandetail-card h3 {
      font-size: 25px;
    }
.plandetail-card p {
font-size: 20px;
}
.Free-plan-basic-ul{
font-size: 16px;
}
.Starter-plan-basic-ul{
font-size: 16px;
}
.Pro-plan-basic-ul{
 font-size: 16px;
}
.Enterprise-plan-basic-ul{
font-size: 16px;
}
.plan-button {
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    margin-bottom:20px;
    color: #000;
    width: 100%;
    height: 47px;
    border:1px solid #B2B6C0;
    box-sizing: border-box;
    background:#B2B6C0;
  }
.pro-plandetail-card h3 {
font-size: 25px;
}
.pro-plandetail-card p {
font-size: 20px;
}
.contact-us-button {
      border-radius: 4px;
      font-weight: 600;
      font-size: 18px;
      line-height: 17px;
      text-align: center;
      border:1px solid #B2B6C0;
      color: #000;
      width: 100%;
      height: 47px;
      box-sizing: border-box;
      background:#B2B6C0;
      margin-bottom:20px;

    }
   .planList{
   .getstart-button{

      width:80%
   }
   }

}

.prisingTag{
border: 1px solid #707070;
color: white;
background-color: #181818;
border-radius: 16px;
font-size: 14px;
padding-left: 15PX;
padding-right: 15PX;
margin-bottom: 20px;
}

.pricingTextTag{
font-size: 14px !important;
color: white !important;
text-align: start !important;
line-height: 20px;
}

.dapplandingpage1{
width: 100%;
background-color: #0A77FF;
height: 300px;
display: flex;
margin-left: 0px;
padding: 30px;
border-radius: 16px;
flex-direction: column;
justify-content: space-between;
.header{
      font-size: 1.625rem !important;
      margin-bottom: 10px;
}
.sub-header{
  font-size: 1rem !important;
  line-height: 22px;
}
}
.dapplandingpage2{
width: 100%;
  border: 2px solid #3A3C44;
  height: 215px;
  display: flex;
  margin-left: 0px;
  padding: 30px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  background: #1C1D21;
  .sub-header{
      font-size: 1rem !important;
      line-height: 22px;
    }
}
.dapplandingpagecol{
width: 100%;
display: flex;
height: auto;
justify-content: space-between;
flex-direction: column;
}

.banner_btn_docs {
font-family: Inter,sans-serif;
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;
padding: 12px 20px 12px 20px;
width:max-content;
border-radius: 50px;
background-color:#000000;
color: #FFFFFF !important;
border:none;
}

.banner_btn_github{
font-family: Inter,sans-serif;
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;
padding: 12px;
width:max-content;
border-radius: 50px;
background-color:#FFFFFF;
color: #000000;
border:none;
}
.NewLandingEnterpriceN-wrapper{
  background: #121316;
  padding: 0 3.125rem 0;
}
.NewLandingEnterpriceN{
width: 100%;
height: auto;
border-radius: 10px;
border-radius: var(--Main-System-16px, 16px);
background-image: url('./Icons/enterprise-bg-min.webp');
background-position: center;
background-repeat: no-repeat;
background-size: cover;
display: flex;
align-items: end;
justify-content:flex-start;

}

.enterprice_img1{
margin: -20px !important;
// height: 350px;

}
.enterprice_img2{
margin: -20px !important;
// height: 250px;
}

.NewLandingEnterprice{
width: 100%;
height: auto;
// background-image: url('./Icons/Rectangle 28.svg');
border-radius: var(--Main-System-16px, 16px);
background: linear-gradient(258deg, rgba(248, 31, 73, 0.68) 14.44%, rgba(250, 82, 43, 0.00) 61.75%);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
padding: 50px 30px 0px 30px;

}

.landingpageenterprise{
padding: 20px ;
}

.enterpriseimg12{
width: 70%;
}

.new_footer{
background:#1C1D22;
padding: 30px 80px 20px 30px;
height:auto;
border-radius: 16px;
}
.new_footer_container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.text-right1{
  float: right;
  padding-bottom: 3px;
}


.new_footer_card {
  padding: 0px;
  border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
}
.new_footer_card h2{
  color: #E2E2E2;
  font-family: 'Poppins' ;;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 40px !important;
}
.new_footer_card_logo{
width:200px;
height:100px;
}
.left-card {
  width:30%;
  background-color: transparent;
  color: #fff;
  display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
}

.right-card {
  background-color: transparent;
  color: #333;
 display: flex;
    flex-direction: row;
     flex-wrap: wrap;
     align-content: space-between;
     justify-content: space-around;
     align-items: flex-start;

}

.sub-grid-item-1 {
   background: transparent;
   /* padding: 10px; */
   border-radius: 5px;
   display:grid;
   justify-items:start;
   flex: 0 0 auto;
   width: auto;
}
.sub-grid-item-1 h3{
font-size:18px;
font-weight:900;
color:#fff;
}
.divider {
  border-top: 1px solid rgba(171, 169, 169, 0.22);
  width:100%;
}

.sub-grid-item-1 ul li{
font-size:16px;
color:#fff;
list-style-type:none !important;
text-align:left;
 padding: 5px;
 margin-right:25px;
  transition: color 0.3s;
}
.sub-grid-item-1 ul li:hover {
 color:#fa4b4d;
}
.bottom-section {
     display:flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;
      margin-top: 30px;

}
.vertical_divider {
  border-left: 1px solid #fff;
  height: auto;
  margin: 0 100px;
  width:2px;
}
.word-divider1 span {
  color:#5C5F70 !important;
  margin-right:10px;
  font-size: 12px;
}
.word-divider span {
  color:#787C91  !important;
  margin-right:20px;
  font-size: 12px;

}
.subgrid-right-footer{
  color: #787C91 !important;
}
.word-divider{
 display:flex;
 gap:1rem;
}
.social-icons-footer{
display:inline-flex;
gap:2rem;
justify-content:center;
align-items:center;
}
@media (max-width: 767px) {
  .policy_container {
    padding: 100px 20px 40px 20px !important;

  }
  .terms_container {
    padding: 100px 20px 40px 20px !important;
  }
  .text-right1{
    display: flex;
    width: 100%;
    justify-content: center;
   
  }
  .grid_features_pricingt {
    display: none;


  }

  .plan-details1{
    padding: 10px !important;
    padding-top: 100px !important;
  }

.new_footer_card_logo{
width:inherit;
}
.new_footer_container {
 display: flex;
  justify-content: space-between;
  flex-direction:column;
}
  .left-card {
    width: 100%;
  }

  .right-card {
      /* width: auto;
       max-height: none;
       display: grid;
       align-content: center;
       justify-content: center;
       align-items: center;
       justify-items: start; */
       padding: 0;
       width: 100%;
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between !important;
         --bs-gutter-x: 1.5rem;
         --bs-gutter-y: 0;
         margin-top: 30px;

  }

  .vertical_divider {
    display:none;
    height: auto;
    margin: auto
  }
  .new_footer_card h2{
   color: #E2E2E2;
   font-size: 14px;
   font-style: normal;
   font-weight: normal;
   line-height: 21px;
   margin-top: 40px !important;
   }
  .sub-grid-item-1 {
    /* padding: 10px; */
  }

  .sub-grid-item-1 h3 {
    font-size: 20px;
  }

  .sub-grid-item-1 ul li {
    font-size: 14px;
    margin-right: 15px;
  }
  .bottom-section {
       display:grid;
       justify-content: center;
       align-items: center;
       align-content: center;
       justify-items: center;

  }
  .word-divider{
    display: grid;
    gap: 1rem;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
  .theme-heading2 {
    text-align: center !important;
   }
   .theme-heading3 p {
    text-align: center !important;
  }
  }

  .theme-heading2 {
   /*  margin-top:40px;
    display: flex;
    padding-left: 20px;
    padding-right: 20px; */
  }
  .theme-heading3 {
    margin-top: 10px;
    margin-bottom:40px;
    display: flex;

  }
  .theme-heading-input {
    margin-top: 10px;
    margin-bottom:20px;
    display: flex;
    flex-direction: column;
    width: 80%;

    input{
     background: #2E3038;
      color: #fff;
      border-radius: 8px;
      height: 40px;
      padding: 13px 20px 11px 20px !important;
      border: #9A9DAC;
      outline: none;
      font-size: 1rem;

    }

  }
  .theme-heading5 {
    color: white;
  }
  .theme-heading5 button{
    background-color: #007AEB;
      color: white;
      border-radius: 25px;
      font-size: 15px;
      font-weight: 600;
      border: none;
      height: 40px;
      padding: 8px 40px;
  }

  .theme-heading2 h3 {
    color: #FAFAFC !important;
    font-size: 1.75rem;
    font-weight:500px !important;
  }
  .theme-heading3 p {
    color:#787C91 !important;
    font-size: 18px;
    font-weight:500;
  }
.new-footer-wrapper{
  background: #121316  ;
  padding: 50px 50px 50px 50px;
}

.plan-details1{
background: black;
padding: 150px;
}

.plan-details {
background:transparent;

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}


.animate {
-webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
}




@-webkit-keyframes fadeInUp {
from {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}
@keyframes fadeInUp {
from {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}

.fadeInUp {
-webkit-animation-name: fadeInUp;
animation-name: fadeInUp;
}

/*=== FADE IN DOWN ===*/
.fadeInDown {
-webkit-animation-name: fadeInDown;
animation-name: fadeInDown;
}
/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
from {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}
@keyframes fadeInRight {
from {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

to {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
}

.fadeInRight {
-webkit-animation-name: fadeInRight;
animation-name: fadeInRight;
}


.banner-highlight {
    background: linear-gradient(90deg, #b447eb 0%, #2797ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.plan-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.plan-heading h2 {
  margin-top: 50px !important;
  color: #FCF7FF;
  text-align: center;
  font-family: 'Poppins' ;;
  font-size: 45px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
}
.plan-heading h5{
 color: #BDBBCB;
 font-family: 'Poppins' ;;
 font-size: 18px;
 font-style: normal;
 font-weight: 400;
 line-height: 27.644px; /* 153.577% */
}
.plan-heading p {
  margin-bottom: 0;
}
.planTable-button{
border-radius: 4px;
           font-weight: 550;
           font-size: 18px;
           line-height: 17px;
           text-align: center;
           border:none;
           color: #000;
            width: 100%;
            height: 50px;
           box-sizing: border-box;
               background: #B5ADC3;
           &:focus {
             outline: none;
           }
}
.planTable-button-getStart{
border-radius: 4px;
           font-weight: 550;
           font-size: 18px;
           line-height: 17px;
           text-align: center;
           border:none;
           color: #000;
           width: 100%;
           height: 50px;
           box-sizing: border-box;
           background:#B5ADC3;
           &:focus {
             outline: none;
           }
}
.planTable-button-contact-us-pro{
border-radius: 4px;
           font-weight: 550;
           font-size: 18px;
           line-height: 17px;
           text-align: center;
           border:none;
           color: #000;
           width: 100%;
           height: 50px;
           box-sizing: border-box;
           background:linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
           &:focus {
             outline: none;
           }
}
.plan-section {

    background:#100A13;
    border-radius:0px;
     box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
     border-radius: 16px;
     border: 2px solid #3A3C44;
     border-radius:16px;

     }
     .main-container  {
       display: flex;
       flex-wrap: nowrap;
       justify-content: space-between;
           width: 100%;
           gap:1rem;

     }

     .card_features_pricing {
       width: calc(75% - 20px);
       background: transparent;
//        box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
       padding: 25px;
       box-sizing: border-box;
       margin-bottom: 20px;
//        border-radius:15px;


     .feature-highlight{
      color: transparent;
    //  background: linear-gradient(90deg, #B447EB 0%, #2797FF 100%);
    //  background-clip: text;
    //  -webkit-background-clip: text;
    //  -webkit-text-fill-color: transparent;
     }
     }
     .grid_features_pricing {
       background-color: transparent;
       border: none;
       padding: 0px;
       color:#FBF5FF;
       min-height: 200px;
      //  box-sizing: border-box;
       margin-bottom: 10px;
           font-size: 20px;
           font-weight: 600;
               text-align:left;


     }
     .grid_features_pricingt {
      background-color: transparent;
      border: none;
      padding: 10px;
      color:#FBF5FF;
      min-height: 220px;
     //  box-sizing: border-box;
      margin-bottom: 10px;
          font-size: 20px;
          font-weight: 600;
              text-align:left;


    }
     .grid_features_pricing img{
     height:50px;
     width:100%;
     }
    .grid_features_pricing .features_img{
    height:100px !important;
         width:100%;
    }
   .banner-highlight {
               background: linear-gradient(149deg, #AA0AFC 0%, #D5D3F3 54.14%, #007AEB 100%);
               background-clip: text;
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               font-family: 'Poppins' ;;
               font-size: 45px;
               font-style: normal;
               font-weight: 700;
               line-height: normal;
               letter-spacing: 0.6px;
              }
     .pricing_listing_features{
        text-align:left;
        display:grid;
        align-content:center;
        justify-content:center;
        font-size:14px;
        line-height:2.5;
        font-weight:bold;
        color:#FBF5FF;
     }
     .pricing_listing{
     text-align:left;
             display:grid;
             align-content:center;
             justify-content:start;
             padding-left: 15px;
             font-size:14px;
             line-height:2.5;
             color:#FBF5FF;

     }
     .pricing_listing li::before {
       content: '\2713';
       margin-right: 8px;
     }
    .pricing_listing {
       list-style: none;
     }

      .card-front_starter{
              transform: scale(1.1);
               z-index: 1;
            }
 .card-front_pro {
         transform: scale(1.1);
          z-index: 1;
       }
  .card-front_enterprise {
          transform: scale(1.1);
           z-index: 1;
        }
     .listing_features_pro{
     color:#fff;
     }
     .card_features_head{
      width:80%;
       cursor: pointer;
     }
    .card_features_pro{
    background: linear-gradient(182deg, #30064D 0%, rgba(77, 36, 106, 0.00) 100%);
    border:none !important;
    color:#fff !important;
          cursor: pointer;
              transition: transform 0.1s;
    }


     @media screen and (max-width: 1200px) {
      .main-container {
               display: grid;
              justify-content: center;
            }
       .card_features_pricing {
         width: 100%;
       }
     }

     @media screen and (max-width: 767px) {
      .card-contact-right{


        background: #383838;
         border-radius: 30px;
         border: 2px solid #3A3C44;
         width: 100% !important;
         height: fit-content;

       }
     .plan-heading h2 {
       margin-top: 120px !important;
       font-size:30px;
       text-align:center !important;
     }
     .plan-heading h5{
      color:#fff;
      font-size:20px;
     }
       .main-container {
          display: grid;
         justify-content: center;
       }
     .plan-section{
     margin:10px;
   
     }
     }
}

.prisingTagt{
  color: transparent !important;
}
.plan-credit-numt{
  color: transparent !important;
  font-size: 36px;
  font-weight: 600;
}
.pricingTextTagt{
  color: transparent !important;
  font-size: 14px !important;
  text-align: start !important;
  line-height: 20px;
}

.prisingTag1{
  border: 1px solid #707070;
  color: white;
  background-color: #181818;
  border-radius: 16px;
  font-size: 14px;
  padding-left: 15PX;
  padding-right: 15PX;
  margin-bottom: 20px !important;
}

.pricingbuttonwidth{
  width: 100%;
}
.hiddenbreaktag{
  display: none;
}
.hiddenbreaktag1{
  display: block;
}
.infra-card-box{
  margin-top: 20px;
  margin-bottom: 30px;
  p{
      font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
  }
}
.text-black-text{
  color: #121212 !important
}
.hidden{
  visibility: hidden !important;
}



//Contact-us  
.plan-details2 {
    background: url('./Icons/light-red-gradient-contact-us-page.svg');
  /* margin-top: 150px; */
  padding-top: 150px;
  padding-bottom: 500px;
  background-size: cover;
  background-repeat: no-repeat;


  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}


.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}




@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*=== FADE IN DOWN ===*/
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}


  }
  .plan-heading-contact {
  display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
  }
  .plan-heading-contact h2{
  font-size:45px !important;
  color: white !important;
  }
  .contact-subtext{
  color: #000;
  font-family: 'Poppins' ;;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 20px;
  line-height: 27.644px; /* 153.577% */
  display:flex;
  text-align: center;
  flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
  }
  .contact-highlight{
  background: linear-gradient(153deg, #AA0AFC 0%, #D5D3F3 54.14%, #007AEB 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  .plan-section1{
  background:transparent;

  }



  .contact-page-container{
//    background:url('./Icons/');
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: auto;
    width:100%;
    padding-left: 12%;
    padding-right: 12%;
  
    .card-contact-left {
      width: 40%;
     background: transparent;
      border-radius: 5px;
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      border:none !important;
     align-items: start;
         align-content: start;
         justify-items: start;
         display: grid;
         justify-content: start;
  p{
      color: white !important;
      text-align: left;
  }
      .card-contact-left h3{
               font-size: 25px !important;
               color:#fff !important;
               font-weight:700px !important;
                }
  
      .contact-text{
      color: white !important;
      font-family: poppins;
      font-size: 48px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 58.19px; /* 161.638% */
    // margin-top: 300px;
  
      }
      .card-contact-left p{
      color: #000 !important;
      font-family: 'Poppins' ;;
      font-size: 18px !important;
      font-style: normal;
      text-align: left;
      font-weight: 400;
      line-height: 30px; /* 166.667% */
      }
       .contact-link{
         color:#e86389 !important
       }
       .planTable-card-icon-button {
         display: inline-flex;
         align-items: center;
         padding: 10px 15px;
         background:  #2f4f4f;
         color: #fff;
         border: none;
         border-radius: 5px;
         font-size: 20px;
         font-weight:600;
         cursor: pointer;
       }
  
       .planTable-card-icon-button img {
         margin-right: 8px;
       }
  
      .social-link-page {
               display: grid;
               justify-content: space-around;
               align-items: center;
               flex-direction: row;
             }
  
  
     .social-link-contact-page{
       justify-content: space-between;
               display: flex;
               flex-direction: row;
               align-content:space-between;
               align-items: center;
               gap:1rem;
  
    }
    .square-box {
         width: 150px;
         height: 150px;
         background-color: #311c44;
         justify-content: center;
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         align-content: center;
         align-items: center;
     }
     }
    .card-contact-right{


       background: #383838;
        border-radius: 30px;
        border: 2px solid #3A3C44;
        width: 520px;
        height: fit-content;

      }

      .get-in-touch-h2{
          font-family: poppins;
         font-size: 32px;
         font-weight:700;
         color:#fff;
         margin-top: -15px;
          }
   .card-contact-right p{
         font-size: 16px;
         color:#fff;
          }
   .card-contact-right span{
          font-size: 16px;
          color:#fff;
           }
   .contact-form-input-container {
       margin-top: 5px;
       color: #a0a0a0;

           font-size: 16px;
           text-indent: 40px;
           width: 450px;
           height: 56px;
           border-style: solid;
           background: #000000;
           border-radius: 25px;
           padding: 10px;

       &::placeholder {
       font-family: 'Poppins';
       font-size: 16px;
       font-weight: 400;
       line-height: 35px;
       text-align: left;
       text-indent: 40px;
       color: #a0a0a0;
      //  opacity: 0.5;
       transition: opacity 0.3s ease;

       }
       &:focus::placeholder {
           opacity: 1; /* Increase opacity when input is focused */

       }
       
     }
     
     .custom-select-container {
      position: relative;
      width: 450px;
      height: 56px;
      font-family: Arial, sans-serif;
  }
  
  .custom-select {
    display: flex;
    align-items: center;
      padding: 10px;
      color: #a0a0a0;
    font-size: 16px;
    text-indent: 40px;
      border: 1px solid;
      border-radius: 25px;
      height: 56px;
      cursor: pointer;
      background-color:black;
      user-select: none;
  }
  
  .custom-options-container {
      padding-top: 10px;
      padding-bottom: 10px;
      position: absolute;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 8px;
      background-color: black;
      margin-top: 5px;
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      z-index: 1000;
      max-height: 200px;
      overflow-y: auto;
  }
  
  .custom-option {
    font-family: 'Poppins';
      padding: 10px;
      color: white;
      border-radius: 25px;
      cursor: pointer;
      user-select: none;
  }
  
  .custom-option:hover {
      background-color: gray;
  }







  .form-submit-btn {

      width: 122px;
      height: 42px;


      border-radius: 20px;
      background: #fffefe;
      float: right;
      margin-right: 35px;
      font-family: 'Poppins';
      font-size: 15px;
      font-weight: 700;
      line-height: 15px;
      margin-bottom: -15px;




      
//       font-family: Inter, sans-serif;
//       font-style: normal;
//       font-weight: 500;
//       font-size: 18px;
//       line-height: 20px;
//       padding: 17px 26px 16px;
//       border-radius: 50px;
//       background: #0a77ff;
//       color: white !important;
//       border: none;
//       width: 100%;
//       margin-top: 1rem;
    }
    .capcha{
    display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: center;
        gap: 1rem;
    }
  
  }
  
  
  .captcha-button{
  border-radius: 4px;
        font-weight: 600;
        font-size: 20px;
        line-height: 17px;
        text-align: center;
        color: #fff;
        width: 200px;
        height: 47px;
        border:1px solid #24C6DC;
        box-sizing: border-box;
        background:#24C6DC;
        &:focus {
          outline: none;
        }
  }
  
  .icon-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background: linear-gradient(to right, #00f260, #0575e6);
    color: #fff;
     width: 70px;
      height: 70px;
      border-radius: 50%;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  @media (max-width: 1200px) {
  
  }
  @media (max-width: 767px) {
    .card-contact-right{


      background: #383838;
       border-radius: 30px;
       border: 2px solid #3A3C44;
       width: 100% !important;
       height: fit-content;

     }
     .custom-select-container {
      position: relative;
      width: 100% !important;
      height: 56px;
      font-family: Arial, sans-serif;
  }
  .plan-heading-contact {
  display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin: 0px;
  }
  .plan-heading-contact h2{
  font-size:30px ;
  }
  
  .contact-page-continer{

//         background: url('./Icons/');
        display: flex;
        justify-content: center;
        gap: 20px;
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
  
    .card-contact-left {
      width: auto;
     background: transparent;
      border-radius: 5px;
      border:none !important;
     align-items: center;
         align-content: center;
         justify-items: center;
         display: grid;
         justify-content: center;
         margin-top: 300px;

  
      .card-contact-left h3{
               font-size: 30px !important;
               color:#fff !important;
               font-weight:700px !important;
                }
  
      .contact-text{
          font-size: 30px !important;
           color:#fff !important;
           font-weight:700px !important;

      }
       .contact-link{
         color:#e86389 !important
       }
       .planTable-card-icon-button {
         display: inline-flex;
         align-items: center;
         padding: 10px 15px;
         background:  #2f4f4f;
         color: #fff;
         border: none;
         border-radius: 5px;
         font-size: 20px;
         font-weight:600;
         cursor: pointer;
       }
  
       .planTable-card-icon-button img {
         margin-right: 8px;
       }
  
      .social-link-page {
               display: grid;
               justify-content: space-around;
               align-items: center;
               flex-direction: row;
             }
  
  
     .social-link-contact-page{
       justify-content: space-between;
               display: flex;
               flex-direction: column;
               align-content:space-between;
               align-items: center;
               gap:1rem;
  
    }
    .square-box {
         width: 150px;
         height: 150px;
         background-color: #311c44;
         justify-content: center;
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         align-content: center;
         align-items: center;
     }
     }
    .card-contact-right{
       
      border-radius: 20px;
       border: 2px solid #3A3C44;
      }
      .card-contact-right h2{
         font-size: 30px;
         font-weight:700px;
         color:#fff;
          }
   .card-contact-right p{
         font-size: 16px;
         color:#fff;
          }
   .card-contact-right span{
          font-size: 16px;
          color:#fff;
           }
   .contact-form-input-container {
        color: #fff;
        font-size: 16px;
        width: 100%;
   //      max-width: 750px;
        border: 1px solid #D0D5DD;
        border-style: solid;
   //      border-color: #ccb3b3;
        background:#2E3038 ;
        border-radius: 8px;
        padding: 10px;
        &::placeholder {
          color:#bcb7b7;
        }
      }
  .form-submit-btn {
      // background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
      // box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
      // border-radius: 6px;
      // font-family: 'Poppins' ;
      // font-style: normal;
      // font-weight: 500;
      // font-size: 16px;
      // line-height: 20px;
      // color: white;
      // padding: 12px;
      // width: 100%;
      // margin-top: 1rem;
    }
    .capcha{
    display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: center;
        gap: 1rem;
    }
    input[id="cb1"] + label {
      background-color: transparent;
      background-size: contain;
      display: inline-block;
      width: 50px;
      height: 19px;
      border: none;
      border-radius: 2px;
      cursor: pointer;
      margin: 0;
      color:#fff;
    }
  
  }
  }



//Privacy Policy
  .policy_container {
    margin: 0 auto;
    font-family: 'Poppins';
    padding: 150px 12%;
    background: black;
  
  
    .policy-logocard{
         display: flex;
         flex-direction: row;
         flex-wrap: nowrap;
         align-content:space-around;
         justify-content: space-around;
         align-items: center;
  
    }
    .policy-heading{
      font-family: 'Poppins';
        font-size: 42px;
        color: white;
        font-weight: 500;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
      margin:0 0 36px auto;
    }
    .policy-logo img{
    width: 300px;
        height: 300px;
    }
    .policy-content{
      font-family: 'Poppins';
    font-size: 18px;
      font-weight: 400;
      margin-top: -10px !important;
      color: white;
      opacity: 0.8 !important;
      margin-bottom: 40px;
      line-height: 30px;
      text-align: left;
    .policy-content-heading{
      font-family: 'Poppins';
//       color:#5858e4;
      color:#ffffff;
         font-size:24px;
         font-weight:700;
    }
    .policy-content-paragraph{
      font-family: 'Poppins';
    color:whitesmoke;
     text-align: left;
           font-size:18px;
           font-weight:normal;
    }
    .policy-content-paragraph_bold{
      font-family: 'Poppins';
    color:whitesmoke;
             font-size:20px;
             font-weight:600;
    }
    .policy-list-content{
      font-family: 'Poppins';
     color:whitesmoke;
             font-size:18px;
             font-weight:normal;
             padding:40px;
    }
    .policy-list-content li{
       list-style:disc;
    }
    }
  }



//Terms & Conditions
  .terms_container {
    font-family: 'Poppins';
    margin: 0 auto;
    padding: 150px 12%;
    background: black;
  
  
    .terms-logocard{
         display: flex;
         flex-direction: row;
         flex-wrap: nowrap;
         align-content: space-around;
         justify-content: space-around;
         align-items: center;
  
    }
    .terms-heading{
      font-family: 'Poppins';
        font-size: 42px;
        color: white;
        font-weight: 500;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
      margin: 0 0 36px auto;
    }
    .terms-logo img{
    width: 300px;
        height: 300px;
    }
    .terms-content{
      font-family: 'Poppins';
    font-size: 18px;
      font-weight: 400;
      margin-top: -10px !important;
      color: white;
      opacity: 0.8 !important;
      margin-bottom: 40px;
      line-height: 30px;
      text-align: left;
    .terms-content-heading{
      font-family: Poppins;
      color:#494AF2;
         font-size:24px;
         font-weight:700;
    }
    .terms-content-paragraph{
      font-family: 'Poppins';
    color:whitesmoke;
    text-align: left;
           font-size:18px;
           font-weight:normal;
    }
    .terms-content-paragraph_bold{
      font-family: 'Poppins';
    color:whitesmoke;
             font-size:18px;
             font-weight:600;
    }
    .terms-list-content{
      font-family: 'Poppins';
     color:whitesmoke;
             font-size:18px;
             font-weight:normal;
             padding:40px;
    }
    .terms-list-content li{
       list-style:disc;
    }
    }
  }
.im-not-robot-container{
  display: flex;
  flex-direction: row;
  gap: 20px;

  }
.pricing-table-container {
     width: 100%;
     padding-left: 20%;
     padding-right: 20%;
     padding-bottom: 30px;
 }

 .pricing-table {
     width: 100%;
     height: 800px;
     margin: 0 auto;
     border-collapse: collapse;
     table-layout: fixed; /* Added property for equal spacing */
     border-radius: 15px; /* Adjust the border radius as needed */
     border-color: #4D4D4D;
     overflow: hidden; /* Ensure border-radius applies correctly */
     background-color: black;
//      box-shadow: 0 0 0 1px white; /* Adjust the thickness and colors of the border */
     box-shadow: inset 0px 0px 1px 1px #4D4D4D;
 }

// .pricing-table::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     border: 1px solid transparent; /* Ensure table border is transparent */
//     border-radius: 15px;
//     background: linear-gradient(to right, #4a90e2, #f09819);
//     z-index: -1;
// }

/* Override default table border color */


 .pricing-table th,
 .pricing-table td {
     border: 1px solid;
//      border: 0 0 0 1px linear-gradient(to right, #4a90e2, #f09819);
     padding: 1rem;
     text-align: center;
     width: auto; /* Reset width for equal spacing */
     border-color: #4D4D4D;
//      border-image-source: linear-gradient(90deg, #4D4D4D 61%, #B3B3B3 100%);

 }

 .pricing-table th {
     font-family: 'Poppins';
     font-size: 23px;
     font-weight: 700;
     line-height: 60px;
     text-align: left;
     text-indent: 80px;

 }

 .pricing-table td {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-indent: 80px;
 }

 .pricing-table .row-heading {
    font-family: 'Poppins';
     font-size: 20px;
     font-weight: 700;
     line-height: 50px;
     text-align: left;
     text-indent: 40px;
 }


 .next-line-indent {
     display: block;
     text-indent: 80px; /* Adjust the indentation as needed */

 }

.gradient-text {
    background: linear-gradient(90deg, #F81F49 0%, #A219BC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.new-pricing-plan {

 background: url('./Icons/light-red-gradient-pricing-page.svg');


  background-repeat: no-repeat !important;
      background-size: 100% auto;
 }




@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;