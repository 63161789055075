.bg-light2{
    background-color: black!important;
    width: 100%;
    height: auto;
    font-family: 'Poppins';


    /* background: url('./Icons/Header-Blur-bg.svg');
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  // overflow-x: hidden;

  @keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}


.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*=== Optional Delays, change values here  ===*/

@-webkit-keyframes fadeInUp1 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp1 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp1 {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}


// .four {
//   -webkit-animation-delay: 0.1s;
//   -moz-animation-delay: 0.1s;
//   animation-delay: 0.1s;
// }


@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*=== FADE IN DOWN ===*/
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.bg-light1{
     background: url('../../assets/icons/Group352.svg');
     background-color: rgba(0, 0, 0, 0);
  //    background-position-x: 18%;
  // background-position-y: -50%;
     background-size: 100% auto;
  //  background-position: center !important;
   background-repeat: no-repeat !important;
  //  background-size: cover !important;
}


  .navbar-nav li div span{
    color: #121212;
    font-size:14px;
    cursor: pointer;
    font-weight: 500;
  }
  .text-line{
    font-family: 'Poppins' ;font-size: 18px ;
        border-left: 2px solid #99989b;
        color: #99989b !important;
  }
}

.header-wrapl {
  font-family: 'Poppins';
  background:transparent;
  position: fixed;
  z-index: 100;
  top: 0;
   width: 100%;
}
.header-wrapl1 {
  position: fixed;
  opacity: 90%;
  z-index: 100;
  top: 0;
   width: 100%;
   background-color: black;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
}

.navbar {
  padding: 15px 12% 15px 12% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.navbar-brand img.logo {
  height: 50px; /* Adjust the height as needed */
  width: 200px;
}



.navbar-toggler {
  border: none;
  background-color: transparent;
  display:flex; /* Hide the toggler for screens wider than 768px */
}

.nav-item{
  display: flex;
  align-items: center;
}

.nav-item .nav-link{
  cursor: pointer;
  margin-left: 70px;
  font-size:14px;
  color: #121212;
  cursor:pointer;
font-weight: 500;
}
.nav-item .nav-link1{
  cursor: pointer;
  margin-left: 46px;
  color: #FFFFFF;
  cursor:pointer;
  font-family: 'Poppins' ;font-size: 22px ;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}
.navbar-nav .active-link {
  color: #d988ddcc; /* Set color for the active link */
  font-weight: bold; /* Example: Make the active link bold */
  font-size:14px;
    cursor:pointer;
}
.nav-item .nav-link:hover{
color:#b447eb;
}
.navbar-logo-division {
    display: flex;
    align-items: center;
  }
.menu-right .header-signupbtn {
  padding: 10px 16px;
  border-radius: 50px;
  color: white !important;
}
.header-signupbtnl {
    font-family: 'Poppins' ;font-size: 20px ;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
   color: black !important;
   cursor:pointer;

   }
   .text-white-signl{
    font-family: 'Poppins' ;font-size: 22px  !important;
    font-weight: 500 !important;
    line-height: 22px;
        text-align: left;
       color: white !important;
       cursor:pointer;
    }
    .text-white-signl1{
      font-family: 'Poppins' ;font-size: 20px  !important;
        font-weight: 600 !important;
        line-height: 24px;
        text-align: left;
       color: black !important;
       background-color: white;
       border-radius: 20px;
       padding: 3px 20px;
       cursor:pointer;
       border:1px solid black;
    }
.text-white{
font-size:14px;
color: black !important;;
cursor:pointer;
font-weight: 500;
}
.text-whiteR{
  font-size:14px;
  color: black !important;;
  cursor:pointer;
  font-weight: 500;
  }
.text-white1{
  font-family: 'Poppins' ;font-size: 14px ;
    border-left: 2px solid #99989b;
    color: #99989b !important;
    font-weight: 500;
}

.expandResource{
  position: absolute;
  top:0;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1000;
  width: 100%;
  height: auto;
  color: black!important;
  min-height: 15vh;
  box-shadow: 0 2px 400px rgba(0, 0, 0, 0.3);
  //  border: 2px solid #E4E5EB;
}
.headerExpand{
  display: flex;
  flex-direction: column;
}
.headerExpandImg{
  width: 20%;
}
.light-theme-hiddenbreaktag1{
  display:none;
}


.mobile_responsive_header{

}

.light-theme-banner-container{
    width: 100%;
    display: flex;
    padding-top: 145px;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}
.faucetcontainer{
    width: 100%;
    display: flex;
    margin-bottom: 18px;
    align-items: end;
    justify-content: end;
}

.faucetlogo1{
  width: fit-content;
  // box-shadow: 0 9px 20px 0 rgba(85, 97, 227, 0.1), 0 36px 36px 0 rgba(85, 97, 227, 0.09), 0 82px 49px 0 rgba(85, 97, 227, 0.05), 0 146px 58px 0 rgba(85, 97, 227, 0.01), 0 228px 64px 0 rgba(85, 97, 227, 0);
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  padding: 2px;
  margin-right: 12%;
  // border-image-source: linear-gradient(to bottom, #4c59e3, #f81f49);
  // border-image-slice: 1;
  border-image-slice: 0 !important;
  background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #4c59e3, #f81f49);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 1px solid;

  border-image-source: linear-gradient(156deg, #f21111 9%, #f2eeee 22%, #cd3f86 46%, #f0f0fc 73%, #4c59e3 95%);
  box-shadow: 0px 4px 4px 0px #00000040;



}

.faucetlogo{
  display: flex;
  flex-direction: column;

  border-radius: 50px;
  background: white;


    padding: 9.8px 16.5px 20.2px 17.5px;
    font-family: 'Poppins' ;font-size: 20px ;
    font-weight: 700;
    height: 95px;
    cursor: pointer;
    width: 97px;
    align-items: center;
    justify-content: center;
    line-height: 24px;
    text-align:center;
    color: #FFFFFF;
}
.faucetlogo-properties{
  height: 30px;
  width: 30px;
}
.faucetlogo-properties1{
  height: 30px;
  width: 30px;
}
.faucetlogo-text{
  margin-top: 5px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  // background: linear-gradient(267.1deg, #EA153E 1.31%, #3545F2 90.28%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}

.light-theme-banner{

font-family: 'Poppins' ;font-size: 65px ;
font-weight: 600;
margin-bottom: 0px;
line-height: 100px;
text-align: center;

    color: #FDFDFD;

}
.light-theme-banner11{


  background: linear-gradient(91.13deg, #FE0B0B , #E30F3F , #C4147C , #A519B8);

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

  }
  .light-theme-banner111{
    color: #4C59E3 ;
  }


.light-theme-banner-para{
  font-family: 'Poppins' !important;
    color:#FFFFFF;
    font-family: 'Poppins' ;font-size: 24px ;
    margin-top: 45px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;

}
.light-theme-banner-button-container{
  display: flex;
  align-items: center;
  margin-bottom: 170px;
  gap: 30px;
}
.light-theme-banner-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    font-family: 'Poppins' ;font-size: 24px ;
    font-weight: 700;
    line-height: 22px;
    color: white;
    padding:20px 50px;
    border-radius: 26px;
    outline: none;
    border: none;
    cursor: pointer;
    background: linear-gradient(90deg, #9E1DBE 5.35%, #D01C80 52.68%, #F94633 100%);
}
.light-theme-banner-button1{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  font-family: 'Poppins' ;font-size: 24px ;
  font-weight: 700;
  line-height: 22px;
  color: white;
  padding:18px 20px 18px 30px;
  border-radius: 26px;
  outline: none;
  border: 3px solid white;
  cursor: pointer;
  background: transparent;
}

.light-theme-infrastructure-header{
  z-index: 1000;
  padding-top: 7%;
  padding-left: 12%;
   padding-right: 12%;
  background: url('../../assets/icons/Group811548.svg');
     background-color: rgba(0, 0, 0, 0);
  //    background-position-x: 18%;
  // background-position-y: -50%;
     background-size: 100% auto;
  //  background-position: center !important;
   background-repeat: no-repeat !important;
}

.light-theme-scroll-section{
  box-sizing: border-box;
  display: block;
}
.light-theme-scroll-inner{
  box-sizing: border-box;
  position: sticky;
    top: 0;
    height: auto;
    // background-color: #9cf !important;
    display: flex;
}
.light-theme-left-scroll{
  width: 35%;
    // background-color: #fff;
    border-radius: 0 60px 60px 0;
    // box-shadow: 0 2px 71.44px 4.56px rgba(0,0,0,0.14);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    position: relative;
    z-index: 1;
}
.light-theme-image-box {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  transform: translateX(100%);
  // position: absolute;
  transition: .5s;
  margin-bottom: 100px;
  // padding: 120px;
}
.light-theme-right-scroll{
  width: 65%;
    display: flex;
    flex-direction: column;
    // gap:40px;
    // position: relative;
    // overflow: scroll;
    // padding: 100px 0px;
}
.light-theme-left-scroll-inner{
  max-width: 500px;
    width: 100%;
}
.light-theme-right-side-header-tabs{
  font-family: 'Poppins' ;font-size: 48px ;
  font-weight: 600;
  line-height: 65px;
  text-align: left;
  color: white;
  cursor: pointer;
  opacity: 50%;
  margin-bottom: 40px;
}
.light-theme-right-side-header-tabs-active{
  border: 0.5px solid white;
  border-radius: 20px;
  padding: 5px 20px;
  opacity: 100%;
  background: linear-gradient(90deg, rgba(160, 160, 160, 0.4) 0%, rgba(115, 115, 115, 0.44) 100%);
  ;
}

.light-theme-right-para{
    font-family: 'Poppins' ;font-size: 24px ;
    font-weight: 400;
    line-height: 35px;
    text-align: left;
    color: white;

}


.light-theme-features{
  padding-left: 80px;
  padding-right: 80px;
}
.light-theme-features-header{
font-family: 'Poppins' ;font-size: 60px  !important;
font-weight: 400 !important;
line-height: 80px !important;
text-align: left !important;
color: #121212 !important;
margin-bottom: 5px !important;

}
.light-theme-features-header1{
color: #121212 !important;
font-family: 'Poppins' ;font-size: 60px  !important;
font-weight: 700 !important;
line-height: 80px !important;
text-align: left !important;

}
.light-theme-features-gradient{
  background: linear-gradient(92.91deg, #F60505 22.18%, #4C59E3 44.62%);
  -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

}
.light-theme-features-para{
  margin-top: 40px;
  color: #121212;
font-family: 'Poppins' ;font-size: 20px ;
font-weight: 400;
line-height: 30px;
text-align: left;

}

.light-startK-footer{
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  .light-buildK-field{

      .light-buildK-text-card{
          border-radius: 25px;
            background: #fff;
            color: #000;
            width: 100%;
            padding: 10px 22px  10px 22px;
            font-family: 'Poppins' ;font-size: 12px ;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            transition: all 0.3s ease;
            height: 46px;
            width: 290px;
      }
  }

}
.light-theme-feature-header2{
font-family: 'Poppins' ;font-size: 32px  !important;
font-weight: 700 !important;
line-height: 80px !important;
text-align: left !important;
color: #121212 !important;
margin-bottom: 0px;
}
.light-theme-feature-header2-gradient{
  background: linear-gradient(90deg, #4C59E3 35.02%, #A519B8 69.4%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.light-theme-sdk-card{
  width: 410px;
  height: 217px;
  gap: 0px;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(251, 12, 12, 0.5) 0%, rgba(251, 12, 12, 0.15) 0.01%, rgba(76, 89, 227, 0.15) 100%);
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 4px 40px 0px #00000040;


}
.light-theme-sdk-card-field{
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.light-theme-features-box-heading{
  display: flex;
  align-items: center !important;
  gap: 20px;
}
.light-theme-features-box{
  display: flex;
  flex-direction: column;
  width: 410px;
  padding-top: 10px;

  margin-top: 50px;
}
.light-theme-features-box:hover{
  border-bottom: 1px solid black; /* Add top border for spacing */

  /* Add linear gradient to border-bottom */
  border-image:  linear-gradient(90deg, #4C59E3 0%, #714AB7 21%, #8B4098 36%, #B92E62 62%, #D52241 78%, #FC1313 100%); /* Adjust colors as needed */
  border-image-slice: 1;



}
.light-theme-features-box-para{
  padding-top: 20px;
  font-family: 'Poppins' ;font-family: 'Poppins' ;font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 25px !important;
  text-align: left !important;
  color: #121212 !important;

}
.light-theme-features-row{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blockchaincomplexheader{
  font-family: 'Poppins' ;font-size: 62px  !important;
  font-weight: 600 !important;
  line-height: 65px !important;
  text-align: left !important;
  color: white !important;
  padding-top: 120px;
  margin-bottom: 0px;

}
// .blockchaincomplexheader-border{
//   border-bottom: 1px solid black; /* Add top border for spacing */

//   /* Add linear gradient to border-bottom */
//   border-image:  linear-gradient(90deg, #4C59E3 0%, #714AB7 21%, #8B4098 36%, #B92E62 62%, #D52241 78%, #FC1313 100%); /* Adjust colors as needed */
//   border-image-slice: 1;
//   padding-bottom: 30px;
//   margin-left: 40%;
//   margin-right: 40%;

// }

.BlockchainComplexities{
  background: url('../../assets/icons/Ellipse35.svg');
  background-color: rgba(0, 0, 0, 0);
  background-size:65% 120%;
  padding-left: 12%;
  margin-top: 00px;
  padding-right: 12%;
  background-repeat: no-repeat !important;
  display: flex;
  justify-content: space-between;
}
.blockchaincomplexheader1{
  font-family: 'Poppins' ;font-size: 48px  !important;
  font-weight: 400 !important;
  line-height: 65px !important;
  text-align: center !important;
  color: #121212 !important;

}
.blockchaincomplexheader-gradient{
  background: linear-gradient(91.13deg, #FE0B0B 36.03%, #E30F3F 44.7%, #C4147C 70.65%, #A519B8 89.08%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.blockchaincomplexheader-gradient1{
  background: linear-gradient(258.78deg, #F81F49, #4C59E3) ;


  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.blockchaincomplexheader-para{
  margin-top: 20px;
font-family: 'Poppins' ;font-size: 20px ;
font-weight: 400;
line-height: 31px;
text-align: left;
color: white;

}

.light-theme-blockchain-container{
  padding-top: 200px;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 100px;
}
.light-theme-blockchain{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blockchainlogoborder{
//   padding: 50px;
  border: 1px solid orangered;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.blockchainlogoborder1{

  border-radius: 50%;
  width: fit-content;
  padding: 30px;

  box-shadow:0 -92px 51px 82px rgba(225, 29, 29, 0.09), 0 12px 13px 0 rgba(213, 29, 29, 0.17), 0 4px 6px 0 rgba(218, 31, 31, 0.12), 0 -12px 60px 0 rgba(227, 32, 32, 0.12), 0 54px 55px 0 rgba(230, 15, 15, 0.25);
  background-color: #fff;
}
.BlockchainComplexities-icon-container1-inner{
  margin-right: 0px;
}
.BlockchainComplexities-icon-container3{
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.BlockchainComplexities-icon-container1{
  display: flex;
  gap:20px
}
.BlockchainComplexities-icon-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  border: 1px solid transparent;
  background: #504F4FD6;
  border-radius: 20px;
  height: 240px;
  width: 220px;
  padding: 40px 60px 20px 30px;
}
.BlockchainComplexities-icon{
  height: 80px;
  width: 80px;
}
.BlockchainComplexities-icon-text{
  font-family: 'Poppins' ;font-size: 24px ;
  font-weight: 700;
  // line-height: 80px;
  text-align: left;
  color: white;
}


.paragraph {
  height: 265px;
  width: 250px;
  position: relative;
  perspective: 1000px;
  cursor: pointer;
}

.front,
.back ,.back2, .back3,.back4  , .back5 ,.back6{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 0.5s;
}

.front {

  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap:20px;
  border: 1px solid transparent;
  background: #504F4FD6;
  border-radius: 20px;
  height: 265px;
  width: 250px;
  padding: 40px 60px 20px 30px;

}
.back1{
  padding: 25px;
}

.back {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  color: white;
  font-family: 'Poppins' ;font-size: 14px ;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  background: #504F4FD6;
  border-radius: 20px;
  height: 265px;
  width: 250px;
  transform: rotateY(180deg);
  box-shadow: 5px 5px 11px 11px rgba(250, 78, 45, 0.1), 83px 83px 33px 33px rgba(249, 11, 11, 0.01), 130px 130px 36px 0 rgba(249, 11, 11, 0), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-style: solid;
  border-width: 2px;
  padding: 0px;
  border-image-slice: 0 !important;
  border: 3px solid;

border-image-source: linear-gradient(180deg, #FFFFFF 0%, #4C59E3 48.63%);


  background-image: linear-gradient(to right, #000, #000), linear-gradient(180deg, #FFFFFF 0%, #4C59E3 48.63%);
  background-origin: border-box;
  background-clip: content-box, border-box;

}
.back2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  color: white;
  font-family: 'Poppins' ;font-size: 14px ;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  background: #504F4FD6;
  border-radius: 20px;
  height: 265px;
  width: 250px;
  transform: rotateY(180deg);
  box-shadow: 5px 5px 11px 11px rgba(250, 78, 45, 0.1), 83px 83px 33px 33px rgba(249, 11, 11, 0.01), 130px 130px 36px 0 rgba(249, 11, 11, 0), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-style: solid;
  border-width: 2px;
  padding: 0px;
  border-image-slice: 0 !important;
  border: 3px solid;
border-image-source: linear-gradient(135.21deg, #FFFFFF 0%, #FF1CBF 0%, #FFFFFF 48.45%, #FF98E2 67.21%, #FF00B7 100%);


  background-image: linear-gradient(to right, #000, #000), linear-gradient(135.21deg, #FFFFFF 0%, #FF1CBF 0%, #FFFFFF 48.45%, #FF98E2 67.21%, #FF00B7 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;

}
.back3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  color: white;
  font-family: 'Poppins' ;font-size: 14px ;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  background: #504F4FD6;
  border-radius: 20px;
  height: 265px;
  width: 250px;
  transform: rotateY(180deg);
  box-shadow: 5px 5px 11px 11px rgba(250, 78, 45, 0.1), 83px 83px 33px 33px rgba(249, 11, 11, 0.01), 130px 130px 36px 0 rgba(249, 11, 11, 0), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-style: solid;
  border-width: 2px;
  padding: 0px;
  border-image-slice: 0 !important;
  border: 3px solid;

  border-image-source: linear-gradient(136.58deg, #C12C56 1.65%, #FFFFFF 45.79%, #BF6B83 98.87%);


  background-image: linear-gradient(to right, #000, #000), linear-gradient(136.58deg, #C12C56 1.65%, #FFFFFF 45.79%, #BF6B83 98.87%);
  background-origin: border-box;
  background-clip: content-box, border-box;

}
.back4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  color: white;
  font-family: 'Poppins' ;font-size: 14px ;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  background: #504F4FD6;
  border-radius: 20px;
  height: 265px;
  width: 250px;
  transform: rotateY(180deg);
  box-shadow: 5px 5px 11px 11px rgba(250, 78, 45, 0.1), 83px 83px 33px 33px rgba(249, 11, 11, 0.01), 130px 130px 36px 0 rgba(249, 11, 11, 0), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-style: solid;
  border-width: 2px;
  padding: 0px;
  border-image-slice: 0 !important;
  border: 3px solid;

  border-image-source: linear-gradient(136.73deg, #A61A1E 1.63%, #FFFFFF 50.21%, #A61A1E 96.77%);


  background-image: linear-gradient(to right, #000, #000), linear-gradient(136.73deg, #A61A1E 1.63%, #FFFFFF 50.21%, #A61A1E 96.77%);
  background-origin: border-box;
  background-clip: content-box, border-box;

}
.back5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  color: white;
  font-family: 'Poppins' ;font-size: 14px ;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  background: #504F4FD6;
  border-radius: 20px;
  height: 265px;
  width: 250px;
  transform: rotateY(180deg);
  box-shadow: 5px 5px 11px 11px rgba(250, 78, 45, 0.1), 83px 83px 33px 33px rgba(249, 11, 11, 0.01), 130px 130px 36px 0 rgba(249, 11, 11, 0), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-style: solid;
  border-width: 2px;
  padding: 0px;
  border-image-slice: 0 !important;
  border: 3px solid;

  border-image-source: linear-gradient(134.44deg, #B82F61 1.66%, #FFFFFF 49.98%, #CC8C93 97.39%);


  background-image: linear-gradient(to right, #000, #000), linear-gradient(134.44deg, #B82F61 1.66%, #FFFFFF 49.98%, #CC8C93 97.39%);
  background-origin: border-box;
  background-clip: content-box, border-box;

}
.back6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  color: white;
  font-family: 'Poppins' ;font-size: 14px ;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  background: #504F4FD6;
  border-radius: 20px;
  height: 265px;
  width: 250px;
  transform: rotateY(180deg);
  box-shadow: 5px 5px 11px 11px rgba(250, 78, 45, 0.1), 83px 83px 33px 33px rgba(249, 11, 11, 0.01), 130px 130px 36px 0 rgba(249, 11, 11, 0), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-style: solid;
  border-width: 2px;
  padding: 0px;
  border-image-slice: 0 !important;
  border: 3px solid;

  border-image-source: linear-gradient(136.14deg, #73359C 0%, #FFFFFF 51.24%, #C0A6CD 98.45%);


  background-image: linear-gradient(to right, #000, #000), linear-gradient(136.14deg, #73359C 0%, #FFFFFF 51.24%, #C0A6CD 98.45%);
  background-origin: border-box;
  background-clip: content-box, border-box;

}

.paragraph.flipped .front {
  transform: rotateY(180deg);
}

.paragraph.flipped .back ,.paragraph.flipped .back2 ,.paragraph.flipped .back3 ,.paragraph.flipped .back4 ,.paragraph.flipped .back5 ,.paragraph.flipped .back6  {
  transform: rotateY(0deg);

}

.light-theme-application-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 14%;
  padding-right: 14%;
  padding-top: 200px;
}

.light-theme-application-header{
  font-family: 'Poppins' ;font-size: 62px  !important;
  font-weight: 700 !important;
  line-height: 60px !important;
  text-align: center !important;
  color: white !important;
}
.light-theme-application-header-grad{
  background: linear-gradient(89.97deg, #FC050A -70.01%, #9E20B3 97.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.light-theme-application-header-para{
  font-family: 'Poppins' ;font-size: 24px ;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
  color: white;

}
.light-theme-application-img{
  height: auto;
  width: auto;
  margin-top: 20px;
}
.light-theme-application-header-mini{
font-family: 'Poppins' ;font-size: 30px ;
font-weight: 700;
line-height: 40px;
text-align: left;
color: white;
}
.light-theme-application-para-mini{
font-family: 'Poppins' ;font-size: 16px;
font-weight: 400;
line-height: 25px;
text-align: left;
color: white;
margin-top: 20px;
}

.light-theme-application-contauiner2{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap:40px;
  margin-top: 100px;
}
.light-theme-application-contauinerf{
  width: 50%;
}
.light-theme-application-contauinerf:hover{
  border-radius: 22px;
  box-shadow: 5px 5px 11px 11px rgba(250, 78, 45, 0.1), 83px 83px 33px 33px rgba(249, 11, 11, 0.01), 130px 130px 36px 0 rgba(249, 11, 11, 0), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-style: solid;
  border-width: 2px;
  padding: 0px;
  border-image-slice: 0 !important;
  border: 2px solid;
 border-image-source: linear-gradient(98.42deg, #FF0000 , #E10F26 , #B7245B , #823E9F , #4C59E3 );
 cursor: default;
  background-image: linear-gradient(to right, #fff, #fff), linear-gradient(to right, #f80a0a, #4c59e3);
  background-origin: border-box;
  background-clip: content-box, border-box;

}

.light-theme-application-contauiner1{
  width: 100%;
 display: flex;
 justify-content: space-between;
 align-items: center;
 gap:40px
}

.light-theme-application-contauiner{
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap:8px;
  border: 0px solid none;
  border-radius: 20px;
  padding: 18px 40px 10px 40px;
  width:100%;
  height: 245px;
  background: linear-gradient(180deg, #616060 0%, #222222 100%);


}



.light-theme-blockchain-header{
  font-family: 'Poppins' ;font-size: 60px  !important;
  font-weight: 700 !important;
  line-height: 80px !important;
  text-align: left !important;
  color: white !important;
}
.light-theme-blockchain-header-gradient{
  background: linear-gradient(90deg, #CC2080 , #EF1E55, #9622C2 );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.light-theme-blockchain-para{
  font-family: 'Poppins' ;font-size: 24px ;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: white;

}

.marquee-content1 {
  display: flex;
  animation: marquee-left 50s linear infinite;
}

@keyframes marquee-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee-content1:hover {
  // -moz-animation-play-state: paused;
  // -webkit-animation-play-state: paused;
  // animation-play-state: paused;
 }

 .marquee-content2 {
  display: flex;
  animation: marquee-right 50s linear infinite;
}

@keyframes marquee-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.marquee-content2:hover {
  // -moz-animation-play-state: paused;
  // -webkit-animation-play-state: paused;
  // animation-play-state: paused;
 }

 .light-theme-plandetails-sec2-image-container_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:2rem;
  margin-top: 50px;

}
.light-theme-plandetails-sec2-image-container_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:2rem;
  margin-top: 120px;

}

.light-theme-clientlogo-wrap {
  width: 100%;
  overflow-x: hidden;
  background-color:black;
}
.light-theme-clientlogowidth{
  width: 70px;
  height: 50px;
}

.light-theme-web3-features-container{
  padding-top: 10px;
}

.light-theme-web3-features-semicontainer{
  display: flex;
  align-items: center;
  margin-top: 80px;
}
.light-theme-web3-features-leftlogo{
  height: auto;
  width: auto;
}
.light-theme-web3-features-rightlogo{
  height: auto;
  width: auto;
}


.light-theme-web3-features-header{
  margin-left: 10px !important;
    font-family: 'Poppins' ;font-size: 48px  !important;
    font-weight: 600 !important;
    line-height: 65px !important;
    text-align: left !important;
    color: #121212 !important;
    margin-bottom: 0px !important;
}

.light-theme-web3-features-para{
  margin-top: 30px;
  margin-left: 140px;
  font-family: 'Poppins' ;font-size: 20px ;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #121212;
}
.light-theme-web3-features-semicontainer1{
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 80px;
}
.light-theme-web3-features-header1{
  margin-right: 10px !important;
    font-family: 'Poppins' ;font-size: 48px  !important;
    font-weight: 600 !important;
    line-height: 65px !important;
    text-align: right !important;
    color: #121212 !important;
    margin-bottom: 0px !important;
}

.light-theme-web3-features-para1{
  margin-top: 30px;
  margin-right: 140px;
  font-family: 'Poppins' ;font-size: 20px ;
  font-weight: 400;
  line-height: 35px;
  text-align: right;
  color: #121212;
}

.light-theme-pricing-container{
  padding-top: 100px;
}

.light-theme-pricing-container-inner2{
  background: url('../../assets/icons/Group811584.svg');
  background-color: rgba(0, 0, 0, 0);
  background-size: 100% auto;
  background-repeat: no-repeat !important;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content:center;
  gap: 10px;
  // padding-top: 100px;
  margin-top: 80px;
  padding-top: 30px;
  padding-bottom: 100px;
}

.light-theme-pricing-container-inner2pricing{
  background-color: rgba(0, 0, 0, 0);
  background-size: 100% auto;
  background-repeat: no-repeat !important;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content:center;
  gap: 10px;
  // padding-top: 100px;
  margin-top: 80px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.light-theme-pricing-container-inner111{
  padding: 30px 30px 50px 30px;
  display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 23px;
}
.light-theme-pricing-container-inner11{
  border: solid 2px #e4e2e2;
  width: 363px;
  border-radius: 24px;


}
// .light-theme-pricing-container-inner11:hover{
//  margin-left: -20px;
//   margin-right: -20px;
//   background-color: white;
//   border-radius: 26px !important;
//   padding: 1px;
//   z-index: 10;
//   height: 570px;
//   box-shadow: 0 9px 20px 0 rgba(85, 97, 227, 0.1), 0 36px 36px 0 rgba(85, 97, 227, 0.09), 0 82px 49px 0 rgba(85, 97, 227, 0.05), 0 146px 58px 0 rgba(85, 97, 227, 0.01), 0 228px 64px 0 rgba(85, 97, 227, 0);
//   border-style: solid;
//   border-width: 1px;
//   border-image-slice: 1;
//   background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #4c59e3, #f81f49);
//   background-origin: border-box;
//   background-clip: content-box, border-box;

// }
.light-theme-pricing-container-inner112{
  padding: 30px 30px 50px 30px;
  display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 23px;
}

.light-theme-pricing-container-inner12{
  border: solid 2px #e4e2e2;
  width: 363px;
  border-radius: 24px;


}
// .light-theme-pricing-container-inner12:hover{
//   margin-left: -20px;
//   margin-right: -20px;
//   background-color: white;
//   z-index: 10;
//   height: 570px;
//   box-shadow: 0 9px 20px 0 rgba(85, 97, 227, 0.1), 0 36px 36px 0 rgba(85, 97, 227, 0.09), 0 82px 49px 0 rgba(85, 97, 227, 0.05), 0 146px 58px 0 rgba(85, 97, 227, 0.01), 0 228px 64px 0 rgba(85, 97, 227, 0);
//   border-style: solid;
//   border-width: 1px;
//   padding: 1px;
//   // border-image-slice: 1;
//   background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #4c59e3, #f81f49);
//   background-origin: border-box;
//   background-clip: content-box, border-box;

// }
.light-theme-pricing-container-inner113{
  padding: 30px 30px 50px 30px;
  display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 23px;
}
.light-theme-pricing-container-inner13{
  border: solid 2px #e4e2e2;
  width: 363px;
  border-radius: 24px;

}
// .light-theme-pricing-container-inner13:hover{
//   margin-left: -20px;
//   margin-right: -20px;
//   background-color: white;
//   z-index: 10;
//   height: 570px;
//   box-shadow: 0 9px 20px 0 rgba(85, 97, 227, 0.1), 0 36px 36px 0 rgba(85, 97, 227, 0.09), 0 82px 49px 0 rgba(85, 97, 227, 0.05), 0 146px 58px 0 rgba(85, 97, 227, 0.01), 0 228px 64px 0 rgba(85, 97, 227, 0);
//   border-style: solid;
//   border-width: 1px;
//   padding: 1px;
//   border-image-slice: 1;
//   background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #4c59e3, #f81f49);
//   background-origin: border-box;
//   background-clip: content-box, border-box;

// }

.light-theme-pricing-header{
  font-family: 'Poppins' ;font-size: 60px  !important;
  font-weight: 700 !important;
  line-height: 80px !important;
  text-align: center !important;
  color: white !important;
}
.light-theme-pricing-container-header{
  font-family: 'Poppins' ;font-size: 32px ;
  font-weight: 700;
  line-height: 80px;
  text-align: center;
  color: #121212;
}
.light-theme-pricing-container-para{
  font-family: 'Poppins' ;font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #121212;
}
.light-theme-pricing-container-inner{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.light-theme-pricing-feature{
  border: 1px solid #E5E5E7;
  text-align: center;
//  width: fit-content;
  border-radius: 50px;
  white-space: nowrap;
  margin-top: 5px;
  padding:12px 71px;
  font-family: 'Poppins' ;font-size: 16px;
  font-weight: 400;
  line-height: 25px;

}
.light-theme-pricing-btn{
  border: 1px solid #E5E5E7;
  background-color: white;
  text-align: center;
//  width: fit-content;
  border-radius: 50px;
  margin-top: 20px;
  padding:12px 42px;
  font-family: 'Poppins' ;font-size: 15px ;
  font-weight: 700;
  line-height: 22px;
  background: linear-gradient(90deg, #4C59E3 0%, #92122B 100%);
  -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

}
.light-theme-blogs-container-main{
  padding-top: 80px;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 150px;
}
.light-theme-blogs-container-main-join{
  padding-top: 50px;
  padding-left: 75px;
  padding-right: 75px;
  padding-bottom: 100px;
}
.light-theme-blogs-header{
  font-family: 'Poppins' ;font-size: 60px  !important;
  font-weight: 700 !important;
  line-height: 80px !important;
  text-align: left !important;
  color: white !important;
}
.light-theme-blogs-container{
  display: flex;

  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  gap:30px;
  margin-top: 70px;
}
.light-theme-blogs-container-image{
  height: 260px;
  width: 500px;
  border-radius: 20px;
}
.light-theme-blogs-readmore{
  margin-top: 50px;
  font-family: 'Poppins' ;font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: right;
  color: #121212 ;
}

.light-theme-join-community-container{
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
}

.light-theme-join-community-header{
  font-family: 'Poppins' ;font-size: 60px  !important;
  font-weight: 700 !important;
  line-height: 56px !important;
  text-align: left !important;
  color: white !important;
}
.light-theme-join-community-para{
  font-family: 'Poppins' ;font-size: 24px ;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #121212;
}
.light-theme-join-community-container-inner{
  // margin-right: 100px;
  // width: fit-content;
  // box-shadow: 0 9px 20px 0 rgba(85, 97, 227, 0.1), 0 36px 36px 0 rgba(85, 97, 227, 0.09), 0 82px 49px 0 rgba(85, 97, 227, 0.05), 0 146px 58px 0 rgba(85, 97, 227, 0.01), 0 228px 64px 0 rgba(85, 97, 227, 0);
  // border-width: 2px;
  // border-radius: 24px;
  // padding: 1px;
  // border-image-slice: 1;
  // background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #4c59e3, #f81f49);
  // background-origin: border-box;
  // background-clip: content-box, border-box;
}
.light-theme-join-community-container-inner1{
  padding: 50px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.light-theme-join-community-container-row{
  display: flex;
  gap: 40px;
}
.light-theme-join-community-container-row1{
  display: flex;
  justify-content: center;
  gap: 40px;
}
.light-theme-join-community-img{
  border: 1.8px solid transparent;
  background-color: white;
  border-radius: 50px;
  padding: 8px
}

.ant-tabs {
  .ant-tabs-nav::before {
    border-bottom:3px  solid white;
    border-radius: 50px;
    opacity: 70%;
  }

  // .ant-tabs-tab {
  //   font-weight: 400;
  //   font-family: 'Poppins' ;font-size: 18px ;
  //   line-height: 10px;
  //   color: #fff !important;
  //   opacity: 0.8 !important;
  //   background: transparent;
  // }
  .ant-tabs-ink-bar {
    display: none; /* Hide the bottom line indicator */
  }
  .ant-tabs-nav .ant-tabs-tab-active::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;

    width: 100%;
    border-radius: 50px;
    height: 3px; /* Adjust the thickness of the gradient line */
    background: linear-gradient(90deg, #F81F49 20%, #9D3E9B 50%, #4C59E3 100%);
  }

   .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 3px solid transparent !important;
    border-bottom: 5px solid;

  //  border-image-source: linear-gradient(90deg, #F81F49 0%, #9D3E9B 53%, #4C59E3 100%);


  //   z-index: 1;
  }

  // .ant-tabs-tab-btn:focus,
  // .ant-tabs-tab-remove:focus,
  // .ant-tabs-tab-remove:active {
  //   color: rgba(239, 239, 239, 0.4);
  // }

  // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  // .ant-tabs-tab-btn:active {
  //   color: #efefef;
  // }
}

.community-resource-container-main{
  display: flex;
  margin-top: 70px;
  align-items: center;
  flex-wrap: wrap;
  gap:30px
  // justify-content: space-between;
}

.community-resource-header{
color: black !important;
font-family: 'Poppins' ;font-size: 32px  !important;
font-weight: 700 !important;
line-height: 50px !important;
text-align: left !important;
margin-bottom: 10px;

}
.community-resource-container{
  width: 30%;
  border-radius: 20px;
  background-color: white;
  padding: 30px 20px 40px 40px;
}
.community-resource-container-mini{
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.community-resource-text{
  display: flex;
  align-items: center;
font-family: 'Poppins' ;font-size: 16px;
font-weight: 400;
line-height: 30px;
color: black;
text-align: left;

}
.community-resource-text-dot{
  height:8px;
  width:8px;
  margin-bottom:2px;
  border-radius:50px;
  background-color:black;
  margin-right: 10px;
}
.community-resource-header1{
  color: white !important;
  font-family: 'Poppins' ;font-size: 32px  !important;
  font-weight: 700 !important;
  line-height: 50px !important;
  text-align: left !important;
  margin-bottom: 10px;

  }
  .community-resource-container1{
    width: 30%;
    border-radius: 20px;
    border: 1px solid rgba(111, 111, 111, 0.64);
    background: linear-gradient(180deg, rgba(111, 111, 111, 0.64) 0%, rgba(33, 33, 33, 0.68) 100%);
    padding: 30px 20px 40px 40px;
  }
  .community-resource-container-mini1{
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .community-resource-text1{
    display: flex;
    align-items: center;
  font-family: 'Poppins' ;font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: white;
  text-align: left;

  }
  .community-resource-text-dot1{
    height:8px;
    width:8px;
    margin-bottom:2px;
    border-radius:50px;
    background-color:white;
    margin-right: 10px;
  }

  .community-resource-container2{
    color: white;
    font-family: 'Poppins' ;font-size: 20px ;
    font-weight: 700;
    padding: 15px 30px;
    text-align: left;
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 20px;
    background-color: #4B4B4B;
    border-radius: 50px;
    width: fit-content;

  }

.community-resource-logo{
  height: 70px;
  width: 70px;
}

.community-container{
  margin-top: 80px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  gap:30px;
}
.light-theme-banner-bottom-container{
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 150px;
}
.light-theme-banner-bottom-logo{
  text-align: center;
}
.light-theme-banner-bottom-logo1{
  height: 80px;
  width: 340px;
}
.light-theme-banner-bottom-header{
    font-family: 'Poppins' ;font-size: 60px  !important;
    font-weight: 700 !important;
    line-height: 80px !important;
    text-align: center !important;
    color: white !important;
    margin-top: 80px !important;
}
.light-theme-banner-bottom-header-gradient{
  background: linear-gradient(89.6deg, #EE0F0F , #4C59E3 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.light-theme-banner-bottom-para{
  font-family: 'Poppins' ;font-size: 24px ;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
  color: white;
  margin-top: 30px;
}
.light-theme-banner-bottom-btn-container{
  display: flex;
  justify-content: center;
  gap:20px;
  margin-top: 60px;
}
.light-theme-banner-bottom-btn1{
  display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins' ;font-size: 24px ;
    font-weight: 700;
    line-height: 22px;
    color: white;
    height: 65px;
    padding:25px 60px;
    border-radius: 26px;
    outline: none;
    border: none;
    cursor: pointer;
    background: linear-gradient(90deg, #9E1DBE 5.35%, #D01C80 52.68%, #F94633 100%);
}
.light-theme-banner-bottom-btn2{
  display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins' ;font-size: 24px ;
    font-weight: 700;
    line-height: 22px;
    color: black;
    height: 65px;
    padding:25px 45px;
    border-radius: 26px;
    outline: none;
    border: 1px solid #121212;
    cursor: pointer;
    background: white;
}
.light-theme-banner-bottom-bg{
  height: 250px;
  background: url('../../assets/icons/Group_237.svg');
  background-position-x: 18%;
  background-position-y: 12%;
  background-color: rgba(0, 0, 0, 0);
  // background-position: center !important;
  // background-repeat: no-repeat !important;
  // background-size: cover !important;
}

.light-theme-footer-container{
  background: url('../../assets/icons/Group811563.png');
     background-color: #202020;
  //    background-position-x: 18%;
  // background-position-y: -50%;
     background-size: 100% auto;
  //  background-position: center !important;
   background-repeat: no-repeat !important;
  // background: #202020;
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
  padding-bottom: 65px;
  padding-left: 12%;
  padding-right: 12%;
}
.light-theme-footer-container1{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.light-theme-footer-logos-container{
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.light-theme-footer-container-para{
    font-family: 'Poppins' ;font-size: 20px ;
    font-weight: 400;
    line-height: 35px;
    text-align: left;
    color: white;
    margin-top: 50px;
}
.light-theme-footer-logos{
  border: 2px solid white;
  border-radius: 50px;
  height: auto;
  width: auto;
  padding: 10px;
}
.light-theme-footer-left-container{
  display: flex;
   gap: 100px;
}

.light-theme-footer-left-container1{
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.light-theme-footer-left-container2{
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.light-theme-footer-right-bottom-content{
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 100px;
}

.light-theme-footer-input-container{
  background-color:white;
  width: fit-content;
  padding: 7px;
  border-radius: 11px;
}
.light-theme-footer-input{
  background-color: white;
  outline: none;
  border: none;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  width: 300px;
  font-family: 'Poppins' ;font-size: 14px ;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #121212;
}
.light-theme-footer-input-submit-btn{
  color: white;
  font-family: 'Poppins' ;font-size: 16px;
  padding: 0px 20px;
  font-weight: 700;
  border-radius: 11px;
  line-height: 35px;
  text-align: left;
  border: none;
  outline: none;
  background: linear-gradient(110deg, rgba(76, 89, 227, 0.5) -13%, rgba(108, 73, 196, 0.9) 8%, rgba(147, 54, 159, 0.78) 34%, rgba(177, 39, 129, 0.69) 54%, rgba(210, 23, 98, 0.59) 75%, rgba(239, 9, 70, 0.5) 95%);
}
.light-theme-footer-privacy-policy-container{
  justify-content: last baseline;
  display: flex;
  gap: 20px;
  color: white;
  font-family: 'Poppins' ;font-size: 15px ;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  margin-top: 30px;

}
.light-theme-footer-left-container-text{
    font-family: 'Poppins' ;font-size: 22px ;
    font-weight: 400;
    line-height: 50px;
    text-align: left;
    color: white;

}

@media (max-width:1540px){
  .navbar {
    padding: 5px 12% 5px 12% !important;

}
.text-white-signl1 {
  font-size: 18px !important;
    font-weight: 500 !important;
}
.faucetlogo{
    height: 80px;
    width: 82px;

}
.light-theme-application-contauiner {
  height: 205px;
  padding: 12px 40px 1px 40px;
}
.light-theme-application-contauiner2{
  gap:25px;
  margin-top: 60px;
}



.light-theme-application-contauiner1{
  gap:25px
}
.light-theme-application-header-mini {
  font-size: 24px;
}
.light-theme-application-img {
  height: 68px;
  width: auto;
  margin-top: 20px;
}
  .light-theme-application-para-mini{
    font-size: 13.5px;
  }
  .nav-item .nav-link1{
    font-size: 18px ;
    font-weight: 450;
  }
  .text-white-signl{
    font-size: 18px !important ;
    font-weight: 450;
}
.light-theme-banner{
  font-size: 54px ;
  line-height: 75px;
  }
  .navbar-brand img.logo {
    height: 45px;
    width: 180px;
}
  .light-theme-banner-para{
    font-size: 18px ;
    margin-top: 30px;
  }
  .light-theme-banner-button{
    font-size: 18px ;
    padding:13px 38px;

}
.light-theme-banner-button1{
  font-size: 18px ;
  padding:12px 15px 12px 24px;
}
.light-theme-banner-container{
  padding-top: 125px;
}
.blockchaincomplexheader{
 font-size: 48px  !important;
 padding-top: 85px;
}
.blockchaincomplexheader-para{
  font-size: 16px ;
}
.BlockchainComplexities-icon-text {
  font-size: 20px;
  font-weight: 700;

}
.paragraph {
  height: 220px;
  width: 210px;
}
.front {
  height: 220px;
  width: 210px;
}
.BlockchainComplexities-icon {
  height: 70px;
  width: 70px;
}
.back1 {
  padding: 20px;
}
.back {
  height: 220px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
}
.back2 {
  height: 220px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
}
.back3 {
  height: 220px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
}
.back4 {
  height: 220px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
}
.back5 {
  height: 220px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
}
.back6 {
  height: 220px;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
}
.BlockchainComplexities-icon-container3{
  padding-top: 85px;

  gap: 10px;
}

.BlockchainComplexities-icon-container1{
  gap:10px
}
.light-theme-application-header{
  font-size: 48px  !important;
}

.light-theme-application-header-para{
  font-size: 17px;
  line-height: 30px;
}
.light-theme-blockchain-header {
  font-size: 48px !important;
  line-height: 60px !important;

}
.light-theme-blockchain-para {
  font-size: 20px;
  line-height: 32px;
}
.blockchainlogoborder {
  width: 320px;
}
.light-theme-blockchain-container {
  padding-right: 8%;
  padding-bottom: 70px;
}
.light-theme-plandetails-sec2-image-container_2 {
  margin-top: 80px;
}
.light-theme-banner-bottom-header {
  font-size: 48px !important;
  line-height: 60px !important;
  margin-top: 60px !important;
}
.light-theme-banner-bottom-para {
  font-size: 18px;
  line-height: 30px;
  margin-top: 24px;
}
.light-theme-banner-bottom-btn1 {
  font-size: 20px;
  height: 50px;
  padding: 20px 55px;
}
.light-theme-banner-bottom-btn2 {
  font-size: 20px;
  height: 50px;
  padding: 20px 35px;
}
.light-theme-pricing-header {
  font-size: 54px !important;
}
.light-theme-pricing-container-header {
  font-size: 24px;
  line-height: 50px;
}
.light-theme-pricing-container-para {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0px;
}
.light-theme-pricing-container-inner {
  margin-top: 15px;
}
.light-theme-pricing-feature {
  padding: 8px 50px;
  font-size: 15px;
}
.light-theme-pricing-btn {
  margin-top: 15px;
  padding: 8px 26px;
  font-size: 14px;
}

.light-theme-pricing-container-inner11 {
  width: 310px;
}
.light-theme-pricing-container-inner111 {
  padding: 25px 20px 35px 20px;
}
.light-theme-pricing-container-inner12 {
  width: 310px;
}
.light-theme-pricing-container-inner112 {
  padding: 25px 20px 35px 20px;
}
.light-theme-pricing-container-inner13 {
  width: 310px;
}
.light-theme-pricing-container-inner113 {
  padding: 25px 20px 35px 20px;
}
.light-theme-pricing-container-inner2 {
  margin-top: 60px;
}
.light-theme-blogs-header {
  font-size: 48px !important;
}
.light-theme-blogs-container-image {
  height: 200px;
  width: 390px;

}
.community-resource-header {
  font-size: 28px !important;

}
.community-resource-header1 {
  font-size: 28px !important;

}
.light-theme-footer-container-para {
  font-size: 18px;
}
.light-theme-footer-left-container-text {
  font-size: 20px;
  line-height: 45px;
}

}

@media (max-width:1440px){
  .navbar {
    padding: 15px 40px 15px 40px !important;
  }
  .light-theme-infrastructure-header{
    padding-left: 40px;
    padding-right: 40px;
  }
  .BlockchainComplexities{
    padding-left: 40px;
    padding-right: 40px;
  }
  .light-theme-application-main{
    padding-left: 40px;
    padding-right: 40px;
  }
  .light-theme-blockchain-container{
    padding-left: 40px;
    padding-right: 40px;
  }
  .light-theme-blogs-container-main{

    padding-left: 40px;
    padding-right: 40px !important;
  }
  .light-theme-footer-container{
    padding-left: 40px;
    padding-right: 40px;
  }
  .light-theme-application-contauiner{

  }
  .light-theme-application-para-mini{
    font-size: 15px;
  }
  .light-theme-blogs-container-image{
    height: 240px;
    width: 450px;

  }

}



@media (max-width: 1200px) {
  .light-theme-application-contauinerf{
    width: 100%;
  }
  .navbar-brand img.logo {
    height: 30px; /* Adjust the height as needed */
    width: 128px;
  }
  .faucetlogo1{
    width: fit-content;
    // box-shadow: 0 9px 20px 0 rgba(85, 97, 227, 0.1), 0 36px 36px 0 rgba(85, 97, 227, 0.09), 0 82px 49px 0 rgba(85, 97, 227, 0.05), 0 146px 58px 0 rgba(85, 97, 227, 0.01), 0 228px 64px 0 rgba(85, 97, 227, 0);
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    margin-right: 0px;
    // border-image-source: linear-gradient(to bottom, #4c59e3, #f81f49);
    border-image-slice: 0 !important;
    background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #4c59e3, #f81f49);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid;
   border-image-source: radial-gradient(53.25% 53.25% at 49.32% 53.25%, #F21111 0%, rgba(241, 10, 10, 0.5) 14.29%, #D84343 28.57%, rgba(156, 34, 202, 0) 42.86%, #CD2526 57.14%, #CD3F86 71.43%, #F0F0FC 85.71%, #4C59E3 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

  }

  .faucetlogo{
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    background: white;
      padding: 7px 8px 10px 9px;
      font-family: 'Poppins' ;font-size: 20px ;
      font-weight: 700;
      height: 70px;
      cursor: pointer;
      width: 72px;
      align-items: center;
      justify-content: center;
      line-height: 24px;
      text-align:center;
      color: #FFFFFF;
  }
  .faucetlogo-properties{
    display: none;
  }
  .faucetlogo-properties1{
    display: block;
    height: 30px;
    width: 30px;
  }
  .faucetlogo-text{
    font-family: 'Poppins' ;font-size: 12px ;
  }
  .expandResource{
    display: none !important;
  }
  .mobile_responsive_header{
    display: flex !important;
    align-items: center;
    text-align: left !important;
    margin-left: 35px !important;
    width: fit-content !important;
  }
  .nav-item .nav-link{
    cursor: pointer;
    margin-left: 35px !important;
    padding-left: 0px !important;
  }
  .text-whiteR{
    display: none !important;
    }
    .text-white1{
      display: none;
    }
  .nav-item {
    display: flex; /* Hide the navigation items */
  }

  .navbar-toggler {
    display: block; /* Show the toggler for screens up to 1200px */
  }
  .navbar-logo-division {
    margin-right  : 17%;
  }
  .navbar-collapse {
    display:none;
    background-color: white;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index:111;
    font-size:20px;
  }

  .navbar-nav {

    text-align: end;
    display: flex !important;
    flex-direction: row !important;
  }

  .navbar-collapse.show {

    display: block !important;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
         background:black !important;
          font-size:20px;
          padding:10px;
  }

  .navbar-collapse li {
    text-align: center;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }
  .light-theme-banner{

    font-family: 'Poppins' ;font-size: 35px ;
    font-weight: 700;
    line-height: 46px;
    text-align: left;
    }
    .light-theme-banner-container{
      padding-left: 20px;
      padding-right: 20px;
      min-height: auto;
    }
    .light-theme-banner-para{
      font-family: 'Poppins' ;font-size: 14px ;
      margin-top: 25px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
  }

  .light-theme-application-main{

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 80px;
  }
  .light-theme-application-contauiner1{
    flex-direction: column;
    gap:30px
  }
  .light-theme-application-contauiner{
    width: 100%;
    height: auto;
    padding: 20px 20px 10px 20px;
  }
  .light-theme-application-header-mini{
    font-family: 'Poppins' ;font-size: 28px ;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: white;
    }
    .light-theme-application-para-mini{
    font-family: 'Poppins' ;font-size: 14px ;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: white;
    }
    .light-theme-application-header{
      font-family: 'Poppins' ;font-size: 32px  !important;
      line-height: 40px !important;
    }
    .light-theme-application-header-para{
      font-family: 'Poppins' ;font-size: 16px;
      line-height: 24px;
    }

    .BlockchainComplexities{
      background: none;
      background-color: rgba(0, 0, 0, 0);
      background-size:65% 120%;
      padding-left: 20px;
      margin-top: 00px;
      padding-right: 20px;
      background-repeat: no-repeat !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .blockchaincomplexheader1{
      font-family: 'Poppins' ;font-size: 32px  !important;
      font-weight: 400 !important;
      line-height: 65px !important;
      text-align: center !important;
      color: #121212 !important;

    }
    .blockchaincomplexheader-gradient{
      background: linear-gradient(90deg, #FF0404, #9622C1 );

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .blockchaincomplexheader-para{
      margin-top: 0px;
    font-family: 'Poppins' ;font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: white;

    }

    .light-theme-blockchain-container{
      padding-top: 200px;
      padding-left: 12%;
      padding-right: 12%;
      padding-bottom: 100px;
    }
    .light-theme-blockchain{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .blockchainlogoborder{
      padding: 50px;
      border: 1px solid orangered;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .blockchainlogoborder1{

      border-radius: 50%;
      width: fit-content;
      padding: 30px;

      box-shadow: 0 -3px 5px 0 rgba(225, 29, 29, 0.09), 0 12px 13px 0 rgba(213, 29, 29, 0.17), 0 4px 6px 0 rgba(218, 31, 31, 0.12), 0 -12px 30px 0 rgba(227, 32, 32, 0.12), 0 54px 55px 0 rgba(230, 15, 15, 0.25);
      background-color: #fff;
    }
    .BlockchainComplexities-icon-container1-inner{
      margin-right: 0px;
    }
    .BlockchainComplexities-icon-container3{
      padding-top: 80px;

    }

    .BlockchainComplexities-icon-container1{
      display: flex;
      flex-direction: column;
      gap:20px
    }
    .BlockchainComplexities-icon-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap:20px;
      border: 1px solid transparent;
      background: #504F4FD6;
      border-radius: 20px;
      height: 240px;
      width:300px;
      padding: 40px 60px 20px 30px;
    }
    .BlockchainComplexities-icon{
      height: 80px;
      width: 80px;
    }
    .BlockchainComplexities-icon-text{
      font-family: 'Poppins' ;font-size: 24px ;
      font-weight: 700;
      // line-height: 80px;
      text-align: left;
      color: white;
    }


    .paragraph {
      width: 100%;
      height: 240px;
      position: relative;
      perspective: 1000px;
      cursor: pointer;
    }

    .front,
    .back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transition: transform 0.5s;
    }

    .front {

      display: flex;
      flex-direction: column;
      align-items: center;
      gap:20px;
      border: 1px solid transparent;
      background: #504F4FD6;
      border-radius: 20px;
      height: 240px;
      width: 100%;
      padding: 40px 60px 20px 30px;

    }

    .back {

      gap:20px;
      color: white;
      font-family: 'Poppins' ;font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
      border-radius: 20px;
      height: 240px;
      width: 100%;
      padding: 0px ;

    }
    .back1{
      padding: 20px;
    }

    .paragraph.flipped .front {
      transform: rotateY(180deg);
    }

    .paragraph.flipped .back {
      transform: rotateY(0deg);

    }

}
@media (max-width: 767px) {
  .navbar {
    padding: 15px 20px 15px 20px !important;
  }

  @-webkit-keyframes fadeInUp1 {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInUp1 {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  .fadeInUp1 {
    -webkit-animation-name: fadeInRight !important;
  animation-name: fadeInRight !important;
  }


  .text-white-sign{
    font-style: normal;
    font-weight: 500;
    font-family: 'Poppins' ;font-size: 15px ;
    line-height: 22px;
    padding: 10px 20px 10px 20px;
    background: #fff;
    border-radius: 50px;
    color: white;
    border: 1px solid #e5e5e7;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    }
  .mobile_responsive_header{
    display: flex !important;
    justify-content: center;
    // flex-direction: column !important;
    text-align: left !important;
    // align-items: flex-start;
    margin-left: 32px !important;
    width: fit-content !important;
  }
  .navbar-nav {

    flex-direction: column !important;
  }
  .text-whiteR{
    display: none !important;
    }
    .text-white1{
      display: none;
    }

  .nav-item {
        display: grid; /* Hide the navigation items */
  }
  .navbar-logo-division {
      margin-right  : 0% !important;
    }

  .navbar-toggler {
    display: block; /* Show the toggler for screens up to 360px */
  }

  .navbar-collapse {
    display:none;
    background: transparent;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    font-size:18px;

  }
  .nav-item .nav-link1{
    cursor: pointer;
    margin-left: 32px;
    color: white;
    cursor:pointer;
    font-family: 'Poppins' ;font-size: 20px ;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
  }
  .text-white-signl{
    font-family: 'Poppins' ;font-size: 20px  !important;
    font-weight: 600 !important;
    line-height: 24px;
    text-align: left;
   color: white\ !important;
   cursor:pointer;
}

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
    display: flex; /* Show the navbar items when toggler is clicked */
     display: block !important;
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            background:transparent;
            padding:10px;
  }

  .navbar-collapse li {
    text-align: left;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }
  .light-theme-infrastructure-header{
    padding-left: 20px;
    padding-right: 20px;
  }


  .menu-right .header-signupbtn {
    font-family: 'Poppins' ;font-size: 14px ; /* Adjust the font size for smaller screens */
  }

  .light-theme-hiddenbreaktag{
    display: none;
  }
  .light-theme-hiddenbreaktag1{
    display:flex;
  }
  .light-theme-banner-para{
    font-family: 'Poppins' ;font-size: 16px;
    margin-top: 25px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.light-theme-banner-button-container{

  margin-bottom: 70px;
  gap: 10px;
}
.light-theme-banner-button{
  margin-top: 20px;
  font-family: 'Poppins' ;font-size: 16px;
  line-height: 22px;
  padding:15px 25px;
  border-radius: 50px;
}
.light-theme-banner-button1{
  margin-top: 20px;
  font-family: 'Poppins' ;font-size: 16px;
  line-height: 22px;
  padding:13px 20px;
  border-radius: 50px;
}

.light-theme-banner-container{
  width: 100%;
  display: flex;
  padding-top: 80px;
  align-items: start;
}

.light-theme-features{
  padding-left: 20px;
  padding-right: 20px;
}
.light-theme-features-header{
  font-family: 'Poppins' ;font-size: 32px  !important;
  font-weight: 500 !important;
  line-height: 40px !important;
}
.light-theme-features-header1{
  font-family: 'Poppins' ;font-size: 32px  !important;
  font-weight: 700 !important;
  line-height: 40px !important;
}
.light-theme-features-para{
  margin-top: 20px;
  font-family: 'Poppins' ;font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.light-theme-features-box{
  padding-top: 10px;

  margin-top: 50px;
}
.light-theme-feature-header2{
  font-family: 'Poppins' ;font-size: 24px  !important;
  line-height: 60px !important;

  }
  .light-startK-footer{

    .light-buildK-field{

        .light-buildK-text-card{
              padding: 6px 10px  6px 10px;
              font-size: 10px;
              height: 36px;
              width: 220px;
              margin-right: 10px;
        }
    }

  }
  .light-theme-sdk-card{
    width: 100% !important;
    height: 200px;
  }
  .light-theme-sdk-card-field{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .light-theme-features-box-heading{
    display: flex;
    align-items: center !important;
    gap: 20px;
  }
  .light-theme-features-box{
    width: 100% !important;
    padding-top: 10px;
    margin-top: 40px;
  }

  .light-theme-features-box-para{
    padding-top: 5px;
  }
  .blockchaincomplexheader{
    font-family: 'Poppins' ;font-size: 32px  !important;
    line-height: 45px !important;
    font-weight: 700 !important;
    padding-top: 80px;

  }
  .blockchaincomplexheader-border{
    padding-bottom: 20px;
    margin-left: 20%;
    margin-right: 20%;

  }
  // .blockchaincomplexheader1{
  //   font-size: 25px !important;
  //   line-height: 35px !important;

  // }
  .light-theme-blockchain-container{
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 100px;
  }
  .light-theme-blockchain{
    flex-direction: column;
  }

  .light-theme-blockchain-header{
    font-family: 'Poppins' ;font-size: 32px  !important;
    line-height: 40px !important;

  }
  .light-theme-blockchain-para{
    font-family: 'Poppins' ;font-size: 16px;
    line-height: 25px;
    text-align: left;

  }

  .blockchainlogoborder{
    padding: 30px;
    border: 1px solid orangered;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 300px;
  }
  .blockchainlogoborder1{
    margin-top: 50px;
    border-radius: 50%;
    width: fit-content;
    padding: 20px;

    box-shadow: 0 -3px 5px 0 rgba(225, 29, 29, 0.09), 0 12px 13px 0 rgba(213, 29, 29, 0.17), 0 4px 6px 0 rgba(218, 31, 31, 0.12), 0 -12px 30px 0 rgba(227, 32, 32, 0.12), 0 54px 55px 0 rgba(230, 15, 15, 0.25);
    background-color: #fff;
  }
  .light-theme-plandetails-sec2-image-container_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:0.5rem;
    margin-top: 50px;

  }
  .light-theme-plandetails-sec2-image-container_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:0.5rem;
    margin-top: 80px
  }
  .light-theme-web3-features-leftlogo{
    height: 180px;
    width: 100px;
  }
  .light-theme-web3-features-rightlogo{
    height: 180px;
    width: 100px;
  }
  .light-theme-web3-features-header{
    margin-left: 0px !important;
      font-family: 'Poppins' ;font-size: 22px  !important;
      line-height: 35px !important;
      margin-bottom: 10px !important;
  }

  .light-theme-web3-features-para{
    margin-top: 0px;
    margin-left: 100px;
    font-family: 'Poppins' ;font-size: 16px;
    padding-right: 10px;
    line-height: 25px;
  }

  .light-theme-web3-features-header1{
    margin-right: 0px !important;
      font-family: 'Poppins' ;font-size: 22px  !important;
      line-height: 35px !important;
      margin-bottom: 10px !important;
      padding-left: 10px !important;

  }

  .light-theme-web3-features-para1{
    margin-top: 0px;
    margin-right: 100px;
    font-family: 'Poppins' ;font-size: 16px;
    padding-left: 10px;
    line-height: 25px;
  }
  .light-theme-web3-features-semicontainer{
    margin-top: 45px;
    background: url('../../assets/icons/Group316.svg');
  background-color: rgba(0, 0, 0, 0);
  background-size: 100% auto;
  background-repeat: no-repeat !important;
  }
  .light-theme-web3-features-semicontainer1{
    margin-top: 45px;
    background: url('../../assets/icons/Group316.svg');
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% auto;
    background-repeat: no-repeat !important;
  }


  .light-theme-pricing-container{
    padding-top: 80px;
  }

  .light-theme-pricing-container-inner2{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:center;
    gap: 10px;
    padding-top: 40px;
  }

  .light-theme-pricing-container-inner111{
    padding: 30px 30px 50px 30px;
  }
  .light-theme-pricing-container-inner11{
    border: solid 2px #e4e2e2;
    width: 90% !important;
    border-radius: 24px;


  }
  // .light-theme-pricing-container-inner11:hover{
  //  margin-left: -20px;
  //   margin-right: -20px;
  //   background-color: white;
  //   z-index: 10;
  //   height: 570px;
  //   box-shadow: 0 9px 20px 0 rgba(85, 97, 227, 0.1), 0 36px 36px 0 rgba(85, 97, 227, 0.09), 0 82px 49px 0 rgba(85, 97, 227, 0.05), 0 146px 58px 0 rgba(85, 97, 227, 0.01), 0 228px 64px 0 rgba(85, 97, 227, 0);
  //   border-style: solid;
  //   border-width: 1px;
  //   border-image-source: linear-gradient(to bottom, #4c59e3, #f81f49);
  //   border-image-slice: 1;
  //   background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #4c59e3, #f81f49);
  //   background-origin: border-box;
  //   background-clip: content-box, border-box;

  // }
  .light-theme-pricing-container-inner112{
    padding: 30px 30px 50px 30px;
  }

  .light-theme-pricing-container-inner12{
    border: solid 2px #e4e2e2;
    width: 90% !important;
    border-radius: 24px;


  }
  // .light-theme-pricing-container-inner12:hover{
  //   margin-left: -20px;
  //   margin-right: -20px;
  //   background-color: white;
  //   z-index: 10;
  //   height: 570px;
  //   box-shadow: 0 9px 20px 0 rgba(85, 97, 227, 0.1), 0 36px 36px 0 rgba(85, 97, 227, 0.09), 0 82px 49px 0 rgba(85, 97, 227, 0.05), 0 146px 58px 0 rgba(85, 97, 227, 0.01), 0 228px 64px 0 rgba(85, 97, 227, 0);
  //   border-style: solid;
  //   border-width: 1px;
  //   border-image-source: linear-gradient(to bottom, #4c59e3, #f81f49);
  //   border-image-slice: 1;
  //   background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #4c59e3, #f81f49);
  //   background-origin: border-box;
  //   background-clip: content-box, border-box;

  // }
  .light-theme-pricing-container-inner113{
    padding: 30px 30px 50px 30px;
  }
  .light-theme-pricing-container-inner13{
    border: solid 2px #e4e2e2;
    width: 90% !important;
    border-radius: 24px;

  }
  .light-theme-pricing-feature{

    padding:12px 55px;


  }


  .light-theme-pricing-header{
    font-family: 'Poppins' ;font-size: 32px  !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    text-align: center !important;
    color: white !important;
  }
  .light-theme-pricing-container-header{
    font-family: 'Poppins' ;font-size: 30px ;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
  }
  .light-theme-pricing-container-para{
    text-align: center;
  }


  .light-theme-blogs-container-main{
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px !important;
  }
  .light-theme-blogs-container-join{
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 0px;
  }
  .light-theme-blogs-header{
    font-family: 'Poppins' ;font-size: 32px  !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    text-align: left !important;
    color: white !important;
  }
  .light-theme-blogs-container{
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: scroll;
    gap:30px;
    margin-top: 70px;
  }
  .light-theme-blogs-container-image{
    height:200px;
    width: 350px;
  }
  .light-theme-blogs-readmore{
    margin-top: 20px;
    margin-right: 20px;
  }

  .light-theme-join-community-container{
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
  }

  .light-theme-join-community-header{
    font-family: 'Poppins' ;font-size: 32px  !important;
    line-height: 40px !important;
  }
  .light-theme-join-community-para{
    font-family: 'Poppins' ;font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #121212;
  }
  .light-theme-join-community-container-inner{
    margin-right: 0px;
    border-width: 1px;
    // background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, 184.91deg, #5865F2 -4.31%, #FFFFFF 1.63%, #5865F2 7.69%, #0077B5 32.16%, #039BE5 57.84%, #EEEFFE 103.02%, #666666 103.03%);
    // border-image-source: linear-gradient(184.91deg, #5865F2 -4.31%, #FFFFFF 1.63%, #5865F2 7.69%, #0077B5 32.16%, #039BE5 57.84%, #EEEFFE 103.02%, #666666 103.03%);
  }
  .light-theme-join-community-container-inner1{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .light-theme-join-community-container-row{
    display: flex;
    // flex-direction: column;
    gap: 10px;
  }
  .light-theme-join-community-container-row1{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
  .light-theme-join-community-img{
    border: none;
    padding: 0px;
    height: 60px;
    width: 60px;

    border: 1.8px solid transparent;
  background-color: white;
  border-radius: 50px;
  padding: 8px
  }
  .light-theme-banner-bottom-container{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .light-theme-banner-bottom-logo1{
    height: 40px;
    width: 200px;
  }

  .light-theme-banner-bottom-header{
    font-family: 'Poppins' ;font-size: 24px  !important;
    line-height: 40px !important;
    margin-top: 50px !important;
}

.light-theme-banner-bottom-para{
  font-family: 'Poppins' ;font-size: 14px ;
  line-height: 25px;
  margin-top: 20px;
}
.light-theme-banner-bottom-btn-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap:10px;
  margin-top: 20px;
}
.light-theme-banner-bottom-btn1{
    font-family: 'Poppins' ;font-size: 16px;
    padding:15px 20px;
    width: 200px;
    height: 50px;
    white-space: nowrap;;
    border-radius: 26px;
}
.light-theme-banner-bottom-btn2{
    font-family: 'Poppins' ;font-size: 16px;
    padding:15px 20px;
    border-radius: 26px;
    height: 50px;
    width: 200px;
    outline: none;
    border: 1px solid #121212;
    cursor: pointer;
    background: white;
}
.light-theme-banner-bottom-bg{
  height: 100px;

}

.light-theme-footer-container{
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 65px;
  padding-bottom: 65px;
  padding-left: 25px;
  padding-right: 25px;
}
.light-theme-footer-container1{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.light-theme-footer-logos-container{
  display: flex;
  align-items: center;
  margin-top: 40px;
  justify-content: space-between;
  gap: 30px;
}

.light-theme-footer-container-para{
    display: none;
}
.light-theme-footer-logos{
  border: none;
  border-radius: 50px;
  margin-top: 20px;
  padding: 0px;
}
.light-theme-footer-left-container{
  display: flex;
   gap: 100px;
}

.light-theme-footer-left-container1{
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.light-theme-footer-left-container2{
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.light-theme-footer-right-bottom-content{
  display:none;
  flex-direction: column;
  justify-content: end;
  margin-top: 40px;
}

.light-theme-footer-input-container{
  background-color:white;
  width: fit-content;
  padding: 7px;
  border-radius: 11px;
  margin-top: 40px;
}
.light-theme-footer-input{
  background-color: white;
  outline: none;
  border: none;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100% !important;
  font-family: 'Poppins' ;font-size: 20px ;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #121212;
}
.light-theme-footer-input-submit-btn{
  color: white;
  font-family: 'Poppins' ;font-size: 16px;
  padding: 0px 20px;
  font-weight: 700;
  border-radius: 11px;
  line-height: 35px;
  text-align: left;
  border: none;
  outline: none;
  background: linear-gradient(110deg, rgba(76, 89, 227, 0.5) -13%, rgba(108, 73, 196, 0.9) 8%, rgba(147, 54, 159, 0.78) 34%, rgba(177, 39, 129, 0.69) 54%, rgba(210, 23, 98, 0.59) 75%, rgba(239, 9, 70, 0.5) 95%);
}
.light-theme-footer-privacy-policy-container{
  justify-content: last baseline;
  display: flex;
  gap: 20px;
  color: white;
  font-family: 'Poppins' ;font-size: 15px ;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  margin-top: 30px;

}
.light-theme-footer-left-container-text{
    font-family: 'Poppins' ;font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    color: white;
}

.light-theme-footer-input{
  width: 265px;
  font-family: 'Poppins' ;font-size: 16px;

}
.light-theme-blogs-container-main-join{
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}


.community-resource-container-main{
  flex-direction: column;
  gap:30px;
  margin-top: 70px;

}

.community-resource-header{
color: black !important;
font-family: 'Poppins' ;font-size: 24px  !important;
font-weight: 700 !important;
line-height: 50px !important;
text-align: left !important;
margin-bottom: 10px;

}
.community-resource-container{
  width: 100%;
  border-radius: 20px;
  background-color: white;
  padding: 30px 20px 40px 40px;
}
.community-resource-container-mini{
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.community-resource-text{
  display: flex;
  align-items: center;
font-family: 'Poppins' ;font-size: 16px;
font-weight: 400;
line-height: 30px;
color: black;
text-align: left;

}
.community-resource-text-dot{
  height:8px;
  width:8px;
  margin-bottom:2px;
  border-radius:50px;
  background-color:black;
  margin-right: 10px;
}
.community-resource-header1{
  color: white !important;
  font-family: 'Poppins' ;font-size: 24px  !important;
  font-weight: 700 !important;
  line-height: 50px !important;
  text-align: left !important;
  margin-bottom: 10px;

  }
  .community-resource-container1{
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgba(111, 111, 111, 0.64);
    background: linear-gradient(180deg, rgba(111, 111, 111, 0.64) 0%, rgba(33, 33, 33, 0.68) 100%);
    padding: 30px 20px 40px 40px;
  }
  .community-resource-container-mini1{
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .community-resource-text1{
    display: flex;
    align-items: center;
  font-family: 'Poppins' ;font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: white;
  text-align: left;

  }
  .community-resource-text-dot1{
    height:8px;
    width:8px;
    margin-bottom:2px;
    border-radius:50px;
    background-color:white;
    margin-right: 10px;
  }

  .community-resource-container2{
    color: white;
    font-family: 'Poppins' ;font-size: 20px ;
    font-weight: 700;
    padding: 15px 30px;
    text-align: left;
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 20px;
    background-color: #4B4B4B;
    border-radius: 50px;
    width: fit-content;

  }

.community-resource-logo{
  height: 70px;
  width: 70px;
}

.community-container{
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap:50px;
}

}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;