@import "../../styleConstant.less";
.krypcore_sensor {
    width: auto;
    overflow-x:hidden;
     background-color:  #07030A;
*{
font-family: 'Poppins' ;
}
/* Base styles for the navbar */
.header-wrap {
  background: #080322;
  position: fixed;
  top: 0;
   width: 100%;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.navbar-brand img.logo {
  height: 40px; /* Adjust the height as needed */
}

.navbar-toggler {
  border: none;
  background-color: transparent;
  display:none; /* Hide the toggler for screens wider than 768px */
}

.nav-item .nav-link{
  cursor: pointer;
  margin-left: 15px;
  font-size:18px;
  color: #fff;
  cursor:pointer;

}
.navbar-nav .active-link {
  color: #d988ddcc; /* Set color for the active link */
  font-weight: bold; /* Example: Make the active link bold */
  font-size:18px;
    cursor:pointer;
}
.nav-item .nav-link:hover{
color:#b447eb;
}
.navbar-logo-division {
    display: flex;
    align-items: center;
  }
.menu-right .header-signupbtn {
  padding: 8px 16px;
}
.header-signupbtn {
      font-family: 'Poppins' ;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         padding: 17px 26px 16px;
         border-radius: 0.875rem;
        background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
         color: white;
//          .gradient-border();
   }
.text-white{
font-size:18px;
color:white;
cursor:pointer;
}
/* Media query for responsiveness up to 1200px */
@media (max-width: 1200px) {
  .nav-item {
    display: flex; /* Hide the navigation items */
  }

  .navbar-toggler {
    display: block; /* Show the toggler for screens up to 1200px */
  }
  .navbar-logo-division {
    margin-right  : 17%;
  }
  .navbar-collapse {
    display:none;
    background-color: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index:-1;
    font-size:20px;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
//     display: flex; /* Show the navbar items when toggler is clicked */
    display: grid !important;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
         background:#000;
          font-size:20px;
  }

  .navbar-collapse li {
    text-align: center;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }
}
@media (max-width: 360px) {
  .nav-item {
        display: grid; /* Hide the navigation items */
  }
  .navbar-logo-division {
      margin-right  : 0% !important;
    }

  .navbar-toggler {
    display: block; /* Show the toggler for screens up to 360px */
  }

  .navbar-collapse {
    display:none;
    background: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    font-size:18px;

  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
    display: flex; /* Show the navbar items when toggler is clicked */
     display: grid !important;
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            background:#000;
  }

  .navbar-collapse li {
    text-align: left;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }

  .navbar-brand img.logo {
    height: 30px; /* Adjust the logo height for smaller screens */
  }

  .menu-right .header-signupbtn {
    font-size: 14px; /* Adjust the font size for smaller screens */
  }
}

/* Basic styles for the banner section */
.Bannersection-wrap {
  text-align: center;
  position: relative;
//   background:linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) top right / 50% 50% no-repeat, #0C0610;
  background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #080322;
  backdrop-filter: blur(25px);
  min-height:100vh;
}

.Bannersection-wrap::before {
    content: "";
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
.banner-head-container {
  margin: 0 auto;
  max-width:1200px;
//     background: url(../../../assets/images/banner-bcg.avif)no-repeat;
// background:linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) top right / 50% 50% no-repeat, #0C0610;
        background-position: center;
        background-size: contain;




}

.banner-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: normal;
  color: #ececec;
  margin:40px;
   padding: 100px;
//    margin-bottom: 100px;
background:linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0) 50%) top right / 50% 50% no-repeat, #0C0610;

}
 .banner-highlight {
     background: linear-gradient(to right, #AA0AFC, #D5D3F3, #007AEB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.banner-para {
  font-size: 20px;
  line-height: 1;
  color: grey;
  margin-top: -100px;
  margin-bottom: 100px;
  text-align:center;
}

.banner_btn {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 17px 26px 16px;
    width:max-content;
    border-radius: 6px;
    background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
    color: white;
    .gradient-border();
//     border:1px solid #b447eb;
}
.docs_btn{
font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 17px 26px 16px;
    background: transparent;
    border-radius: 4px;
    color: white;
   border:1px solid #fff;
}
.banner-button-section{
display:inline-flex;
gap:1rem;
}
.banner-docs{
display:inline-flex;
gap:1rem;
}
/* Responsive styles for screens up to 1200px */
@media (max-width: 1200px) {
  .banner-head-container {
    padding: 0 15px;
  }

  .banner-heading {
    font-size: 55px;
      line-height: normal;
      padding: 20px;
//       margin-bottom: 40px;
  }

  .banner-para {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .theme-btn {
    padding: 10px 20px;
    font-size: 14px;
  }
}

/* Responsive styles for screens up to 768px (tablet and mobile) */
@media (max-width: 768px) {
  .banner-heading {
    font-size: 28px;
    line-height:1.5;
    margin:20px;
  }

  .banner-para {
      margin-top: 20px;
    font-size: 16px;
    margin-bottom:30px;
  }

  .theme-btn {
    padding: 8px 16px;
    font-size: 12px;
  }
  .Bannersection-wrap {
    margin-top: 0px !important;
  }
  .banner_btn {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 10px;
//       padding: 15px 13px 16px;
      width:auto;
      background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
      border-radius: 4px;
      color: white;
      .gradient-border();
      border:1px solid #b447eb;
  }
  .banner-button-section{
  display: inline-flex;
  flex-direction: column;
  }
}
@media (max-width: 360px) {
  .banner-heading {
    font-size: 22px;
    line-height:1.5;
    margin:0px;
  }


}
.header-wrap {
  z-index: 5;
}
.Bannersection-wrap {
    padding-top:100px;
}

.Bannersection-wrap .experience-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin:10px;
  grid-gap: 10px;
}


.Bannersection-wrap .experience-card {
  flex: 1; /* Make each card take up equal space */
  max-width: calc(25% - 20px); /* 25% width minus margin */
  height:250px;
  margin: auto; /* Adjust the margin for spacing between cards */
  border: 1px solid #bbb8e5;
  padding: 20px;
  border-radius:20px;
  text-align: center;
  background-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   transition: background-color 0.3s;
   cursor: pointer !important;
   position:relative

}
.experience-card:hover {
background: linear-gradient(135deg, #b447eb, #007bff);
 border: 1px solid #b447eb;
  color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  .launch-btn{
   background: #000 !important;
  }
}
.experience-card p{
font-size: 25px !important;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
 color: #FBF5FF;
 text-align:center;
}
.experience-card .launch-btn{
      font-family: 'Poppins' ;
         font-style: normal;
         font-weight: 500;
         font-size: 20px;
         line-height: 20px;
         padding: 17px 26px 16px;
        border-radius: 6px;
        background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
         color: white;
         .gradient-border();
   }

/* Media query for responsiveness */
@media (max-width: 768px) {
  .experience-card {
    max-width: calc(50% - 20px); /* Two cards per row on smaller screens */
  }
}
@media (max-width: 360px) {
  .experience-card {
    max-width: 100%; /* Two cards per row on smaller screens */
  }
  .experience-card p{
  font-size: 20px !important;
  }
  .experience-card button{
           font-size: 17px;
           line-height: 20px;
           padding: 8px 26px 9px;
     }
}
.new_footer{
background:transparent;
padding: 20px;
height:auto;
}
.new_footer_container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}


.new_footer_card {
  padding: 0px;
  border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
}
.new_footer_card h2{
  color: #E2E2E2;
  font-family: 'Poppins' ;;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 40px !important;
}
.new_footer_card_logo{
width:200px;
height:100px;
}
.left-card {
  width:30%;
  max-height:300px;
  background-color: transparent;
  color: #fff;
  display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
}

.right-card {
  background-color: transparent;
  max-height:300px;
  color: #333;
 display: flex;
    flex-direction: row;
     flex-wrap: wrap;
     align-content: space-between;
     justify-content: space-around;
     align-items: flex-start;

}

.sub-grid-item-1 {
   background: transparent;
   /* padding: 10px; */
   border-radius: 5px;
   display:grid;
   justify-items:start;
   flex: 0 0 auto;
   width: auto;
}
.sub-grid-item-1 h3{
font-size:18px;
font-weight:900;
color:#fff;
}
.divider {
  border-top: 1px solid rgba(171, 169, 169, 0.22);
  width:100%;
}

.sub-grid-item-1 ul li{
font-size:16px;
color:#fff;
list-style-type:none !important;
text-align:left;
 padding: 5px;
 margin-right:25px;
  transition: color 0.3s;
}
.sub-grid-item-1 ul li:hover {
 color:#fa4b4d;
}
.bottom-section {
     display:flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;

}
.vertical_divider {
  border-left: 1px solid #fff;
  height: auto;
  margin: 0 100px;
  width:2px;
}
.word-divider1 span {
  color:#fff;
  margin-right:10px;
}
.word-divider span {
  color:#fff;
  margin-right:20px;

}
.word-divider{
 display:flex;
 gap:1rem;
}
.social-icons-footer{
display:inline-flex;
gap:2rem;
justify-content:center;
align-items:center;
}
@media (max-width: 767px) {

.new_footer_card_logo{
width:inherit;
}
.new_footer_container {
 display: flex;
  justify-content: space-between;
  flex-direction:column;
}
  .left-card {
    width: 100%;
  }

  .right-card {
      /* width: auto;
       max-height: none;
       display: grid;
       align-content: center;
       justify-content: center;
       align-items: center;
       justify-items: start; */
       padding: 0;
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between !important;
         --bs-gutter-x: 1.5rem;
         --bs-gutter-y: 0;
         margin-top: 30px;

  }

  .vertical_divider {
    display:none;
    height: auto;
    margin: auto
  }
  .new_footer_card h2{
   color: #E2E2E2;
   font-size: 14px;
   font-style: normal;
   font-weight: normal;
   line-height: 21px;
   margin-top: 40px !important;
   }
  .sub-grid-item-1 {
    /* padding: 10px; */
  }

  .sub-grid-item-1 h3 {
    font-size: 20px;
  }

  .sub-grid-item-1 ul li {
    font-size: 14px;
    margin-right: 15px;
  }
  .bottom-section {
       display:grid;
       justify-content: center;
       align-items: center;
       align-content: center;
       justify-items: center;

  }
  .word-divider{
    display: grid;
    gap: 1rem;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
  }

    .kcsensor-container_1 {
      display: flex;
      flex-direction:row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 50px;
}

    .left-section {
    display: grid;
        align-content: center;
      flex: 1;
      padding: 20px;
      box-sizing: border-box;
    }
    .left-section h2{
        color: #E2E2E2;
           font-size: 30px !important;
           font-style: normal;
           font-weight: 700;
           line-height: 1;
           margin-top: 40px !important;
        }
  .left-section p{
                color: #E2E2E2;
                   font-size: 20px;
                   font-style: normal;
                   font-weight: normal;
                   line-height: 30px;
                   margin-top: 40px !important;
                }

       .left-section ul {
           list-style-type: none;
           padding: 0;

         }
     .left-section ul li{
     color: #E2E2E2;
          font-size: 20px;
          font-style: normal;
          font-weight: normal;
          line-height: 50px;
          text-align:start;

     }

         .left-section ul li::before {
           content: '\2713'; /* Unicode character for a tick mark */
           color: #008000; /* Color of the tick mark */
           margin-right: 8px;
         }

    .right-section {
      flex: 1;
      box-sizing: border-box;
      padding: 20px;
          background: #15475b;

          border-radius: 30px;
    }

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }

    @media (max-width: 768px) {
      .container {
        flex-direction: column;
      }
      .kcsensor-container_1 {
      flex-direction:column;
      }
    }
    @media (max-width: 360px) {
              .container {
                flex-direction: column;
              }
              .kcsensor-container_1 {
                   flex-direction:column;
                   }
            }

     .kcsensor-container_2 {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-direction:row !important;
          padding: 50px;

}
        .left-section {
        display: grid;
            align-content: center;
          flex: 1;
          padding: 20px;
          box-sizing: border-box;
        }
        .left-section h2{
            color: #E2E2E2;
               font-size: 30px !important;
               font-style: normal;
               font-weight: 700;
               line-height: 1;
               margin-top: 40px !important;
            }
      .left-section p{
                    color: #E2E2E2;
                       font-size: 20px;
                       font-style: normal;
                       font-weight: normal;
                       line-height: 30px;
                       margin-top: 40px !important;
                    }

          .left-section ul {
               list-style-type: none;
               padding: 0;

             }
        .left-section ul li{
         color: #E2E2E2;
              font-size: 20px;
              font-style: normal;
              font-weight: normal;
              line-height: 50px;
              text-align:start;

         }

             .left-section ul li::before {
               content: '\2713'; /* Unicode character for a tick mark */
               color: #008000; /* Color of the tick mark */
               margin-right: 8px;
             }

        .right-section {
          flex: 1;
          box-sizing: border-box;
          padding: 20px;
              background: #15475b;

              border-radius: 30px;
        }

        img {
          max-width: 100%;
          height: auto;
          display: block;
        }

        @media (max-width: 768px) {
          .container {
            flex-direction: column-reverse;
          }
          .kcsensor-container_2 {
               flex-direction:column-reverse !important;
               }
        }
  @media (max-width: 360px) {
            .container {
              flex-direction: column;
            }
            .kcsensor-container_2 {
                 flex-direction:column-reverse !important;
                 }
          }

         .kcsensor-container_3 {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              flex-direction:row !important;
              padding: 50px;
}

            .left-section {
            display: grid;
                align-content: center;
              flex: 1;
              padding: 20px;
              box-sizing: border-box;
            }
            .left-section h2{
                color: #E2E2E2;
                   font-size: 30px !important;
                   font-style: normal;
                   font-weight: 700;
                   line-height: 1;
                   margin-top: 40px !important;
                }
          .left-section p{
                        color: #E2E2E2;
                           font-size: 20px;
                           font-style: normal;
                           font-weight: normal;
                           line-height: 30px;
                           margin-top: 40px !important;
                        }

              .left-section ul {
                   list-style-type: none;
                   padding: 0;

                 }
            .left-section ul li{
             color: #E2E2E2;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: normal;
                  line-height: 50px;
                  text-align:start;

             }

                 .left-section ul li::before {
                   content: '\2713'; /* Unicode character for a tick mark */
                   color: #008000; /* Color of the tick mark */
                   margin-right: 8px;
                 }

            .right-section {
              flex: 1;
              box-sizing: border-box;
              padding: 20px;
                  background: #15475b;

                  border-radius: 30px;
            }

            img {
              max-width: 100%;
              height: auto;
              display: block;
            }

            @media (max-width: 768px) {
              .container {
                flex-direction: column !important;
              }
              .kcsensor-container_3 {
                 flex-direction:column !important;
               }
            }
   @media (max-width: 360px) {
                .container {
                  flex-direction: column !important;
                }
                .kcsensor-container_3 {
                   flex-direction:column;
                 }
              }

             .kcsensor-container_4 {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  flex-direction:row;
                  padding: 50px;

}
                .left-section {
                display: grid;
                    align-content: center;
                  flex: 1;
                  padding: 20px;
                  box-sizing: border-box;
                }
                .left-section h2{
                    color: #E2E2E2;
                       font-size: 30px !important;
                       font-style: normal;
                       font-weight: 700;
                       line-height: 1;
                       margin-top: 40px !important;
                    }
              .left-section p{
                            color: #E2E2E2;
                               font-size: 20px;
                               font-style: normal;
                               font-weight: normal;
                               line-height: 30px;
                               margin-top: 40px !important;
                            }

                  .left-section ul {
                       list-style-type: none;
                       padding: 0;

                     }
                .left-section ul li{
                 color: #E2E2E2;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: normal;
                      line-height: 50px;
                      text-align:start;

                 }

                    .left-section ul li::before {
                       content: '\2713'; /* Unicode character for a tick mark */
                       color: #008000; /* Color of the tick mark */
                       margin-right: 8px;
                     }

                .right-section {
                  flex: 1;
                  box-sizing: border-box;
                  padding: 20px;
                      background: #15475b;

                      border-radius: 30px;
                }

                img {
                  max-width: 100%;
                  height: auto;
                  display: block;
                }

                @media (max-width: 768px) {
                  .container {
                    flex-direction: column;
                  }
                  .kcsensor-container_4 {
                    flex-direction:column-reverse;
                                 }
                }
                @media (max-width: 360px) {
                                  .container {
                                    flex-direction: column;
                                  }
                                  .kcsensor-container_4 {
                                    flex-direction:column-reverse;
                                                 }
                                }

}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;