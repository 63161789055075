@import "../../styleConstant.less";

.project-details-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.upgrade-btn {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 123px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  background-color: transparent;
  color: white;
  .gradient-border();

  &:focus {
    outline: none;
  }
}
.ai-button{
    outline: 2px solid transparent;
      outline-offset: 2px;
      color: #fff !important;
      border-radius: 25px !important;
      background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
      box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
      margin-right: 10px;
      font-weight: 500 !important;
      padding: .375rem 1.75rem !important;
      display: flex !important;
      margin-right: 0px;

      img{
        height: 24px;
        padding-right: 5px;
      }
}

.add-to-team-button {
    margin-right: 2rem;
}

.modalButton {
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #090a2c;
  width: 150px;
  height: 47px;
  box-sizing: border-box;
  margin-left: 2rem;
  .button-background();

  &:focus {
    outline: none;
  }
}

.table-options {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.search-box-container {
  position: relative;
  display: inline-flex;
  align-items: center;

  .anticon {
    position: absolute;
    left: 20px;
    color: #d9d9d9;
    font-size: 16px;
  }

  .input-box {
    width: 160px;
    height: 42px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    background-color: transparent;
    color: #fff;
    padding-left: 35px;
    
    &:focus {
      outline: none;
    }
  }
}

.add-to-teams {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 2rem;

    .ant-select-multiple .ant-select-selection-item-content {
      color: black;
    }
}

.ant-select {
    border: 0.5px solid white;
    border-radius: 10px;

    .ant-select-selection-search {
        color: white;
    }

    .ant-select-selection-item {
        border-radius: 5px !important;
    }

    .ant-select-clear {
        color: white;
        background: #0f133d;
    }

    .ant-select-clear:hover {
        color: white;
    }
}

.ant-select-arrow {
    color: white !important;
}

.status-button {
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: white;
  padding: 0.31rem 1rem;
  border: 1.02824px solid #CA5A5A;
  border-radius: 51.4121px;
}

.status-button-unsuspend {
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: white;
  padding: 0.31rem 1rem;
  border: 1.02824px solid #35B08B;
  border-radius: 51.4121px;
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;