@import "../../styleConstant.less";

.user-icon-container {
  display: inline-block;
  img{
    width:40px;
    border-radius: 50%;
  }
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;