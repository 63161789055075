@import "../../styleConstant.less";

.transaction-page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;


  .endpointDetails_header{
         display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: space-between;
                justify-content: space-between;
                align-items: center;
       }
  .title{
       display: flex;
       gap: 2rem;
       flex-direction: row;
       flex-wrap: wrap;
       align-content: flex-end;
       justify-content: flex-end;
       align-items: center;
  }

  .endpointDetails_text {
    font-family: 'Poppins';
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          color: #e0e0e0;
          width:20%;
          text-align: start;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 16px 16px;
        }
        .endpointDetails_text p{
        margin-bottom: 0px !important;
        font-family: 'Poppins' ;
        }

  .endpointDetails_text_inner {
          font-family: 'Poppins';
          font-weight: 500!important;
          font-size: 16px;
          line-height: 30px;
          color: #e0e0e0;
          text-align: start;
        }       
  .endpointDetails_text_header {
    font-family: 'Poppins';
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            color: #e0e0e0;
          }
  .transaction-header-section1 {
    display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: flex-end;
        align-items: center;
    .header-section1 {
    display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: flex-end;
        align-items: center;
        margin-top: -90px;
        margin-right: -15px;

      .text {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #e0e0e0;
        padding-right: 20px;
      }
    }
  }

  .gradient-button{
    font-family: 'Poppins';
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    color: white;
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    border-radius: 6px;
    border: 0.5px solid black;
  }
}
.my-account-page-container {
  .ant-tabs {
    .ant-tabs-nav::before {
      border-bottom: none !important;
    }

    .ant-tabs-tab {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: rgba(239, 239, 239, 0.4);
      background: transparent;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: 2px solid @success-color !important;
      z-index: 1;
    }

    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-remove:active {
      color: rgba(239, 239, 239, 0.4);
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab-btn:active {
      color: #efefef;
    }
  }
}

.App {
    width:-webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0px;
    background: linear-gradient(90deg, #4a464b -4.52%, #2f475e 120.27%);
    border-radius: 10px;
}

.App table {
    border: none !important;
    width: -webkit-fill-available;
    height: auto;
    border-radius: 10px !important;
}

th {
    border-bottom: 1px solid black;
    color:white;
    text-align: center;
}

td {
    text-align: left;
    color:white;
    word-break: break-all;
}
.endpoint_button{
display:flex;
flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    /* justify-content: space-around; */
    align-items: center;
    .gradient-button {
            border-radius: 8px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #090a2c;
            width: 200px;
            height: 47px;
            box-sizing: border-box;
            .button-background();
            &:focus {
              outline: none;
            }
          }
}
.endpoint_button1{
display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.ant-modal{
 top:30%;
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;