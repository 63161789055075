@import "../../../styleConstant.less";

.dashboard-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .ant-skeleton-content .ant-skeleton-title {
    height: 40px!important;
  }


  .name-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name-section {
      display: flex;
      flex-direction: column;

      .name {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        color: #d9d9d9;
      }

      .sub-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #d8d9ff;
        padding-top: 5px;
      }
    }

    .create-project-section {
      .create-project-btn {
        width: 150px;
        height: 40px;
        background-color: @success-color;
        border-radius: 7.94305px;
        font-size: 14px;
        font-weight: 600;
        color: #090a2c;
      }
    }
  }

  .card-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);

    .card-section-container {
      min-width: calc(100% / 4);
      height:115px;
    }
  }

  .container-text {
    display: inline-flex;
    align-items: center;

    .text {
      font-size: 32px;
      font-weight: 700;
      line-height: 35px;
      text-align: left;
      color: white !important;
    }
  }

  .active-tag,
  .inactive-tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0px 11px;
    background: rgba(53, 176, 139, 0.1);
    border-radius: 51.4121px;
  }

  .active-tag {
    border: @border;
  }

  .inactive-tag {
    color: #646464;
    border: 1.02824px solid #646464;
  }

  .table-cell-data {
    display: inline-flex;
    align-items: center;

    .total-count {
      color: @success-color;
      padding-left: 5px;
    }
  }

  .ant-table {
    .ant-table-container {
      .ant-table-tbody {
        .ant-table-cell {
          z-index: 1;
          font-weight: 400 !important;
          font-size: 16px !important;
          line-height: 35px;
          color: white;
        }
      }
    }
  }

  .api-key {
    cursor: pointer;
  }

  .row-chart {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 70px;
    height: 70px;
    right: 0;
    top: 248px;
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    filter: blur(50px);
  }

  .dashboard-container-service {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 0rem;
  }

  .dashboard-services {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 28px;
    gap: 10px;
    left: -0.57%;
    right: 85.27%;
    top: 0%;
    bottom: 0%;
    .button-background();
    &:focus {
      outline: none;
    }
  }

  .dashboard-services > span {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #f2ecfb;
  }

  .dashboard-services-border > span {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #f2ecfb;
  }

  .dashboard-services-border {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 28px;
    gap: 10px;

    color: white;

    left: 83.94%;
    right: -4.37%;
    top: 0%;
    bottom: 0%;

    background: #0c0c0c;
    box-shadow: 0px 1.76599px 27.3729px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    .gradient-border();
    &:focus {
      outline: none;
    }
  }
}

.dashboard-container .dashboard-services-border {
  position: unset;
}

.dashboard-container .dashboard-services-border::before {
  background: unset;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 5px !important;
}

.tooltip {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
  opacity: 1 !important;
  position: relative !important;

  .tooltip-text {
    padding-right: 5px;
  }
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: transparent !important;
}
.ant-collapse{
    background-color: transparent !important;
    /* border: 1px solid rgba(173, 163, 163, 0.14) !important; */
    border: 2px solid #b447eb !important;
    box-shadow: 0px 3.53712px 8.8428px rgba(0, 0, 0, 0.1);
}
.ant-collapse-content{
    background-color: transparent !important;
    color: #e3e3e3 !important;
    border: none !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    color: #e3e3e3 !important;
}
.ant-collapse > .ant-collapse-item {
    border: none !important;
}




.zoom-in-out-box {
  background: #fff;
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

      .button-glowing:focus {outline: none;}

      .button-glowing {
          cursor: pointer;
          display: inline-block;
          text-align: center;
          -webkit-animation: glowing 1500ms infinite;
          -moz-animation: glowing 1500ms infinite;
          -o-animation: glowing 1500ms infinite;
          animation: glowing 1500ms infinite;
      }

      .button-glowing:hover {
          border: none;
          color: #FFFFFF;
          cursor: pointer;
          display: inline-block;
          -webkit-animation: glowing 1500ms infinite;
          -moz-animation: glowing 1500ms infinite;
          -o-animation: glowing 1500ms infinite;
          animation: glowing 1500ms infinite;
      }

      @-webkit-keyframes glowing {
          0% {
                    background-color: #0091b2;
                    -webkit-box-shadow: 0 0 3px #0091b2;
                  }
                  50% {
                    background-color: #21c7ed;
                    -webkit-box-shadow: 0 0 15px #21c7ed;
                  }
                  100% {
                    background-color: #0091b2;
                    -webkit-box-shadow: 0 0 3px #0091b2;
                  }
      }

      @-moz-keyframes glowing {
          0% {
                    background-color: #0091b2;
                    box-shadow: 0 0 3px #0091b2;
                  }
                  50% {
                    background-color: #21c7ed;
                    box-shadow: 0 0 15px #21c7ed;
                  }
                  100% {
                    background-color: #0091b2;
                    box-shadow: 0 0 3px #0091b2;
                  }
      }

      @-o-keyframes glowing {
          0% {
                    background-color: #0091b2;
                    box-shadow: 0 0 3px #0091b2;
                  }
                  50% {
                    background-color: #21c7ed;
                    box-shadow: 0 0 15px #21c7ed;
                  }
                  100% {
                    background-color: #0091b2;
                    box-shadow: 0 0 3px #0091b2;
                  }
      }

      @keyframes glowing {
          0% {
                    background-color: #0091b2;
                    box-shadow: 0 0 3px #0091b2;
                  }
                  50% {
                    background-color: #21c7ed;
                    box-shadow: 0 0 15px #21c7ed;
                  }
                  100% {
                    background-color: #0091b2;
                    box-shadow: 0 0 3px #0091b2;
                  }
      }
.table_Link{
  color: #e3e3e3;
}
.table_Link:hover{
  font-weight: bold;
  text-decoration: underline;
}
.input-visible{
    padding: 0 7px !important;
    border: 0 !important;
}
.marginBtm15{
    margin-bottom: 15px !important;
}
.ant-input-suffix {
  margin-left: 20px !important;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background: transparent !important;
  color: #e1e1e1 !important;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
  background: transparent !important;
  border: none !important;
}
.header-one-new{
  font-family: 'Poppins';
  white-space: nowrap;
  font-size: 40px;
  font-weight: 700;
  line-height: 35px;
  text-align: left;
   color: white;
   .header-one-new-gradient{
//     background: linear-gradient(89.99deg, #F94334 , #4C59E3 );
    background: linear-gradient(77deg, #F94334, #9130d7);
    -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   }
}
.dashboard-subheader-container{
  margin-top: 35px;
}
.subheader-new{
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 5px;
}
.copyimage-new{
  height: 40px;
  width: auto;
  cursor: pointer;
  margin-left: 20px;
  padding: 10px;
}
.dashboard-box-1{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  text-align: left;
  color: white;
  cursor: pointer;
  // width: 320px;
  padding: 30px 20px;
  border-radius: 10px;
  height: fit-content;
  background-color: #808080;

  .dashboard-box-1-img{
    height: 50px;
    width: 50px;
    margin-left: 10px;
  }
}

.create-dapp-form-row{
  display: flex;
  justify-content: space-between;
  gap:20px
}

.ant-form-item {
  width: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
  margin-right: 10px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 9px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: black;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  color:black;
  background-color:black
}

.slider.round:before {
  border-radius: 50%;
}
.slider.round:before {
  border-radius: 50%;
  background: linear-gradient(180deg, #DF2D2D 0%, #A219BC 100%);

}
.createdappbtn-container{
  display: flex;
  align-items: center;
}
.createdappbtn-container-btn{
  font-size: 32px;
  font-weight: 500;
  cursor: pointer;
}
.createdappbtn-container-btn1{
  font-size: 28px;
  font-weight: 500;
  margin-top: -2px;
  cursor: pointer;
}
.createdappbtn-container-number{
  color: black;
  background-color: white;
  padding: 5px;
  border-radius: 50px;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 1440px) {
  .dashboard-box-1{
    font-weight: 700;
    line-height: 35px;
    padding: 20px 10px;
  
    .dashboard-box-1-img{
      height: 40px;
      width: 40px;
    }
  }
}

.pwd-style{
    background-color: #262626;
    border: 0;
    width: 48%;
    color: white;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;