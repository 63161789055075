@import "../../styleConstant.less";
.planDetails-Page{
 width: auto;
    overflow-x:hidden;
     background-color: #020315;
*{
font-family: 'Poppins' ;
}
/* Base styles for the navbar */
.header-wrap {
  background: #080322;
  position: fixed;
  top: 0;
   width: 100%;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.navbar-brand img.logo {
  height: 40px; /* Adjust the height as needed */
}

.navbar-toggler {
  border: none;
  background-color: transparent;
  display:none !important; /* Hide the toggler for screens wider than 768px */
}

.nav-item .nav-link{
  cursor: pointer;
  margin-left: 15px;
  font-size:18px;
  color: #fff;
  cursor:pointer;

}
.nav-item .nav-link:hover{
color:#b447eb;
}
.navbar-logo-division {
    display: flex;
    align-items: center;
  }
.menu-right .header-signupbtn {
  padding: 8px 16px;
}
.header-signupbtn {
      font-family: 'Poppins' ;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         padding: 17px 26px 16px;
         border-radius: 0.875rem;
        background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
         color: white;
//          .gradient-border();
   }
.text-white{
font-size:18px;
color:white;
cursor:pointer;
}
/* Media query for responsiveness up to 1200px */
@media (max-width: 1200px) {
  .nav-item {
    display: flex; /* Hide the navigation items */
  }

  .navbar-toggler {
    display: block !important; /* Show the toggler for screens up to 1200px */
  }
  .navbar-logo-division {
    margin-right  : 17%;
  }
  .navbar-collapse {
    display:none;
    background-color: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index:-1;
    font-size:20px;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
//     display: flex; /* Show the navbar items when toggler is clicked */
    display: block !important;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
         background:#000;
          font-size:20px;
          padding:10px;
  }

  .navbar-collapse li {
    text-align: center;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }
}
@media (max-width: 360px) {
  .nav-item {
        display: grid; /* Hide the navigation items */
  }
  .navbar-logo-division {
      margin-right  : 0% !important;
    }

  .navbar-toggler {
    display: block !important; /* Show the toggler for screens up to 360px */
  }

  .navbar-collapse {
    display:none;
    background: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    font-size:18px;

  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
//     display: flex; /* Show the navbar items when toggler is clicked */
     display: block !important;
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            background:#000;
            padding:10px;
  }

  .navbar-collapse li {
    text-align: left;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }

  .navbar-brand img.logo {
    height: 30px; /* Adjust the logo height for smaller screens */
  }

  .menu-right .header-signupbtn {
    font-size: 14px; /* Adjust the font size for smaller screens */
  }
  .navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.plan-details {
background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #080322;
.banner-highlight {
    background: linear-gradient(90deg, #b447eb 0%, #2797ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.plan-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.plan-heading h2 {
  margin-top: 200px !important;
  color: #FCF7FF;
  text-align: center;
  font-family: 'Poppins' ;;
  font-size: 45px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
}
.plan-heading h5{
 color: #BDBBCB;
 font-family: 'Poppins' ;;
 font-size: 18px;
 font-style: normal;
 font-weight: 400;
 line-height: 27.644px; /* 153.577% */
}
.plan-heading p {
  margin-bottom: 0;
}
.planTable-button{
border-radius: 4px;
           font-weight: 550;
           font-size: 18px;
           line-height: 17px;
           text-align: center;
           border:none;
           color: #000;
            width: 100%;
            height: 50px;
           box-sizing: border-box;
               background: #B5ADC3;
           &:focus {
             outline: none;
           }
}
.planTable-button-getStart{
border-radius: 4px;
           font-weight: 550;
           font-size: 18px;
           line-height: 17px;
           text-align: center;
           border:none;
           color: #000;
           width: 100%;
           height: 50px;
           box-sizing: border-box;
           background:#B5ADC3;
           &:focus {
             outline: none;
           }
}
.planTable-button-contact-us-pro{
border-radius: 4px;
           font-weight: 550;
           font-size: 18px;
           line-height: 17px;
           text-align: center;
           border:none;
           color: #000;
           width: 100%;
           height: 50px;
           box-sizing: border-box;
           background:linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
           &:focus {
             outline: none;
           }
}
.plan-section {
    margin:60px;
    background:#100A13;
    border-radius:0px;
     box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
     border: 1px solid #AA0AFC;
     border-radius:10px;

     }
     .main-container  {
       display: flex;
       flex-wrap: nowrap;
       justify-content: space-between;
           width: 100%;
           gap:1rem;

     }

     .card_features_pricing {
       width: calc(75% - 20px);
       background: transparent;
//        box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
       padding: 5px;
       box-sizing: border-box;
       margin-bottom: 20px;
//        border-radius:15px;

     .feature-highlight{
     background: linear-gradient(90deg, #B447EB 0%, #2797FF 100%);
     background-clip: text;
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     }
     }
     .grid_features_pricing {
       background-color: transparent;
       border: none;
       padding: 10px;
       color:#FBF5FF;
       box-sizing: border-box;
       margin-bottom: 10px;
           font-size: 20px;
           font-weight: 600;
               text-align: center;


     }
     .grid_features_pricing img{
     height:50px;
     width:100%;
     }
    .grid_features_pricing .features_img{
    height:100px !important;
         width:100%;
    }
   .banner-highlight {
               background: linear-gradient(149deg, #AA0AFC 0%, #D5D3F3 54.14%, #007AEB 100%);
               background-clip: text;
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               font-family: 'Poppins' ;;
               font-size: 45px;
               font-style: normal;
               font-weight: 700;
               line-height: normal;
               letter-spacing: 0.6px;
              }
     .pricing_listing_features{
        text-align:left;
        display:grid;
        align-content:center;
        justify-content:center;
        font-size:14px;
        line-height:2.5;
        font-weight:bold;
        color:#FBF5FF;
     }
     .pricing_listing{
     text-align:left;
             display:grid;
             align-content:center;
             justify-content:center;
             font-size:14px;
             line-height:2.5;
             color:#FBF5FF;

     }
     .pricing_listing li::before {
       content: '\2713';
       margin-right: 8px;
     }
    .pricing_listing {
       list-style: none;
     }

      .card-front_starter{
              transform: scale(1.1);
               z-index: 1;
            }
 .card-front_pro {
         transform: scale(1.1);
          z-index: 1;
       }
  .card-front_enterprise {
          transform: scale(1.1);
           z-index: 1;
        }
     .listing_features_pro{
     color:#fff;
     }
     .card_features_head{
      width:80%;
       cursor: pointer;
     }
    .card_features_pro{
    background: linear-gradient(182deg, #30064D 0%, rgba(77, 36, 106, 0.00) 100%);
    border:none !important;
    color:#fff !important;
          cursor: pointer;
              transition: transform 0.1s;
    }


     @media screen and (max-width: 1200px) {
      .main-container {
               display: grid;
              justify-content: center;
            }
       .card_features_pricing {
         width: 100%;
       }
     }

     @media screen and (max-width: 767px) {
     .plan-heading h2 {
       margin-top: 120px !important;
       font-size:30px;
       text-align:center !important;
     }
     .plan-heading h5{
      color:#fff;
      font-size:20px;
     }
       .main-container {
          display: grid;
         justify-content: center;
       }
     .plan-section{
     margin:10px;
     background:none
     }
     }
}

.plandetails-sec2-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(90deg, #642B73 0%,#4BC0C8 100%);
  padding: 40px;
  border-radius:10px;
  margin: 40px;
}

.plandetails-sec2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card {
  background-color: #e1e1e1;
  padding: 20px;
}

.plandetails-sec2-image-container {
  flex: 0 0 50%;
  overflow:hidden;
  padding:100px;

.plandetails-sec2-image-container_1{
display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}
      .marquee-container-left{
        width: 100%;
        overflow: hidden;
      }

      .marquee-content-left{
        display: inline-block;
        white-space: nowrap;
        animation: marquee-left 20s linear infinite;
      }
      @keyframes marquee-left {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(100%);
        }
      }

      .marquee-content-left:hover {
        -moz-animation-play-state: paused;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
       }

      @keyframes marquee-right {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
      .marquee-container-right{
        width: 100%;
        overflow: hidden;
      }

      .marquee-content-right{
        display: inline-block;
        white-space: nowrap;
        animation: marquee-right 5s linear infinite;
      }

}

.plandetails-sec2-title{
color:#fff;
font-size:30px;
font-weight:600;
text-align:left;
}
.plandetails-sec2-content{
color:#fff;
font-size:18px;
font-weight:normal !important;
}
.plandetails-sec2-button{
border-radius: 4px;
           font-weight: 550;
           font-size: 20px;
           line-height: 17px;
           text-align: center;
           color: #fff;
           width: 150px;
           height: 47px;
           box-sizing: border-box;
           background:#290d29;
           &:focus {
             outline: none;
           }
}
  @media(max-width: 1200px) {
      .plandetails-sec2-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        background: linear-gradient(90deg, #642B73 0%,#4BC0C8 100%);
        padding: 40px;
        border-radius:10px;
        margin: 60px;
      }
      }

     @media screen and (max-width: 767px) {
     .plandetails-sec2-container {
       display: grid;
       justify-content: center;
       align-items: center;
       background: linear-gradient(90deg, #642B73 0%,#4BC0C8 100%);
//        padding: 40px;
       border-radius:10px;
          margin: auto;
          width: 80%;
     }
     .plandetails-sec2-image-container {
       flex: 0 0 50%;
       overflow:hidden;
       padding:0px;
       margin-top:70px;
      }
     .plandetails-sec2-image-container_1{
     display: grid;
         flex-direction: row;
         flex-wrap: nowrap;
         align-content: space-between;
         justify-content: space-between;
         align-items: center;
         gap: 2rem;
     }
     .plandetails-sec2-image-container img {
       max-height:40%;
     }
     }

.new_footer{
background:#07030A;
padding: 20px;
height:auto;
}
.new_footer_container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}


.new_footer_card {
  padding: 20px;
  border-radius: 5px;
}
.new_footer_card h2{
  color: #E2E2E2;
  font-family: 'Poppins' ;;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.new_footer_card_logo{
width:200px;
height:100px;
}
.left-card {
  width:30%;
  max-height:300px;
  background-color: transparent;
  color: #fff;
  display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
}

.right-card {
  background-color: transparent;
  max-height:300px;
  color: #333;
 display: flex;
    flex-direction: row;
     flex-wrap: wrap;
     align-content: space-between;
     justify-content: space-around;
     align-items: flex-start;

}

.sub-grid-item-1 {
   background: transparent;
   padding: 10px;
   border-radius: 5px;
   display:grid;
   justify-items:start;
}
.sub-grid-item-1 h3{
font-size:18px;
font-weight:900;
color:#fff;
}
.divider {
 border-top: 1px solid rgba(171, 169, 169, 0.22);
  width:100%;
}

.sub-grid-item-1 ul li{
font-size:16px;
color:#fff;
list-style-type:none !important;
text-align:left;
 padding: 5px;
 margin-right:25px;
  transition: color 0.3s;
}
.sub-grid-item-1 ul li:hover {
 color:#fa4b4d;
}
.bottom-section {
     display:flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;

}
.vertical_divider {
  border-left: 1px solid #fff;
  height: auto;
  margin: 0 100px;
  width:2px;
}
.word-divider1 span {
  color:#fff;
  margin-right:10px;
}
.word-divider span {
  color:#fff;
  margin-right:20px;

}
.word-divider{
 display:flex;
 gap:1rem;
}
.social-icons-footer{
display:flex;
gap:1rem;
justify-content:center;
align-items:center;
}
@media (max-width: 767px) {

.new_footer_card_logo{
width:inherit;
}
.new_footer_container {
 display: flex;
  justify-content: space-between;
  flex-direction:column;
}
  .left-card {
    width: 100%;
  }

  .right-card {
      width: auto;
       max-height: none;
       display: grid;
       align-content: center;
       justify-content: center;
       align-items: center;
       justify-items: start;

  }

  .vertical_divider {
    display:none;
    height: auto;
    margin: auto
  }
  .new_footer_card h2{
   color: #E2E2E2;
   font-size: 14px;
   font-style: normal;
   font-weight: normal;
   line-height: 21px;
   }
  .sub-grid-item-1 {
    padding: 10px;
  }

  .sub-grid-item-1 h3 {
    font-size: 20px;
  }

  .sub-grid-item-1 ul li {
    font-size: 14px;
    margin-right: 15px;
  }
  .bottom-section {
       display:grid;
       justify-content: center;
       align-items: center;
       align-content: center;
       justify-items: center;

  }
  .word-divider{
    display: grid;
    gap: 1rem;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
  }
.planList{
   display: flex;
       flex-direction: row;
       flex-wrap: wrap;
       align-content: center;
       justify-content: center;
       align-items: center;
.getstart-button{
                border-radius: 4px;
                font-weight: 500;
                font-size: 18px;
                line-height: 17px;
                cursor:pointer;
                 text-align: center;
                color: #090a2c;
                 width: 250px;
                 z-index:1;
               height: 50px;
                box-sizing: border-box;
                  .button-background();
                  &:focus {
                   outline: none;
  }
}
}
}


@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;