@import "../../styleConstant.less";
.terms_responsive {
    width: auto;
    overflow-x:hidden;
     background-color:  #07030A;
*{
font-family: 'Poppins' ;
}
/* Base styles for the navbar */
.header-wrap {
  background: #080322;
  position: fixed;
  top: 0;
   width: 100%;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-nav .nav-link {
  font-size:18px;
  color:white;
  cursor:pointer;
}

.nav-item .nav-link:hover{
color:#b447eb;
}
.navbar-nav .active-link {
  color: #d988ddcc; /* Set color for the active link */
  font-weight: bold; /* Example: Make the active link bold */
  font-size:18px;
    cursor:pointer;
}

.navbar-brand img.logo {
  height: 40px; /* Adjust the height as needed */
}

.navbar-toggler {
  border: none;
  background-color: transparent;
  display:none; /* Hide the toggler for screens wider than 768px */
}

.nav-item {
  cursor: pointer;
  margin-left: 15px;
}
.navbar-logo-division {
    display: flex;
    align-items: center;
  }
.menu-right .header-signupbtn {
  padding: 8px 16px;
}
.header-signupbtn {
      font-family: 'Poppins' ;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         padding: 17px 26px 16px;
        border-radius: 0.875rem;
        background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
         color: white;
//          .gradient-border();
   }
.text-white{
font-size:18px;
color:white;
cursor:pointer;
}
/* Media query for responsiveness up to 1200px */
@media (max-width: 1200px) {
  .nav-item {
    display: flex; /* Hide the navigation items */
  }

  .navbar-toggler {
    display: block; /* Show the toggler for screens up to 1200px */
  }
  .navbar-logo-division {
    margin-right  : 17%;
  }
  .navbar-collapse {
    display:none;
    background-color: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index:-1;
    font-size:20px;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
//     display: flex; /* Show the navbar items when toggler is clicked */
    display: grid !important;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
         background:#000;
          font-size:20px;
  }

  .navbar-collapse li {
    text-align: center;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }
}
@media (max-width: 360px) {
  .nav-item {
        display: grid; /* Hide the navigation items */
  }
  .navbar-logo-division {
      margin-right  : 0% !important;
    }

  .navbar-toggler {
    display: block; /* Show the toggler for screens up to 360px */
  }

  .navbar-collapse {
    display:none;
    background: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    font-size:18px;

  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
    display: flex; /* Show the navbar items when toggler is clicked */
     display: grid !important;
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            background:#000;
  }

  .navbar-collapse li {
    text-align: left;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }

  .navbar-brand img.logo {
    height: 30px; /* Adjust the logo height for smaller screens */
  }

  .menu-right .header-signupbtn {
    font-size: 14px; /* Adjust the font size for smaller screens */
  }
}



.terms_container {
  margin: 0 auto;
  padding: 50px;
  background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #0C0610;


  .terms-logocard{
       display: flex;
       flex-direction: row;
       flex-wrap: nowrap;
       align-content: space-around;
       justify-content: space-around;
       align-items: center;

  }
  .terms-heading{
    color:#fff;
    font-size:40px;
    font-weight:700;
    margin: 100px auto;
  }
  .terms-logo img{
  width: 300px;
      height: 300px;
  }
  .terms-content{
  .terms-content-heading{
    color:#9698cd;
       font-size:25px;
       font-weight:700;
  }
  .terms-content-paragraph{
  color:#fff;
         font-size:18px;
         font-weight:normal;
  }
  .terms-content-paragraph_bold{
  color:#fff;
           font-size:20px;
           font-weight:600;
  }
  .terms-list-content{
   color:#fff;
           font-size:18px;
           font-weight:normal;
           padding:40px;
  }
  .terms-list-content li{
     list-style:disc;
  }
  }
}

.new_footer{
background:transparent;
padding: 20px;
height:auto;
}
.new_footer_container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}


.new_footer_card {
  padding: 20px;
  border-radius: 5px;
}
.new_footer_card h2{
  color: #E2E2E2;
  font-family: 'Poppins' ;;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.new_footer_card_logo{
width:200px;
height:100px;
}
.left-card {
  width:30%;
  max-height:300px;
  background-color: transparent;
  color: #fff;
  display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
}

.right-card {
  background-color: transparent;
  max-height:300px;
  color: #333;
 display: flex;
    flex-direction: row;
     flex-wrap: wrap;
     align-content: space-between;
     justify-content: space-around;
     align-items: flex-start;

}

.sub-grid-item-1 {
   background: transparent;
   padding: 10px;
   border-radius: 5px;
   display:grid;
   justify-items:start;
}
.sub-grid-item-1 h3{
font-size:18px;
font-weight:900;
color:#fff;
}
.divider {
  border-top: 1px solid rgba(171, 169, 169, 0.22);
  width:100%;
}

.sub-grid-item-1 ul li{
font-size:16px;
color:#fff;
list-style-type:none !important;
text-align:left;
 padding: 5px;
 margin-right:25px;
  transition: color 0.3s;
}
.sub-grid-item-1 ul li:hover {
 color:#fa4b4d;
}
.bottom-section {
     display:flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;

}
.vertical_divider {
  border-left: 1px solid #fff;
  height: auto;
  margin: 0 100px;
  width:2px;
}
.word-divider1 span {
  color:#fff;
  margin-right:10px;
}
.word-divider span {
  color:#fff;
  margin-right:20px;

}
.word-divider{
 display:flex;
 gap:1rem;
}
.social-icons-footer{
display:flex;
gap:1rem;
justify-content:center;
align-items:center;
}
@media (max-width: 767px) {

.new_footer_card_logo{
width:inherit;
}
.new_footer_container {
 display: flex;
  justify-content: space-between;
  flex-direction:column;
}
  .left-card {
    width: 100%;
  }

  .right-card {
      width: auto;
       max-height: none;
       display: grid;
       align-content: center;
       justify-content: center;
       align-items: center;
       justify-items: start;

  }

  .vertical_divider {
    display:none;
    height: auto;
    margin: auto
  }
  .new_footer_card h2{
   color: #E2E2E2;
   font-size: 14px;
   font-style: normal;
   font-weight: normal;
   line-height: 21px;
   }
  .sub-grid-item-1 {
    padding: 10px;
  }

  .sub-grid-item-1 h3 {
    font-size: 20px;
  }

  .sub-grid-item-1 ul li {
    font-size: 14px;
    margin-right: 15px;
  }
  .bottom-section {
       display:grid;
       justify-content: center;
       align-items: center;
       align-content: center;
       justify-items: center;

  }
  .word-divider{
    display: grid;
    gap: 1rem;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
  }



}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;