// @import "../../styleConstant.less";
.enterprise_responsive {
    width: auto;
    overflow-x:hidden;
     background-color:black;
     
     
*{
  font-family: 'Poppins' ;
  color: white;

}

.banner-bg{
  background: url('../new-landing-page/Icons/Header-Blur-bg.svg');
  background-size: cover;
  /* Ensure the background image remains fixed */
  background-attachment: fixed;
  /* Position the background image at the top */
  background-position: top;
}
/* Base styles for the navbar */
.navbar-nav li div span{
  color: #121212;
  font-size:14px;
  cursor: pointer;
  font-weight: 500;
}
.text-line{
  font-size: 18px;
      border-left: 2px solid #99989b;
      color: #99989b !important;
}


.dapp-sectionb{
    margin:auto;
    background: transparent;
    // backdrop-filter: brightness(1.5);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-top: 100px;

    .animate {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    @-webkit-keyframes fadeIn {
      from {
        opacity: 0;
      }
    
      to {
        opacity: 1;
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
    
      to {
        opacity: 1;
      }
    }
    .fadeIn {
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
    }
    
    .hidden{
      visibility: hidden !important;
    }
.dapp-heading {
      display: flex;
      justify-content: start;
      font-family: 'Poppins';
      font-size: 40px;
      font-style: normal;
      font-weight: 550;
      line-height: normal;
      margin: auto;
      width: 100%;
      color: #FBF5FF;
      margin-bottom: 40px;
      margin-top: 30px;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
}

.dapp-card-content2-text{
  font-size: 18px !important;
    color: #fff !important;
    text-align: left !important;
    line-height: 1.5 !important;
}
 .dapp-highlight {
   color:#121316 ;
   
  }

.dapp-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px;
}

.dapp-card {
    width: calc(33.33% - 20px); /* Adjust this width as needed */
   border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
//       background: url(../../../assets/images/img.png) no-repeat #160B1E;;
      background-size:cover;
      background-position:center;
      background:transparent;
      /* border: 1px solid #e1e1e1; */

}

.dapp-card img {
    max-width: 100%;
    height:auto;
}

.dapp-card-img {
    padding-top: 15px;
    margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
}
.dapp-card-content3 {
  padding: 35px;
  font-style: normal;
}
.dapp-card-content {
    padding-top: 15px;
    // padding-left: 5px;
    // padding-right: 5px;
}
.dapp-card-content h2 {
font-family: 'Poppins';;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
}
.subheading-highlight{
  font-family: 'Poppins';
    font-size: 32px !important;
    color: white !important;
    font-weight: 500 !important;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left !important;
  text-align: left !important;
  // margin-bottom: 36px;
  // margin-top: 50px!important;
  letter-spacing: -.01em;
    line-height: 1.15;
    text-rendering: optimizeLegibility;
}
.subheading-highlight1{
  font-family: 'Poppins';
    font-size: 48px !important;
    color: white !important;
    font-weight: 500 !important;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left !important;
    margin-bottom: 0px;
  text-align: left !important;
  letter-spacing: -.01em;
    line-height: 1.15;
    text-rendering: optimizeLegibility;

}
.headerwordpress{
  padding-left: 40px;
  padding-right: 40px;
}

.dapp-card-content p {
  font-size: 16px;
  line-height: 1.5;
  color: white;
  margin-top: 30px;
  text-align: left;
  font-weight: 400;
  p{
    font-size: 16px;
    line-height: 1.5;
    color: white;
    opacity: 50%;
    margin-top: 30px;
    text-align: left;
    font-weight: 400;

  }
}
.dapp-card-content2 p {
  font-size: 16px;
    line-height: 1.5;
    color:white;
    opacity: 70%;
    margin-top: 30px;
    text-align: left;
    font-weight: 400;
}

.dapp-card-container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
}

.dapp-card1 {
  width: calc(60% - 20px); /* Adjust this width as needed */
 border-radius: 10px;
  margin-bottom: 20px;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  // border: 1px solid #3A3C44;
  background-color: transparent;


}
// .dapp-card1:hover{
//   border:1px solid #7d51e1;
// }
.dapp-card1 img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  margin-top: 20px;
}
.dapp-card1 h2 {
  color: white !important;
  font-size: 32px !important;
  font-weight: 500 !important;
}
.dapp-card1 h3 {
  margin-top: 50px;
  color: white !important;
}
.dapp-card1 h4 {
  margin-top: 50px;
  color: white !important;
}
.dapp-card1 li {
  font-size: 18px;
    line-height: 1.5;
    color: whitesmoke;
    margin-top: 10px;
    text-align: left;
    font-weight: 500;
    line-height: 30px;
}
.dapp-card1 p {
  font-size: 18px;
    line-height: 1.5;
    color: white;
    margin-top: 30px;
    text-align: left;
    font-weight: 400;
}
.dapp-card1 a {
  font-size: 18px;
    line-height: 1.5;
    color: #33a2a5;
    margin-top: 30px;
    text-align: left;
    font-weight: 500;
    line-height: 30px;
}


.dapp-card1 table {
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  width: 100% !important;
  color: #FBF5FF;
  border: 1px solid white!important;
}
.dapp-card1 td {
  border: 1px solid white!important;
  padding: 5px !important;
  min-width: 30% !important;
  white-space:pre-wrap !important;
  word-break:break-word !important;
  min-width: 100px!important;
  text-align: left!important;
}

.dapp-sectionflex{
  display: flex;
  width: 100%;
  justify-content: end;
}

.dapp-sectionflexTab{
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
  padding-left: 40px;
}

.dapp-headingTab {
  display: flex;
  justify-content: center;
  gap: 5px;
  font-family: 'Poppins';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // margin: 10px;
  color: white;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  // border-bottom: 1px solid #121316;
  
}
.dapp-headingText{
  font-size: 18px;
  background-color: white;
    color:black;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #e1e1e1;
    padding: 8px 20px;
  cursor: pointer;
}
.dapp-headingTextActive{
  font-size: 18px;
  background-color: #007AEB;
    color: white;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 500;
    border: none;
    padding: 8px 20px;
  cursor: pointer;
}


.dapp-heading1 {
  display: flex;
  justify-content: space-between;
  font-family: 'Poppins';
  font-size: 40px;
  padding-left: 60px;
  padding-right: 60px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  // margin: 10px;
  width: 65%;
  color: #FBF5FF;
  margin-bottom: 10px;
  margin-top: 30px;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.buildK-copy-btn1{
  background: transparent;   
        border-radius: 8px;
        height: 40px;
        padding: 11px 20px 11px 20px !important;
        border: 1px solid #cecaca;
        outline: none;
        font-size: 14px;
 }
 .buildK-copy-btn2{
  font-family: 'Poppins';
  display:inline-flex;
  outline: none;
  border: none;
  align-items:center;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  padding: 10px 26px 12px;
  background: linear-gradient(90deg, #c372ec -4.52%, #77b1e7 120.27%);
  border-radius: 30px;
  color: white;
  // .gradient-border();
 }
 .buildK-copy-btn3{
  width: 40%;
  background: white;   
  border-radius: 8px;
  height: 40px;
  padding: 11px 20px 11px 20px !important;
  border:1px solid #e1e1e1;
  outline: none;
  font-size: 1rem;
  margin-right: 10px;
  
 }
 .buildK-copy-btn4{
  background-color: #007AEB;
        color: white;
        border-radius: 25px;
        font-size: 15px;
        font-weight: 500;
        border: none;
        height: 40px;
        padding: 8px 40px;
  // .gradient-border();
 }
.blogViewImg{
  border-radius: 15px;
}

.dapp-card-container2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px;
}
.dapp-card-content2{
  width: 50%;
  padding: 0 15px 0 0;
  color: whitesmoke;
}

.enterprise_responsive p {
  font-size: 18px;
  color: whitesmoke !important; 
  text-align: left;
  line-height: 1.5;
}


.dapp-card2 {
  display: flex;
  width: 100%; /* Adjust this width as needed */
 border-radius: 10px;
  margin-bottom: 20px;
  padding: 35px;
  overflow: hidden;
  border:1px solid #e1e1e1;
//       background: url(../../../assets/images/img.png) no-repeat #160B1E;;
    background-size:cover;
    background-position:center;
  background: transparent;
  gap: 30px;
}

.dapp-card2 img {
  max-width: 100%;
  height: auto;

}
.loadingspinner{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.paginationui{
  display: inline-block;
  padding-left: 40px;
  padding-right: 60px;
}

.custom-pagination1 {
  background-color: transparent;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-pagination1 .ant-pagination-item {
  border: 1px solid transparent;
  background-color: transparent;
}
.ant-pagination-item-ellipsis{
  color: whitesmoke !important;
}

.custom-pagination1 .ant-pagination-item-active {
  background-color: transparent;
  border-color: transparent;
  color: blue;
}
.custom-pagination1 .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link ,
.custom-pagination1 .ant-pagination-next .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link  {
  /* Your custom styles here */
  background-color: transparent!important; /* Example background color */
  border: 1px solid transparent; /* Example border color */
  color: #fff; /* Example text color */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for the Next and Prev buttons when disabled */
.custom-pagination1 .ant-pagination-disabled {
  /* Your custom styles for disabled buttons here */
  opacity: 0.9; /* Example: reduce opacity for disabled buttons */
  // margin-bottom: 6px;
}

.subscritefield{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  color: #AA0AFC;
  padding-left: 50px;
  padding-right: 50px;
  
  // justify-content: space-around;
}
.subscritefield{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  color: #AA0AFC;
  padding-left: 40px;
  padding-right: 50px;
  margin-bottom: 30px;
  // justify-content: space-around;
}
.subscritefield1{
 
  color: white;
  font-size: 24px;
  font-weight: 500 !important;
}
h2.wp-block-heading{
   font-family: 'Poppins';
    font-size: 42px !important;
    color: white !important;
    font-weight: 500 !important;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left !important;
    margin-bottom: 36px;
  text-align: left !important;
  margin-top: 50px!important;
}
h3.wp-block-heading{
  
  font-size: 24px !important;
  color: white !important;
  font-weight: 500 !important;
  text-align: left !important;
}
.footerText{
  font-size: 18px;
  color: #fff;
  text-align: left;
  line-height: 1.5;
  margin-top: 24px;
}

.hideMe {
  font-size: 18px;
  -moz-animation: cssAnimation 0s ease-in 5s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 5s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 5s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes cssAnimation {
  to {
      width:0;
      height:0;
      overflow:hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
      width:0;
      height:0;
      visibility:hidden;
  }
}

.blogTitle{
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  margin-top: 3px;
}
.blogTitle1{
  color: whitesmoke;
  font-size: 10px !important;
  font-weight: 500;
}

.banner-head-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* max-width: 1200px; */
}
.banner_btn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding: 12px 20px 12px 20px;
  width:max-content;
  border-radius: 50px;
  background: #0A77FF;
  color: white;
  border:none;
}
.docs_btn{
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    padding: 12px 20px 12px 20px;
    background: #fff;
    border-radius: 50px;
    color: white;
    border: 1px solid #e5e5e7;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  }

  .banner-highlight{
    /* background: linear-gradient(to right, #33001a, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    
    background: linear-gradient(91.13deg, #FE0B0B, #E30F3F, #C4147C, #A519B8);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;

    text-align: left;
    margin-top: 130px;
}
.banner-button-section1 {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: left;
  align-items: center;
  padding-top: 20px;
}
.individual-blog-header{
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right:10px ;
}

.dapp-card-containerI {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px;
  width: 60%;
}

.dapp-cardI {
  width: calc(50% - 20px); /* Adjust this width as needed */
 border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
//       background: url(../../../assets/images/img.png) no-repeat #160B1E;;
    background-size:cover;
    background-position:center;
    background:transparent;
    /* border: 1px solid #e1e1e1; */

}

.dapp-cardI img {
  max-width: 100%;
  height:180px;
}

.dapp-card-imgI {
  padding: 15px;
  margin: auto;
      // display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
}
.dapp-card-content3I {
padding: 35px;
font-style: normal;
}
.dapp-card-contentI {
  padding: 15px;
}
.dapp-card-contentI h2 {
font-family: 'Poppins';;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
}
.subheading-highlightI{
font-family: 'Poppins';
  font-size: 32px !important;
  color: white !important;
  font-weight: 500 !important;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left !important;
text-align: left !important;
// margin-bottom: 36px;
// margin-top: 50px!important;
letter-spacing: -.01em;
  line-height: 1.15;
  text-rendering: optimizeLegibility;
}

.headerwordpressI{
padding-left: 40px;
padding-right: 40px;
}

.dapp-card-contentI p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Number of lines to display */
font-size: 18px;
line-height: 1.5;
color: whitesmoke;
margin-top: 10px;
text-align: left;
font-weight: 400;
}
.blogViewImgI{
  border-radius: 15px;
}

.skelonloader1{
  padding-left:60px;
  padding-right:60px;
  padding-top:50px;
  border-radius: 16px;
}
.skelonloader2{
  display: flex;
  justify-content: space-between;
  padding-left:60px;
  padding-right:60px;
  padding-top:80px
}
.skelonloader3{
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left:60px;
  padding-right:60px;
  padding-top:100px
}
.skelonloader1l{
  height: 500px;
  width: 450px;
  border-radius: 16px;
}
.skelonloader2l{
  height: 500px;
  width: 450px;
  border-radius: 16px;
}
.skelonloader3l{
  height: 1000px;
  width: 1000px;
  border-radius: 16px;
}


@media (max-width: 1200px) {

  .dapp-card-containerI {
    display: flex;
    flex-direction: column;

    padding: 10px;
    width: 50%;
  }
  
  .dapp-cardI {
    width: calc(100%); /* Adjust this width as needed */
  }
  .dapp-card-imgI {
    padding: 10px;
  }
  .dapp-card-contentI p {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
 
  //    text-transform: capitalize;
  }
  .subheading-highlightI{
    font-family: 'Poppins';
      font-size: 28px !important;
      line-height: 45px !important;
  }

}



@media (max-width: 768px) {
  .skelonloader1{
    display: none;
  }
  .skelonloader2{
    flex-direction: column;
    padding-left:20px;
    padding-right:20px;
    padding-top:10px
  }
  .skelonloader2l{
    margin-top: 20px;
    height: 400px;
    width: 350px;
  }
  .skelonloader3l{
    height: 600px;
    width: 360px;
  }
  .skelonloader3{
    padding-left:20px;
    padding-right:20px;
    padding-top:30px
  }

  .dapp-card-containerI {
    display: flex;
    flex-direction: column;

    padding: 10px;
    width: 100%;
  }
  
  .dapp-cardI {
    width: calc(100%); /* Adjust this width as needed */
  }
  .dapp-card-imgI {
    padding: 10px;
  }
  .dapp-card-contentI p {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
 
  //    text-transform: capitalize;
  }
  .subheading-highlightI{
    font-family: 'Poppins';
      font-size: 28px !important;
      line-height: 45px !important;
  }

  .individual-blog-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5px;
    padding-right:5px;
  }
  
  .subheading-highlight{
    font-family: 'Poppins';
      font-size: 28px !important;
      line-height: 45px !important;
  }
  .subheading-highlight1{
    font-family: 'Poppins';;
      font-size: 36px !important;
      line-height: 45px !important;
  }
  .dapp-card-content p {
    font-size: 16px !important;

  //    text-transform: capitalize;
  }
  .dapp-card-content2 p {
    font-size: 16px !important;

  //    text-transform: capitalize;
  }
  .dapp-card1 a {
    font-size: 16px !important;
  }
  .dapp-card1 h2 {
    font-size: 26px !important;
  }

  h2.wp-block-heading{
     font-size: 32px !important;
 }
 h3.wp-block-heading{
  
  font-size: 22px !important;
}

  .dapp-card1 p {
    font-size: 16px !important;
  }
  .dapp-card1 li {
    font-size: 16px !important;
  }

  .dapp-sectionflexTab{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0px;
    padding-left: 0px;
  }
.dapp-card {
    width: 100%;
}
.dapp-card1 {
  width: 100%;
}
.dapp-card2 {
  width: 100%;
  flex-direction: column-reverse;
  padding: 15px;
}
.dapp-card-content2{
  width: 100%;
  padding: 5px;
}
.dapp-card-content3 {
  padding: 10px;
}
.dapp-card-container2 {
  padding: 5px;
}
.dapp-heading1 {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-evenly;
}
.dapp-headingTab{
  width: 100%;
}
.dapp-card-container {
    padding: 10px;
}
.dapp-heading {
      font-size: 22px;

}
.dapp-card img {
  height: 200px;
}

.dapp-card1 img {

  height: 200px;
}
.dapp-card2 img {
  height: auto;

}
.dapp-card-container1 {

  padding: 5px;
}
.headerwordpress{
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -10px;
}
.buildK-copy-btn1{
  margin-top: 10px;
}
.buildK-copy-btn3{
  margin-bottom: 10px;
  width: 100%;
}
.buildK-copy-btn4{
  margin-top: 5px;
}
.paginationui{
  padding-left: 0px;
  padding-right: 0px;
}
.subscritefield{
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
}
.subscritefield1{
 
  font-size: 22px;
}

.subscritefield{
  padding-left: 0px;
  padding-right: 0px;
}
}

}
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;