@import "../../component/styleConstant.less";

.ant-tabs-nav{
    margin-bottom: -25px !important;
    z-index: 10;
}
// .ant-tabs-content-holder{
//     margin-top: -25px;
//     z-index: 0;
// }
.header-one{
    font-family: 'Poppins';
    font-size: 28px;
    opacity: 0.8;
    color: #ffffff;
}
.header-two{
    font-family: 'Poppins';
    color: #ffffff;
    font-size: 27px;
    font-weight: 400;
    line-height: 35px;
    text-align: left;

}
.header-two-24{
    font-family: 'Poppins';
    font-size: 24px;
    opacity: 0.8;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 18px;
}
.header-3{
    font-family: 'Poppins';
    font-size: 22px;
    opacity: 0.8;
    color: #ffffff;
}
.subheader{
    font-family: 'Poppins';
    font-size: 16px !important;
      margin-top: -10px !important;
      color: #ffffff !important;
      opacity: 0.8 !important;
      margin-bottom: 40px !important;
      line-height: 24px !important;
      text-align: left !important;
}
.box-1{
    text-align:left;
    display:inline-block;
    background:#5453DF;
    color:#fff;
    padding: 30px;
    width: 40%;
    border-radius: 8px;
    cursor:pointer;
    margin-left:50px;
    height: 202px;
}
.box-1 {
    .icon-container{
        font-family: 'Poppins';
        font-size: 26px;
        font-weight: bold;
        height: 10px;
        margin-top:-16px;
    }
    .create{
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: bold;
        line-height:32px;
        padding-top:12px;
    }
}
.box-2{
    text-align:left;
    float:right;
    display:grid;
    color:#fff;
    background: linear-gradient(0deg, #9523C1, #FA4F2C);
    padding: 30px;
     width: 40%;
     border-radius: 8px;
     cursor:pointer;
     height: 202px;

    .icon-container{
        font-family: 'Poppins';
        font-size: 26px;
        font-weight: bold;
        height: 10px;
        margin-top:-16px
    }
    .create{
        font-family: 'Poppins';
        font-size: 24px;
        font-weight: bold;
        line-height:32px;
        padding-top:12px;
    }
}
.span-fr{
    display:flex;
    flexDirection:row;
    align-items: center;
}
.copy-1{
    z-index: 1;
    position: relative;
    left:10px;
    top: -3px
}
.copy-2{
    z-index: 1;
    position: relative;
    left:10px;
    top: 8px
}
.service-card {
  justify-content: space-between;
  width: 23%;
  height: 18rem;
  background: #222222;
  border: 1px solid #464646;
  border-radius: 10px;
  position: relative;
    &:hover {
            border: 3px solid #095fdb;
          }

}
.service-card__body{
    padding: 30px;
}
.service-card {
    .header{
        font-family: 'Poppins';
        font-size: 28px;
        opacity: 0.8;
        color: #ffffff;
    }
    .subheader{
        font-family: 'Poppins';
        font-size: 16px;
        margin-top: 0px;
        color: #ffffff;
        opacity: 0.8;
        margin-bottom:40px;
        line-height: 24px;
        text-align: left;
    }
}

.service-card__footer {
  display: flex;
  align-items: center;
  align-self: flex-end;
  width: 100%;
  height: 3.5rem;
  background: #181818;
  border-radius: 0 0 10px 10px;
  bottom: 0;
  position: absolute;
  left: 0;
}


.service-card__button {
    font-family: 'Poppins';
  background: #0000;
    border: 1px solid #303030;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-left: 30px;
    margin-right: 1rem;
    opacity: .8;
    padding: 7px 17px;
    text-transform: uppercase;
}

.service-card__button:hover {
  color: #ffffff;
  opacity: 0.5;
}
.g-30{
    gap: 30px;
}
.project-desc{
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 24px;
    min-height: 48px;
    word-wrap: break-word;
}
.project-hr{
      box-sizing: content-box;
      height: 0px;
      overflow: visible;
      border: 1px solid #303030;
      width: 100%;
}
.project-card__footer {
  display: flex;
  align-items: center;
  align-self: flex-end;
  width: 100%;
  height: 4rem;
  background: #181818;
  border-radius: 0 0 10px 10px;
  bottom: 0;
  position: absolute;
  left: 0;
}
.endpoint-name-section{
    display: flex;
    padding-bottom: 10px;

    svg{
        height: 40px;
    }
    .d1{
        margin-top: -6px;
    }
    .p1{
        font-family: 'Poppins';
        font-size: 16px;
        margin-bottom: 0px;
        text-transform: capitalize;
    }
    .p2{
              font-family: 'Poppins';
              font-size: 12px;
              margin-top: -10px;
              margin-bottom: 0;
              opacity: 0.8;
              text-transform: capitalize;
        }
}
.dapp-form-label{
    color: #fff;
    opacity: 0.8;
}
.dapp-faq-details {
      .ant-collapse {
        color: #e2e2e2;
        background-color: transparent;
        border: 2px solid #303030 !important;

        .ant-collapse-item {
          border-bottom: none;
          border-radius: 6px;

          .ant-collapse-header {
            padding: 20px;
            color: #fff;
            background: #303030;
          }
          .ant-collapse-header-text{
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 600;
          }

          .ant-collapse-content {
            padding: 20px 0px;
            color: #e2e2e2;
            background-color: transparent;
            border-top: @border;

            .ant-collapse-content-box {
              padding: 10px 16px;
              white-space: pre;
            }
          }
        }
      }
      .card{
        width: 160px;
      }
    }

    .d-grid{
        display: grid !important;
    }
    .ant-form-item-label{
        text-align: left !important;
    }

    .dashboard-sub-text{
        font-family: 'Poppins';
        margin-bottom: 4px;
          font-size: 16px;
          text-align: justify;
          color: #fff;
          opacity: 0.8;
          font-weight: 500;
    }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;