@import "../../styleConstant.less";

.filter-container {
  display: inline-flex;
  align-items: center;

  .search-box-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: -9px -1px;

    .anticon {
          position: inherit;
          left: -43px;
      color: #d9d9d9;
      font-size: 16px;
    }


    .input-box {
      width: 130px;
      height: 47px;
      font-size:12px;
      border: 1px solid #403f3f;
      border-radius: 7.94305px;
      background-color: transparent;
      color: #fff;
      padding-left: 5px;
      text-indent: 15px;
    }
                  .input-box::placeholder {
                    text-indent: 20px;
                  }
  }

  .element-padding {
    padding-right: 1.4rem;
  }

  .filter-section {
    padding: 0px 10px;
    .filter-btn {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
    }
  }

  .createBtn {
    font-family: 'Poppins' !important;
    border-radius: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: white;
    width: 150px;
    height: 49px;
    background: none;
    background-color: white;
    box-sizing: border-box;
    // .button-background();
    &:focus {
      outline: none;
    }

  }
  .createBtn1{
    background: linear-gradient(90deg, #F81F49 0%, #A219BC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.ant-radio-group.filter-option-list {
  display: flex;
  flex-direction: column;
  background: #1e1e1e;
  box-shadow: 0px 0px 6px rgba(32, 205, 137, 0.25);
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  padding-bottom: 0px;

  .option-header {
    color: #cfcfcf;
    font-size: 16px;
  }

  .ant-radio-wrapper {
    padding-bottom: 10px;
    color: #cfcfcf;

    .ant-radio-inner::after {
      background-color: @success-color !important;
    }
  }
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;