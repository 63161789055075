@import "../../styleConstant.less";

.project-details-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .name-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

   /*  .name-section {
      display: flex;
      flex-direction: column;
      width: 55%;

      .name {
        font-family: 'Poppins' ;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #efefef;
      }

      .sub-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #d8d9ff;
        padding-top: 5px;
      }
    } */

    .btn-section {
      .req-unlock-btn {
        cursor: pointer;
        width: 150px;
        height: 40px;
        background-color: transparent;
        border-radius: 8px;
        border: 1px so;
        font-size: 14px;
        font-weight: 600;
        color: #a9a9a9;
        border: 1.65582px solid #20cd89;
        margin-right: 20px;
      }

      .create-project-btn {
        cursor: pointer;
        width: 150px;
        height: 40px;
        background-color: transparent;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 600;
        color: white;
        border: 1.66px solid #464646;
        outline: none;
      }

      .gradient-background {
        .button-background();
        outline: none;
        margin-left: 2rem;
      }
    }
  }

  .card-container {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 1fr);

    .card-section-container {
      min-width: calc(100% / 4);
    }
  }
  .card-container-dapp {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(2, 1fr);

      .card-section-container {
        min-width: calc(100% / 4);
      }
    }

  .container-text {
    display: inline-flex;
    align-items: center;

    .text {
      font-weight: 600;
      font-size: 20px;
      color: #d9d9d9;
      padding-right: 10px;
    }
  }

  .api-adminrow-container {
    display: flex;
    justify-content: space-between;
  }

  .row-chart {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }
}

.active-tag,
.inactive-tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px 11px;
  background: rgba(53, 176, 139, 0.1);
  border-radius: 51.4121px;
}

.active-tag {
  border: @border;
}

.inactive-tag {
  color: #646464;
  border: 1.02824px solid #646464;
}

.adminproject-info-container {
  .gradient-border();
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 1.76599px 27.3729px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 219px;
  width: 360px;
  .api-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #cdcdcd;
    padding-bottom: 20px;
  }

  .apiKey-section {
    z-index: 1;
    background: #242424;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 3.49493px;
    margin-bottom: 30px;
    color: #adadad;
    .ant-input-affix-wrapper {
      background: #242424;
      padding: 0px;
      padding-right: 10px;
      box-sizing: border-box;
      border: none;
      cursor: default;
    }

    .ant-input {
      color: #adadad;
      background-color: transparent;
    }

    .anticon {
      color: #cdcdcd;
      &:hover {
        color: #cdcdcd;
      }
    }
    svg {
      cursor: pointer;
    }
  }

  .reset-btn-container {
    margin-bottom: 50px;
    .reset-btn {
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
      background-color: transparent;
      .gradient-border();
      outline: none;
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
  }

  .tab-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .tab {
      background-color: transparent;
      border: none;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: rgba(205, 205, 205, 0.5);
      padding-bottom: 10px;
    }

    .tab.active-tab {
      color: #cdcdcd;
      border-bottom: 2px solid #9b00f9;
    }
  }

  .tab-section {
    .tab-item {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #cdcdcd;
    }
  }

  .carousel-items {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    .hide {
      visibility: hidden;
    }

    .prev-btn,
    .next-btn {
      width: 30px;
      height: 30px;

      svg {
        fill: #e0e0e0;
        cursor: pointer;
      }
    }

    .prev-btn.disabled,
    .next-btn.disabled {
      opacity: 0.5;
      visibility: hidden;
      svg {
        cursor: not-allowed;
      }
    }

    .content-section {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 0px 10px;
      width: calc(100% - 60px);
      overflow: hidden;

      .item-section {
        padding-right: 5px;
        text-align: center;
        min-width: calc(100% / 3);
        box-sizing: border-box;

        .column {
          display: inline-flex;
          align-items: center;

          .item-text {
            padding-left: 5px;
            font-size: 14px;
            line-height: 17px;
            color: #cdcdcd;
          }
        }
      }
      .hide {
        display: none;
      }
    }
  }
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 5px !important;
}

.tooltip {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .tooltip-text {
    padding-right: 5px;
  }
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: transparent !important;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;