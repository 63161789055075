@import "/node_modules/highlight.js/styles/atom-one-dark.css";


.subtitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #e3e3e3;
  }
  .ant-checkbox-wrapper,
    .ant-radio-wrapper {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: white !important;
    }

    ul {
      list-style-type: none;
      padding: 0px;
    }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
  }
  .custom-dropdown .ant-select-dropdown {
    width: 1000px !important;
  }
  .ant-select-multiple .ant-select-selection-item{
  border: 1px solid black !important;
  border-radius: 2px !important;
  }

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;