@import "../../../styleConstant.less";

.transaction-page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;


  .endpointDetails_header{
         display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: space-between;
                justify-content: space-between;
                align-items: center;
       }
  .title{
       display: flex;
       gap: 2rem;
       flex-direction: row;
       flex-wrap: wrap;
       align-content: flex-end;
       justify-content: flex-end;
       align-items: center;
  }

  .endpointDetails_text {
    font-family: 'Poppins';
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          color: #e0e0e0;
          width:20%;
          text-align: start;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 11px 35px;
        }
        .endpointDetails_text p{
        margin-bottom: 0px !important;
        font-family: 'Poppins' ;
        }

  .endpointDetails_text_inner {
    font-family: 'Poppins';
          font-weight: 500!important;
          font-size: 16px;
          line-height: 30px;
          color: #e0e0e0;
          text-align: start;
        }
  .endpointDetails_text_header {
    font-family: 'Poppins';
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            color: #e0e0e0;
          }
  .transaction-header-section1 {
    display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: flex-end;
        align-items: center;
    .header-section1 {
    display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: flex-end;
        align-items: center;
        margin-top: -90px;
        margin-right: -15px;

      .text {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #e0e0e0;
        padding-right: 20px;
      }
    }
  }

  .gradient-button{
    font-family: 'Poppins';
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    color: white;
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    border-radius: 6px;
    border: 0.5px solid black;
  }

    .suspend-btn{
      font-family: 'Poppins';
     width: 181px;
     height: 49px;
     top: 170px;
     left: 1356px;
     gap: 0px;
     border-radius: 20px 20px 20px 20px;
     opacity: 0px;
     background: #FFFFFF;
     font-family: 'Poppins';
     font-size: 20px;
     font-weight: 700;
     line-height: 35px;
     text-align: center;
     color: #E91D1D;
     margin-right:10px;
      }

     .doc-btn{
      font-family: 'Poppins';
         width: 291px;
         height: 49px;
         top: 170px;
         left: 1548px;
         gap: 0px;
         border-radius: 20px 20px 20px 20px;
         border: 1px 0px 0px 0px;
         opacity: 0px;
         background: #000000;
         border: 1px solid #FFFFFF;
         font-family: 'Poppins';
         font-size: 20px;
         font-weight: 700;
         line-height: 35px;
         text-align: center;
         color: #FFFFFF;
         }
}
.my-account-page-container {
  .ant-tabs {
    // .ant-tabs-nav::before {
    //   border-bottom: none !important;
    // }

      .ant-tabs-nav::before {
        border-bottom:1.5px  solid white !important;
        border-radius: 50px;
        opacity: 70%;
      }
      .ant-tabs-ink-bar {
        display: none; /* Hide the bottom line indicator */
      }
      .ant-tabs-nav::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        border-radius: 50px;
        height: 3px !important; /* Adjust the thickness of the gradient line */

      }


    .ant-tabs-tab {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: rgba(239, 239, 239, 0.4);
      background: transparent;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: 2px solid @success-color !important;
      z-index: 1;
    }

    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-remove:active {
      color: rgba(239, 239, 239, 0.4);
    }

    .ant-tabs-tab-btn{
      font-family: 'Poppins';
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      text-align: left;
      color: white !important;
      opacity: 100% !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab-btn:active {
      font-family: 'Poppins';
      font-size: 20px;
      font-weight: 700;
      line-height: 35px;
      text-align: left;
      color: white !important;
      opacity: 100% !important;

    }
  }
}

.App {
    width:-webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0px;
    border-radius: 20px 20px 20px 20px;
    background: #000000;
    border-radius: 10px;
}

.App table {
    border: none !important;
    width: -webkit-fill-available;
    height: auto;
    border-radius: 10px !important;
}

th {
    border-bottom: 1px solid black;
    color:white;
    text-align: center;
}

td {
    text-align: left;
    color:white;
    word-break: break-all;
}
.endpoint_button{
display:flex;
flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    /* justify-content: space-around; */
    align-items: center;
    .gradient-button {
      font-family: 'Poppins';
            border-radius: 8px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #090a2c;
            width: 200px;
            height: 47px;
            box-sizing: border-box;
            .button-background();
            &:focus {
              outline: none;
            }
          }
}
.endpoint_button1{
display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
// .ant-modal{
//  top:20%;
//  width: 60% !important;
//  box-shadow: black;
// }
.endpoint-popup{
  .popup-text-endpoint{
    font-family: 'Poppins';
         text-align: left;
           padding-left: 20px;
           padding-bottom: 5px;
           font-size: 25px;
           font-weight: 800;
     }
     .ant-space{
      font-family: 'Poppins';
          display: flex;
            margin-top: 25px;
            padding-left: 20px;
            padding-right: 20px;
            gap: 25px;
     }
     .deploy-nft-collection{
            display: block;
            text-align: left;
            margin-top: 40px;
            float: right;
            }


            .deploy-nft-collection button{
              font-family: 'Poppins';
              font-size: 16px;
              margin-bottom: 40px;
              background: #A219BC;
              margin-right: 50px;
            }
        .deploy-nft-collection button:hover {
          background-color: #8B0FA3; /* Change to a slightly different shade on hover */
        }

  }
  .new-button{
          padding: 5px 10px;
          background: transparent;
          border-radius: 5px;
          border: 1px solid #FFFFFF;
  }
.createdappbtn-container{
  display: flex;
  align-items: center;
}
.createdappbtn-container-btn{
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: 500;
  cursor: pointer;
}
.createdappbtn-container-btn1{
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 500;
  margin-top: -2px;
  cursor: pointer;
}
.createdappbtn-container-number{
  color: black;
  background-color: white;
  padding: 5px;
  border-radius: 50px;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 10px;
}



.create-dapp-form-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}




.input-wrapper {
  display: flex;
  flex-direction: column;
}

.input-wrapper1 {
  display: flex;

}





.input-label {
  font-family: 'Poppins';
font-size: 18px;
font-weight: 400;
line-height: 35px;
text-align: left;
color:white;

margin-bottom: -8px;
}

.input-field {
  padding: 15px; /* Adjust the padding to change the size of the input field */
  border-radius: 10px;
  margin-bottom: 5px; /* This should be replaced with a valid value (e.g., 10px) */
  background-color: #242424;
  border: none;
  box-shadow: none;
  width: 500px; /* Adjust the width of the input field */
  height: 50px;
  color: #bfbfbf; /* Change the color of the font when input is focused */
  font-size: 17px;
}


.input-field::placeholder {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  font-color: #FFFFFF;
  caret-color: red;
  text-indent: 10px;
}
.input-field:focus {
  outline: none; /* Remove the outline border */
 /* Increase the font size when input is focused */
}

.input-field::selection {
  background-color: #4169E1; /* Change the background color of the selected text */
   /* Change the text color of the selected text */
}

.input-field::-moz-selection {
  background: transparent; /* Change the background color of the selected text (for Firefox) */
  color: #ffffff; /* Change the text color of the selected text (for Firefox) */
}



.input-wrapper2{
    margin-top:11px

    }
// .ant-modal-body {
//  background: black;
//  min-height: 600px;
//  width: 1200px;
// }
// .ant-modal-body button {
//   border-radius: 20px;
//   font-weight: 700;
//   font-size: 16px;
// }


.Inviting{
  display: flex;
  flex-direction: column;
justify-content: space-between;
  }

.team{
    display:flex;
    flex-direction: row;
    }

.role{
         display:flex;
         flex-direction: row;
         justify-content:space-between;
         align-items: center;

         }
     .dropdown-filter {
         width: 300px; /* Adjust dropdown width as needed */
         padding: 8px; /* Adjust dropdown padding */
         height: 50px;

         border-radius: 10px; /* Add border radius */
         outline: none; /* Remove outline on focus */
         margin-right: 10px; /* Adjust spacing between dropdown and button */
         font-size: 17px;
         color:#bfbfbf;
     }

.role .ant-btn{
    margin-top: 10px;
    }


.addrem{
    margin-top: 40px;



    }
.addrem .ant-btn {
    /* Apply styles to all Ant Design buttons within .addrem */
    /* For example: */
    margin-right: 95px; /* Adjust spacing between buttons */
    margin-left: -90px;
    margin-top: -2px;
}

.Add-member-container {
    display:flex;
    flex-direction: column;
    gap: 30px;
    }
.add-member-input-field {
      padding: 15px; /* Adjust the padding to change the size of the input field */
      border-radius: 10px;
      margin-bottom: 5px; /* This should be replaced with a valid value (e.g., 10px) */
      background-color: #242424;
      border: none;
      box-shadow: none;
      width: 500px; /* Adjust the width of the input field */
      height: 60px;
      color: #bfbfbf; /* Change the color of the font when input is focused */
      font-size: 17px;
    }
.add-member-input-field option {
    background-position: right 55px center; /* Adjust the position as needed */
}
.add-member-input-field::placeholder {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  font-color: #FFFFFF;
  caret-color: red;
  text-indent: 10px;
}
.add-member-input-field:focus {
  outline: none; /* Remove the outline border */
 /* Increase the font size when input is focused */
}

.add-member-input-field::selection {
  background-color: #4169E1; /* Change the background color of the selected text */
   /* Change the text color of the selected text */
}

.Add-member-container-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Adjust spacing between contents */
    gap: 70px;

}

.Add-member-container-two{
        display:flex;
        flex-direction: row;
        gap: 30px;

    }

.manage-teams-button-container{

display:flex;
flex-direction: row;
float: right;
gap:20px;

}
/* Styles for the delete button */
.delete-button .button-with-icon {
    font-family: 'Poppins';
    width: 214px;
    height: 58px;
    background: #AE2525;
    color: white; /* White text color */
    border: none; /* Remove border */
    padding: 10px 20px; /* Adjust padding */
    font-size: 20px; /* Adjust font size */
    border-radius: 20px; /* Add border radius */
   font-family: 'Poppins';
   font-weight: 700;
   line-height: 35px;
   text-align: left;
   }







/* Styles for the manage limits button */
.manage-limit-button .button-with-icon {
    background: #FFFFFF;
    color: black; /* White text color */
    border: none; /* Remove border */
    padding: 10px 10px; /* Adjust padding */
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 700;
    line-height: 35px;
    text-align: left;
    border-radius: 20px; /* Add border radius */
    width: 210px;
    height: 49px;
    margin-top: 3px;

}

.assign-project-button{
    float:right;

        background: #FFFFFF;
        color: black; /* White text color */
        border: none; /* Remove border */
        padding: 8px 33px; /* Adjust padding */
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 700;
        line-height: 35px;
        text-align: left;
        border-radius: 20px; /* Add border radius */
        width: 214px;
        height: 49px;
        margin-top: 3px;
    }
.delete-icon {
    width: 30px; /* Adjust icon width */
    height: 30px; /* Adjust icon height */
    background-image: url('../enterprise/images/delete-cross-icon.svg');
    background-size: cover;
    background-position: center;
    margin-right: 10px;
}
.manage-limit-icon {
        width: 30px; /* Adjust icon width */
        height: 30px; /* Adjust icon height */
        background-image: url('../enterprise/images/manage-setting-gear-icon.svg');
        background-size: cover;
        background-position: center;
        margin-right: 10px;
        margin-left: 5px;
    }














/* Styles for the icon within the buttons */
.button-with-icon {
    display: flex;
    align-items: center;
}

.button-with-icon .icon {
    margin-right: 5px; /* Add space between icon and text */
    font-size: 20px; /* Adjust icon size */
}

.add-member-button .button-with-icon {
    background: #FFFFFF;
    color: black; /* White text color */
    border: none; /* Remove border */
    padding: 10px 10px; /* Adjust padding */
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 700;
    line-height: 35px;
    text-align: left;
    border-radius: 20px; /* Add border radius */
    width: 210px;
    height: 49px;
    margin-top: 3px;

}

.add-member-icon {
        width: 30px; /* Adjust icon width */
        height: 30px; /* Adjust icon height */
        background-image: url('../enterprise/images/add-member-manage-team-icon.svg');
        background-size: cover;
        background-position: center;
        margin-right: 10px;
        margin-left: 5px;
    }

// .confirmation-dialog {
//     height: 200px;
//     width: 500px;
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -65%);
//     background-color: black;
//     padding: 20px;
//     border-radius: 5px;
//     box-shadow: 0 0 10px rgba(13, 23, 16, 20.1);
//     z-index: 1000;
// }

.confirmation-dialog p {
    margin-left: 40px;
    margin-bottom: 39px;

    font-size: 23px;

}

.confirmation-button{
    margin-right: 85px;
    float: right;
    }

.confirmation-button button {
    color: white;
    margin-right: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: #484848;
    font-size: 16px
}

.confirmation-dialog button:last-child {
    margin-right: 0;
}

.ant-modal-content {
  background-color: transparent !important;
  box-shadow:none;
  padding-left: 400px;
}


@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;