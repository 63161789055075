@import "../../styleConstant.less";
.experience_responsive {
    width: auto;
    overflow-x:hidden;
     background-color:  #07030A;
*{
font-family: 'Poppins' ;
}
/* Base styles for the navbar */
.header-wrap {
  background: #080322;
  position: fixed;
  top: 0;
   width: 100%;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.navbar-brand img.logo {
  height: 40px; /* Adjust the height as needed */
}

.navbar-toggler {
  border: none;
  background-color: transparent;
  display:none; /* Hide the toggler for screens wider than 768px */
}

.nav-item .nav-link{
  cursor: pointer;
  margin-left: 15px;
  font-size:18px;
  color: #fff;
  cursor:pointer;

}
.navbar-nav .active-link {
  color: #d988ddcc; /* Set color for the active link */
  font-weight: bold; /* Example: Make the active link bold */
  font-size:18px;
    cursor:pointer;
}
.nav-item .nav-link:hover{
color:#b447eb;
}
.navbar-logo-division {
    display: flex;
    align-items: center;
  }
.menu-right .header-signupbtn {
  padding: 8px 16px;
}
.header-signupbtn {
      font-family: 'Poppins' ;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         padding: 17px 26px 16px;
         border-radius: 0.875rem;
        background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
         color: white;
//          .gradient-border();
   }
.text-white{
font-size:18px;
color:white;
cursor:pointer;
}
/* Media query for responsiveness up to 1200px */
@media (max-width: 1200px) {
  .nav-item {
    display: flex; /* Hide the navigation items */
  }

  .navbar-toggler {
    display: block; /* Show the toggler for screens up to 1200px */
  }
  .navbar-logo-division {
    margin-right  : 17%;
  }
  .navbar-collapse {
    display:none;
    background-color: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index:-1;
    font-size:20px;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
//     display: flex; /* Show the navbar items when toggler is clicked */
    display: grid !important;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
         background:#000;
          font-size:20px;
  }

  .navbar-collapse li {
    text-align: center;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }
}
@media (max-width: 360px) {
  .nav-item {
        display: grid; /* Hide the navigation items */
  }
  .navbar-logo-division {
      margin-right  : 0% !important;
    }

  .navbar-toggler {
    display: block; /* Show the toggler for screens up to 360px */
  }

  .navbar-collapse {
    display:none;
    background: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    font-size:18px;

  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
    display: flex; /* Show the navbar items when toggler is clicked */
     display: grid !important;
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            background:#000;
  }

  .navbar-collapse li {
    text-align: left;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }

  .navbar-brand img.logo {
    height: 30px; /* Adjust the logo height for smaller screens */
  }

  .menu-right .header-signupbtn {
    font-size: 14px; /* Adjust the font size for smaller screens */
  }
}

/* Basic styles for the banner section */
.Bannersection-wrap {
  text-align: center;
  position: relative;
  background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #080322;
  backdrop-filter: blur(25px);
  min-height:100vh;
}

.Bannersection-wrap::before {
    content: "";
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
.banner-head-container {
  margin: 0 auto;
  max-width:1200px;
    background: url(../../../assets/images/banner-bcg.avif)no-repeat;
        background-position: center;
        background-size: contain;



}

.banner-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: normal;
  color: #ececec;
  margin:40px;
   padding: 100px;
}
 .banner-highlight {
     background: linear-gradient(to right, #AA0AFC, #D5D3F3, #007AEB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.banner-para {
  font-size: 20px;
  line-height: 1;
  color: grey;
  margin-top: -50px;
  margin-bottom: 40px;
  text-align:center;
}

.banner_btn {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 17px 26px 16px;
    width:max-content;
    border-radius: 6px;
    background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
    color: white;
    .gradient-border();
//     border:1px solid #b447eb;
}
.docs_btn{
font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 17px 26px 16px;
    background: transparent;
    border-radius: 4px;
    color: white;
   border:1px solid #fff;
}
.banner-button-section{
display:inline-flex;
gap:1rem;
}
.banner-docs{
display:inline-flex;
gap:1rem;
}
/* Responsive styles for screens up to 1200px */
@media (max-width: 1200px) {
  .banner-head-container {
    padding: 0 15px;
  }

  .banner-heading {
    font-size: 55px;
      line-height: normal;
      padding: 20px;
//       margin-bottom: 40px;
  }

  .banner-para {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .theme-btn {
    padding: 10px 20px;
    font-size: 14px;
  }
}

/* Responsive styles for screens up to 768px (tablet and mobile) */
@media (max-width: 768px) {
  .banner-heading {
    font-size: 28px;
    line-height:1.5;
    margin:20px;
  }

  .banner-para {
      margin-top: 20px;
    font-size: 16px;
    margin-bottom:30px;
  }

  .theme-btn {
    padding: 8px 16px;
    font-size: 12px;
  }
  .Bannersection-wrap {
    margin-top: 0px !important;
  }
  .banner_btn {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 10px;
//       padding: 15px 13px 16px;
      width:auto;
      background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
      border-radius: 4px;
      color: white;
      .gradient-border();
      border:1px solid #b447eb;
  }
  .banner-button-section{
  display: inline-flex;
  flex-direction: column;
  }
}
@media (max-width: 360px) {
  .banner-heading {
    font-size: 22px;
    line-height:1.5;
    margin:0px;
  }


}
.header-wrap {
  z-index: 5;
}
.Bannersection-wrap {
    padding-top:100px;
}

.Bannersection-wrap .experience-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin:10px;
  grid-gap: 10px;
}


.Bannersection-wrap .experience-card {
  flex: 1; /* Make each card take up equal space */
  max-width: calc(25% - 20px); /* 25% width minus margin */
  height:250px;
  margin: auto; /* Adjust the margin for spacing between cards */
  border: 1px solid #bbb8e5;
  padding: 20px;
  border-radius:20px;
  text-align: center;
  background-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   transition: background-color 0.3s;
   cursor: pointer !important;
   position:relative

}
.experience-card:hover {
background: linear-gradient(135deg, #b447eb, #007bff);
 border: 1px solid #b447eb;
  color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  .launch-btn{
   background: #000 !important;
  }
}
.experience-card p{
font-size: 25px !important;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
 color: #FBF5FF;
 text-align:center;
}
.experience-card .launch-btn{
      font-family: 'Poppins' ;
         font-style: normal;
         font-weight: 500;
         font-size: 20px;
         line-height: 20px;
         padding: 17px 26px 16px;
        border-radius: 6px;
        background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
         color: white;
         .gradient-border();
   }

/* Media query for responsiveness */
@media (max-width: 768px) {
  .experience-card {
    max-width: calc(50% - 20px); /* Two cards per row on smaller screens */
  }
}
@media (max-width: 360px) {
  .experience-card {
    max-width: 100%; /* Two cards per row on smaller screens */
  }
  .experience-card p{
  font-size: 20px !important;
  }
  .experience-card button{
           font-size: 17px;
           line-height: 20px;
           padding: 8px 26px 9px;
     }
}
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;