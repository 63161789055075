/* Chat.css */

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.right-msg {
  flex-direction: row-reverse;
}

.message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.right-msg .msg-bubble {
  background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
  color: #fff;
  border-bottom-right-radius: 0;
}
.left-msg .msg-bubble {
  background: linear-gradient(90deg, #2797ff -4.52%, #2797ff 120.27%);
  color: #fff;
  border-bottom-right-radius: 0;
}
.msg-bubble {
  max-width: 95%;
  padding: 15px;
  border-radius: 15px;
    border-bottom-right-radius: 15px;

}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}
.right-msg .msg-bubble {
  color: #fff;
}

.message-form {
  display: flex;
  margin-top: 10px;
}

.message-form input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: transparent;
  color: white;
  outline: none;
}


 .chat-card-container {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    .card-section-container {
      min-width: calc(100% / 3);
    }
  }

  .chat-container-text {
    align-items: center;
    text-align: center;
    .text {
      font-weight: 600;
      font-size: 30px;
      color: #d9d9d9;
      padding-right: 10px;
    }
  }

.chat-content {
        display: block;
        justify-content: space-between;
        margin: auto;
    }
    .chat-content h5 {
            margin-top: 0;
            padding-bottom: 10px;
            color: #fff;
            text-align: center;
    }
.chat-box {
        flex: 1;
        background-color: #1f262c;
        padding: 30px;
        border-radius: 6px;
        color: #fff;
        min-height: 151px;
    }
.chat-box h2 {
        margin-top: 0;
        padding-bottom: 10px;
        border-bottom: 2px solid #e30046;
        text-transform: uppercase;
    }
.chat-box p {
        margin-top: 0;
        text-align: center;
        font-weight: normal;
    }
.chat-box p span {
        font-size: 33px;
    }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;