@import '../../styleConstant.less';

.pagination-bottom-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0px;

    .hits-per-page-container {
      display: inline-flex;
      align-items: center;
      padding-left: 15px;

      .ant-select {
        .ant-select-selector {
          color: #8b8d97;
          background-color: transparent;
          height: 25px;
          border: 2px solid #8b8d97;
            border-radius: 10px;
            margin: -1px;
            width: 65px;
            .ant-select-selector:focus {
              border:2px solid #8b8d97!important;
            }

          .ant-select-selection-item {
            line-height: unset !important;
            left: 8px;
             top: 2px;
          }
        }

        .ant-select-arrow {
          color: #8b8d97;
        }
      }
      .page-text {
        padding-left: 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #666666;
      }

      .hits-per-page-text {
        display: inline-flex;
        align-items: center;
        padding-left: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        .items-text {
          color: #a6a8b1;
          font-size:12px;
        }

        .number-text {
          color: #666666;
          padding-left: 10px;
           font-size:12px;
        }
      }
    }

    .pagination-container {
      display: inline-flex;
      align-items: center;

      .left-icon,
      .right-icon {
        cursor: pointer;
        color: white!important;
        font-size: 10px;
      }

      .left-icon {
        padding-right: 10px;
      }

      .left-icon.disabled,
      .right-icon.disabled {
        color: #666666!important;
      }

      .pagination-section {
        display: inline-flex;
        align-items: center;
        padding-left: 15px;

        .ant-select {
          .ant-select-selector {
            color: #8b8d97;
            background-color: transparent;
            height: 25px;
            border: 2px solid #8b8d97;
            border-radius: 10px;
            margin: -1px;
            width: 60px;
            .ant-select-selector:focus{
              border:2px solid #8b8d97;
            }

            .ant-select-selection-item {
              line-height: unset !important;
            }
          }

          .ant-select-arrow {
            color: #8b8d97;
          }
        }

        .page-text {
          padding-left: 5px;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #666666;
        }
      }
    }
  }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;