.sc-button{
  border-radius: 12px;
  font-family: 'Poppins';
  //opacity: 0px;
  border: 1px solid #FFFFFF;
  color: #4C59E3;
  background: white;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
}
.sc-button1{
  border-radius: 12px;
  font-family: 'Poppins';
  //opacity: 0px;
  border:none;
  background: transparent;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
}
.ant-modal {
  width: 75% !important;
}
.ant-modal-body {
 background: black;
 min-height: 500px;
}
.add-contract-choose-button{
  border-radius: 15px;
  //opacity: 0px;
  border: 2px solid #FFFFFF;
  color: white;
  height: 60px;
  width: 300px;
  background: transparent;
  margin-bottom:10px;
}
.add-contract-container{
 display: flex;
 flex-direction: column;
 align-items: center;
 margin-bottom: 18px;
 padding-bottom: 20px;
}
.add-contract-container-button-text{
  font-family: 'Poppins';
 font-size: 16px;
 font-weight: 400;
 text-align: center;
 padding:15px;
}
.add-contract-modal-title{
 font-size: 24px;
 font-weight: 700;
 text-align: center;
 padding-bottom:30px;
 color: white;
}
.load-contract-input-container {
    color: #bfbfbf;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-color: #464646;
    background: #2e2e2e;
    border-radius: 12px;
    padding: 5px;
    font-weight: 400;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }
.load-contract-dropdown-filter {
    color: rgba(183, 183, 183, 0.65);
    font-size: 16px;
    width: 400px;
    border: 1px;
    border-style: solid;
    border-color: #464646;
    border-radius: 12px;
    margin-left: 0px;
    padding: 10px;
    cursor:pointer;
    height: 60px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }
.ant-modal-body button {
  background: #4C59E3;
  border-radius: 20px;
  font-weight: 700;
  font-size: 16px;
}
.template-container{
   display: grid;
   grid-gap: 20px;
   grid-template-columns: repeat(3, 1fr);
   margin-top: 40px;
   width: 60%;
   grid-column-gap: 0px;
}
.template-header{
  font-family: 'Poppins';
   font-size: 24px;
   font-weight: 400;
   line-height: 35px;
   text-align: left;
   color: #FFFFFF;
}
.template-box{
   background: linear-gradient(180deg, #262626 0%, #373737 100%);
   border: 1px solid #7F7F7F;
   width: 200px;
   height: 130px;
   border-radius: 15px;
   cursor: pointer;
}
.smartcontract input {
   color: rgba(183, 183, 183, 0.65);
    font-size: 16px;

//     max-width: 750px;
    width: 550px;
    background: #2e2e2e;
    border-radius: 15px;
    font-weight: 400;
    margin-top: 0px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
      padding:10px;
    }
    border: 1.8px solid #716F6F;
    height : 50px;

  }


 .create-contract-dropdown-filter {
     color: rgba(183, 183, 183, 0.65);
     font-size: 16px;
     width: 550px;
     border: 1.8px solid #716F6F;
     border-radius: 15px;
     margin-left: 0px;
     margin-bottom: 10px;
     padding: 10px;
     font-weight: 400;
     height: 50px;
     background:#2e2e2e;
     &::placeholder {
       color: rgba(183, 183, 183, 0.65);
       padding:10px;
     }
   }
.create-contract-text{
   font-size: 16px;
   font-weight: 400;
   text-align: left;
   color: #878787;
}
// .ant-checkbox{
//  background: #D9D9D9;
//   border-color: #D9D9D9;
//   border: 1px solid #D9D9D9;
//   border-radius: 5px;
//   width: 20px;
// }
// .ant-checkbox-inner::before{
//   background: #D9D9D9;
//   border-color: #D9D9D9;
//   border: 1px solid #D9D9D9;
//   border-radius: 5px;
// }
//
// .ant-checkbox-checked .ant-checkbox-inner{
//   background-color: #D9D9D9;
//   border-color: #D9D9D9;
//   border-radius: 5px;
// }
.deploy-button{
   padding: 5px 30px 5px 30px;
   border-radius: 15px;
   background: #4C59E3;
   color: #fff;
   border: transparent;
}
pre{
  background: #000000;
  border-radius: 20px;
  margin: 0px
}
.hljs {
      color: #fff;
      border-radius: 20px;
      width: 575px;
      background: transparent;
}
.hljs::before{
  position: absolute;
  border: 2px solid transparent;
  border-radius: 20px;
  background: transparent;
//   background-image: linear-gradient(180deg, #D1CFCF 0%, rgba(107, 106, 106, 0) 100%);
}
// .hljs::after{
//   position: absolute;
//   border: 2px solid transparent;
//   border-radius: 20px;
// //   background-image: linear-gradient(0deg, rgba(107, 106, 106, 0), #D1CFCF 100%);
// background-image: linear-gradient(180deg, #D1CFCF 0%, rgba(107, 106, 106, 0) 100%);
// }
.copy-button{
  width: 100px;
  height: 29px;
  border-radius: 15px;
  background: #FFFFFF;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  color: black;
  font-weight: 700;
  font-size: 12px;
}
.ant-checkbox-inner::before{
 background: transparent;
}
.ant-radio-inner::before{
 background: transparent;
}
.ant-radio-checked .ant-radio-inner{
 border-color: #fff;
}
.ant-checkbox-checked .ant-checkbox-inner{
 border-color: #fff;
}
.custom-contract-box {
  height: 500px !important;
  background-color: black !important;
  font-family:'Poppins';
  border-block-color: #464646 !important;
  font-size: 14px !important;
  color: white !important;
  border-radius: 20px !important;
  border: 2px solid transparent;
  background: transparent;
  border-color: linear-gradient(180deg, #D1CFCF 0%, rgba(107, 106, 106, 0) 90.43%);
}
.ant-form-item{
 margin-bottom: 0px;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;