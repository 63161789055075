@font-family: "'Inter', sans-serif;";
@bg-color: #0f113b;
@color-side-text: #e9e9e9;
@letter-space: 0.02em;
@placeholder-color: #8e85aa;
@main-bg-color: #02020f;
@color-white: #ffffff;
@border-color: #f5f5f5;
@success-color: #9359ef;
@color-d5: #d5d5d5;
@color-e7: #e7e7e7;
@border: 0.5px solid black;
@side-nav-common-color: #110a16;
@side-nav-dashboard: #181818;

.gradient-border() {
  border: none;
  position: relative;
  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    border: 0.5px solid transparent;
    background: linear-gradient(90deg, #b447eb, #2797ff) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}
.gradient-border1() {
  border: none;
  position: relative;
  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    border: 2.5px solid transparent;
    background: linear-gradient(90deg, #b447eb, #2797ff) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  
}


.button-background() {
  color: white;
  background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
  border-radius: 6px;
  border: 0.5px solid black;
}

img {
  aspect-ratio: auto 640 / 360 !important;
}


