@import "../../styleConstant.less";

.teams-page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

   p {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #d8d9ff;
      margin: 0;
      margin-top: 5px;
    }

  .teams-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-section {
      display: inline-flex;
      align-items: center;

      .text {
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #e0e0e0;
        padding-right: 20px;
      }
    }
  }

  .save-changes-btn {
    background: transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: white;
    padding: 0.31rem 1rem;
    border: 1.02824px solid #35b08b;
    border-radius: 51.4121px;
  }

  .save-changes-btn-difer {
    background: transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: white;
    padding: 0.31rem 1rem;
    border: 1.02824px solid #ca5a5a;
    border-radius: 51.4121px;
  }
}

.customModel {
  background-color: #0e114e !important;
  color: #fff !important;
}

.ant-modal-header {
  background-color: #0e114e !important;
  color: #fff !important;
}

.input-box {
  width: 100%;
  height: 42px;
  margin: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  background-color: transparent;
  color: #fff;
  padding-left: 35px;
}

.title {
  color: #fff;
  width: 100%;
  text-align: center;
}

.footer {
  text-align: center;

  button {
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
    width: 200px;
    box-sizing: border-box;
    padding: 10px;
    background: @success-color;
    border-radius: 5px;
    color: #0d1164;
  }
}

.steps {
  width: 100%;
  div {
    width: 50%;
  }
}

.highlight {
  background: @success-color;
  color: #fff;
  width: 30px !important;
  padding: 8px;
  border-radius: 10px;
}

.ant-steps-item-wait {
  .ant-steps-item-title {
    color: #646b7c !important;
  }

  .ant-steps-item-title::after {
    background-color: #0d1164 !important;
  }

  .ant-steps-item-icon {
    background: #2e2f4c !important;
    border-color: #646b7c !important;

    .ant-steps-icon {
      color: #646b7c !important;
    }
  }
}

.ant-steps-item-active {
  .ant-steps-item-title {
    color: #fff !important;
  }

  .ant-steps-item-title::after {
    background-color: #0d1164 !important;
  }

  .ant-steps-item-icon {
    background: black !important;
  }
}

.autosearch-container {
  margin-top: 30px !important;
}

.add-teams-container {
  .ant-select-multiple .ant-select-selection-item {
    background-color: transparent;
    color: #000 !important;
  }
}

.ant-select-item {
  color: #000 !important;
}

.devselect {
  display: flex;
  align-items: stretch;
  padding: 10px;
  flex-direction: column;

  .ant-select {
    margin: 10px;
  }
  

  .ant-select {
    .ant-select-selector {
      color: #8b8d97;
      background-color: transparent;
    }
  

    .ant-select-arrow {
      color: #8b8d97;
    }

  }

  button {
    margin-top: 6px;
  }
}

.developer-details-section{
.table-heading-text{
      font-weight: 600;
      font-size: 20px;
      color: #d9d9d9;

}
}
.select-role-width{
width: 250px !important;
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;