@import "../../styleConstant.less";

.create-app-container {
  display: flex;
  justify-content: stretch;

  .slider-layout {
    width: 352px;
    //background: @bg-color;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 170px);
    /* width: -webkit-fill-available; */
    margin-left: 20px;
    padding: 20px;
    box-sizing: border-box;
    //background: @main-bg-color;

    .content-container {
      padding: 20px;
      height: 100%;
      display:flex;
      flex-wrap: wrap;
          align-content: flex-start;
          justify-content: flex-start;
          align-items: flex-start;
    }
  .ant-row {
  width:160%;
  }
  }

}

 @media (max-width: 1200px) {
   .create-app-container {
   display: grid;
   }

   }

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;