@import "../../styleConstant.less";


       .sub-static-container    {
        *{
        font-family: 'Poppins' ;

          }
            margin: 0 auto;
            padding: 0px;
            height:100vh;
            overflow:hidden;
        }
         .split-background-container {
                            position: relative;
                            margin: 0;
                            padding: 0;
                            height: 100vh;
                             overflow-y:scroll;
                         background: url('./dot-texture.avif') repeat 0 0 #161b28;
                        }

                        .split-image {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            width: 50%;
                            height: 100%;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                        }

                        .second-image {
                           background: url('./404-bg.avif');
                            height: auto; /* Height of each triangle is 50% of the container height */
                            position: absolute;
                        }

                        .right-top-half {
                            top: 0;
                            clip-path: polygon(100% 0, 0 0, 100% 50%);
                        }

                        .right-bottom-half {
                            bottom: 0;
                            clip-path: polygon(0 100%, 100% 50%, 100% 100%);
                        }

                        /* Responsive styles for smaller screens */
                        @media (max-width: 768px) {
                            .sub-static-container    {
                                   *{
                                   font-family: 'Poppins' ;

                                     }
                                       margin: 0 auto;
                                       padding: 0px;
                                       height:100vh;
                                       overflow-y:scroll;
                                   }
                            .split-image {
                                width: 100%;
                                height: 0;
                                left: 0;
                            }
                            .right-bottom-half {
                                clip-path: polygon(0 50%, 100% 50%, 100% 100%);
                            }
                        }
         .main-static-container{

           max-width: 800px;
                     margin: 0 auto;
                     padding: 20px;
                     background-color: transparent; /* Semi-transparent white background for the content */
                     border-radius: 5px;
                         margin-top: 100px;
                         margin-left: 20px;
                         margin-right: auto;
                         margin-bottom: auto;
         }
         .banner-highlight {
           background: linear-gradient(90deg, #B447EB 0%, #2797FF 100%);
           -webkit-background-clip: text;
           -webkit-text-fill-color: transparent;
         }
        h1 {
            color: #fff; /* White heading text color */
            font-size:35px;
        }

        p {
            margin-bottom: 15px;
            font-size:18px;
            text-align:justify;
            color: #fff;
        }
        h2{
        font-size:80px;
        font-weight:700;
        color:#fff;
        text-align:center;
        }
        .display-align{
         display:flex;
         width:80%;
         align-items: center;
         justify-content:space-between;
         align-content:space-between;

        }
        .goback-button{
          padding: 15px 30px;
                    background-color:#54b723;
                    color: #fff;
                    border: none;
                    border-radius: 5px;
                    font-size: 18px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

        }
        .goback-button:hover {
                    background-color:#1F8A70;
                }

        a {
            color: #3fb2b5;
            text-decoration: none;
        }

        a:hover {
            text-decoration: none;
        }

        /* Responsive styles for smaller screens */
        @media (max-width: 600px) {
            .sub-static-container  {
                max-width: 100%; /* Reduce container width for smaller screens */
            }
            .display-align{
                      display:flex;
                      flex-direction: column-reverse;
                      width:100%;
                      align-items: center;

                     }
           .main-static-container{
            border-radius: 5px;
            margin-top: 0px;
            margin-left: 0px;
            margin-right: auto;
            margin-bottom: auto;
                    }
            .split-background-container {
                                      overflow-y:scroll;
                                      overflow-x:hidden;
                               }
             h2{
                    font-size:50px;
                    font-weight:600;
                    color:#fff;
                    text-align:center;
                    }
             h1 {
                        color: #fff; /* White heading text color */
                        font-size:30px;
                    }
        }

.activate-page-container{
    *{
          font-family: 'Poppins' ;
    }
    background: #100111;
    background:linear-gradient(to bottom right,#9e73ff21,#08031200 50%) 100% 100% /50% 50% no-repeat,linear-gradient(to bottom left,#9e73ff21,#08031200 50%) 0 100% /50% 50% no-repeat,linear-gradient(to top left,#9e73ff21,#08031200 50%) 0 0 /50% 50% no-repeat,linear-gradient(to top right,#9e73ff21,#08031200 50%) 100% 0 /50% 50% no-repeat,#080322;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
}
.activate-page-content-container{
   background: #40084A;
   display: grid;
   place-items: center;
   padding: 50px;
   text-align: center;
   width: 800px;
   max-width: 80%;
   border-radius: 40px;
   position: relative;
}
.activate-page-content-container img.logo  {
   position: absolute;
   top: -10%;
   left: 50%;
   transform: translateX(-50%);
   width: 130px;
   height: 130px;
   border-radius: 50%;
   border: 5px solid transparent;
   background-clip: padding-box;
   padding: 3px;
   background-image: linear-gradient(to right, #ff00ff, #00ffff);
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;