@import "../../../styleConstant.less";

.dapp-details-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.details-section {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 60px;
}
.dapp-details-header{
  font-size: 25px;
  font-weight: 700;
  text-align: left;
  color: #fff;
  padding-bottom: 10px;
}
.dapp-details-text{
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  color: #fff;
  line-height: 30px;
}
.dapp-details-label{
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #fff;
}
.label-text{
    margin-right: 55px;
    }
.label-text1{
    margin-right: 15px;
    }

.dapp-details-value{
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #fff;
}
.dapp-details-row{
   display: flex;
   margin-bottom:10px;
}
.tab-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .tab {
      background-color: transparent;
      border: none;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #fff;
      padding-bottom: 10px;
    }

    .tab.active-tab {
      color: #fff;
      border-bottom: 2px solid #9b00f9;
      font-weight: 700;
    }
  }

.code-layout{
     width: 668px;
     top: 550px;
     left: 340px;
     gap: 0px;
     border-radius: 20px 20px 20px 19px;
//      opacity: 0px;
}

.form-sandbox-layout{
     display: flex;
     gap:62px;
}

.curl-code-layout {
  font-family: 'Inter', sans-serif !important;
  font-size: 14px !important;
  color: white !important;
  border-radius: 20px !important;
  background: #000000 !important;
  border-image-source: linear-gradient(180deg, #D1CFCF 0%, rgba(107, 106, 106, 0) 90.43%) !important;

}
.dapp-endpoints-table{
    background: #000000;
    border: 1px solid #FFFFFF;
    width: 100%;
    height: 10px;
}
.dapp-tab-container{
    background: #000000;
    border-radius: 15px;
    padding:30px;
}
.dapp-endpoint-table-button{
    border-radius: 15px;
    background: #FFFFFF;
    color: #000;
    height: 40px;
}
.dapp-endpoint-header{
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    text-align: left;
}
.dapp-endpoint-text{
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
}
.dapp-endpoints-table th,
.dapp-endpoints-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
.dapp-endpoints-table td,
.dapp-endpoints-table tr{
     height: 0px !important;
     line-height:0px !important;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;