@import "../../styleConstant.less";

.add-developer-section {
  display: flex;

  .add-developer-section-1 {
    width: 40%;
    margin-top: 30px;
    }
    .add-developer-section-2 {
        width: 70%;
        margin-top: 30px;
    }
     .input-container {
          color: @color-d5;
          font-size: 16px;
          width: 100%;
          max-width: 750px;
          border: 1px;
          border-style: solid;
          border-color: #464646;
          background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
          border-radius: 12px;
          padding: 10px;
          &::placeholder {
            color: rgba(183, 183, 183, 0.65);
          }
        }
         .add-dev-submit {
              border-radius: 8px;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              color: #090a2c;
              width: 150px;
              height: 47px;
              box-sizing: border-box;
              .button-background();
              &:focus {
                outline: none;
              }
            }
}
.gradient-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();
    &:focus {
      outline: none;
    }
  }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;