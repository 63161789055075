@import "../../../styleConstant.less";

.text {
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #efefef;
  margin: 0;
}

.nft-collection-cards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 3rem;
}

.template-header {
  display: flex;
  align-items: flex-start;
  justify-content: start;

  .refresh-collections {
    width: 131px;
    height: 47px;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border: 1px solid #403f3f;
    border-radius: 7.94305px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #c4c4c4;
    cursor: pointer;
    margin-right: 25px;
  }

  .allocate-budget {
    width: 180px !important;
  }

  .gradient-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();

    &:focus {
      outline: none;
    }
  }
}

.contract-card-templates {
  background: #151617;
  box-shadow: 0px 3.49493px 8.73734px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 430px;
  height: 220px;
  border: none;
  position: relative;
  align-content: center;
  .gradient-border();
  cursor: pointer;

  img {
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    width: 100%;
  }

  .nft-collection-image {
    border-radius: 6px;
    object-fit: fill;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  h5 {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #f8f2ff;
  }

  p {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #f1e7fc;
    overflow: auto;
    position: relative;
    z-index: 1;
    max-height: 4rem;
    min-height: 16px;
  }

  .nft-card-lower {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }

  .nft-card-detail-section {
    padding: 20px;

    .nft-card-content {
      .nft-card-status {
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;

        .nft-status-success {
          background: transparent;
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          width: 4rem;
          height: 1.5rem;
          color: white;
          border: 1.02824px solid #35b08b;
          border-radius: 51.4121px;
        }

        .nft-status-pending {
          background: transparent;
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          width: 4rem;
          height: 1.5rem;
          color: white;
          border: 1.02824px solid #ca5a5a;
          border-radius: 51.4121px;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .ntf-card-details {
    p {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #4a545f;
    }

    h6 {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #f8f2ff;
    }

    .collection-chain-details {
      display: flex;
      align-items: center;
      justify-content: center;

      h6 {
        margin: 0;
        margin-left: 5px;
      }
    }
  }
}

.nft-trait-button {
  max-width: 750px;
  background: #151617 !important;
  box-shadow: 0px 3.49493px 8.73734px rgb(0 0 0 / 10%) !important;
  flex-direction: column;
  box-sizing: border-box;
  border: none !important;
  color: white !important;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.01px !important;
  border-color: #d9d9d9 !important;
  border-style: dotted !important;
}

.ant-modal-body {
  background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
  border-radius: 2side navbarpx;

  .ant-list-items {
    .ant-list-item {
      color: white;
    }
  }

  .developers-listing-loading {
    margin: 2rem;
  }

  .ant-spin-nested-loading {
    .ant-spin-dot-item {
      background-color: white;
    }

    .ant-spin-text {
      color: white;
    }
  }

  .nft-collection-popup {
    color: white;

    .ant-upload.ant-upload-drag {
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
      border: 1.65582px dashed #464646;
      border-radius: 8px;
      max-width: 750px;
    }

    .ant-upload-drag-container {
      display: flex !important;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
    }

    .nft-upload-header {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }

    .nft-upload-browse {
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 123px;
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      background-color: transparent;
      color: white;
      .gradient-border();

      &:focus {
        outline: none;
      }
    }

    .nft-upload-limmiter {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      color: rgba(255, 255, 255, 0.2);
    }
  }

  .close-icon {
    display: flex;
    justify-content: flex-end;
  }

  .popup-text {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    color: #ede6f5;
    align-items: center;
    justify-content: center;
  }

  .input-container {
    color: @color-d5;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-color: #464646;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border-radius: 12px;
    padding: 10px;

    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }

  button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: white !important;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();

    &:focus {
      outline: none;
    }
  }

  .deploy-nft-collection {
    display: flex;
    justify-content: center;
    align-items: center;
  }


}
.endpoint-popup{
.popup-text-endpoint{
       text-align: left;
         padding-left: 42px;
         padding-bottom: 5px;
         font-size: 20px;
         font-weight: 600;
   }
   .ant-space{
        display: flex;
          margin-top: 25px;
          padding-left: 40px;
          padding-right: 40px;
          gap: 25px;
   }
   .deploy-nft-collection{
        display: block;
          text-align: left;
          margin-top: 40px;

          button{
            font-size: 16px;
              margin-bottom: 40px;
          }
   }
}

.nft-collection {
  .nft-collection-api {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-left: 5px;
    }

    .ant-input-affix-wrapper {
      padding: 0;
      border: none;
      background-color: transparent;
      color: white;
      // margin-top: 5px;

      input.ant-input {
        background-color: transparent;
        color: white;
      }
    }
  }

  p {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #d8d9ff;
    margin: 0;
    margin-top: 5px;
  }

  .nft-collection-api-key {
    width: 5rem;
  }

  .nft-collection-contract-address {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 14.5rem;
  }

  .nft-minting {
    width: 220px !important;
  }
}

.nft-collection-header-buttons {
  display: flex;
  justify-content:center;
   align-items:center
}

.dropdown-filter {
  width: 160px;
    margin-right: 10px;
    height: 47px;
    outline: none;
    border: 1.65582px solid #464646;
    border-radius: 4px;
    color: #f8f2ff;
    background: #2e2e2e;
    padding: 10px 10px 10px 16px;
    cursor: pointer;
    font-family: 'Poppins' ;
  
}

.nft-card {
  height: 435px;
}

.nft-card-preview {
  height: 360px;
}

.nfts {
  margin-top: 30px;
  display: grid;
}

.nfts>* {
  grid-area: 1;
}

.nft-details {
  display: flex;
  align-content: space-between;
  flex-direction: row;
  justify-content: space-between;
  max-height: 8rem;

  h6 {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #f1e7fc;
  }

  .flex-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    overflow: auto;
    overflow-wrap: anywhere;
  }

  .flex-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 45%;
  }
}

.nft-mint-section {
  display: flex;

  .nft-mint-section-1 {
    width: 60%;
    margin-top: 30px;

    .nft-mint-form-submit {
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #090a2c;
      width: 150px;
      height: 47px;
      box-sizing: border-box;
      .button-background();

      &:focus {
        outline: none;
      }
    }

    .input-container {
      color: @color-d5;
      font-size: 16px;
      width: 100%;
      max-width: 750px;
      border: 1px;
      border-style: solid;
      border-color: #464646;
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
      border-radius: 12px;
      padding: 10px;

      &::placeholder {
        color: rgba(183, 183, 183, 0.65);
      }
    }

    label {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2ecfb;
    }

    .ant-upload.ant-upload-drag {
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
      border: 1.65582px dashed #464646;
      border-radius: 8px;
      max-width: 750px;
    }

    .ant-upload-drag-container {
      display: flex !important;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
    }

    .nft-upload-header {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }

    .nft-upload-browse {
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 123px;
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      background-color: transparent;
      color: white;
      .gradient-border();

      &:focus {
        outline: none;
      }
    }

    .nft-upload-limmiter {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      color: rgba(255, 255, 255, 0.2);
    }

    .mint-nft-select {
      width: 100%;
      max-width: 750px;
    }

    .ant-input-affix-wrapper {
      input {
        color: @color-d5;
        font-size: 16px;
        width: 100%;
        max-width: 750px;
        border: 1px;
        border-style: solid;
        border-color: #464646;
        background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
        border-radius: 12px;
        padding: 10px;

        &::placeholder {
          color: rgba(183, 183, 183, 0.65);
        }
      }
    }
  }

  .nft-mint-section-2 {
    width: 40%;
    margin-top: 30px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;

    label {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2ecfb;
    }
  }
}

.instances-table {
  .ant-table-tbody>tr>td {
    padding: 16px 11px !important;
  }

  .ant-table {
    .ant-table-content {
      .ant-table-tbody {
        .ant-table-cell {
          z-index: 1;
          font-weight: 400 !important;
          font-size: 20px !important;
          line-height: 35px;
          color: white;
        }
      }
    }
  }
}

.create-collection-drop {
  width: 100% !important;
}

.popup-request-container {
  .btn-section {
    justify-content: space-around !important;
  }

  .nft-instance-create {
    .popup-text {
      padding-bottom: 0 !important;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border: 0.89743px solid #949494 !important;
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-icon {
      .gradient-border();
    }

    .ant-steps-item-icon::before {
      border-radius: 16px !important;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e !important;
    }
  }
}

.table-section-container .ant-table .ant-table-tbody>tr.ant-table-row>td>a {
  z-index: 1;
  position: relative;
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #f8f2ff;
}

.the-nft {
  .label {
    padding: 20px 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ececec;
  }

  .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(227, 227, 227, 0.5);
    padding-left: 30px;
    padding-right: 30px;
  }

  height: 86%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.contract-card-templates:hover {
  box-shadow: 0px 3.49493px 8.73734px rgb(0 0 0 / 10%);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  .gradient-border();
  background: linear-gradient(90deg, #b447eb, #2797ff) border-box
}



.save-changes-btn {
  z-index: 1;
  position: inherit;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: white;
  padding: 0.31rem 1rem;
  border: 1.02824px solid #35b08b;
  border-radius: 51.4121px;
}

.save-changes-btn-difer {
  z-index: 1;
  position: inherit;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: white;
  padding: 0.31rem 1rem;
  border: 1.02824px solid #ca5a5a;
  border-radius: 51.4121px;
}

.nft-trait-space {
  .ant-space-item:nth-child(-n + 2) {
    width: 50%;
  }
}

.nft-not-minted {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 80%;
  align-items: center;

  h6 {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #efefef;
    margin: 0;
  }
}

.info-list {
  list-style: none;
  font-size: 20px;
  color: #fff;

  li {
    padding: 8px;
  }

  span {
    color: #d8d9ff;
    font-size: 18px;
  }
}

.add-to-teams {
  .ant-select-selection-item {
    color: #fff !important;
  }
}

.ant-upload-list-item {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }

  .ant-upload-list-item-name {
    color: white;
  }
}

.table_Link_nftStudio {
  color: #e3e3e3;
}

.table_Link_nftStudio:hover {
  font-weight: bold;
  text-decoration: underline;
}

.content-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;

  .column-container {
    font-family: @font-family;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .label-text {
    letter-spacing: 0.02em;
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #f2ecfb;
  }

  button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    //               width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();

    &:focus {
      outline: none;
    }
  }

  .create-Endpoint-btn {
    background: transparent;
    box-shadow: 0px 2px 14px rgba(167, 167, 167, 0.25);
    border-radius: 6px;
    font-family: @font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    width: auto;
    height: 40px;
    .gradient-border();

    &:active {
      color: white;
      border-color: #d9d9d9;
      background: transparent;
      text-shadow: none;
      box-shadow: none;
    }

    &:hover {
      color: white;
      border-color: #d9d9d9;
      background: transparent;
      text-shadow: none;
      box-shadow: none;
    }
  }

  .deploy-nft-collection {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.contract-template-menu {
  list-style: none;
  margin-top: 10px;

  li {
    display: inline-block;
    width: 100px;
    margin-right: 10px !important;

    button {
      width: max-content !important;
    }
  }
}

.custom-contract-code {
  height: 500px !important;
  background-color: black !important;
  font-family: '', s !important;
  border-block-color: #464646 !important;
  font-size: 14px !important;
  color: white !important;
  border-color: #b447eb !important;
  border-radius: 10px !important;
}
.ant-popover-buttons{
 text-align: center !important;
}
.ant-popover .ant-popover-message-title{
 text-align: center !important;
}
.custom-copy-alignment{
display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: baseline;
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;