@import "../../styleConstant.less";

.enterprise-signup-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .enterprise-role-select {
    width: 50%;
    height: 100vh;
    background-color: #02020f;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-typography {
      color: white;
    }

    .ant-input:focus,
    .ant-input-focused {
      border-color: #8e85aa !important;
    }

    .enterprise-role-select-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      width: 75%;
    }

    .form-title h1 {
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      color: white;
    }

    .form-context span {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      color: rgba(254, 254, 254, 0.5);
    }

    .form-card {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      margin-top: 2rem;
      align-items: center;

      .enterprise-signup-card {
        background: url(../../../assets/images/enterprise-card.avif) no-repeat;
        background-size: 100% 100%;
        padding: 1.5rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
      }

      .enterprise-signup-card h4 {
        font-family: 'Poppins' ;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #b7b7b7;
        padding-top: 1.5rem;
      }
    }
  }

  .promo-tile-left {
    width: 50%;
    background-color: @side-nav-common-color;
    height: 100vh;
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: @side-nav-common-color;
      z-index: 0;
      opacity: 0.65;
    }
    .promo-tile-left-inner {
      z-index: 1;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      padding: 4em;
      height: 100%;
      position: relative;

      .content {
        position: absolute;
        width: 506px;
        height: 150px;
        left: 106px;
        top: 279px;
        font-family: "'Poppins'";
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 70px;
        text-align: center;
        color: #d8d8d8;
      }

      .promo-content {
        color: white;
        width:max-content;
      }

      .banner-highlight {
        background: linear-gradient(90deg, #b447eb 0%, #2797ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .social-link {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        width: 21%;
      }

      .social-link svg {
        width: 20px;
      }
    }
    .login-shape-bg1 {
      position: absolute;
      width: 280px;
      height: 220px;
      right: -120px;
      background: linear-gradient(90deg, #351346 96.28%, #2797ff 126.84%);
      opacity: 0.5;
      transform: rotate(105.2deg);
      top: -120px;
    }
    .login-shape-bg2 {
      position: absolute;
      width: 280px;
      height: 220px;
      left: -200px;
      bottom: 100px;
      background: linear-gradient(90deg, #351346 96.28%, #2797ff 126.84%);
      opacity: 0.5;
      transform: rotate(-61.66deg);
    }
  }
}

.enterprise-role-select {
  .ant-typography {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #d9d9d9;
  }

  .form-content {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #8e85aa;
  }

  .input-container {
    color: @color-d5;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-radius: 6px;
    border-color: #464646;
    background: #02020f;
    padding: 10px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }

  .form-submit-btn {
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
    border-radius: 6px;
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: white;
    padding: 12px;
    width: 100%;
    margin-top: 1rem;
  }

  .form-login-p {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: rgba(254, 254, 254, 0.6);
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .form-login-span {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    color: white;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.enterprise-signup-icon img {
  width: 50px;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;