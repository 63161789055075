@import "../../styleConstant.less";

.faucet-page-container {
 width: 100%;
 background-size: 100% 100%;
 transition: background-size 0.5s ease-in-out;
 padding-top: 100px;
 background: url('../../assets/icons/Frame238.svg');
  background-color: rgba(0, 0, 0, 0);
  background-size: 100% auto;
  background-position-x: 364px;
  background-position-y: -40%;
  background-repeat: no-repeat !important;
 }
 .faucet-page-container1 {
  width: 100%;
  background-size: 100% 100%;
  transition: background-size 0.5s ease-in-out;
  padding-top: 10px;
  background: url('../../assets/icons/Frame238.svg');
   background-color: rgba(0, 0, 0, 0);
   background-size: 100% 100%;
   background-position-x: -505px;
    background-position-y: 27%;
   background-repeat: no-repeat !important;
  }
.faucet-heading {
  width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-top:40px;
}
.faucet-heading h2{
  font-size:40px !important;
  color: #fff;
  margin-bottom:0px;
}
.faucet-heading1 {
  width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.faucet-heading1 h2{
  font-size:40px !important;
  color: #fff;
  margin-bottom:10px;
}
.faucet-section{
  background:transparent;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(521px, 1fr));
  justify-content: center;
  padding: 3rem 12% 7rem 12%;
  grid-gap: 40px;
}
.faucet-section-inside{
  background:transparent;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  padding: 3rem 0;
  grid-gap: 40px;
}
.light-theme-faucet-image-container-main{
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: end;
  
}
.light-theme-faucet-image{

  height: auto;
  width: auto;
}
.light-theme-faucet-image1{
  height: 70px;
  width: 70px;
  border-radius: 50px;
}
.light-theme-faucet-image-container-text{
font-size: 24px;
font-weight: 400;
line-height: 22px;
text-align: left;
color: white;

}
.light-theme-faucet-image-container{
  display: flex;
  align-items: center;
  gap:20px;
  color: white;
  width: fit-content;
  width: 450px;
  background-color: #383838;
  border-radius: 26px;
  padding: 15px 20px 15px 20px;
  cursor: pointer;
}
.light-theme-faucet-image-container-2{
  display: flex;
  align-items: center;
  gap:30px;
  border: 1px solid #BEB8B8;
  width: fit-content;
  width: 400px;
  background-color: white;
  border-radius: 26px;
  padding: 10px 20px 10px 10px;
}
.light-theme-faucet-para{
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: white;
}
.light-theme-faucet-para-container{
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 100px;
  background: url('../../assets/icons/Frame238.svg');
  background-color: rgba(0, 0, 0, 0);
  background-size: 100% auto;
  background-position-x: 364px;
  background-position-y: -40%;
  background-repeat: no-repeat !important;
}
.navbar-brand img.logo {
  height: 40px; /* Adjust the height as needed */
}
.faucet-card-container {
     /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
     grid-column-gap: 1.5rem;
     grid-row-gap: 1.5rem;
     grid-template-rows: auto;
     grid-template-columns: 1fr 1fr 1fr;
     grid-auto-columns: 1fr;
     display: grid; */
}
.faucet-card {
background: #222222;
  width: 100%;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  text-align: left;
  border: 1px solid #464646;
  border-radius: 10px;
  align-items: center;
  padding: 1rem 1.25rem;
  display: flex;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  position: relative;
  min-height: 150px;
  min-width: 410px;
}
.disabled-card {
//   width: 100%;
//     grid-column-gap: 1.25rem;
//     grid-row-gap: 1.25rem;
//     text-align: left;
//     border: 1px solid rgba(255, 255, 255, 0.8);
//     border-radius: 1rem;
//     align-items: center;
//     padding: 1rem 1.25rem;
//     display: flex;
//     transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
//     position: relative;
//     filter: grayscale(100%);
//     opacity: 0.7;
  color: #000;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
}
.faucet-card-text {
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  opacity: 0.8;
  text-align: start;
  line-height: 1.5;
  min-width: 220px;
  margin:10px;
}
.faucet-card-text1 {
  font-size: 1.25rem;
  font-weight: 700;
  color: #000;
  opacity: 0.8;
  text-align: start;
  line-height: 1.5;
  min-width: 220px;
  margin:10px;
}

.faucet-card:hover {
  /* background:  #080322; */
  /* transform: scale(1.05); */
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
  cursor: pointer;
  border: 3px solid #095fdb;
  .faucet-card-text {
  color: #ffffff;
  }
//   .faucets-header_arrow-wrapper {
//     background: rgba(13, 6, 18, 0.7);
//     transform: scale(1.05);
//     cursor: pointer;
//   }
}

.faucets-header_arrow-wrapper .arrow-icon {
  color: #ffffff;
}

.faucets-header_arrow-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  transition: transform 0.3s ease, background-color 0.3s ease;
  position: relative;
}

// .faucets-header_arrow-wrapper::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(255, 255, 255, 0);
//   transition: background-color 0.3s ease;
//   z-index: -1;
// }
//
// .faucets-header_arrow-wrapper:hover::before {
//   background-color: rgba(255, 255, 255, 0.2);
// }
//
// .faucets-header_arrow-wrapper:hover {
//   transform: scale(1.05);
//   cursor: pointer;
// }
.faucet-transaction-card-container{
 display: flex;
   justify-content: center;
   margin: 50px;
}
.faucet-transaction-card {
  width: 500px;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  text-align: left;
  background: #222222;
  border-radius: 8px;
  align-items: center;
  padding: 1rem 1.25rem;
  display: flex;
  border: 1px solid #464646;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  position: relative;
  margin-bottom: 100px;
}
// .faucet-transaction-card:hover{
//   border:1px solid #7d51e1;
// }
.faucet-transaction-card-header{
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
}
.faucet-transaction-input-container{
    color: black;
    font-size: 16px;
    width: 100%;
    border: 1px solid #7d51e1;
    border-style: solid;
    border-radius: 3px;
    border-color: #fff;
    background: #fff;
    outline: none;
    padding: 10px;
    &::placeholder {
      color: black;
    }
    margin-right: 40px;

}
.faucet-instruction{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
 .faucet-transaction-capcha{
  display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      color:#fff;
  }

  .gradient-button {
  border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #090a2c;
      width: 150px;
      height: 47px;
      box-sizing: border-box;
      .button-background();
      &:focus {
        outline: none;
      }
  }
  .light-theme-faucet-header{
      font-size: 64px !important;
      font-weight: 700 !important;
      line-height: 22px !;
      text-align: left !important;
      color: white !important;
      margin-bottom: 10px;
      padding-left: 12%;
      padding-right: 12%;
  }
  .light-theme-faucet-header-gradient{
    background: linear-gradient(89.99deg, #FD0F0F , #4C59E3 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }

  .faucet-transaction-card-label{
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }
  .faucet-transaction-card-label1{
    color: gray;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 0px
  }

  .wallet-input-container {
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 100%;
  }

   .connect-wallet-gradient-button {
    border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        background: linear-gradient(135deg, #f39c12, #e91e63);
        width: 150px;
        height: 47px;
        box-sizing: border-box;
//         .button-background();
        &:focus {
          outline: none;
        }
    }

.warning-content {
  font-weight: normal;
  font-size: 12px !important;
  font-style: italic;
  color:#faad14;
}
.faucet-card-description {
   color: #fffff0 !important;
   font-size: 1rem;
   line-height: 1.5;
   font-weight: 400;
}
.faucet-card-description1 {
  color: #000 !important;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}
.hide-arrow{
display:none;
}
.faucet-popup-container {
  background: #fffff0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;

  .close-icon {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 25px;
    svg {
      cursor: pointer;
    }
  }
 .popup-title {
    color: black;
     font-size: 18px;
     font-weight: 700;
     line-height: 1.5;
     text-align: center;
     padding-bottom: 25px;
  }
  .popup-msg {
      color: black;
       font-size: 16px;
       font-weight: 600;
       line-height: 1.5;
       text-align: center;
       padding-bottom: 25px;
    }

  .btn-section {
    display: flex;
    align-items: center;
    justify-content: center;

    .ok-btn {
      width: 150px;
      height: 47px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;

      cursor: pointer;
      background: blueviolet;
       border-color: black;
      &:focus {
        outline: none;
      }
    }

    .disabled-btn {
      cursor: not-allowed;
    }

    .cancel-btn {
      width: 131px;
      height: 47px;
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
      border: 1px solid #403f3f;
      border-radius: 7.94305px;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #c4c4c4;
      cursor: pointer;
      margin-right: 25px;
    }
  }
  }
  .faucet-custom-modal {
    background-color: transparent;
  }
.faucet-custom-modal .ant-modal-body {
 background: #fffff0;
}

.subheader1{
  font-size: 16px !important;
    margin-top: -10px !important;
    color: white !important;
    opacity: 0.8 !important;
    margin-bottom: 40px !important;
    line-height: 24px !important;
    text-align: center !important;
}

@media (max-width: 768px) {

  .light-theme-faucet-image{
    height: 300px;
    width: 270px;
  }
  .light-theme-faucet-image1{
    height: 60px;
    width: 60px;
  }
  .light-theme-faucet-image-container-text{
  font-size: 16px;
  
  }
  .light-theme-faucet-image-container-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}
 
  .light-theme-faucet-image-container{
    display: flex;
    align-items: center;
    gap:30px;
    width: fit-content;
    width: 350px;
    color: white;
    background-color: #383838;
    border-radius: 26px;
    padding: 10px 20px 10px 10px;
  }
  .light-theme-faucet-image-container-2{
    display: flex;
    align-items: center;
    gap:30px;
    border: 1px solid #BEB8B8;
    width: fit-content;
    width: 350px;
    margin-right: 0px;
    background-color: white;
    border-radius: 26px;
    padding: 10px 20px 10px 10px;
  }

  .light-theme-faucet-header{
    font-size: 32px !important;
    line-height: 40px !important;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}
.light-theme-faucet-para {
  font-size: 14px;

 
}
  .faucet-section{
    grid-template-columns: repeat(auto-fill,minmax(410px, 1fr));
    padding: 20px;

  }
  .light-theme-faucet-para-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 100px;
}
  .faucet-transaction-card {
    width: 350px;
    padding: 5px;
  }
  .faucet-transaction-input-container{
    width: 100%;
    margin-right: 4px;
}
.faucet-transaction-capcha{
  width: 90%;
}
.faucet-heading h2{
font-size:30px !important;
}
  }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;