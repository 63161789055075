.collapsible {
   padding:10px;
   font-style: normal;
          font-weight: 700;
          font-size: 18px !important;
//           color: #ececec;
          text-align:start;
   cursor: pointer;
 }
 .expandcontent {
   max-height: 0;
   overflow: hidden;
   transition: max-height 0.5s ease-in-out;
   color: #ececec;
 }

 .expandcontent p{
 font-style: normal;
           font-weight: 500;
           font-size: 16px !important;
           color: #ececec;
           text-align:start;

 }
 .text-underline {
  text-decoration: underline;
     text-decoration-color: #AA0AFC;
     color:#AA0AFC !important;
     display: inline-flex;
         flex-direction: row;
         flex-wrap: wrap;
         align-content: stretch;
         justify-content: center;
         align-items: center;
         gap: 1rem;
 }
 .text-underline-none {
    text-decoration: none;
  }

 .disable-drag {
   user-drag: none; /* For newer browsers */
   -webkit-user-drag: none; /* For Safari */
   -webkit-touch-callout: none; /* Disable callout on touch devices */
 }
 .expanded .expandcontent {
   max-height: 1000px; /* Adjust this value as needed */
 }

 hr {
   border-color: #333399; /* HR color */
 }
@media (max-width: 767px) {
.text-underline {
         gap: 10px;
 }
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;