@import "../../styleConstant.less";

.text {
  font-family: 'Poppins';
  font-size: 27px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #efefef;
  margin: 0;
}
.tab-container{
   .ant-tabs-nav::before {
       border-bottom: none !important;
     }

     .ant-tabs-tab {
      font-family: 'Poppins';
       font-weight: 600;
       font-size: 22px;
       line-height: 27px;
       color: rgba(239, 239, 239, 0.4);
       background: transparent;
     }

     .ant-tabs-tab.ant-tabs-tab-active {
       border-bottom: 2px solid @success-color !important;
       z-index: 1;
     }

     .ant-tabs-tab-btn:focus,
     .ant-tabs-tab-remove:focus,
     .ant-tabs-tab-remove:active {
       color: rgba(239, 239, 239, 0.4);
     }

     .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
     .ant-tabs-tab-btn:active {
       color: #efefef;
     }
}
.transaction-page-container {
  display: flex;
  gap: 20px;
}

.transaction-container {
  color: white;
  width: fit-content;
  max-width: 850px;
  //       height: calc(100vh - (10% + 10%));

  .input-container {
    font-family: 'Poppins';
    color: #ffffff;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-color: #464646;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border-radius: 12px;
    padding: 10px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }

  .dropdown-filter {
    width: 160px;
    margin-right: 10px;
    height: 47px;
    outline: none;
    border: 1.65582px solid #464646;
    border-radius: 8px;
    color: #f8f2ff;
    background: #2e2e2e;
    padding: 5px;
    cursor:pointer;
  }

  .create-transaction-drop {
    width: 100%;
    max-width: 750px;
  }

  .ant-upload.ant-upload-drag {
    padding: 0px 10px 0px 10px;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border: 1.65582px dashed #464646;
    border-radius: 8px;
    max-width: 750px;
  }

  .ant-upload-drag-container {
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }

  .transaction-upload-header {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
  .mint-nft-select {
    width: 100%;
    max-width: 750px;
  }
  .filter-option-list {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-direction: row;
    background: #1e1e1e;
    box-shadow: 0px 0px 6px rgba(32, 205, 137, 0.25);
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;

    .option-header {
      color: #cfcfcf;
      font-size: 16px;
      padding-right: 24px;
    }

    .ant-radio-wrapper {
      padding-bottom: 10px;
      color: #cfcfcf;

      .ant-radio-inner::after {
        background-color: @success-color !important;
      }
    }
  }
  .form-submit {
    font-family: 'Poppins';
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();
    &:focus {
      outline: none;
    }
  }
  button {
    font-family: 'Poppins';
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();
    &:focus {
      outline: none;
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .marginBtm50 {
    margin-bottom: 0px;
  }
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;