/* Chat.css */

.history-tab-container {
    background-color: #333;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      box-shadow: 0 2px 5px #0000001a;
      color: #fff;
      height: 100%;
      padding: 20px;
      position: fixed;
      right: 0;
      top: 0;
      transition: right .5s ease;
      width: 60%;
      z-index: 9999999999;

  }

  .history-tab-container.open {
    right: calc(20% - 100%); /* Show the history tab container when open */
  }
  .history-tab-container.close {
      right: calc(100% - 20%); /* Hide the history tab container when close */
    }
  .history-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #fff; /* Optional: Add a border between history items */
    cursor:pointer;
  }
.enableBlur>* {
  filter: blur(3px);
}

.disableBlur {
  filter: blur(0);
}
  .history-content {
    flex-grow: 1;
  }
  .history-bullet {
    margin-right: 10px;
    font-size: 18px;
    margin-bottom:7px;
  }

.history-item:hover {
  background-color: #555; /* Add background color on hover */
}
  .history-delete {
    cursor: pointer;
    color: #fff;
    padding: 5px;
  }

.chat-container {
      display: flex;
      flex-direction: column;
      height: 97% !important;
//       border: 1px solid #ccc;
      padding: 20px;

    }

    .chat-messages {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }

.right-msg {
  flex-direction: row-reverse;
}

.message {
   /*  background-color: #f1f0f0; */
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.right-msg .msg-bubble {
  background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
  color: #fff;
  border-bottom-right-radius: 0;
}
.left-msg .msg-bubble {
  background: linear-gradient(90deg, #2797ff -4.52%, #2797ff 120.27%);
  color: #fff;
  border-bottom-right-radius: 0;
}
.msg-bubble {
  max-width: 95%;
  padding: 15px;
  border-radius: 15px;
    border-bottom-right-radius: 15px;

}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}
.right-msg .msg-bubble {
  color: #fff;
}

.message-form {
  display: flex;
  margin-top: 10px;
}

.message-form input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: transparent;
  color: white;
  outline: none;
}


.back-arrow {
    cursor: pointer;
    display: flex;
    align-items: end;
    padding: 8px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-end;
    justify-content: flex-end;
}

 .chat-card-container {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    .card-section-container {
      min-width: calc(100% / 3);
    }
  }

  .chat-container-text {
    align-items: center;
    text-align: center;
    display:flex;
    justify-content:center;
    .text {
      font-weight: 600;
      font-size: 30px;
      color: #d9d9d9;
      padding-right: 10px;
    }

    .icon-container{
      display:flex;
      margin-left: auto;
      justify-content:space-around;
      gap:1rem;
    }
    /* Base styles */
    .main-icon {
      cursor: pointer;
      background-color: #76827a;
      color: #fff;
      padding: 10px;
      border-radius: 50px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      position: relative;
    }

    .collapsible-content {
      position: fixed;
       top: calc(10% + 10px);
      transform: translateX(-50%);
      right:0;
      background-color: #f1f1f1;
      padding: 10px;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease;
      z-index: 999; /* Ensure the sub-divs appear above other content */
    }

    .collapsed {
      opacity: 1;
      visibility: visible;
      cursor: pointer;
    }

    /* Your styles for the sub-divs */
    .sub-div {
      display: block;
      margin-bottom: 10px;
      padding: 5px;
      background-color: #e0e0e0;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

  }



/* .chat-div{
        display: block;
        justify-content: space-between;
        margin: auto;
}
.chat-div h2 {
            margin-top: 0;
            padding-bottom: 10px;
            color: #fff;
    } */
.chat-content {
        display: block;
        justify-content: space-between;
        margin: auto;
    }
    .chat-content h5 {
            margin-top: 0;
            padding-bottom: 10px;
            color: #fff;
            text-align: center;
    }
.chat-box {
        flex: 1;
        background-color: #1f262c;
        padding: 30px;
        border-radius: 6px;
        color: #fff;
        min-height: 151px;
    }
.chat-box h2 {
        margin-top: 0;
        padding-bottom: 10px;
        border-bottom: 2px solid #e30046;
        text-transform: uppercase;
    }
.chat-box p {
        font-weight: 400;
          margin-top: 0;
          text-align: center;
          font-size: 14px;
          line-height: 24px;
    }
.chat-box p span {
        font-size: 33px;
    }

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;