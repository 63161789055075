@import "../../styleConstant.less";


.plan_text {
     font-family: 'Poppins' ;
    font-size: 13px;
    font-variant: tabular-nums;
    line-height: 2;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
}
.view_text {
     font-family: 'Poppins' ;
    font-size: 15px;
    font-variant: tabular-nums;
    line-height: 2;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
}
.curent_text{
    font-family: 'Poppins' ;
    font-size: 17px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    font-weight: 550;
}


.account-setting-enterprise{
  display:flex;
  justify-content: space-between;
  .text1 {
       font-family: 'Poppins' ;
       font-style: normal;
       font-weight:600 ;
       font-size: 22px;
       line-height: 40px;
       color: #efefef;
       margin: 0;
     }
  }
  
  .my-account-page-container{
    margin-top: 50px;
  .text {
     font-family: 'Poppins' ;
     font-style: normal;
     font-weight:550 ;
     font-size: 20px;
     line-height: 40px;
     color: #efefef;
     margin: 0;
   }
}

.gradient-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();
    &:focus {
      outline: none;
    }
  }
.disabled-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    cursor: not-allowed;
    color: #cbcbd6;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    background:transparent;
    border: 0.5px solid #605353;
  }
.manage_icon_alignment{
 display:flex;
 gap: 0.3rem;
  cursor:pointer;
}



.error_text{
   color: #dc1110;
   font-size: 14px;

}
.error_text_billingDetails{
color: #dc1110;
font-size: 14px;
margin-bottom: -23px
}

.App {
  width: 100%;
  display: flex;
  justify-content: start;
}
.planTable{
 width: 100%;
 display: flex;
 justify-content: start;
 }

table {
  border: 2px solid #9359ef !important;
  width: inherit;
  height: 200px;
  color: white;
  text-align:center;
}
.plan_table {
  border: 2px solid #9359ef !important;
  width: 800px;
  height: 200px;
  color: white;
  text-align:center;

}

th {
  text-align: center;
//   border-bottom: 1px solid #9359ef !important;
  font-family: 'Poppins' ;
    font-style: normal;
    font-weight:100 ;
    font-size: 16px;
    line-height: 40px;
    color: #efefef;
    margin: 0;
}
.plan_th {
  text-align: center;
  border-bottom: 1px solid #9359ef !important;
  font-family: 'Poppins' ;
    font-style: normal;
    font-weight:100 ;
    font-size: 14px;
    line-height: 20px;
    color: #efefef;
    margin: 0;
    padding: 10px;
            text-align: start;
            width: 20%;

}
.plan_th_active {
  text-align: center;
  border-bottom: 1px solid #9359ef !important;
  font-family: 'Poppins' ;
    font-style: normal;
    font-weight:100 ;
    font-size: 14px;
    line-height: 20px;
    color:#20CD89;
    margin: 0;
    padding: 10px;
            text-align: start;
            width: 20%;


}

td {
  text-align: center;
  font-family: 'Poppins' ;
    font-style: normal;
    font-weight:100 ;
    font-size: 16px;
    line-height: 40px;
    color: #efefef;
    margin: 0;
}
.plan_td {
  text-align: center;
  font-family: 'Poppins' ;
    font-style: normal;
    font-weight:100 ;
    font-size: 14px;
    line-height: 20px;
    color: #efefef;
    margin: 0;
    padding: 10px;
    text-align: start;
    width: 20%;
}
.plan_td_active {
  text-align: center;
  font-family: 'Poppins' ;
    font-style: normal;
    font-weight:100 ;
    font-size: 14px;
    line-height: 20px;
    color: #20CD89;
    margin: 0;
    padding: 10px;
        text-align: start;
        width: 20%;
}

.column-add-project-container {
  font-family: @font-family;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.column-mt-4{
display: flex;
gap:1rem;
}
.error-span{
display:grid;
width:100%;
}
.error_none{
margin-top:1rem;
}
 .ant-modal-body .input-container {
  max-width: 950px;
  }
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #fff !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #171616;
    border-color: #143aa3;
}


.btn-section{
    display: flex;
    gap:1rem;
.back-btn {
    width: 131px;
    height: 47px;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border: 1px solid #403f3f;
    border-radius: 6.94305px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #c4c4c4;
    cursor: pointer;
    margin-right: 25px;
}
}

.btn-section1{
    display: flex;
    gap:1rem;
    justify-content:center;

    .gradient-button {
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #090a2c;
        width: 150px;
        height: 47px;
        box-sizing: border-box;
        margin-left: 5px;
        color: white;
        background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
        border-radius: 6px;
        border: 0.5px solid black;
    }
    }

.profile-section-container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 20px;

    .header-text {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #e2e2e2;
    }

    .profile-section {
      display: flex;
      flex-direction: column;
      /* width: 650px; */
      background: #151617;
      border: 1px solid #464646;
      box-shadow: 0px 3.49493px 8.73734px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
      .row-data {
        display: flex;
        align-items: center;
        margin-bottom: 35px;
        margin-top: 20px;

        .user-icon-container {
          .ant-avatar.ant-avatar-icon {
            font-size: 50px;
            width: 70px;
            height: 70px;
            line-height: 48px;
          }
          .ant-avatar-lg {
          width:100px;
          height:100px;
          }
        }
        .anticon.anticon-edit {
          font-size: 30px;
          svg {
            width: 25px;
            height: 25px;
            fill: #e2e2e2;
          }
        }
      }
      .name-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        /* width: 650px; */
      }

      .fieldset-container {

      }

      .btn-container {
        display: flex;
        align-items: center;
        .save-changes-btn {
          font-weight: 600;
          font-size: 14px;
          width: 200px;
          box-sizing: border-box;
          padding: 10px;
          border-radius: 24px;
          color: white;
          background: transparent;
          border-image-source: linear-gradient(
            90deg,
            #b447eb -4.52%,
            #2797ff 120.27%
          );
          border-image-slice: 7;

          &:focus {
            outline: none;
            outline: none;
          }
        }

        .cancel-changes-btn {
          font-weight: 600;
          font-size: 14px;
          width: 200px;
          box-sizing: border-box;
          padding: 10px;
          background: transparent;
          border-radius: 3.49493px;
          color: #c4c4c4;
          border: @border;
          margin-left: 20px;
        }
      }

       @media(min-width: 800px) {
        .profile-section  .name-section {
                display: grid;

              }
       }
    }

    .wallet-section {
      padding-left: 20px;
      /* width: calc(100% - 650px); */

      .link-wallet-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 7px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        margin-top: 20px;
        border-radius: 8px;
        border: 1px solid #464646;

        .link-wallet-section {
          display: flex;
          flex-direction: column;
          .link-wallet {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: rgba(215, 215, 215, 0.5);
          }

          .wallet-info {
            .wallet-name {
              display: flex;
              align-items: center;
              color: white;
              font-size: 15px;
              .wallet-name {
                padding-left: 10px;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                color: #b9b9b9;
              }
            }
            .wallet-key {
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              color: #b9b9b9;
            }

            .copy-text-section {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: 0.5rem;
              cursor: pointer;
              .copy-text {
                padding-left: 10px;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #7b7c88;
              }
            }
          }
        }

        .link-wallet-btn {
          .link-btn,
          .unlink-btn {
            .button-background();
            border-radius: 6px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            width: 100px;
            height: 35px;
            text-align: center;
            padding: 10px;
          }

          .link-btn {
            background-color: @success-color;

          }

          .unlink-btn {
            color: #c7c7c7;
            background-color: transparent;
            border: @border;
          }
        }
      }
    }
  }
  .reward-program-container {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;  
    justify-content: start;
    align-items: start;

    .reward-program {
      width: calc(20% - 10px);
      margin-bottom: 20px;
      box-sizing: border-box;
    }
  }

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;