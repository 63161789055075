@import "../../styleConstant.less";

.full-page-layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  font-family: @font-family;

  .slider-layout {
    width: 352px;
    background: @side-nav-common-color;
    height: 100vh;
    padding: 1rem 2rem;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 352px);
    height: 100vh;
    background: @main-bg-color;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 812px 559px;

    .header-container {
      height: 60px;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      padding: 2rem;
      box-sizing: border-box;

      .menu-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: @color-white;
        padding-right: 4.5rem;
        letter-spacing: @letter-space;
      }

      .contact-us-btn {
        border: 1px solid #676767;
        border-radius: 5.72709px;
        outline: none;
        font-weight: 600;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        padding: 5px 20px;
        background-color: transparent;
        align-items: center;
        margin-right: 25px;
        filter: drop-shadow(0px 2.14766px 2.86354px rgba(0, 0, 0, 0.23));
        width: 129px;
        height: 43px;
      }
    }

    .content-container {
      height: calc(100vh - 110px);
      padding: 2rem;
    }

    .footer-container {
      height: 50px;
      padding: 0px 20px 25px 20px;
      box-sizing: border-box;
      font-family: @font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: @letter-space;

      color: #b1b1b1;
    }
  }
}
 .service-text {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
  .disabled-service-text {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: gray;
    }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;