@import "../../styleConstant.less";

.project-page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .project-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-text {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #e0e0e0;
    }
  }

  .data-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }
}

.card-box-container {
  position: relative;
  background: #151617;
  border: 1px solid #464646;
  box-shadow: 0px 3.49493px 8.73734px rgb(0 0 0 / 10%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  width: 410.5px;
  height: 359.88px;
  justify-content: space-evenly;
  align-content: center;

  .lock-unlock {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
  }

  .name-container {
    display: flex;
    padding-bottom: 15px;

    .icon-container {
      width: 55.92px;
      height: 59.78px;
      background: #303030;
      border-radius: 4px;
      font-weight: 500;
      font-size: 23px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #cdcdcd;
    }

    .name-section {
        width: 100%;
      display: inline-flex;
      flex-direction: column;
      color: #cdcdcd;
      /* padding-left: 20px; */

      .project-name {
      font-family: 'Poppins' ;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        /* line-height: 20px; */
        line-height: inherit;
        padding-bottom: 5px;
        text-align: left;
      }
      .project-id {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              text-align: left;
              line-height: inherit;
              padding-bottom: 5px;
              /* margin-top:-5px; */
              opacity: 0.7;

              .anticon{margin-top: 3px;}
            }

      .date-section {
        font-weight: 600;
        font-size: 10.4848px;
        line-height: 13px;
        color:#ffc107;
      }
    }
  }

  .description-section {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-height: 230px;
    overflow-y:auto;
    word-wrap: break-word;
    color: #9d9d9d;
      overflow-y: hidden;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* padding: 1rem 0rem; */
    margin-bottom: auto;
  }
  .description-section:hover {
    overflow-y: auto; /* Show the scrollbar on hover */
  }
  .description-section::-webkit-scrollbar {
    width: 5px;
    display:flex;
  }
  .description-section::-webkit-scrollbar-thumb {
    background: #9d9d9d;
    border-radius: 10px;
  }

  /* Scrollbar track style */
  .description-section::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  .apiKey-section {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 0.873733px solid rgba(255, 255, 255, 0.26);
    border-radius: 3.49493px;
    margin-bottom: 15px;

    .ant-input-affix-wrapper {
      background-color: transparent;
      padding: 0px;
      padding-right: 10px;
      box-sizing: border-box;
      border: none;
      cursor: default;
    }

    .ant-input {
      color: #adadad;
      background-color: transparent;
    }

    .anticon {
      color: #cdcdcd;
      &:hover {
        color: #cdcdcd;
      }
    }
    svg {
      cursor: pointer;
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .export-config {
      border-radius: 3.49493px;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      background: transparent;
      border: 1px solid #464646;
      border-radius: 4px;
      margin-right: 20px;
      width: calc((100% - 10px) / 2);
      font-family: 'Poppins' ;
      font-style: normal;
      color: #f8f8f8;
      outline: none;
        .gradient-border();
      /* height: 39px; */
    }
    
    .view-details {
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      color: white;
      width: calc((100% - 10px) / 2);
      background-color: black;
      .gradient-border();
      outline: none;
      /* height: 39px; */
    }
  }
}
.card-box-container-dapp {
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  box-sizing: border-box;
  width: 50%;
  height: 260px;
  justify-content: space-evenly;
  align-content: center;

  .lock-unlock {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
  }

  .name-container {
    display: flex;
    padding-bottom: 15px;
    gap: 15px;

    .icon-container {
      /* width: 55.92px;
      height: 59.78px; */
      background: #303030;
      border-radius: 4px;
      font-weight: 500;
      font-size: 23px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #cdcdcd;
      width: 160px;
        height: 100px;
        font-size: 53px;
        text-align: center;
        p{
            opacity: 0.5;
              font-size: 50px;
              text-align: center;
              margin: 0;
        }
    }

    .name-section {
        width: 100%;
      display: inline-flex;
      flex-direction: column;
      color: #cdcdcd;
      /* padding-left: 20px; */

      .project-name {
      font-family: 'Poppins' ;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: inherit;
        padding-bottom: 5px;
        text-align: left;
      }
      .project-id {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              text-align: left;
              line-height: inherit;
              padding-bottom: 5px;
              margin-top: 6px;
              opacity: 0.7;
              .anticon{margin-top: 3px;}
            }
            .project-desc{
                font-size: 16px;
                line-height: 24px;
                min-height: 48px;
                word-wrap: break-word;
                opacity: 0.8;
            }

      .date-section {
        font-weight: 500;
          font-size: 14px;
          color: #8acaff;
          padding-top: 12px !important;
      }
    }
  }

  .description-section {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-height: 230px;
    overflow-y:auto;
    word-wrap: break-word;
    color: #9d9d9d;
      overflow-y: hidden;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* padding: 1rem 0rem; */
    margin-bottom: auto;
  }
  .description-section:hover {
    overflow-y: auto; /* Show the scrollbar on hover */
  }
  .description-section::-webkit-scrollbar {
    width: 5px;
    display:flex;
  }
  .description-section::-webkit-scrollbar-thumb {
    background: #9d9d9d;
    border-radius: 10px;
  }

  /* Scrollbar track style */
  .description-section::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  .apiKey-section {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 0.873733px solid rgba(255, 255, 255, 0.26);
    border-radius: 3.49493px;
    margin-bottom: 15px;

    .ant-input-affix-wrapper {
      background-color: transparent;
      padding: 0px;
      padding-right: 10px;
      box-sizing: border-box;
      border: none;
      cursor: default;
    }

    .ant-input {
      color: #adadad;
      background-color: transparent;
    }

    .anticon {
      color: #cdcdcd;
      &:hover {
        color: #cdcdcd;
      }
    }
    svg {
      cursor: pointer;
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .export-config {
      border-radius: 3.49493px;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      background: transparent;
      border: 1px solid #464646;
      border-radius: 4px;
      margin-right: 20px;
      width: calc((100% - 10px) / 2);
      font-family: 'Poppins' ;
      font-style: normal;
      color: #f8f8f8;
      outline: none;
        .gradient-border();
      /* height: 39px; */
    }

    .view-details {
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      color: white;
      width: calc((100% - 10px) / 2);
      background-color: black;
      .gradient-border();
      outline: none;
      /* height: 39px; */
    }
  }
}
.search-container {
    color: #ffffff;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-color: #464646;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border-radius: 4px;
    padding: 10px;
    outline: none;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
    }
.gradient-button {
border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();
    &:focus {
      outline: none;
    }
}
.width-95{
    width: 95% !important;
}
.font-24{
    font-size: 24px !important;
}

 .search-box {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: -7px 10px;

    .anticon {
      position: absolute;
      left: 129px;
      color: #d9d9d9;
      font-size: 16px;
      cursor:pointer;
    }


    .input-box {
      width: 130px;
      height: 30px;
      font-size:12px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 4px;
      background-color: transparent;
      color: #fff;
      padding-left: 35px;
    }
  }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;