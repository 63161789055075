@import "../../styleConstant.less";
@import "/node_modules/highlight.js/styles/atom-one-dark.css";

.text {
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #efefef;
  margin: 0;
}

.nft-collection-cards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 3rem;
}

.studio-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .refresh-collections {
    width: 131px;
    height: 47px;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border: 1px solid #403f3f;
    border-radius: 7.94305px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #c4c4c4;
    cursor: pointer;
    margin-right: 25px;
  }

  .gradient-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 200px;
    height: 47px;
    box-sizing: border-box;
    .button-background();
    &:focus {
      outline: none;
    }
  }

  .gradient-button2 {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 100px;
    height: 47px;
    box-sizing: border-box;
    margin-left: 5px;
    .button-background();
    &:focus {
      outline: none;
    }
  }
}

.contract-templates-card-collection {
  background: #151617;
  box-shadow: 0px 3.49493px 8.73734px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 298px;
  height: 440px;
  border: none;
  position: relative;
  align-content: center;
  .gradient-border();


 

  img {
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    width: 100%;
  }

  .nft-collection-image {
    border-radius: 6px;
    object-fit: fill;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  h5 {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #f8f2ff;
  }

  p {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #f1e7fc;
    overflow: auto;
    position: relative;
    z-index: 1;
    max-height: 4rem;
  }

  .nft-card-lower {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }

  .contract-templates-card-detail-section {
    padding: 20px;
    color: #fff;

    .sc-title {
      font-size: large;
    }

    .contract-templates-info {
      .contract-templates-table {
        border: 0px !important;
        width: 100%;
        overflow: hidden;
        tr td,
        tr th{
            border-bottom: none !important;
            padding: 0px 0px;
            overflow: hidden;
        }

        tr th {
            color: #949494;
        }

        tr td  {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
     .contract-templates-info .make-it-center{
     margin-top:96px;
     }


    .btn-container {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .save-changes-btn {
        font-weight: 600;F
        font-size: 14px;
        width: 100%;
        box-sizing: border-box;
        padding: 5px;
        border-radius: 24px;
        color: white;
        background: transparent;
        border-image-source: linear-gradient(
          90deg,
          #b447eb -4.52%,
          #2797ff 120.27%
        );
        border-image-slice: 7;

        &:focus {
          outline: none;
          outline: none;
        }
        .save-changes-btn:hover {
          background-image: linear-gradient(to right, #b447eb , #2797ff)!important;
          border-radius: 0px!important;
        }

        
      }
    }
  }

  .ntf-card-details {
    p {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #4a545f;
    }

    h6 {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #f8f2ff;
    }

    .collection-chain-details {
      display: flex;
      align-items: center;
      justify-content: center;

      h6 {
        margin: 0;
        margin-left: 5px;
      }
    }
  }
}

.ant-modal-body {
  background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
  border-radius: 12px;

  .nft-collection-popup {
    color: white;

    .ant-upload.ant-upload-drag {
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
      border: 1.65582px dashed #464646;
      border-radius: 8px;
      max-width: 750px;
    }

    .ant-upload-drag-container {
      display: flex !important;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
    }

    .nft-upload-header {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }

    .nft-upload-browse {
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 123px;
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      background-color: transparent;
      color: white;
      .gradient-border();

      &:focus {
        outline: none;
      }
    }

    .nft-upload-limmiter {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      color: rgba(255, 255, 255, 0.2);
    }
  }

  .close-icon {
    display: flex;
    justify-content: flex-end;
  }

  .popup-text {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    color: #ede6f5;
    align-items: center;
    justify-content: center;
  }

  .input-container {
    color: @color-d5;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-color: #464646;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border-radius: 12px;
    padding: 10px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }

  button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();
    &:focus {
      outline: none;
    }
  }

  .deploy-nft-collection {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.nft-collection {
  .nft-collection-api {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }
  p {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #d8d9ff;
    margin: 0;
    margin-top: 5px;
  }

  .nft-minting {
    width: 220px !important;
  }
}

.dropdown-filter {
  width: 160px;
  margin-right: 10px;
  height: 47px;
  outline: none;
  border: 1.65582px solid #464646;
  border-radius: 8px;
  color: #f8f2ff;
  background: #2e2e2e;
  padding: 5px;
  cursor:pointer;
}

.nft-card {
  height: 435px;
}

.nft-card-preview {
  height: 360px;
}

.nfts {
  margin-top: 30px;
  display: grid;
}

.nfts > * {
  grid-area: 1;
}

.nft-details {
  display: flex;
  align-content: space-between;
  flex-direction: row;
  justify-content: space-between;
  max-height: 8rem;

  h6 {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #f1e7fc;
  }

  .flex-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    overflow: auto;
    overflow-wrap: anywhere;
  }

  .flex-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 45%;
  }
}

.nft-mint-section {
  display: flex;

  .nft-mint-section-1 {
    width: 60%;
    margin-top: 30px;

    .nft-mint-form-submit {
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #090a2c;
      width: 150px;
      height: 47px;
      box-sizing: border-box;
      .button-background();
      &:focus {
        outline: none;
      }
    }

    .input-container {
      color: @color-d5;
      font-size: 16px;
      width: 100%;
      max-width: 750px;
      border: 1px;
      border-style: solid;
      border-color: #464646;
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
      border-radius: 12px;
      padding: 10px;
      &::placeholder {
        color: rgba(183, 183, 183, 0.65);
      }
    }

    label {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2ecfb;
    }

    .ant-upload.ant-upload-drag {
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
      border: 1.65582px dashed #464646;
      border-radius: 8px;
      max-width: 750px;
    }

    .ant-upload-drag-container {
      display: flex !important;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
    }

    .nft-upload-header {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }

    .nft-upload-browse {
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 123px;
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      background-color: transparent;
      color: white;
      .gradient-border();

      &:focus {
        outline: none;
      }
    }

    .nft-upload-limmiter {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      color: rgba(255, 255, 255, 0.2);
    }

    .mint-nft-select {
      width: 100%;
      max-width: 750px;
    }

    .ant-input-affix-wrapper {
      input {
        color: @color-d5;
        font-size: 16px;
        width: 100%;
        max-width: 750px;
        border: 1px;
        border-style: solid;
        border-color: #464646;
        background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
        border-radius: 12px;
        padding: 10px;
        &::placeholder {
          color: rgba(183, 183, 183, 0.65);
        }
      }
    }
  }

  .nft-mint-section-2 {
    width: 40%;
    margin-top: 30px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;

    label {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2ecfb;
    }
  }
}

.instances-table {
  margin-top: 2rem;
}

.create-collection-drop {
  width: 100% !important;
}

.popup-request-container {
  .btn-section {
    justify-content: space-around !important;
  }

  .nft-instance-create {
    .popup-text {
      padding-bottom: 0 !important;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border: 0.89743px solid #949494 !important;
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-icon {
      .gradient-border();
    }
    .ant-steps-item-icon::before {
      border-radius: 16px !important;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e !important;
    }
  }
}

.table-section-container
  .ant-table
  .ant-table-tbody
  > tr.ant-table-row
  > td
  > a {
  z-index: 1;
  position: relative;
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #f8f2ff;
}

.the-nft {
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-changes-btn {
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: white;
  padding: 0.31rem 1rem;
  border: 1.02824px solid #35b08b;
  border-radius: 51.4121px;
}

.save-changes-btn-difer {
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: white;
  padding: 0.31rem 1rem;
  border: 1.02824px solid #ca5a5a;
  border-radius: 51.4121px;
}

.smartcontract {
  color: #fff;
  font-family: 'Poppins' ;

  .dropdown-filter {
    color: @color-d5;
    font-size: 16px;
    width: 80% !important;
    border: 1px;
    margin: 10px;
    border-style: solid;
    border-color: #464646;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border-radius: 12px;
    margin-left: 0px;
    padding: 10px;
    cursor:pointer;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #d8d9ff;
    text-align: left;
  }

  .contract.active {
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
  }

  .contract {
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: white;
    width: calc((100% - 10px) / 2);
    background-color: black;
    .gradient-border();
    outline: none;
    height: 39px;
  }

  .subtitle {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #e3e3e3;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  input {
    color: @color-d5;
    font-size: 16px;
    width: 80%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-color: #464646;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border-radius: 12px;
    padding: 10px;
    margin: 10px 10px 10px 0px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }

  .ant-checkbox-wrapper,
  .ant-radio-wrapper {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: white;
  }

  ul {
    list-style-type: none;
    padding: 0px;
  }

  .note {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 22px;
    color: #e3e3e3;
    // margin-top: 25px;
    margin-bottom: 10px;
  }
}

.cards {
  display: block;
  width: 100%;

  margin-top: 100px;
}

.card-container {
  display: grid;
  grid-gap: 30px;

  .card-section-container {
    min-width: calc(100% / 3);
  }
}

.grid-repeat-container {
  grid-template-columns: repeat(4, 1fr);
}

.ant-table-row {
  td {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.accountid {
  color: #fff;
  font-size: small;
}

.interact-tab-container {
  color: #fff;
  text-align: center;
  font-size: 12px;
  // text-transform: capitalize;
  .ant-row {
    padding-top: 30px;
  }

  .view {
    background-color: #4983f9;
    padding: 10px 10px;
    border-radius: 8px 0px 0px 8px;
  }

  .nonpayable {
    background-color: #a64eec;
    padding: 10px 10px;
    border-radius: 8px 0px 0px 8px;
  }

  .action {
    padding: 10px 10px;
    border: 1px solid #464646;
    color: #464646;
    border-radius: 0px 8px 8px 0px;
  }

  .downico {
    float: right;
  }
}

.interact-tab-container {
  width: 100%;

  .inp-section {
    width: 100%;
    margin-top: 30px;
    margin-left: 100px;
    text-align: left;

    .interact-table{
        height:0px !important;
        width: 100%;
    }

    h5 {
      color: #ffff;
    }

    .labell {
      font-size: 15px;
      padding-top: 2px;
    }
    .input-container {
      color: @color-d5;
      font-size: 16px;
      width: 90%;
      border: 1px;
      margin: 10px;
      border-style: solid;
      border-color: #464646;
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
      border-radius: 12px;
      padding: 10px;
      &::placeholder {
        color: rgba(183, 183, 183, 0.65);
      }
    }
  }

  .gradient-button {
    float: right;
    margin-top: 10px;
    border-radius: 12px !important;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    width: 200px;
    height: 47px;
    background-color: #a64eec;
    &:focus {
      outline: none;
    }
  }
}

.contract-menu {
  list-style: none;
  margin-top: 10px;
  li {
    display: inline-block;
    width: 100px;
    margin-right: 10px !important;

    button {
      width: 100% !important;
    }
  }
}

.hljs {
  border-radius: 1px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 1);
  .gradient-border();
}

.action {
  text-align: left !important;
}

.excresult {
  border-radius: 6px !important;
  padding: 0 13px !important;
  background-color: rgba(0, 0, 0, 1) !important;
  border: 1px solid #b447eb !important;
  color: white !important;
  //background: linear-gradient(90deg, #b447eb, #2797ff) border-box;
}

.addBox {
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  line-height: 50px;
  .gradient-border();
}
.addBox:hover{
  color: #b447eb;
  font-weight: bold;
  .gradient-border1();
} 

.ant-checkbox-inner {
  border: none;
  position: relative;
  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1px;
    border: 0.5px solid transparent;
    background: linear-gradient(90deg, #b447eb, #2797ff) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  background-color: #1c1b1c !important;
}

.ant-radio-inner {
  border: none;
  position: relative;
  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    border: 0.5px solid transparent;
    background: linear-gradient(90deg, #b447eb, #2797ff) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  background-color: #1c1b1c !important;
}

.contractbtn.active {
  background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
}

.contractbtn {
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #090a2c;
  width: 200px;
  height: 47px;
  box-sizing: border-box;
  .button-background();
  &:focus {
    outline: none;
  }
}

.nodata {
  color: #fff;
  text-align: center;
  padding-top: 100px;
}

.table_Link_smartContact{
  color: #e3e3e3;
}
.table_Link_smartContact:hover{
  font-weight: bold;
  text-decoration: underline;
}


.nft-collection-deploy {
  .nft-collection-api-deploy {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-left: 5px;
    }

    .ant-input-affix-wrapper {
      padding: 0;
      border: none;
      background-color: transparent;
      color: white;
      // margin-top: 5px;
      width: 15%;
      input.ant-input {
        background-color: transparent;
        color: white;
      }
    }
  }
  p {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #d8d9ff;
    margin: 0;
    margin-top: 5px;
  }

  .nft-collection-api-key {
    width: 5rem;
  }

  .nft-collection-contract-address {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 14.5rem;
  }

  .nft-minting {
    width: 220px !important;
  }
}

 .back-page-btn {
  font-family: @font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #6f7988;
      margin-right: 35px;
    }

.connectWalletFlex{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8rem;
    width: 30%;
}

.normalCheckbox{
      outline: 1px solid blueviolet !important;
      color: black !important;
      background-color: black !important;
      accent-color: black;
      height: 13px !important;
      width: auto!important;
}
.copy-alignment{
 display:flex;
 justify-content:end;
 margin-bottom:20px;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;