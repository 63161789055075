.section .alert:not(:first-child) {
  margin-top: 15px;
}
.alert.alert-warning {
  background-color: #f87d09;
  border: 1px solid #f87d09;
}
.alert {
  color: #fff;
  padding: 15px 20px;
  z-index: 999;
  /* top: 75px; */
}
.alert.alert-warning .alert-container {
  position: relative;
  margin: 0 auto;
  font-size: 16px;
  color: #fff;
}
.alert.alert-warning .alert-icon {
  float: left;
  margin-right: 15px;
  margin-top:-3px;
}
.alert.alert-warning .alert-info {
  margin: 0 10px 0 0;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
  background: transparent;
}
.alert.alert-warning .close-icon {
  float: right;
  color: #fff;
  margin-top: 0;
  margin-left: 0;
  width: 21px;
  height: 21px;
  position: relative;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;