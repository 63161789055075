@import "../../../styleConstant.less";
.contact-us-page{
 width: auto;
    overflow-x:hidden;
background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #080322;
*{
font-family: 'Poppins' ;
}
/* Base styles for the navbar */
.header-wrap {
  background: #080322;
  position: fixed;
  top: 0;
   width: 100%;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.navbar-brand img.logo {
  height: 40px; /* Adjust the height as needed */
}

.navbar-toggler {
  border: none;
  background-color: transparent;
  display:none !important; /* Hide the toggler for screens wider than 768px */
}

.nav-item .nav-link{
  cursor: pointer;
  margin-left: 15px;
  font-size:18px;
  color: #fff;
  cursor:pointer;

}
.navbar-nav .active-link {
  color: #d988ddcc; /* Set color for the active link */
  font-weight: bold; /* Example: Make the active link bold */
  font-size:18px;
    cursor:pointer;
}
.nav-item .nav-link:hover{
color:#b447eb;
}
.navbar-logo-division {
    display: flex;
    align-items: center;
  }
.menu-right .header-signupbtn {
  padding: 8px 16px;
}
.header-signupbtn {
      font-family: 'Poppins' ;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         padding: 17px 26px 16px;
         border-radius: 0.875rem;
        background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
         color: white;
//          .gradient-border();
   }
.text-white{
font-size:18px;
color:white;
cursor:pointer;
}
/* Media query for responsiveness up to 1200px */
@media (max-width: 1200px) {
  .nav-item {
    display: flex; /* Hide the navigation items */
  }

  .navbar-toggler {
    display: block !important; /* Show the toggler for screens up to 1200px */
  }
  .navbar-logo-division {
    margin-right  : 17%;
  }
  .navbar-collapse {
    display:none;
    background-color: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index:-1;
    font-size:20px;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
//     display: flex; /* Show the navbar items when toggler is clicked */
    display: block !important;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
         background:#000;
          font-size:20px;
          padding:10px;
  }

  .navbar-collapse li {
    text-align: center;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }
}
@media (max-width: 360px) {
  .nav-item {
        display: grid; /* Hide the navigation items */
  }
  .navbar-logo-division {
      margin-right  : 0% !important;
    }

  .navbar-toggler {
    display: block !important; /* Show the toggler for screens up to 360px */
  }

  .navbar-collapse {
    display:none;
    background: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    font-size:18px;

  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
//     display: flex; /* Show the navbar items when toggler is clicked */
     display: block !important;
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            background:#000;
            padding:10px;
  }

  .navbar-collapse li {
    text-align: left;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }

//   .navbar-brand img.logo {
//     height: 30px; /* Adjust the logo height for smaller screens */
//   }

  .menu-right .header-signupbtn {
    font-size: 14px; /* Adjust the font size for smaller screens */
  }
  .navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.plan-details {
margin-top: 150px;
}
.plan-heading-contact {
display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.plan-heading-contact h2{
font-size:45px !important;
}
.contact-subtext{
color: #BDBBCB;
font-family: 'Poppins' ;;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 27.644px; /* 153.577% */
display:flex;
flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.contact-highlight{
background: linear-gradient(153deg, #AA0AFC 0%, #D5D3F3 54.14%, #007AEB 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.plan-section{
background:transparent;
}
.contact-page-continer{

  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: auto;
  width:90%;

  .card-contact-left {
    width: 40%;
   background: transparent;
    border-radius: 5px;
    border:none !important;
   align-items: start;
       align-content: start;
       justify-items: start;
       display: grid;
       justify-content: start;

    .card-contact-left h3{
             font-size: 25px !important;
             color:#fff !important;
             font-weight:700px !important;
              }

    .contact-text{
    color: #FFF !important;
    font-family: Inter;
    font-size: 30px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 58.19px; /* 161.638% */

    }
    .card-contact-left p{
    color: #FBF5FF !important;
    font-family: 'Poppins' ;;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    }
     .contact-link{
       color:#e86389 !important
     }
     .planTable-card-icon-button {
       display: inline-flex;
       align-items: center;
       padding: 10px 15px;
       background:  #2f4f4f;
       color: #fff;
       border: none;
       border-radius: 5px;
       font-size: 20px;
       font-weight:600;
       cursor: pointer;
     }

     .planTable-card-icon-button img {
       margin-right: 8px;
     }

    .social-link-page {
             display: grid;
             justify-content: space-around;
             align-items: center;
             flex-direction: row;
           }


   .social-link-contact-page{
     justify-content: space-between;
             display: flex;
             flex-direction: row;
             align-content:space-between;
             align-items: center;
             gap:1rem;

  }
  .square-box {
       width: 150px;
       height: 150px;
       background-color: #311c44;
       justify-content: center;
       display: flex;
       flex-direction: row;
       flex-wrap: wrap;
       align-content: center;
       align-items: center;
   }
   }
  .card-contact-right{
      flex-basis: 50%;
     background:#100A13;
      border-radius: 20px;
      border: 1px solid #b447eb;
    }
    .card-contact-right h2{
       font-size: 35px;
       font-weight:700px;
       color:#fff;
        }
 .card-contact-right p{
       font-size: 16px;
       color:#fff;
        }
 .card-contact-right span{
        font-size: 16px;
        color:#fff;
         }
 .contact-form-input-container {
     color: #fff;
     font-size: 16px;
     width: 100%;
//      max-width: 750px;
     border: 1px solid #D0D5DD;
     border-style: solid;
//      border-color: #ccb3b3;
     background:#1A1120 ;
     border-radius: 8px;
     padding: 10px;
     &::placeholder {
       color:#bcb7b7;
     }
   }
.form-submit-btn {
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
    border-radius: 6px;
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: white;
    padding: 12px;
    width: 100%;
    margin-top: 1rem;
  }
  .capcha{
  display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: center;
      gap: 1rem;
  }

}


.captcha-button{
border-radius: 4px;
      font-weight: 600;
      font-size: 20px;
      line-height: 17px;
      text-align: center;
      color: #fff;
      width: 200px;
      height: 47px;
      border:1px solid #24C6DC;
      box-sizing: border-box;
      background:#24C6DC;
      &:focus {
        outline: none;
      }
}

.icon-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  background: linear-gradient(to right, #00f260, #0575e6);
  color: #fff;
   width: 70px;
    height: 70px;
    border-radius: 50%;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 1200px) {

}
@media (max-width: 767px) {
.plan-heading-contact {
display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.plan-heading-contact h2{
font-size:30px ;
}

.contact-page-continer{

      display: flex;
      justify-content: center;
      gap: 20px;
      margin: auto;
      width: 90%;
      flex-direction: column-reverse;
      flex-wrap: nowrap;

  .card-contact-left {
    width: auto;
   background: transparent;
    border-radius: 5px;
    border:none !important;
   align-items: center;
       align-content: center;
       justify-items: center;
       display: grid;
       justify-content: center;

    .card-contact-left h3{
             font-size: 30px !important;
             color:#fff !important;
             font-weight:700px !important;
              }

    .contact-text{
    font-size: 30px !important;
                 color:#fff !important;
                 font-weight:700px !important;

    }
     .contact-link{
       color:#e86389 !important
     }
     .planTable-card-icon-button {
       display: inline-flex;
       align-items: center;
       padding: 10px 15px;
       background:  #2f4f4f;
       color: #fff;
       border: none;
       border-radius: 5px;
       font-size: 20px;
       font-weight:600;
       cursor: pointer;
     }

     .planTable-card-icon-button img {
       margin-right: 8px;
     }

    .social-link-page {
             display: grid;
             justify-content: space-around;
             align-items: center;
             flex-direction: row;
           }


   .social-link-contact-page{
     justify-content: space-between;
             display: flex;
             flex-direction: column;
             align-content:space-between;
             align-items: center;
             gap:1rem;

  }
  .square-box {
       width: 150px;
       height: 150px;
       background-color: #311c44;
       justify-content: center;
       display: flex;
       flex-direction: row;
       flex-wrap: wrap;
       align-content: center;
       align-items: center;
   }
   }
  .card-contact-right{
     background:#100A13;
          border-radius: 20px;
          border: 1px solid #b447eb;
    }
    .card-contact-right h2{
       font-size: 30px;
       font-weight:700px;
       color:#fff;
        }
 .card-contact-right p{
       font-size: 16px;
       color:#fff;
        }
 .card-contact-right span{
        font-size: 16px;
        color:#fff;
         }
 .contact-form-input-container {
      color: #fff;
      font-size: 16px;
      width: 100%;
 //      max-width: 750px;
      border: 1px solid #D0D5DD;
      border-style: solid;
 //      border-color: #ccb3b3;
      background:#1A1120 ;
      border-radius: 8px;
      padding: 10px;
      &::placeholder {
        color:#bcb7b7;
      }
    }
.form-submit-btn {
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
    border-radius: 6px;
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: white;
    padding: 12px;
    width: 100%;
    margin-top: 1rem;
  }
  .capcha{
  display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: center;
      gap: 1rem;
  }
  input[id="cb1"] + label {
    background-color: transparent;
    background-size: contain;
    display: inline-block;
    width: 50px;
    height: 19px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin: 0;
    color:#fff;
  }

}
}
.new_footer{
background:#07030A;
padding: 20px;
height:auto;
}
.new_footer_container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}


.new_footer_card {
  padding: 20px;
  border-radius: 5px;
}
.new_footer_card h2{
  color: #E2E2E2;
  font-family: 'Poppins' ;;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.new_footer_card_logo{
width:200px;
height:100px;
}
.left-card {
  width:30%;
  max-height:300px;
  background-color: transparent;
  color: #fff;
  display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
}

.right-card {
  background-color: transparent;
  max-height:300px;
  color: #333;
 display: flex;
    flex-direction: row;
     flex-wrap: wrap;
     align-content: space-between;
     justify-content: space-around;
     align-items: flex-start;

}

.sub-grid-item-1 {
   background: transparent;
   padding: 10px;
   border-radius: 5px;
   display:grid;
   justify-items:start;
}
.sub-grid-item-1 h3{
font-size:18px;
font-weight:900;
color:#fff;
}
.divider {
 border-top: 1px solid rgba(171, 169, 169, 0.22);
  width:100%;
}

.sub-grid-item-1 ul li{
font-size:16px;
color:#fff;
list-style-type:none !important;
text-align:left;
 padding: 5px;
 margin-right:25px;
  transition: color 0.3s;
}
.sub-grid-item-1 ul li:hover {
 color:#fa4b4d;
}
.bottom-section {
     display:flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;

}
.vertical_divider {
  border-left: 1px solid #fff;
  height: auto;
  margin: 0 100px;
  width:2px;
}
.word-divider1 span {
  color:#fff;
  margin-right:10px;
}
.word-divider span {
  color:#fff;
  margin-right:20px;

}
.word-divider{
 display:flex;
 gap:1rem;
}
.social-icons-footer{
display:flex;
gap:1rem;
justify-content:center;
align-items:center;
}
@media (max-width: 767px) {

.new_footer_card_logo{
width:inherit;
}
.new_footer_container {
 display: flex;
  justify-content: space-between;
  flex-direction:column;
}
  .left-card {
    width: 100%;
  }

  .right-card {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-top: 30px;

  }

  .vertical_divider {
    display:none;
    height: auto;
    margin: auto
  }
  .new_footer_card h2{
   color: #E2E2E2;
   font-size: 14px;
   font-style: normal;
   font-weight: normal;
   line-height: 21px;
   }
  .sub-grid-item-1 {
    padding: 10px;
  }

  .sub-grid-item-1 h3 {
    font-size: 20px;
  }

  .sub-grid-item-1 ul li {
    font-size: 14px;
    margin-right: 15px;
  }
  .bottom-section {
       display:grid;
       justify-content: center;
       align-items: center;
       align-content: center;
       justify-items: center;

  }
  .word-divider{
    display: grid;
    gap: 1rem;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
  }



}


@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;