@import "../../component/styleConstant.less";

.landingPage {
  *{
font-family: 'Poppins' ;

  }
  background-color: #020315;
  text-align: center;
  .container {
    max-width:100%;
    position: relative;
  }
  .header-wrap {
    background-color: #020315;
    padding: 30px;
    position: fixed;
    z-index: 10;
    right: 0;
    left: 0;
  }
  .header-signupbtn {
    padding: 12px 59px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: white;
    line-height: 20px;
     border-radius: 0.875rem;
     position: sticky;
//           margin-right: 108px;
    background: transparent;
//     .gradient-border();
  }

  .header-wrap .navbar {
    padding: 0 !important;
  }
  .header-wrap .navbar-brand {
    margin-right: 0;
    padding: 0 !important;
  }
  img {
    object-fit: contain;
  }

  .navbar-logo-division {
    border-radius: 25px;
    padding: 7px;
  }

  .menu-right li {
    font-family: 'Poppins' ;;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  /* .menu-right .btn {
    width: 250px;
  } */
  .menu-right {
    padding-left: 15px;
  }
  .navbar-collapse {
    padding-left: 30px;
    font-size:18px;
//     font-weight: 700;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 33px;
  }
  .navbar-expand-lg .navbar-nav .nav-item:last-child .nav-link {
    padding-left: 13px !important;
  }
  .Bannersection-wrap {
    padding: 126px 0 0;
    background-position: center;
    background-size: contain;
    position: relative;
    height: 700px;
  }
  .Bannersection-wrap::before {
    content: "";
    background: #020315;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .banner-heading {
    font-family: 'Poppins' ;;
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 25px;
    color: #ececec;
    margin: 0;
    margin-bottom: 70px;
  }
  .banner-head-container{
  margin: 160px 0px 0px auto;
  }
  .banner-para {
    font-family: 'Poppins' ;;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: center;
    color: #e8fff6;
    margin-bottom: 80px;
  }
  .theme-btn {
    font-family: 'Poppins' ;;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    padding: 17px 26px 16px;
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    border-radius: 4px;
    color: white;
    .gradient-border();
    border:1.5px solid #b447eb;


  }
  .theme-btn:hover {
    color: #fff;
  }
  .pricingplan-wrap .theme-btn:hover {
    color: #fff;
    // background-color: #20cd89;
    // border-color: #20cd89;
  }
  .pricingplan-wrap .theme-btn {
    color: white;
    .gradient-border();
    &:active {
      background-color: none;
    }
  }
  .outline-btn {
    background: transparent;
    border-color: #20cd89;
    color: #20cd89;
  }

  .banner-highlight {
    /* text-transform: capitalize; */
    background: linear-gradient(90deg, #b447eb 0%, #2797ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .pricingplan-row {
    max-width: 1010px;
    margin: 0 auto;
  }
  .pricingplan-row .col-md-6:nth-child(1) {
    padding-right: 30px;
  }
  .pricingplan-row .col-md-6:nth-child(2) {
    padding-left: 30px;
  }
  .clientlogo-wrap {
    background-color: #030316;
    padding: 0 0 150px 0;
    position: relative;
//     margin-top: 150px;
}
.plandetails-sec2-image-container_1{
display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

  .clientlogo-wrap .col-md-2 {
    max-width: 20%;
    width: 20%;
    flex: 0 0 20%;
  }
  .clientlogo-item img{
  width:300px !important;
  height:150px !important;
  }
  .howitswork-wrapper {
    padding: 0px 42px 80px 42px;
    position: relative;
//         top: 0px;
//         left: 0;
        width: 100%;
        height: 100%;
        background: url(../images/project-elipsimg.avif) no-repeat;
        background-size: contain;
        background-position: top left;
  }
  .howitswork-wrapper_1 {
      padding: 0px 42px 80px 42px;
      position: relative;

    }
  .theme-heading h3 {
    font-family: 'Poppins' ;;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 0px;
    color: #fff;
    margin: 0;
    margin-bottom: 20px;
  }
  .theme-heading p {
    font-family: 'Poppins' ;;
    font-style: normal;
//     font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #8e85aa !important;
    margin-bottom: 74px;
  }
  .projects-wrapper {
    padding-top: 110px;
    position: relative;

    .protocol-item {
      width: 10rem;
    }
  }
  .projects-wrapper::before {
    content: "";
    position: absolute;
    top: -120px;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../images/project-elipsimg.avif) no-repeat;
    background-size: contain;
    background-position: top left;
  }
  .deploy{
  margin-top:80px;
  }

      .gradient-border-krypcore {
    width: 100%;
    height: 80px;
    border-radius: 5px;
    background: #b447eb;
    padding: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .content {
      width: 100%;
      height: auto;
    }
  }


@media only screen and (max-width: 600px) {
.circle {
 width: 80px;
  height: 80px;
     margin: 2%;
  }
}
@media only screen and (max-width: 400px) {
   .circle {
      width: 60px;
      height: 60px;
         margin: 2%;
}
}
}

  .create-project-content {
    border-radius: 16.2777px;
    padding: 124px 0;
    overflow: hidden;
    position: relative;
    background-color: #0c0e49;
  }
  .create-project-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1308px;
    height: 480px;
    background-size: contain;
    background-position: center;
  }
  .create-project-content::after {
    content: "";
    background: #0c0e49;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .createproject-wrapper {
    clear: both;
    position: relative;
    padding: 180px 36px 118px;
    background: url(../images/project-elipsimg.avif);
        background-position: bottom left;
  }
  .createproject-wrapper .create-project-content h3 {
    font-family: 'Poppins' ;;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 64px;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 46px;
    color: #e2e2e2;
    z-index: 1;
    position: relative;
  }
  .createproject-wrapper .create-project-content .theme-btn {
    position: relative;
    z-index: 1;
  }

  .social-link {
    padding: 0;
    margin: 0;

    list-style: none;
  }
  .social-link li {
    display: inline-block;
    padding-right: 26px;
  }

  .company-logo-area p {
    font-family: 'Poppins' ;;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    margin-bottom: 32px;
    color: #b6b6b6;
  }
  .company-logo-area img {
    display: block;
    margin: 0;
    padding-bottom: 43px;
    object-fit: contain;
  }
  .footer-link h4 {
    font-family: 'Poppins' ;;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #a9a9a9;
    margin: 0;
    margin-bottom: 21px;
  }
  .footer-link ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .footer-link ul li {
    font-family: 'Poppins' ;;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    color: #cfcfcf;
    margin: 0;
    padding: 0;
  }
  .copyright-area p {
    font-family: "'Poppins'";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 206%;
    font-family: "'Poppins'", sans-serif;
    color: #ffffff;
    margin: 0;
    position: relative;
    margin-bottom: 10px;
  }
  .copyright-area p span {
    display: inline-block;
    padding-right: 9px;
  }
  .footermain {
    background-position: bottom;
    background-size: contain;
    width: 100%;
    position: relative;
    height: 100%;
  }
  .footer-wrap {
    padding: 75px 0 116px;
  }

  .most-popular p {
    font-family: 'Poppins' ;;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 46px;
    color: #ffffff;
    margin: 0;
  }
  .plan-light {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 55px;
    color: #494949;
  }
  .most-popular {
    position: absolute;
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    border-radius: 8px;
//     margin-top: -40px;
    padding: 0 14px;
    max-width: 145px;
    display: inline-block;
//     right:46%;
  }
  .plan-white {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 55px;
    color: #d6d6d6 !important;
  }

input[id="cb1"] + label {
  background-color: transparent;
  background: url(../images/checkbox-style.avif) no-repeat;
  background-size: contain;
  display: inline-block;
  width: 30px;
  height: 19px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  margin: 0;
  color:#fff;
}
input[id="cb1"]:checked + label:after {
  position: relative;
  top: -4px;
  left: 2px;
  content: "\2714";
  font-size: 19px;
}
input[id="cb1"] {
  display: none;
}
.send-btn {
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
  color: white;
  border-radius: 6px;
  max-width: 200px;
  max-height: 30px;
  padding: 7px 18px;
}

.feature-wrapper {
    position: relative;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 30px;
    padding-bottom: 118px;
    position: relative;
//             top: 100px;
//             left: 0;
            width: 100%;
            height: 100%;
            background: url(../images/project-elipsimg.avif) no repeat;
            background-size: contain;
            background-position: top right;
}
.feature-row {
    margin-top:100px;
    background-color: #220a53;
    border-radius: 16px;
    padding: 37px 74px 36px;
    min-height: 400px;
}
.feature-item {
    text-align: left;
    background-size: 100% 100%;
    width: 100%;
    padding: 42px 39px 0px;
    box-sizing: border-box;
  }
  .feature-item p {
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: left;
      color: #b5acd6;
      margin: 0;
    }
    .feature-item h4 {
        font-family: 'Poppins' ;
        font-style: normal;
        font-weight: 700;
        font-size:  20px;
        text-align: left;
        line-height: 22px;
        color: #f5f1ff;
        margin: 0;
        margin-bottom: 14px;
      }
       .feature-item img {
        width: 70px;
        height:70px;
       }

.team-row{
    padding: 37px 74px 36px;
}
.our-team{
    border-radius: 15px;
    text-align: center;
    padding: 0px 0px 30px;
    background: #fff;
}

.our-team .pic{
    background: #fff;
    padding: 0px;
    margin-bottom: 25px;
    display: inline-block;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease 0s;
    border-radius: 15px;
}

// .our-team:hover .pic{
//     background: #17bebb;
//     border-radius: 50%;
// }

.pic img{
    width: 100%;
    height: auto;
    border-radius: 0px;
}

.our-team .title{
    font-weight: 600;
    color: #2e282a;
    text-transform: uppercase;
    display: block;
    font-size: 20px;
    margin: 0 0 7px 0;
}

.our-team .post{
    color: #220a53;
    text-transform: capitalize;
    display: block;
    font-size: 16px;
    font-family: 'Poppins' ;
    margin-bottom: 15px;
}

.our-team .social{
    padding: 0;
    margin: 0;
    list-style: none;
}

.our-team .social li{
    display: inline-block;
    margin-right: 5px;
}

.our-team .social li a{
    border-radius: 50%;
    font-size: 16px;
    font-family: 'Poppins' ;
    color:#220a53;
    border: 1px solid #220a53;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    transition: all 0.5s ease 0s;
}

.our-team:hover .social li a{
    background: #220a53;
    color: #fff;
}

.our-team:hover .pic{
    background: linear-gradient(90deg, #b447eb 0%, #2797ff 100%);
    border-radius: 15px;
}

.our-team:hover .social li a{
    background: #220a53;
    color: #fff;
}

.faq-row{}
.faq-row .ant-collapse {
    font-size: 16px;
    border-radius: 15px;
}
.faq-row .ant-collapse-content > .ant-collapse-content-box{
    background: #220a53;
}
.faq-row .ant-collapse-header-text, p{
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #9c9c9c;
}
.faq-row .ant-collapse-header[aria-expanded="true"] .ant-collapse-header-text, p{
  color: #fff;
}
.anticon.ant-notification-notice-icon-error {
    color: #ff4d4f !important;
}

.anticon.ant-notification-notice-icon-success {
    color: #3ca514 !important;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;