@import "../../../styleConstant.less";

.transaction-page-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;


  .endpointDetails_header{
         display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: space-between;
                justify-content: space-between;
                align-items: center;
       }
  .title{
       display: flex;
       gap: 2rem;
       flex-direction: row;
       flex-wrap: wrap;
       align-content: flex-end;
       justify-content: flex-end;
       align-items: center;
  }

  .endpointDetails_text {
       font-family: 'Poppins';
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          color: #e0e0e0;
          width:20%;
          text-align: start;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 16px 16px;
        }
        .endpointDetails_text p{
        margin-bottom: 0px !important;
        font-family: 'Poppins' ;
        }

  .endpointDetails_text_inner {
          font-family: 'Poppins';
          font-weight: 500!important;
          font-size: 16px;
          line-height: 30px;
          color: #e0e0e0;
          text-align: start;
        }       
  .endpointDetails_text_header {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            color: #e0e0e0;
          }
  .transaction-header-section1 {
    display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: flex-end;
        align-items: center;
    .header-section1 {
    display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: flex-end;
        align-items: center;
        margin-top: -90px;
        margin-right: -15px;

      .text {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #e0e0e0;
        padding-right: 20px;
      }
    }
  }

  .gradient-button{
    font-family: 'Poppins';
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    color: white;
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    border-radius: 6px;
    border: 0.5px solid black;
  }

    .suspend-btn{
      font-family: 'Poppins';
     width: 181px;
     height: 49px;
     top: 170px;
     left: 1356px;
     gap: 0px;
     border-radius: 20px 20px 20px 20px;
     opacity: 0px;
     background: #FFFFFF;
     font-family: 'Poppins';
     font-size: 20px;
     font-weight: 700;
     line-height: 35px;
     text-align: center;
     color: #E91D1D;
     margin-right:10px;
      }

     .doc-btn{
      font-family: 'Poppins';
         width: 291px;
         height: 49px;
         top: 170px;
         left: 1548px;
         gap: 0px;
         border-radius: 20px 20px 20px 20px;
         border: 1px 0px 0px 0px;
         opacity: 0px;
         background: #000000;
         border: 1px solid #FFFFFF;
         font-family: 'Poppins';
         font-size: 20px;
         font-weight: 700;
         line-height: 35px;
         text-align: center;
         color: #FFFFFF;
         }
}
.my-account-page-container {
  .ant-tabs {
    // .ant-tabs-nav::before {
    //   border-bottom: none !important;
    // }
  
      .ant-tabs-nav::before {
        border-bottom:1.5px  solid white !important;
        border-radius: 50px;
        opacity: 70%;
      }
      .ant-tabs-ink-bar {
        display: none; /* Hide the bottom line indicator */
      }
      .ant-tabs-nav::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
     
        width: 100%;
        border-radius: 50px;
        height: 3px !important; /* Adjust the thickness of the gradient line */
      
      }
    

    .ant-tabs-tab {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: rgba(239, 239, 239, 0.4);
      background: transparent;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: 2px solid @success-color !important;
      z-index: 1;
    }

    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-remove:active {
      color: rgba(239, 239, 239, 0.4);
    }

    .ant-tabs-tab-btn{
      font-family: 'Poppins';
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      text-align: left;
      color: white !important;
      opacity: 100% !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab-btn:active {
      font-family: 'Poppins';
      font-size: 20px;
      font-weight: 700;
      line-height: 35px;
      text-align: left;
      color: white !important;
      opacity: 100% !important;

    }
  }
}

.App {
    width:-webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0px;
    border-radius: 20px 20px 20px 20px;
    background: #000000;
    border-radius: 10px;
}

.App table {
    border: none !important;
    width: -webkit-fill-available;
    height: auto;
    border-radius: 10px !important;
}

th {
    border-bottom: 1px solid black;
    color:white;
    text-align: center;
}

td {
    text-align: left;
    color:white;
    word-break: break-all;
}
.endpoint_button{
display:flex;
flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    /* justify-content: space-around; */
    align-items: center;
    .gradient-button {
      font-family: 'Poppins';
            border-radius: 8px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #090a2c;
            width: 200px;
            height: 47px;
            box-sizing: border-box;
            .button-background();
            &:focus {
              outline: none;
            }
          }
}
.endpoint_button1{
display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.ant-modal{
 top:20%;
 width: 60% !important;
 box-shadow: black;
}
.endpoint-popup{
  .popup-text-endpoint{
    font-family: 'Poppins';
         text-align: left;
           padding-left: 20px;
           padding-bottom: 5px;
           font-size: 20px;
           font-weight: 600;
     }
     .ant-space{
          display: flex;
            margin-top: 25px;
            padding-left: 20px;
            padding-right: 20px;
            gap: 25px;
     }
     .deploy-nft-collection{
          display: block;
            text-align: left;
            margin-top: 40px;
            float: right;
  
            button{
              font-family: 'Poppins';
              font-size: 16px;
                margin-bottom: 40px;
            }
     }
  }
  .new-button{
          padding: 5px 10px;
          background: transparent;
          border-radius: 5px;
          border: 1px solid #FFFFFF;
  }
.form-layout::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 10px;
}

.form-layout::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 20px;
}

.form-layout::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.form-layout::-webkit-scrollbar-track {
  display: none;
}
.button-font-size{
    background: #4C59E3;
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px !important;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;