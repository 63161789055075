@import "../../styleConstant.less";

.fieldset-container {
  padding: 5px;
  color: #efefef;
  margin-bottom: 35px;
  border-radius: 8px;
  border: 1px solid #464646;

  legend {
    display: inline;
    width: fit-content;
    padding: 0px 10px;
    font-size: 20px;
  }

  .text-field {
    width: 100%;
    height: 100%;
    border: none;
    padding: 1rem;
    outline: none;
    background-color: transparent;
    color: #efefef;
  }
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;