@import "../../styleConstant.less";
.kc-jumbotron{
    background: linear-gradient(90deg, #7e347b -4.52%, #2f5a83 120.27%);
    border-radius: 10px !important;
    background-image: url("../../assets/support.avif");
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-color: #151617 !important;
    border: 1px solid #464646;
    box-shadow: 0px 3.49493px 8.73734px rgba(0, 0, 0, 0.1);
    padding: 4rem 2rem 4rem 2rem !important;

    h1{
        color: #fff;
        font-weight: 700;
    }
}
.title {
    display: flex;
    gap: 2rem;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
.lead {
 font-size: 2rem !important;
  font-weight: 500 !important;
  margin: 0 10px;
  color: #fff;
}
}
  .allCardsContainer {
     width: 100%;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    min-height: 350px;

    .eachCard {
      background: #222222;
          box-shadow: 0px 3.49493px 8.73734px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
      border-radius: 10px;
      min-height: 220px;
      max-width: 350px;
      position: relative;
      margin-bottom: 30px;
      border: 1px solid #464646;

     /*  &::before {
        z-index: 1;
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  border-radius: 6px;
                  border: 0.5px solid transparent;
                  background: linear-gradient(90deg, #b447eb, #2797ff) border-box;
                  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                    mask-composite: add, add;
                  -webkit-mask-composite: destination-out;
                  mask-composite: exclude;
      } */
    }
    .eachCard:hover {
      border: 3px solid #095fdb;
    }
    .cardImage {
      /* margin-right: 16px; */
      text-align: left;
      margin-bottom: 20px;
    }

    .cardContent {
      /* display: flex; */
      padding: 30px;
      margin-left: 10px;
      margin-top: 10px;

    }
    .cardInfo{
        text-align: left;
    }

    .cardTitle {
      line-height: 1.2;
      color: #fff;
      font-size: 26px;
      text-align: left;
    }

    .cardSubTitle {
      font-size: 14px;
      color: #fff;
    }

    .cardText {
      line-height: 1.6;
      font-weight: normal;
      opacity: 1;
      transition: all 0.5s cubic-bezier(0.83, 0, 0.18, 0.78);
    }


  }
  .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
      }

 .input-container {
    color: @color-d5;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-color: #464646;
    background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
    border-radius: 12px;
    padding: 10px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;