@import "../../styleConstant.less";
.landingPage_responsive {
    width: auto;
    overflow-x:hidden;
     background-color:  #07030A;
*{
font-family:"Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
/* Base styles for the navbar */
.header-wrap {
  background: #080322;
  position: fixed;
  top: 0;
   width: 100%;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


// .navbar-brand img.logo {
//   height: 40px; /* Adjust the height as needed */
// }

.navbar-toggler {
  border: none;
  background-color: transparent;
  display:flex; /* Hide the toggler for screens wider than 768px */
}

.nav-item .nav-link{
  cursor: pointer;
  margin-left: 15px;
  font-size:18px;
  color: #fff;
  cursor:pointer;

}
.navbar-nav .active-link {
  color: #d988ddcc; /* Set color for the active link */
  font-weight: bold; /* Example: Make the active link bold */
  font-size:18px;
    cursor:pointer;
}
.nav-item .nav-link:hover{
color:#b447eb;
}
.navbar-logo-division {
    display: flex;
    align-items: center;
  }
.menu-right .header-signupbtn {
  padding: 8px 16px;
}
.header-signupbtn {
      font-family: Inter,sans-serif;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         padding: 17px 26px 16px;
        border-radius: 0.875rem;
        background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
         color: white;
         border:none;
//          .gradient-border();
   }
.text-white{
font-size:18px;
color:white;
cursor:pointer;
}
/* Media query for responsiveness up to 1200px */
@media (max-width: 1200px) {
  .nav-item {
    display: flex; /* Hide the navigation items */
  }

  .navbar-toggler {
    display: block; /* Show the toggler for screens up to 1200px */
  }
  .navbar-logo-division {
    margin-right  : 17%;
  }
  .navbar-collapse {
    display:none;
    background-color: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index:-1;
    font-size:20px;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
//     display: flex; /* Show the navbar items when toggler is clicked */
    display: block !important;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
         background:#000;
          font-size:20px;
          padding:10px;
  }

  .navbar-collapse li {
    text-align: center;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }
}
@media (max-width: 360px) {
  .nav-item {
        display: grid; /* Hide the navigation items */
  }
  .navbar-logo-division {
      margin-right  : 0% !important;
    }

  .navbar-toggler {
    display: block; /* Show the toggler for screens up to 360px */
  }

  .navbar-collapse {
    display:none;
    background: #000;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    font-size:18px;

  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
    display: flex; /* Show the navbar items when toggler is clicked */
     display: block !important;
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            background:#000;
            padding:10px;
  }

  .navbar-collapse li {
    text-align: left;
    margin: 10px 0;
    font-size:18px;
  }

  .menu-right {
    margin-top: 10px;
  }

//   .navbar-brand img.logo {
//     height: 30px; /* Adjust the logo height for smaller screens */
//   }

  .menu-right .header-signupbtn {
    font-size: 14px; /* Adjust the font size for smaller screens */
  }
}

/* Basic styles for the banner section */
.Bannersection-wrap {
  text-align: center;
  position: relative;
  background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #080322;
  backdrop-filter: blur(25px);
}

.Bannersection-wrap::before {
    content: "";
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
.banner-head-container {
  margin: 0 auto;
  max-width:1200px;
    background: url(../../../assets/images/banner-bcg.avif)no-repeat;
        background-position: center;
        background-size: contain;



}

.banner-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: normal;
  color: #ececec;
  margin:100px;
   padding: 20px;
}
 .banner-highlight {
     background: linear-gradient(to right, #AA0AFC, #D5D3F3, #007AEB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.banner-para {
  font-size: 20px;
  line-height: 1;
  color: grey;
  margin-top: -50px;
  margin-bottom: 40px;
  text-align:center;
}

.banner_btn {
    font-family: Inter,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    padding: 17px 26px 16px;
    width:max-content;
    border-radius: 0.875rem;
    background: linear-gradient(147deg, #AA0AFC 0%, #007AEB 100%);
    color: white;
//     .gradient-border();
    border:none;
}
.docs_btn{
font-family: Inter,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    padding: 17px 26px 16px;
    background: transparent;
    border-radius: 0.875rem;
    color: white;
   border:1px solid #fff;
}
.banner-button-section{
display:inline-flex;
gap:1rem;
}
.banner-docs{
display:inline-flex;
gap:1rem;
}
/* Responsive styles for screens up to 1200px */
@media (max-width: 1200px) {
  .banner-head-container {
    padding: 0 15px;
  }

  .banner-heading {
    font-size: 55px;
      line-height: normal;
      padding: 20px;
//       margin-bottom: 40px;
  }

  .banner-para {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .theme-btn {
    padding: 10px 20px;
    font-size: 14px;
  }
}

/* Responsive styles for screens up to 768px (tablet and mobile) */
@media (max-width: 768px) {
  .banner-heading {
    font-size: 2.8rem;
    line-height: 105%;
    margin:20px;
  }
//   .navbar-brand img.logo{
//     height: 35px;
//   }

  .banner-para {
      margin-top: 20px;
    font-size: 16px;
    margin-bottom:30px;
  }

  .theme-btn {
    padding: 8px 16px;
    font-size: 12px;
  }
  .Bannersection-wrap {
    margin-top: 0px !important;
  }
  .banner_btn {
      font-family: Inter,sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
//       padding: 15px 13px 16px;
      width:100%;
      background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
      border-radius: 0.875rem;
      color: white;
      .gradient-border();
      border:1px solid #b447eb;
  }
  .banner-button-section{
  display: flex;
  flex-direction: column;
  }
  .marquee-container {
    overflow: hidden;
  }

  .marquee-content {
    display: flex;
    animation: marquee-left 20s linear infinite;
  }

  @keyframes marquee-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .marquee-content:hover {
    -moz-animation-play-state: paused;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
   }

}
.header-wrap {
  z-index: 5;
}
.Bannersection-wrap {
    padding-top:100px;
}

/* Basic styles for the client logo section */
.clientlogo-wrap {
  background-color:#080322;
  padding: 20px 0;
  margin-top:0px;
  padding-bottom:150px;
}
.text-trust{
font-size:20px;
color:white;
display:flex;
font-weight:700;
padding-top:100px;
justify-content: center;
background:#080322;

}

.plandetails-sec2-image-container_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:1rem;
}




/* Responsive styles for screens up to 1200px */
@media (max-width: 1200px) {
  .clientlogo-item {
    margin: 0 10px; /* Reduce the margin between logo items */
  }
}

/* Responsive styles for screens up to 768px (tablet and mobile) */
@media (max-width: 768px) {
  .marquee-container-left {
    overflow: hidden; /* Enable horizontal scrolling on smaller screens */
  }
 .plandetails-sec2-image-container_1 {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom:40px;
 }
// .marquee-content-left {
//   display: flex;
//   animation: marquee-left 20s linear infinite;
// }

  .clientlogo-item {
  display: grid;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;

  }
  .clientlogo-wrap {
    margin-top:0 !important;
    margin-bottom:0 !important;
    padding-bottom:0px;
  }
  .text-trust{
  padding-top:50px;
 }
}
.clientlogo-item img {
  max-width: 134px;
  height: auto;
}


/* Basic styles for the how it works section */
.howitswork-wrapper_1 {
  background-color: transparent;
  padding: 50px 0;
  text-align: center;
  margin:20px;
  background: url(../../../assets/images/project-elipsimg.avif) repeat;
  background-size: contain;
  background-position: top left;
}

.theme-heading {
  margin-top:40px;
  margin-bottom: 40px;
}

.theme-heading h3 {
  color: #fff;
  font-size: 35px;
  font-weight:700px;
}
.journey-main-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  background:linear-gradient(90deg, #c5b55a -4.52%, #2772ff 120.27%);
  border-radius:20px;
  padding:20px;
  }
.journey-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.journey-card {
  background: transparent;
  padding: 20px;
  text-align:left;

}
.journey-card img {
  width: 60px;
  height: 60px;
  display: block;

}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.journey-card h2{
font-size:30px;
margin-top:20px;
font-weight:700;
color:#000;
text-align:left;
}
.journey-card p{
font-size:18px;
color:#000;
text-align:left;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

/* Responsive styles for screens up to 1200px */
@media (max-width: 1200px) {
  .journey-card {
    max-width: 250px;
    padding: 15px;
    margin: 15px;
  }

  .journey-card h2 {
    font-size: 22px;
  }

  .journey-card p {
    font-size: 14px;
  }
}

/* Responsive styles for screens up to 768px (tablet and mobile) */
@media (max-width: 768px) {
  .journey-main-container {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
  }

  .journey-container {
        display: flex !important;
        flex-direction: column !important;
  }
  .journey-card {
     width:100%;
    }

  .theme-heading h3 {
    font-size: 25px !important;
  }
  .theme-heading {
    margin-top:0px;
    margin-bottom: 40px;
  }
  .journey-card h2{
  font-size:20px;
  margin-top:20px;
  font-weight:700;
  color:#000;
  text-align:left;
  }
  .journey-card p{
  font-size:16px !important;
  color:#000;
  text-align:left;
  }
}
/* Basic styles for the KrypCore stack section */
.krypCore_stack {
  background-color: transparent;
  padding: 50px 0;
  text-align: center;
  margin:20px;
  background: url(../../../assets/images/project-elipsimg.avif) repeat;
  background-size: contain;
  background-position: top left;
}

.theme-heading {
  margin-top:40px;
  margin-bottom: 40px;
}

.theme-heading h3 {
  color: #fff;
  font-size: 35px;
  font-weight:700px;
}
.section31-container {
  display: flex;
  justify-content: space-around;
  align-content:space-around;
  align-items: center;
  flex-wrap: wrap;

}
.section32-container {
  display: flex;
  justify-content: space-around;
  align-content:space-around;
  align-items: center;
  flex-wrap: wrap;

}
.section-enterprise-container {
  width: 100%;
   max-width: 450px;
   height: 300px;
background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
box-shadow:0 7px 19px rgb(11 4 46);
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   padding: 20px;
   box-sizing: border-box;
   margin-bottom: 100px;
   margin-top: 50px;
}
.section-enterprise-container-inner {
  width: 100%;
   max-width: 450px;
   height: 300px;
  background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
   box-shadow:0 7px 19px rgb(11 4 46);
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   padding: 40px;
   box-sizing: border-box;
   margin-bottom: 60px;
   gap:1rem;
}
.section-enterprise-card-content{
 display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.section-enterprise-title{
  font-size: 30px;
   text-align:center;
   margin: auto;
   color:#fff;
   margin-bottom:10px;

}
.section-enterprise-text{
  font-size: 18px;
  margin-bottom: 50px;
     margin: auto;
     color:#fff;
}
.section-enterprise-card{
   width: calc(50% - 20px);
    height: auto;
       display: flex;
       flex-wrap: wrap;
        background: transparent;
       justify-content: flex-end;
       margin-bottom:10px;
}
.enterprise-expand{
margin-top:-80px;
margin-right:40px;
display:flex;
justify-content:end;
align-items:end;
z-index:10;
cursor:pointer;
}
.enterprise-icon{
cursor:pointer;
z-index:10;
}
.circle-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
  border-radius: 8px;
  background: linear-gradient(to left, #8360c3, #2ebf91);
  color: #fff;
  border: none;
  font-size: 18px;
  z-index:1;
  cursor: pointer;
}

.button-container {
 display: flex;
align-items: center;
margin: auto;
flex-direction: row;
flex-wrap: nowrap;
align-content: center;
justify-content: center;
 }
.enterprise-card-button{
display:flex;
    justify-content: center;
    align-items: center;
}
 .start-button{
         font-family: Inter,sans-serif;
         font-style: normal;
         font-weight: 500;
         font-size: 20px;
         line-height: 20px;
         padding: 17px 26px 16px;
         background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
         border-radius: 4px;
         width:100%;
         color: white;
         width:250px;
         border:3 px solid #2797ff;
         .gradient-border();
}
.collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

@media (max-width: 768px) {
  .section-enterprise-card {
    width: calc(100% - 20px);
    margin-bottom:10px;
  }
  .section-enterprise-container-inner{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;

  }
  .theme-heading {
    margin-top:0px !important;
    margin-bottom: 40px;
  }

   .theme-heading h3 {
      color: #fff;
      font-size: 25px !important;
      font-weight:700px;
    }

  .section-enterprise-title{
    font-size: 20px;
     text-align:center;
     margin: auto;
     color:#fff;
     margin-bottom:10px;

  }
  .section-enterprise-text{
    font-size: 16px;
    margin-bottom: 50px;
       margin: auto;
       color:#fff;
  }
  .start-button{
           font-family: Inter,sans-serif;
           font-style: normal;
           margin:10px auto;
           font-weight: 500;
           font-size: 16px;
           line-height: 20px;
           padding: 17px 26px 16px;
           background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
           border-radius: 4px;
           width:100%;
           color: white;
           border:3 px solid #2797ff;
           .gradient-border();
  }
  .enterprise-expand{
  margin-top:-60px;
  margin-right:0px;
  display:flex;
  justify-content:center;
  align-items:center;
  }
  .circle-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    border-radius: 4px;
    background: linear-gradient(to left, #8360c3, #2ebf91);
    color: #fff;
    border: none;
    font-size: 18px;
    z-index:1;
    cursor: pointer;
  }

}
@media (max-width: 1200px) {
    .section-enterprise-container-inner{
        width: 100%;
        display: grid;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;

    }
    .section-enterprise-card{
      width: calc(50% - 20px);
        height: auto;
        margin-bottom:10px;

    }
    .section-enterprise-title{
      font-size: 20px;
       text-align:center;
       margin: auto;
       color:#fff;
       margin-bottom:10px;

    }
    .section-enterprise-text{
      font-size: 16px;
         margin: auto;
         color:#fff;
    }
}
.web3_feature-wrapper {
  background-color: transparent;
  padding: 50px 0;
  text-align: center;
  margin:20px;
  background: url(../../../assets/images/project-elipsimg.avif) repeat;
  background-size: contain;
  background-position: top left;
}

.web3_theme-heading {
  margin-top:40px;
  margin-bottom: 40px;
}

.web3_theme-heading h3 {
  color: #fff;
  font-size: 35px;
  font-weight:700px;
}

.web3_feature-row {
  margin-top: 30px;
  background:#220a53;
  padding:40px;
  border-radius:10px;
}

.web3_feature-item {
  text-align: left;
  padding: 20px;
}

.web3_howits-icon img {
  max-width: 100px;
  height: 60px;
}

.web3_howits-icon {
  margin-bottom: 20px;
}

h4 {
  font-size: 30px;
  color:#fff;
  font-weight:700;
  text-align: left;
}

p {
  font-size: 18px;
  color:#fff;
  text-align: left;
  line-height:1.5;
}

.section3-card-button {
  text-align: center;
}

.section3-card-button{
display:flex;
justify-content: center;
align-items: flex-start;
}
    .getstart-button{
     border-radius: 4px;
     font-weight: 500;
     font-size: 20px;
     line-height: 17px;
     cursor:pointer;
      text-align: center;
     color: #090a2c;
      width: 250px;
      z-index:1;
      height: 60px;
      box-sizing: border-box;
       .button-background();
       &:focus {
        outline: none;
     }
}

/* Responsiveness */
@media (max-width: 1200px) {
  .web3_container-fluid {
    padding: 10px;
  }

  h3 {
    font-size: 20px;
  }

  .web3_feature-item {
    padding: 10px;
  }

  h4 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }

  .getstart-button {
      width: 250px;
  }
}

@media (max-width: 768px) {
  .web3_feature-item {
    margin-bottom: 20px;

  }
    .getstart-button {
        width: 80%;
    }

h4 {
  font-size: 20px;
  color:#fff;
  font-weight:700;
  text-align: left;
}
 .web3_theme-heading h3 {
    color: #fff;
    font-size: 25px !important;
    font-weight:700px;
  }
  .web3_theme-heading {
    margin-top:0px !important;
    margin-bottom: 40px;
  }

p {
  font-size: 16px;
  color:#fff;
  text-align: left;
  line-height:1.5;
}

}

.pricingplan-wrapper {
  background-color: transparent;
  padding: 0px;
  text-align: center;
  margin:20px;
  border-radius: 16px;
  background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #0C0610;
}

.theme-heading {
  margin-top:40px;
  margin-bottom: 40px;
}

.theme-heading h3 {
  color: #fff;
  font-size: 35px;
  font-weight:700px;
}
.plan-highlight {
     background: linear-gradient(165deg, #AA0AFC 0%, #D5D3F3 54.14%, #007AEB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .plan-credit-num{
    background:  #AA0AFC;
    -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
  }
.plan-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

    }

.plandetail-card {
border-radius: 15px;
border: 1px solid #E4E5EB;
background: rgba(13, 6, 18, 0.47);
 color: #B2B6C0;
  width:100%;
height:520px;
 display: grid;
  align-content: center;
  justify-content: start;
  align-items: start;
  justify-items: start;
  padding: 15px;
}
.plan-card-container {
  width: calc(25% - 40px);
  height:522px;
  margin: 20px;
  padding: 0px;
  color:#fff;
  background: transparent;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 border:1px solid #B2B6C0;
 border-radius:15px;

}
.most-popular-container {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: center; /* Align items horizontally in the center */
    width: 100%;
    margin: -50px auto;
}
 .pro-plandetail-card {
  width:100%;
  height:520px;
  border-radius: 14.806px;
  opacity: 0.9;
  background: linear-gradient(144deg, #100816 0%, rgba(77, 36, 106, 0.52) 100%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  align-content: center;
  justify-content: start;
    align-items: start;
    justify-items: start;
    padding: 15px;
 }
.pro-card-container{
  width: calc(25% - 40px);
  height:522px;
   margin: 20px;
   padding: 0px;
   color:#fff;
   background: transparent;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   border:1px solid #3331D2;
   border-radius:15px;
   }
.plandetail-card h3 {
      font-size: 30px;
      margin-bottom: 20px;
      margin-top:20px;
      font-weight:600;
      color:#B2B6C0;
      text-align:start;

    }
 .plandetail-card h5 {
       font-size: 14px;
       margin-bottom: 20px;
       color:#B2B6C0;
       text-align:start;

     }
.plandetail-card p {
      font-size: 25px;
      margin-bottom: 20px;
      color:#B2B6C0;
      text-align:start;
    }
.pro-text{
 background: linear-gradient(to left, #fdc830, #f37335);
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
 }
 .pro-sub-text{
  background:linear-gradient(to left, #fc4a1a, #fc4a1a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
 .pro-plandetail-card h3 {
  font-size: 30px;
  margin-bottom: 20px;
  margin-top:20px;
  font-weight:600;
  text-align:center;
   color:#B2B6C0;
 }
 .pro-plandetail-card h5 {
   font-size: 14px;
   margin-bottom: 20px;
   text-align:-webkit-auto;
    color:#B2B6C0;
  }
.pro-plandetail-card p {
 font-size: 25px;
  margin-bottom: 20px;
  text-align:center;
   color:#B2B6C0;
}
    .Free-plan-basic-ul{
    list-style-type: none;
    text-align:start;
          font-size: 16px;
          margin-bottom: 33px;
          color:#B2B6C0;

    }
    .Starter-plan-basic-ul{
        list-style-type: none;
        text-align:start;
              font-size: 16px;
              margin-bottom: 32px;
              color:#B2B6C0;

        }
 .Pro-plan-basic-ul{
            list-style-type: none;
            text-align:start;
                  font-size: 16px;
                  margin-bottom: 32px;
                  color:#B2B6C0;

}
.Enterprise-plan-basic-ul{
                list-style-type: none;
                text-align:start;
                      font-size: 16px;
                     margin-bottom:30px;
                      color:#B2B6C0;
}
.Free-plan-basic-ul li {
  position: relative;
  padding-left: 30px;
  line-height: 2;
}
.Free-plan-basic-ul li::before {
  content: '\2713';
  position: absolute;
  color:#3331D2;
  left: 0;
}
.Starter-plan-basic-ul li{
 position: relative;
  padding-left: 30px;
  line-height: 2;
}
.Starter-plan-basic-ul li::before {
  content: '\2713';
  position: absolute;
  color:#3331D2;
  left: 0;
}
.Enterprise-plan-basic-ul li{
 position: relative;
  padding-left: 30px;
  line-height: 2;
}
.Enterprise-plan-basic-ul  li::before {
  content: '\2713';
  position: absolute;
  color:#3331D2;
  left: 0;
}
.Pro-plan-basic-ul li{
 position: relative;
  padding-left: 30px;
  line-height: 2;
}
.Pro-plan-basic-ul  li::before {
  content: '\2713';
  position: absolute;
  color:#3331D2;
  left: 0;
}
 .contact-us-button {
       border-radius: 4px;
       font-weight: 600;
       font-size: 18px;
       line-height: 17px;
       text-align: center;
       border:1px solid #B2B6C0;
       color: #000;
       width: 100%;
       height: 47px;
       box-sizing: border-box;
       background:#B2B6C0;
       margin-bottom:20px;

     }
 .contact-us-button-popular {
        border-radius: 0.875rem;
        font-weight: 600;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        border:none;
        color: #fff;
        width: 100%;
        height: 47px;
        box-sizing: border-box;
        background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
        margin-bottom:20px;

      }
  .most-popular {
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          border:none;
          color: #fff;
          width: 50%;
          height: 30px;
          box-sizing: border-box;
          background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
//           margin: -50px auto;
          position:relative;
        }
.plan-button {
      border-radius: 0.875rem;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      margin-bottom:20px;
      color: #000;
      width: 100%;
      height: 47px;
      border:1px solid #B2B6C0;
      box-sizing: border-box;
      background:#B2B6C0;
    }
.planList{
   display: flex;
       flex-direction: row;
       flex-wrap: wrap;
       align-content: center;
       justify-content: center;
       align-items: center;
.getstart-button{
                border-radius: 4px;
                font-weight: 500;
                font-size: 18px;
                line-height: 17px;
                cursor:pointer;
                 text-align: center;
                color: #090a2c;
                 width: 250px;
                 z-index:1;
               height: 50px;
                box-sizing: border-box;
                  .button-background();
                  &:focus {
                   outline: none;
  }
}
}
@media (max-width: 1200px) {
  .most-popular {
                 border-radius: 4px;
                 font-weight: 500;
                 font-size: 14px;
                 line-height: 17px;
                 text-align: center;
                 border:none;
                 color: #fff;
                 width: 50%;
                 height: 30px;
                 box-sizing: border-box;
                 background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
//                  margin:-50px auto;
                 position:relative;
               }
}

/* Responsive styles for screens up to 768px (tablet and mobile) */
@media (max-width: 768px) {
   .most-popular {
                  border-radius: 4px;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 17px;
                  text-align: center;
                  border:none;
                  color: #fff;
                  width: 50%;
                  height: 30px;
                  box-sizing: border-box;
                  background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
//                   margin:-25px auto;
                  position:relative;
                }
                .most-popular-container {
                    display: flex;
                    align-items: center; /* Align items vertically in the center */
                    justify-content: center; /* Align items horizontally in the center */
                    width: 100%;
                    margin: -20px auto;
                }
  .plan-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction:column;
        gap:1rem;
      }
 .plan-card-container {
   width:100%;
   margin: auto;

}
  .pro-card-container{
    width:100%;
      margin: auto;

  }
  .plandetail-card h3 {
        font-size: 25px;
      }
.plandetail-card p {
font-size: 20px;
}
 .Free-plan-basic-ul{
 font-size: 16px;
}
.Starter-plan-basic-ul{
font-size: 16px;
}
.Pro-plan-basic-ul{
   font-size: 16px;
}
 .Enterprise-plan-basic-ul{
  font-size: 16px;
 }
.plan-button {
      border-radius: 4px;
      font-weight: 600;
      font-size: 18px;
      line-height: 17px;
      text-align: center;
      margin-bottom:20px;
      color: #000;
      width: 100%;
      height: 47px;
      border:1px solid #B2B6C0;
      box-sizing: border-box;
      background:#B2B6C0;
    }
 .pro-plandetail-card h3 {
  font-size: 25px;
 }
.pro-plandetail-card p {
 font-size: 20px;
}
 .contact-us-button {
        border-radius: 4px;
        font-weight: 600;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        border:1px solid #B2B6C0;
        color: #000;
        width: 100%;
        height: 47px;
        box-sizing: border-box;
        background:#B2B6C0;
        margin-bottom:20px;

      }
     .planList{
     .getstart-button{

        width:80%
     }
     }

}
/* Basic Styling */
.accessapi-wrapper {
  background-color: transparent;
  padding: 50px 0;
  text-align: center;
  margin:20px;
  background: url(../../../assets/images/project-elipsimg.avif) repeat;
  background-size: contain;
  background-position: top left;
}

.accessapi-row{
  margin-top:auto;
  background:#220a53;
  padding:40px;
  border-radius:10px;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.accessapi-content h4 {
  font-size: 35px;
  margin-bottom: 10px;
}

.accessapi-content p {
  font-size: 18px;
  margin-bottom: 20px;
  text-align:justify;
  line-height:1.5;
}

.access_btn {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        padding: 12px 12px 13px;
        background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
        border-radius: 4px;
        color: white;
        .gradient-border();
        border:1px solid #b447eb;
}

.accessapi img {
  width: 100%;
  height:100%;
  object-fit: cover;
  border-radius: 12px;
}

/* Responsiveness */
@media (max-width: 1200px) {
  .container-fluid {
    padding: 10px;
  }

  .accessapi-content h4 {
    font-size: 25px;
  }

  .accessapi-content p {
    font-size: 16px;
  }

  .theme-btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .accessapi img {
   height:100%;
  }
}

@media (max-width: 768px) {
  .accessapi-content {
    text-align: center;
  }

  .accessapi img {
   height:100%;
  }
  .text-left{
  text-align:center !important;
  }
   .access_btn{
          font-weight: 500;
          font-size: 16px;
          padding: 12px 12px 13px;
          background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
          border-radius: 4px;
          color: white;
          .gradient-border();
          border:1px solid #b447eb;
          }
}
/* Basic Styling */
.createproject-wrapper {
  background-color: transparent;
  padding: 50px 0;
  text-align: center;
  margin:20px;
  background: url(../../../assets/images/project-elipsimg.avif) repeat;
  background-size: contain;
  background-position: top left;
}
.container-fluid-accessapi{
   margin-top: auto;
    background:#220a53;
    padding:40px;
    border-radius:10px;
}
.create-project-content {
  text-align: center;
  margin-top: 50px;
      margin-bottom: 20px;
}

h3 {
  font-size: 35px;
  margin-bottom: 20px;
  color:#fff;
}

.last_section_btn {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      padding: 12px 12px 13px;
      background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
      border-radius: 4px;
      color: white;
      .gradient-border();
      border:1px solid #b447eb;
}
@media (max-width: 1200px) {
  .container-fluid-accessapi {
    padding: 10px;
  }

  h3 {
    font-size: 30px;
  }

  .last_section_btn {
        font-weight: 500;
        font-size: 16px;
        padding: 15px 12px 13px;
        background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
        border-radius: 4px;
        color: white;
        .gradient-border();
        border:1px solid #b447eb;

  }
}

@media (max-width: 768px) {
  h3 {
    font-size: 25px;
  }

  .last_section_btn {
         font-weight: 500;
         font-size: 16px;
         padding: 15px 12px 13px;
         background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
         border-radius: 4px;
         color: white;
         .gradient-border();
         border:1px solid #b447eb;
}
}

.new_footer{
background:transparent;
padding: 20px;
height:auto;
}
.new_footer_container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}


.new_footer_card {
  padding: 0px;
  border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
}
.new_footer_card h2{
  color: #E2E2E2;
  font-family: 'Poppins' ;;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 40px !important;
}
.new_footer_card_logo{
width:200px;
height:100px;
}
.left-card {
  width:30%;
  max-height:300px;
  background-color: transparent;
  color: #fff;
  display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
}

.right-card {
  background-color: transparent;
  max-height:300px;
  color: #333;
 display: flex;
    flex-direction: row;
     flex-wrap: wrap;
     align-content: space-between;
     justify-content: space-around;
     align-items: flex-start;

}

.sub-grid-item-1 {
   background: transparent;
   /* padding: 10px; */
   border-radius: 5px;
   display:grid;
   justify-items:start;
   flex: 0 0 auto;
   width: auto;
}
.sub-grid-item-1 h3{
font-size:18px;
font-weight:900;
color:#fff;
}
.divider {
  border-top: 1px solid rgba(171, 169, 169, 0.22);
  width:100%;
}

.sub-grid-item-1 ul li{
font-size:16px;
color:#fff;
list-style-type:none !important;
text-align:left;
 padding: 5px;
 margin-right:25px;
  transition: color 0.3s;
}
.sub-grid-item-1 ul li:hover {
 color:#fa4b4d;
}
.bottom-section {
     display:flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;

}
.vertical_divider {
  border-left: 1px solid #fff;
  height: auto;
  margin: 0 100px;
  width:2px;
}
.word-divider1 span {
  color:#fff;
  margin-right:10px;
}
.word-divider span {
  color:#fff;
  margin-right:20px;

}
.word-divider{
 display:flex;
 gap:1rem;
}
.social-icons-footer{
display:inline-flex;
gap:2rem;
justify-content:center;
align-items:center;
}
@media (max-width: 767px) {

.new_footer_card_logo{
width:inherit;
}
.new_footer_container {
 display: flex;
  justify-content: space-between;
  flex-direction:column;
}
  .left-card {
    width: 100%;
  }

  .right-card {
      /* width: auto;
       max-height: none;
       display: grid;
       align-content: center;
       justify-content: center;
       align-items: center;
       justify-items: start; */
       padding: 0;
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between !important;
         --bs-gutter-x: 1.5rem;
         --bs-gutter-y: 0;
         margin-top: 30px;

  }

  .vertical_divider {
    display:none;
    height: auto;
    margin: auto
  }
  .new_footer_card h2{
   color: #E2E2E2;
   font-size: 14px;
   font-style: normal;
   font-weight: normal;
   line-height: 21px;
   margin-top: 40px !important;
   }
  .sub-grid-item-1 {
    /* padding: 10px; */
  }

  .sub-grid-item-1 h3 {
    font-size: 20px;
  }

  .sub-grid-item-1 ul li {
    font-size: 14px;
    margin-right: 15px;
  }
  .bottom-section {
       display:grid;
       justify-content: center;
       align-items: center;
       align-content: center;
       justify-items: center;

  }
  .word-divider{
    display: grid;
    gap: 1rem;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
  }

// platform card styles

.platform-section{
    margin:auto;
    background: transparent;
    backdrop-filter: brightness(1.5);
    padding: 20px;
.platform-card-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.platform-card{
background: url(../../../assets/images/tenx-bc.avif) repeat;
   background-size: contain;
 position: relative;
  overflow: hidden;
   width: 100%;
    height: 100%;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease;
}

.platform-card-bg {
 border-radius: 11.684px;
 border: 0.974px solid rgba(228, 229, 235, 0.16);
 background: #0A0118;
 mix-blend-mode: exclusion;
  position: relative;
  overflow: hidden;
  width: 100%;
  height:auto ;
  padding:20px;
  transition: all 0.3s ease;
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.platform-card-bg img{
width:100%;
height:100%;
}

.platform-card-content {
  position: relative;
  z-index: 2;
}
.platform-card-bg:hover{
//  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border:1px solid #7d51e1;
}
.platform-heading {
      display: flex;
      justify-content: center;
      font-family: 'Poppins' ;;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: auto;
      width: 40%;
      color: #FBF5FF;
      margin-bottom: 40px;
      margin-top: 100px;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
}
 .platform-highlight {
   background: var(--linear, linear-gradient(165deg, #AA0AFC 0%, #D5D3F3 54.14%, #007AEB 100%));
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
  }
@media (max-width: 768px) {
  .platform-card-bg img{
  width:-webkit-fill-available;
  height:auto;
  }
  .platform-card{
    width: 100%;
    margin-bottom: 20px;
  }
  .platform-heading {
        display: flex;
        justify-content: center;
        font-family: 'Poppins' ;;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: auto;
        width: 100%;
        color: #FBF5FF;
        margin-bottom: 40px;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
  }
  .platform-card-container {
    display: block;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;
    column-gap: 2rem;
  }
  .platform-card-bg {
   border-radius: 11.684px;
   border: 0.974px solid rgba(228, 229, 235, 0.16);
   background: #0A0118;
   mix-blend-mode: exclusion;
    position: relative;
    overflow: hidden;
    width: 100%;
    transition: all 0.3s ease;
     box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    height:auto;
    margin-bottom: 20px;
  }
}


}
.tenx-section{
   background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #0C0610;
   backdrop-filter: blur(25px);
    margin: 20px;
.faster-section{
 background: url(../../../assets/images/bc-highlight.avif) no-repeat;
 background-size: cover;
 background-position: bottom;
display:flex;
justify-content:space-between;
    margin: 0 auto;
    padding: 100px;

.faster-heading{
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
  color: #ececec;
  width:50%;
  padding:20px;
}
.faster-highlight{
  background: var(--linear, linear-gradient(165deg, #AA0AFC 0%, #D5D3F3 54.14%, #007AEB 100%));
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
.faster-content{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  color: #ececec;
  width:50%;
  padding:20px;
}
}
.tenx-card-container {
    background: url(../../../assets/images/tenx-bc.avif) repeat;
    background-size: contain;
    background-position: top left;
  display: flex;
  justify-content: space-between;
   margin: 0 auto;
  padding-left: 100px;
  padding-right:100px;
}

.tenx-card {
  background-color: transparent;
  border-radius: 10px;
  padding: 20px;
  width: calc(33.33% - 20px);
  transition: all 0.3s ease;
  position: relative;
}
.tenx-card  h2{
  font-style: normal;
    font-weight: 900;
    font-size: 50px !important;
    line-height: 1.5;
    color: #AA0AFC !important;
}
.tenx-card  p{
  font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 1.5;
    color: #ececec;
}
.tenx-card p:first-child{
    font-size: 2.5rem !important;
    font-weight: 700;
}

.tenx-card::after {
  content: "";
  position: absolute;
  bottom: 0;
  top:10;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #AA0AFC;
//   transition: width 0.3s ease;
}

.tenx-card:hover::after {
  width: 50%;
}

@media (max-width: 768px) {
  .tenx-card {
    width: 100%;
    margin-bottom: 20px;
        display: grid;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
  }
  .tenx-card::after {
    content: "";
    position: absolute;
    bottom: 0;
    top:10;
    left: 0;
    width: 0;
    height: 3px;
    background-color: #AA0AFC;
  //   transition: width 0.3s ease;
  }
  .tenx-card:hover::after {
    width: 100%;
  }
  .faster-section{
   background: url(../../../assets/images/bc-highlight.avif) no-repeat;
   background-size: cover;
   background-position: bottom;
  display:inline;
  justify-content:space-between;
      margin: 0 auto;
      padding: 100px;

 .faster-heading{
   font-style: normal;
   font-weight: 700;
   font-size: 22px;
   line-height: 1;
   color: #ececec;
   width:100%;
   padding:20px;
 }
 .faster-content{
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 1.5;
   color: #ececec;
   width:100%;
   padding:20px;
 }
 }
 .tenx-card-container {
     background: url(../../../assets/images/tenx-bc.avif) repeat;
     background-size: contain;
     background-position: top left;
   display: inline;
   justify-content: space-between;
    margin: 0 auto;
   padding-left: 100px;
   padding-right:100px;
 }
}
}

.buildK-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 100px;
//   backdrop-filter: brightness(1.5);
  background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #0C0610;
}

.buildK-left-column{
background: transparent;
  border-radius: 10px;
  padding: 20px;
//   width: 60%;
}
.buildK-right-column {
  background: transparent;
  border-radius: 10px;
  padding: 20px;
//   width: 40%;
}

.buildK-left-column h2,
.buildK-right-column h2 {
  color: #FBF5FF;
  text-align: center;
  font-family: 'Poppins' ;;
  font-size: 36px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
}
.buildK-left-column p{
  color: #FBF5FF;
  text-align: start;
  font-family: 'Poppins' ;;
  font-size: 16px !important;
  font-style: normal;
}
.buildK-highlight {
    background: linear-gradient(159deg,#D5D3F3 54.14%, #007AEB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
.buildK-text-card {
  border-radius: 6.912px;
  background: rgba(255, 255, 255, 0.09);
  border: 1px solid blueviolet;
//   .gradient-border();
  color:white;
  padding: 10px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 20px auto;
  transition: all 0.3s ease;

}
.buildK-copy-btn{
 font-family: Inter,sans-serif;
 display:inline-flex;
 align-items:center;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 20px;
         padding: 10px 26px 12px;
         background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
         border-radius: 4px;
         color: white;
         .gradient-border();
}
.buildK-view-btn{
 font-family: Inter,sans-serif;
 display:inline-flex;
 align-items:center;
         font-style: normal;
         font-weight: 500;
         font-size: 17px;
         line-height: 18px;
         padding: 10px 26px 12px;
         background: transparent;
         margin-bottom:10px;
         border-radius: 0.875rem;
         border:1px solid white;
         color: white;

}
.buildK-pic-card-left{
  background: transparent;
  .gradient-border();
  box-shadow:0 7px 19px rgb(11 4 46);
  border-radius: 10px;
  padding: 20px;
  width:100%;
  height:400px;
}
.buildK-pic-card-right{
  background: transparent;
  .gradient-border();
  box-shadow:0 7px 19px rgb(11 4 46);
  border-radius: 10px;
  padding: 20px;
  width:100%;
  height:440px;
}
.buildk-docs-button{
display:flex;
gap:1rem;
}
@media (max-width: 768px) {
  .buildK-container {
    flex-direction: column;
    padding:0px;
  }
   .buildK-view-btn{
   font-family: Inter,sans-serif;
       display: inline-flex;
       align-items: center;
       font-style: normal;
       font-weight: 500;
       font-size: 17px;
       line-height: 18px;
       padding: 8px;
       width: 100%;
       background: transparent;
       margin-bottom: 10px;
       border-radius: .875rem;
       border: 1px solid white;
       color: white;
       text-align: center !important;
       flex-direction: row;
       flex-wrap: wrap;
       align-content: center;
       justify-content: center;
   }
  .buildK-left-column,
  .buildK-right-column {
    width: 100%;
    margin-bottom: 20px;
  }
  .buildK-left-column h2,
  .buildK-right-column h2 {
    color: #FBF5FF;
    text-align: center;
    font-family: 'Poppins' ;;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
//     line-height: 52px;
  }
  .buildk-docs-button{
  display: flex;
      gap: 1rem;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
  }
  .buildK-text-card {
     border-radius: 6.912px;
     background: rgba(255, 255, 255, 0.09);
     border: 2px solid transparent;
     .gradient-border();
     color:white;
     padding: 10px;
     display: inline-flex;
     justify-content: space-between;
     align-items: center;
     width: 100%;
     margin: 20px auto;
     transition: all 0.3s ease;

   }
}
.plan-bottom{

background: url(../../../assets/images/plan-bottom.avif) no-repeat;
      background-size: cover;
      background-position: center;
        border-radius: 10px;
        width: 100%;
        height:140px;

}
.workswithK-container{

.workswithK-heading{
 display:flex;
 justify-content:center;
}
.workswithK-heading h2{
  color: #FBF5FF;
  font-family: 'Poppins' ;;
  font-size: 34px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.workswithK-content{
display:flex;
 justify-content:center;
  font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.5;
      color: #ececec;
}
.workswithK-highlight{
  background: linear-gradient(162deg, #AA0AFC 0%, #D5D3F3 54.14%, #007AEB 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
}
.workswithK-card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 100px;

}

.workswithK-card {
  background: transparent;
  border-radius: 10px;
  padding: 20px;
  width: calc(25% - 20px);
  border:1px solid #3331D2;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
   background: url(../../../assets/images/tenx-bc.avif) no-repeat;
   background-size: cover;

}

.workswithK-card img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.workswithK-card h3 {
  margin: 0;
  font-size: 1.2em;
  color: #fff;
  text-align:start;
}

.workswithK-card-img{
border-radius: 6px;
border: 1px solid #000;
background: #262647;
  padding: 20px;
  width: 100%;
  height:200px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.workswithK-card h5{
margin-top:30px;
color:#fff;
font-size: 15px;
text-align:start;
display:flex;
}


@media (max-width: 768px) {
  .workswithK-card {
    width: calc(50% - 20px);
  }
}


.build-App-container {
background: url(../../../assets/images/build-app.avif) no-repeat;
   background-size: contain;
   background-position:right;
  display: flex;
  align-items: center;
  margin: 30px;
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: brightness(5);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.build-App-left-card {
  flex: 1;
  padding: 20px;
}

.build-App-left-card h3 {
  margin-top: 0;
}

.build-App-right-card {

  flex: 1;
  padding: 20px;
  text-align: center;
}

.build-App-right-card img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
.build-App-right-card-Img{
border-radius: 17px;
background: #21102E;
  padding: 20px;
  width: 100%;
  height:400px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}
@media (max-width: 768px) {
.build-App-container {
background: url(../../../assets/images/build-app.avif) no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    align-items: center;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    -webkit-backdrop-filter: brightness(5);
    backdrop-filter: brightness(5);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    }
   .build-App-right-card {
     display:none;
     flex: 1;
     padding: 0px;
     text-align: center;
     width:100%;
   }
   .build-App-left-card {
     flex: 1;
     padding: 0px;
   }
   .build-App-right-card-Img {
       border-radius: 17px;
       background: #21102E;
       width: 100%;
       margin-bottom: 20px;
       padding: 0px !important;
       margin-top:30px;
       transition: all 0.3s ease;
       box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
       text-align: center;
   }
}
@media (max-width: 1200px) {
.build-App-right-card-Img{
border-radius: 17px;
background: #21102E;
  padding: 20px;
  width: 100%;
  height:400px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}
}



.startK-container {
  margin-top:100px;
  padding :20px;

.startK-highlight{
 background: #AA0AFC;
 background-clip: text;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
}
.startK-heading{
 display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

}
 .startK-heading  h2{
       font-family: 'Poppins' ;;
       font-size: 34px !important;
       font-style: normal;
       font-weight: 700;
       line-height: normal;
       color: #FBF5FF;
       }
.startK-content{
 display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
color: #EFEFEF;
font-family: 'Poppins' ;;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px;
}
.startK-card-container {
  display: flex;
  justify-content: space-between;
  flex-direction:row;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding: 20px;
      gap: 2rem;

}
.startK-card{
width:100%;
}
.startK-card-left {
 border-radius: 12px;
 background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #0C0610;
  width: 100%;
  height:450px;
  position:sticky;
  border:none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
   overflow: hidden;

}
.startK-card-right {
  background: transparent;
  border-radius: 10px;
  width: 60%;
  padding:10px;
  border: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;

}
.startK-card-left-img{
 border-radius: 6px;
//   margin:70px auto;
  transition: all 0.3s ease;
  text-align: center;
}
.startK-card-left-img img{
 min-height: 500px;
 width:100%;
}

.walletImg{
display:flex;
}
.startK-card-left-wallet1-img{
  border-radius: 10px;
//   margin:70px auto;
  transition: all 0.3s ease;
  text-align: center;
}
.startK-card-left-wallet1-img img{
height:400px;
width:400px
}
.startK-card-left-wallet2-img{
  border-radius: 10px;
//   margin:70px auto;
  transition: all 0.3s ease;
  text-align: center;
}
.startK-card-left-wallet2-img img{
height:400px;
width:400px
}
.view_doc{
color:#fff;
display:inline-flex;
justify-content:center;
align-items:center;
font-size: 18px;
text-decoration: underline;
font-weight:500;
margin-left: 60px;
}
hr {
  background: rgba(170, 9, 251, 0.14);
}
@media (max-width: 767px) {
 .startK-heading  h2{
  font-size: 34px !important;
  }
  .startK-card-container {
    padding: 0px;
 }
 .startK-card-container {
   display: flex;
   justify-content: space-between;
   flex-direction:column;
   flex-wrap: nowrap;
   margin: 0 auto;
   padding: 20px;
       gap: 2rem;

 }
 .startK-card-left {
  height:auto;
 }
 .startK-card-left-img img{
  min-height: 150px;
  width:100%;
 }
 .startK-card-right {
   width: 100%;
 }
}
@media (max-width: 1200px) {
 .startK-heading  h2{
  font-size: 24px !important;
  }
  .startK-card-container {
    padding: 0px !important;
 }
}
}


.get_Involved-section{
margin:30px;
}
.get_Involved-container{
display:flex;
gap:2rem;
background: linear-gradient(to bottom right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(158, 115, 255, 0.13) 0%, rgba(8, 3, 18, 0.00) 50%) top right / 50% 50% no-repeat, #0C0610;
// backdrop-filter: blur(25px);

}
.get_Involved-heading h2{
 font-size: 34px !important;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
 color: #7508be !important;
}
.get_Involved-heading p{
 font-size: 18px !important;
 font-style: normal;
 font-weight: 500;
 color: #FBF5FF;
}
.get_Involved-cards{
display:flex;
gap:2rem;
}
.card-getinvolved{
border-radius: 10px;
background: #262647;
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.card-getinvolved:hover{
  border: 1px solid #b6b5c7;
}
.card-getinvolved h5{
 font-size: 16px !important;
 font-style: normal;
 font-weight: 500;
 line-height: normal;
 color: #FBF5FF;
}
.card-getinvolved .logos{
 margin-top:30px;
 display:flex;
 gap:1rem;
}
@media (max-width: 767px) {
 .get_Involved-section {
 padding: 0px;
 margin:0px;
 }
}
@media (max-width: 1200px) {
 .get_Involved-section{
 padding: 20px;
 }
}
}

.footerText{
  font-size: 18px;
  color: #fff;
  text-align: left;
  line-height: 1.5;
  margin-top: 24px;
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;