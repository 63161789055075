@import "../../styleConstant.less";

.card-section-container {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins' ;
  width: 100%;
  height: 110px;
  // border: 1px solid rgba(173, 163, 163, 0.14);
  box-shadow: 0px 3.53712px 8.8428px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 20px;
  /* background: linear-gradient(0deg, #1b1b1b, #1b1b1b),
    linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); */

  .icon-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 10px;
    padding-top:0px;
  }

  .count-container {
    font-family: 'Poppins';
      font-size: 41px;
      font-weight: 700;
      line-height: 35px;
      text-align: left;
    color: #FFFFFF;
    margin-left: 20px;
  }

  .text-container {
    font-family: 'Poppins';
     font-size: 20px;
     font-weight: 400;
     line-height: 35px;
     text-align: left;
     color: #FFFFFF;
     margin-left: 20px;
  }
  .text-container-grad {
    font-family: 'Poppins';
     font-size: 20px;
     font-weight: 400;
     line-height: 35px;
     text-align: left;
     color: #FFFFFF;
  }
}
.radial-gradient-card1{
  background: linear-gradient(#000, #000) padding-box,
              linear-gradient(to right, #A219BC , #E68CF7) border-box;
  border: 3px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.radial-gradient-card2{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D31C7A80;
}
.radial-gradient-card3{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FA522B80;
}
.radial-gradient-card4{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5453DF80;

}

.radial-gradient-purple {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 70px;
    height: 70px;
    right: -20px;
    bottom: -20px;
    background: #9359EF;
    filter: blur(50px);
  }
}
.radial-gradient-purple1 {
  border-radius: 10px 10px 10px 10px;
  // opacity: 0px;
  background: #9359EF;
}

.radial-gradient-pink1 {
  border-radius: 10px 10px 10px 10px;
  // opacity: 0px;
  background: #D31C7A80;
}

.radial-gradient-orange1 {
   border-radius: 10px 10px 10px 10px;
  //  opacity: 0px;
   background: #F81F49;
}

.radial-gradient-blue1 {
   border-radius: 10px 10px 10px 10px;
  //  opacity: 0px;
   background: #4C59E3;
}

.radial-gradient-orange {
   border-radius: 10px 10px 10px 10px;
  //  opacity: 0px;
   background: linear-gradient(95.59deg, #000000 17.37%, #F81F49 100.78%);
}

.radial-gradient-blue {
   border-radius: 10px 10px 10px 10px;
  //  opacity: 0px;
   background: linear-gradient(95.42deg, #000000 0%, #4C59E3 98.27%);
}

.radial-gradient-purple {
   border-radius: 10px 10px 10px 10px;
  //  opacity: 0px;
   background: linear-gradient(96.04deg, #000000 0.92%, #A219BC 127.16%);
}

.radial-gradient-thickblue {
   border-radius: 10px 10px 10px 10px;
  //  opacity: 0px;
   background: linear-gradient(96.45deg, #000000 0%, #703ED3 118.45%);
}

.radial-gradient-green {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 70px;
    height: 70px;
    right: -20px;
    bottom: -20px;
    background: #00A441;
    filter: blur(50px);
  }
}

.radial-gradient-blue {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 70px;
    height: 70px;
    right: -20px;
    bottom: -20px;
    background: #4FA2FF;
    filter: blur(50px);
  }
}

.card-section-container2 {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins' ;
  width: 100%;
  height: 250px;
  border: 1px solid rgba(173, 163, 163, 0.14);
  box-shadow: 0px 3.53712px 8.8428px rgb(0 0 0 / 10%);
  border-radius: 0px;
  padding: 20px;
  border-top: 3px solid;
  border-image: linear-gradient(to right,#b447eb,#2797ff) 1 0 0 0;
  background: linear-gradient(0deg, #1b1b1b, #1b1b1b),
    linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));

  .icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .count-container {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 38px;
    line-height: 50px;
    color: #ececec;
  }

  .text-container {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    color: #FFB21D;
  }
  .card-text-body{
    font-family: 'Poppins';
      font-weight: 500;
      margin-top:10px;
      margin-bottom:10px;
      font-size: 14px;
      color: #e3e3e3;
      overflow: auto;
  }
  .card-text-bottom{
    position: absolute;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.chart-box-container2{
    display: flex;
    column-gap: 10px;
}
.card-section-container3 {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins' ;
  width: 25%;
  height: 70px;
  border: 1px solid rgba(173, 163, 163, 0.14);
  box-shadow: 0px 3.53712px 8.8428px rgb(0 0 0 / 10%);
  border-radius: 0px;
  padding: 20px;
  /* background: linear-gradient(0deg, #1b1b1b, #1b1b1b),
    linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); */

  .icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .count-container {
    font-weight: 400;
    font-size: 38px;
    line-height: 50px;
    color: #ececec;
  }

  .text-container {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    word-wrap: break-word!important;
    color: white;
    overflow: hidden;
  }
  .card-text-body{
      font-weight: 500;
      margin-top:10px;
      font-size: 14px;
      color: #e3e3e3;
  }
  .card-text-bottom{
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
  }
}
.endpoint-card-section-container3 {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins' ;
  width: 25%;
  height: 70px;
  border: 1px solid rgba(173, 163, 163, 0.14);
  box-shadow: 0px 3.53712px 8.8428px rgb(0 0 0 / 10%);
  border-radius: 0px;
  padding: 10px;
  background: linear-gradient(0deg, #1b1b1b, #1b1b1b),
    linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));

  .icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .count-container {
    font-weight: 400;
    font-size: 38px;
    line-height: 50px;
    color: #ececec;
  }

  .text-container {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    word-wrap: break-word!important;
    color: white;
    overflow: hidden;
  }
  .card-text-body{
      font-weight: 500;
      margin-top:10px;
      font-size: 14px;
      color: #e3e3e3;
  }
  .card-text-bottom{
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
  }
}
.box-parent {
    display: flex;
    column-gap:10px;
    padding: 30px;
    border: 1px solid rgba(173, 163, 163, 0.14);
    box-shadow: 0px 3.53712px 8.8428px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 20px;
    background: linear-gradient(0deg, #1b1b1b, #1b1b1b), linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));

}
.box-parent-bc{
    background: #696969;
}
.box-parent .gradient-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 30%;
    height: 70px;
    box-sizing: border-box;
    color: white;
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    border-radius: 6px;
    border: 0;
    cursor: pointer;
    }

.box-parent .gradient-a {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    padding: 10px;
}
.box-parent {
              position: relative;
              overflow: hidden;
              &::after {
                content: "";
                position: absolute;
                width: 70px;
                height: 70px;
                right: 0px;
                bottom: 40px;
                background: #9359EF;
                filter: blur(50px);
              }
            }

.account-info-container {
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 1.76599px 27.3729px rgb(0 0 0 / 8%);
    border-radius: 8px;
    height: 318px;
}



@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;