@import "~antd/dist/antd.less";
@import "./component/styleConstant.less";
@import '~antd/dist/antd.css';

@font-face {
  font-family: 'Poppins';
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
  font-display: swap;
}
body {
  font-family: 'Poppins';
  overflow-x:hidden;
}

/* width */
*::-webkit-scrollbar {
  width: 5px;
  display:none;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 5px;
}

.marginBtm50 {
  margin-bottom: 50px;
}
.marginBtm40 {
    margin-bottom: 40px;
}

.marginBtm30 {
  margin-bottom: 30px;
}

.marginBtm20 {
  margin-bottom: 20px;
}
.paddingBtm15 {
  padding-bottom: 15px;
}
.marginBtm15 {
  margin-bottom: 15px;
}

button {
  cursor: pointer;
}

.ant-modal-content {
  background-color: transparent !important;
}

.popup-request-container {
  background: linear-gradient(0deg, #1c1b1c, #1c1b1c),
    linear-gradient(0deg, #1e1e1e, #1e1e1e);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;

  .close-icon {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 25px;
    svg {
      cursor: pointer;
    }
  }

  .popup-text {
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #ede6f5;
  }

  .ant-upload-list {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-section {
    display: flex;
    align-items: center;
    justify-content: center;

    .ok-btn {
      width: 150px;
      height: 47px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;

      cursor: pointer;
      .button-background();
      border-color: black;
      &:focus {
        outline: none;
      }
    }

    .disabled-btn {
      cursor: not-allowed;
    }

    .cancel-btn {
      width: 131px;
      height: 47px;
      background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
      border: 1px solid #403f3f;
      border-radius: 7.94305px;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #c4c4c4;
      cursor: pointer;
      margin-right: 25px;
    }
  }
}
.login-loader-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

.login-loader {
  width: 50px;
  height: 50px;
  border: 5px solid #976EF9;
  border-radius: 50%;
  border-top: 5px solid #f3f3f3;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.login-loading-text {
  margin-top: 10px;
  color: #976EF9;
  font-size: 16px;
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;