.loginpage{
.container {
  background-color: #020315;
  max-width: 1250px;
}

.form-section {
  background: #010321 url(../images/loginright-bg.avif) top left repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  opacity: 1;
  position: relative;
  background-position: right;
}
.form-section .details {
  z-index: 999;
}
.form-section::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/login-top-elips.avif) no-repeat;
  background-position: center;
  background-size: contain;
  width: 244px;
  height: 261px;
}
.form-section::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #010321;
  opacity: 0.7;
}
.login-inner-form {
  position: relative;
}
.login-12 .bg-img {
  background: url(../images/loginbg-left.avif) top left repeat;
  background-size: cover;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  text-align: right;
  z-index: 999;
  opacity: 1;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  flex-wrap: wrap;
  text-align: center;
  padding: 60px 75px;
}
.logo-2 {
  padding-bottom: 90px;
  z-index: 11;
  position: relative;
}
.login-heading {
  padding-bottom: 90px;
  z-index: 11;
  position: relative;
  max-width: 505px;
  margin-left: auto;
  margin-right: auto;
}
.logo-2,
.informeson,
.social-box {
  width: 100%;
  display: block;
  position: relative;
  z-index: 10;
}
.logo-2 a img {
  display: block;
  text-align: left;
  object-fit: contain;

}
.login-12 .bg-img:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #010c39;
  opacity: 0.6;
}
.social-list {
  text-align: left;
}
.social-list li {
  list-style: none;
  display: inline-block;
  padding-right: 15px;
}
.login-heading h2 {
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 50px;
  text-align: center;
  color: #d8d8d8;
}
.login-inner-form .introform-text {
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: rgba(254, 254, 254, 0.5);
}

.banner-highlight {
  background: linear-gradient(90deg, #B447EB 0%, #2797FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.social-list li:last-child {
  padding-right: 0 !important;
}
.login-12 .form-section {
  min-height: 100vh;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 165px 140px 180px;
}
.loginbutton-group .login-btn {
  display: block;
  background-color: #20cd89 !important;
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  border-color: #20cd89;
  padding: 17px 26px 16px;
  margin-bottom: 30px;
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  height: 48px;
  color: #20223c;
  width:100%;
  div{
    background-color: #20cd89 !important;
  }
}
.loginbutton-group .outline-btn {
  background: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  display: block;
  border-radius: 6px;
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #d8d8d8;
  padding: 17px 26px 16px;
  margin-bottom: 26px;
  width: 100%;
}
.loginbutton-group .login-btn:hover {
  color: #20223c;
}
.accessapi-image img {
  max-width: 100%;
  height: auto;
}
.footer-btn-sec {
  margin: 0 !important;
}

.footer-btn-sec .account-btn {
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  padding-right: 5px;
  color: rgba(254, 254, 254, 0.6);
}
.footer-btn-sec .signup-link {
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  text-align: center;

  color: #20cd89;
}
a:hover {
  text-decoration: none;
}
/* {
  background: #011041;
}
{
  background: #010321;
} */

.details h3 {
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #d9d9d9;
  margin: 0;
  margin-bottom: 22px;
}
.details p {
  font-family: 'Poppins' ;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: rgba(254, 254, 254, 0.5);
  margin: 0;
  margin-bottom: 40px;
}

@media (max-width: 1190px) {
  .login-12 .bg-img {
    padding: 60px 35px;
  }
  .login-heading h2 {
    font-size: 32px;
    line-height: 48px;
  }
  .login-12 .form-section {
    padding: 165px 70px 180px;
  }
}

@media (max-width: 767px) {
  .login-12 .form-section {
    padding: 165px 40px 180px;
  }
  .login-heading {
    padding-bottom: 100px;
    max-width: 430px;
  }
  .login-12 .bg-img {
    padding: 60px 35px;
    height: 100% !important;
    min-height: 100% !important;
  }
  .logo-2 {
    padding-bottom: 80px;
  }
  .login-12 .form-section {
    padding: 100px 70px 80px;
    height: 100%;
    min-height: 100%;
  }
}

@media (max-width: 375px) {
  .login-12 .form-section {
    padding: 100px 35px 80px;
  }
  .details h3 {
    font-size: 30px;
    line-height: 36px;
  }
}
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;