@import "../../styleConstant.less";

.projectConfigWrapper {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.5px solid rgba(32, 205, 137, 0.5);
    border-image-slice: 1;
    min-height: 50px;
    border-radius: 10px;
    background-color: transparent;
  }
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color:#e6f7ff !important;
  color: #000 !important;
}
.ant-select-dropdown {
  background-color: white !important;
  color: #000 !important;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: transparent;
  color: white;
}
.anticon {
  color: #e2e2e2 !important;
}
.desc-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: rgba(240, 240, 240, 0.26);
}
.ant-select-multiple .ant-select-selection-item {
  border: none !important;
  background-color: transparent !important;
  color: white !important;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;