@import "../../styleConstant.less";

.table-section-container {
  .ant-table table {
    border-collapse: collapse;
    border-spacing: revert;
  }
  .ant-table {
    background: #0e114e;
    /* font-family: @font-family; */
    font-family:'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    width:auto;
    color: #d6d6d6;
    border: none;
//     .gradient-border();

    box-shadow: 0px 1.76724px 27.3922px rgba(0, 0, 0, 0.08);
    // border-radius: 8px;
    // padding-top: 5px;
       .ant-table-tbody > tr.ant-table-row>td>a {
           font-size: 14px !important;

         }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: unset;
    }

    .ant-table-tbody > tr.ant-table-row > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: unset;
      /* font-family: @font-family; */
      font-family:'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
      align-items: center;
      color: #f8f2ff;
    }

    .ant-table-cell-scrollbar:not([rowspan]) {
      box-shadow: none;
    }


    .ant-table-tbody {
//       background: #151617;
//       box-shadow: 0px 1.76724px 27.3922px rgba(0, 0, 0, 0.08);
//       border-radius: 0px 0px 8px 8px;
      border-image-source: linear-gradient(180deg, #000000 0%, rgba(102, 102, 102, 0.5) 100%);
      background: #434343;
    }

    .ant-table-tbody > tr > td {
      border-bottom: 0.992881px solid rgba(255, 255, 255, 0.12);
       overflow: hidden;
                   text-overflow: ellipsis;
                   white-space: nowrap;
                   max-width: 65px !important;
    }


    .ant-table-tbody > tr:last-child > td {
      border-bottom: none;
    }
    .anticon {
    font-size:17px;
    }

    
   /*  .ant-table-thead {
      height: 72px;
    } */

    .ant-table-thead > tr > th {
      border-bottom: none;
      background: #292929 !important;
      box-shadow: 0px 1.76724px 27.3922px rgb(0 0 0 / 8%);
      /* font-family: @font-family; */
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 14px;
      align-items: center;
      color: #fff !important; 
      // opacity: 0px;
      height: 65px;
    }
  }
  @media (max-width: 1200px) {

   .ant-table {
       font-size: 14px;
        background: #96C291;
        color: #d6d6d6;
      }

  }
  @media (max-width: 767px) {

  .ant-table {
       font-size: 14px;
       background: #FFB7B7;
       color: #d6d6d6;
      }
  }
  @media (min-width: 820px) {

  .ant-table {
       font-size: 14px;
       color: #FFDBAA;
       background: #000;
       width:auto;
       overflow-x:auto;
       overflow-y:scroll;
      }
  }
}

.ant-table-cell{
  text-align: left !important;
  
  
}






@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;