@import "../../styleConstant.less";

.dashboard-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .name-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name-section {
      display: flex;
      flex-direction: column;

      .name {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        color: #d9d9d9;
      }

      .sub-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #d8d9ff;
        padding-top: 5px;
      }
    }

    .create-project-section {
      .create-project-btn {
        width: 150px;
        height: 40px;
        background-color: @success-color;
        border-radius: 7.94305px;
        font-size: 14px;
        font-weight: 600;
        color: #090a2c;
      }
    }
  }

  .card-container1 {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);

    .card-section-container {
      min-width: calc(100% / 3);
    }
  }
  .grid-repeat-container1 {
      grid-template-columns: repeat(3, 1fr);
    }

  .container-text {
    display: inline-flex;
    align-items: center;

    .text {
      font-weight: 600;
      font-size: 20px;
      color: #fff;
      opacity: 0.8;
      padding-right: 10px;
    }
  }

  .active-tag,
  .inactive-tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0px 11px;
    background: rgba(53, 176, 139, 0.1);
    border-radius: 51.4121px;
  }

  .active-tag {
    border: @border;
  }

  .inactive-tag {
    color: #646464;
    border: 1.02824px solid #646464;
  }

  .table-cell-data {
    display: inline-flex;
    align-items: center;

    .total-count {
      color: @success-color;
      padding-left: 5px;
    }
  }

  .ant-table {
    .ant-table-container {
      .ant-table-tbody {
        .ant-table-cell {
          z-index: 1;
          font-weight: 400 !important;
          font-size: 20px !important;
          line-height: 35px;
          color: white;
        }
      }
    }
  }

  .api-key {
    cursor: pointer;
  }

  .row-chart {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 70px;
    height: 70px;
    right: 0;
    top: 248px;
    background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
    filter: blur(50px);
  }

  .dashboard-container-service {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 0rem;
  }

  .dashboard-services {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 28px;
    gap: 10px;
    left: -0.57%;
    right: 85.27%;
    top: 0%;
    bottom: 0%;
    .button-background();
    &:focus {
      outline: none;
    }
  }

  .dashboard-services > span {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #f2ecfb;
  }

  .dashboard-services-border > span {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #f2ecfb;
  }

  .dashboard-services-border {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 28px;
    gap: 10px;

    color: white;

    left: 83.94%;
    right: -4.37%;
    top: 0%;
    bottom: 0%;

    background: #0c0c0c;
    box-shadow: 0px 1.76599px 27.3729px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    .gradient-border();
    &:focus {
      outline: none;
    }
  }
}

.dashboard-container .dashboard-services-border {
  position: unset;
}

.dashboard-container .dashboard-services-border::before {
  background: unset;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 5px !important;
}

.tooltip {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
  opacity: 1 !important;
  position: relative !important;

  .tooltip-text {
    padding-right: 5px;
  }
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: transparent !important;
}
.marginTop10px {
    margin-top: 12px;
}
.gradient-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 100px;
    height: 47px;
    box-sizing: border-box;
    margin-left: 5px;
    .button-background();
    &:focus {
      outline: none;
    }
  }

   .nft-collection-popup1 {
     color: white;
     .ant-upload.ant-upload-drag {
       background: linear-gradient(0deg, #1c1b1c, #1c1b1c), #1e1e1e;
       border: 1.65582px dashed #464646;
       border-radius: 8px;
       max-width: 750px;
     }

     .ant-upload-drag-container {
       display: flex !important;
       justify-content: space-evenly;
       align-items: center;
       align-content: center;
     }

     .nft-upload-header {
       font-family: 'Poppins' ;
       font-style: normal;
       font-weight: 500;
       font-size: 16px;
       line-height: 20px;
       color: #ffffff;
     }

     .nft-upload-browse {
       box-sizing: border-box;
       display: inline-flex;
       align-items: center;
       justify-content: center;
       width: 123px;
       height: 40px;
       border-radius: 8px;
       font-size: 14px;
       background-color: transparent;
       color: white;
       .gradient-border();

       &:focus {
         outline: none;
       }
     }

     .nft-upload-limmiter {
       font-family: 'Poppins' ;
       font-style: normal;
       font-weight: 600;
       font-size: 11px;
       line-height: 13px;
       color: rgba(255, 255, 255, 0.2);
     }
   }
.save-changes-btn1 {
  z-index: 1;
  position: inherit;
  background: transparent;
  font-weight: 500;
  font-size: 12px;
//   line-height: 25px;
  color: white;
//   padding: 0.31rem 1rem;
  border: 1.02824px solid #35b08b;
  border-radius: 4.4121px;
}

.nft-collection1 {
  .nft-collection-api1 {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }
  p {
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #d8d9ff;
    margin: 0;
    margin-top: 5px;
  }
.nft-collection-api-key1 {
    width: 7rem;
  }
  .nft-minting1 {
    width: 220px !important;
  }
}
.studio-header-toi1 {
  display: flex;
  align-items: center;
  justify-content: end;

  .gradient-button {
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #090a2c;
    width: 150px;
    height: 47px;
    box-sizing: border-box;
    .button-background();
    &:focus {
      outline: none;
    }
  }
}
.ant-progress-text{
    color: rgb(232, 232, 232) !important;
  }
.ant-upload-list-item-info {
background: transparent !important;
}

.storage-manager-header-section{
display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    .header-section {
          display: inline-flex;
          align-items: center !important;
          flex-direction:row !important;
          gap:1rem;
          }
}

.storage-button{
display:flex;
justify-content:center;
justify-items:center;
align-items:center;
gap:5px;
}

@media (max-width: 1200px) {
   .storage-button{
   display:grid;
   justify-content:center;
   justify-items:center;
   align-items:center;
   }

   }
   .unpin-icon {
     position: relative;
   }

   .unpin-icon::after {
     content:"\\";
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-40%,-40%);
     width: 100%;
     height: 1px;
   }

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;