@import "../../styleConstant.less";

.help-page-container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  .need-some-help-section {
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .gradient-border();

    .need-some-help-text {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #d9d9d9;
    }

    .contact-us-btn {
      width: 141px;
      height: 47px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #090a2c;
	//  background-color: #2797ff; 
    //  background: @success-color;
	background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
	color: #fff !important;
	border: unset;
      border-radius: 6px;
    }
  }

  .header-text {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #d9d9d9;
    padding-bottom: 30px;
    margin-top: 50px;
  }

  .join-our-community-container {
    display: flex;
    flex-direction: column;

    .join-community-section {
      display: grid;
      grid-template-columns: repeat(4, minmax(250px, 1fr));
      grid-gap: 40px;

      .community-card-section {
        padding: 20px;
        display: flex;
        flex-direction: column;
        background: #0d1164;
        box-shadow: 0px 3.53712px 8.8428px rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        .icon {
          display: flex;
          justify-content: flex-end;

          svg {
            width: 35px;
            height: 35px;
            fill: #e2e2e2;
          }
        }

        .label {
          padding: 20px 0px;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #ececec;
        }

        .description {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: rgba(227, 227, 227, 0.5);
        }
      }
    }
  }

  .faq-container {
    margin-top: 30px;

    .faq-header {
      display: flex;
      justify-content: space-between;

      .search-box-container {
        position: relative;
        display: inline-flex;
        align-items: center;

        .anticon {
          position: absolute;
          left: 8px;
          color: #d9d9d9;
          font-size: 16px;
        }

        .input-box {
          width: 160px;
          height: 42px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 8px;
          background-color: transparent;
          color: #fff;
          padding-left: 35px;
        }
      }
    }

    .faq-details {
      .ant-collapse {
        color: #e2e2e2;
        background-color: transparent;
        border: none;

        .ant-collapse-item {

          border-bottom: none;

          .ant-collapse-header {
            .gradient-border();
          }

          border-radius: 6px;
          margin-bottom: 20px;

          .ant-collapse-header {
            padding: 20px;
            color: #e2e2e2;
          }

          .ant-collapse-content {
            padding: 20px 0px;
            color: #e2e2e2;
            background-color: transparent;
            border-top: @border;

            .ant-collapse-content-box {
              padding: 10px 16px;
              white-space: pre;
            }
          }
        }
      }
    }
  }
}

.userLock {
  background-color: #020315;
  height: 100vh;
  display: grid;
  align-content: space-evenly;
  justify-items: center;
  text-align: center;
  h1 {
    max-width: 1000px;
    color: white;
  }
  button {
    width: 300px;
    padding: 20px;
    border-radius: 10px;
  }
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;