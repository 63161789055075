@import "../../styleConstant.less";

.my-account-page-container {
  .ant-tabs {
    .ant-tabs-nav::before {
      border-bottom: none !important;
    }

    .ant-tabs-tab {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 18px;
      line-height: 10px;
      color: #fff;
      opacity: 0.8;
      background: transparent;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: 2px solid @success-color !important;
      z-index: 1;
    }

    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:focus,
    .ant-tabs-tab-remove:active {
      color: rgba(239, 239, 239, 0.4);
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab-btn:active {
      color: #efefef;
    }
  }

  .profile-section-container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 20px;

    .header-text {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #e2e2e2;
    }

    .profile-section {
      display: flex;
      flex-direction: column;
      /* width: 650px; */
      width: 50%;
      background: #151617;
      border: 1px solid #464646;
      box-shadow: 0px 3.49493px 8.73734px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
      .row-data {
        display: flex;
        align-items: center;
        margin-bottom: 35px;
        margin-top: 20px;

        .user-icon-container {
          .ant-avatar.ant-avatar-icon {
            font-family: 'Poppins';
            font-size: 50px;
            width: 70px;
            height: 70px;
            line-height: 48px;
          }
        }
        .anticon.anticon-edit {
          font-family: 'Poppins';
          font-size: 30px;
          svg {
            width: 25px;
            height: 25px;
            fill: #e2e2e2;
          }
        }
      }
      .name-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* width: 650px; */
      }

      .fieldset-container {
        /* max-width: 650px; */
      }

      .btn-container {
        display: flex;
        align-items: center;
        .save-changes-btn {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 14px;
          width: 200px;
          box-sizing: border-box;
          padding: 10px;
          border-radius: 24px;
          color: white;
          background: transparent;
          border-image-source: linear-gradient(
            90deg,
            #b447eb -4.52%,
            #2797ff 120.27%
          );
          border-image-slice: 7;

          &:focus {
            outline: none;
            outline: none;
          }
        }

        .cancel-changes-btn {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 14px;
          width: 200px;
          box-sizing: border-box;
          padding: 10px;
          background: transparent;
          border-radius: 3.49493px;
          color: #c4c4c4;
          border: @border;
          margin-left: 20px;
        }
      }
    }

    .wallet-section {
      padding-left: 20px;
      /* width: calc(100% - 650px); */
      width: 50%;

      .link-wallet-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        margin-top: 20px;
        border-radius: 8px;
        border: 1px solid #464646;

        .link-wallet-section {
          display: flex;
          flex-direction: column;
          .link-wallet {
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: rgba(215, 215, 215, 0.5);
          }

          .wallet-info {
            .wallet-name {
              font-family: 'Poppins';
              display: flex;
              align-items: center;
              color: white;
              font-size: 15px;
              .wallet-name {
                font-family: 'Poppins';
                padding-left: 10px;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                color: #b9b9b9;
              }
            }
            .wallet-key {
              font-family: 'Poppins';
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              color: #b9b9b9;
            }

            .copy-text-section {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: 0.5rem;
              cursor: pointer;
              .copy-text {
                font-family: 'Poppins';
                padding-left: 10px;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #7b7c88;
              }
            }
          }
        }

        .link-wallet-btn {
          .link-btn,
          .unlink-btn {
            .button-background();
            font-family: 'Poppins';
            border-radius: 6px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            width: 100px;
            height: 35px;
            text-align: center;
          }

          .link-btn {
            background-color: @success-color;
          }

          .unlink-btn {
            color: #c7c7c7;
            background-color: transparent;
            border: @border;
          }
        }
      }
    }
  }

  .plan-billing-container {
    padding-top: 1.5rem;
    .label-text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #efefef;
    }

    .plan-section {
      display: grid;
      grid-template-columns: repeat(4, minmax(300px, 1fr));
      grid-gap: 40px;
      margin: 40px 0px;

      .active-plan {
        display: flex;
        align-items: center;
        width: 300px;

        .tick-text {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 14px;
          padding-left: 10px;
          color: @success-color;
        }
      }
    }

    .billing-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* margin-bottom: 40px; */

      .search-box-container {
        position: relative;
        display: inline-flex;
        align-items: center;

        .anticon {
          font-family: 'Poppins';
          position: absolute;
          left: 20px;
          color: #d9d9d9;
          font-size: 16px;
        }

        .input-box {
          font-family: 'Poppins';
          width: 160px;
          height: 42px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 8px;
          background-color: transparent;
          color: #fff;
          padding-left: 35px;
        }
      }
    }

    .table-section-container {
      margin-bottom: 30px;
    }
  }
}

.selectImage{
  color: blue;
  text-decoration: underline;
}
.selectImage:hover{
  font-weight: bold;
  color: blue;
  text-decoration: underline;
}

.plan-card-section {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 300px;
  filter: drop-shadow(0px 3.2343px 9.70289px rgba(0, 2, 76, 0.1));
  border-radius: 14px;
  .gradient-border();

  


  .popular-btn {
    font-family: 'Poppins';
    position: absolute;
    width: 120px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 82px;
    top: -20px;
    background: linear-gradient(90deg, #B447EB -4.52%, #2797FF 120.27%);
    border-radius: 7px;
    font-weight: 600;
    font-size: 12.9372px;
    color: white;
  }

  .plan-name-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .plan-name {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 16px;
      line-height: 44px;
      color: #e3e3e3;
    }
    .fee-section.free-section {
      font-family: 'Poppins';
      font-weight: 800;
      font-size: 15px;
      line-height: 44px;
      color: #8e85aa !important;
    }

    .free {
      color: #8e85aa !important;
    }

    .fee-section {
      display: inline-flex;
      align-items: center;

      .fee-amount {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 14px;
        line-height: 44px;
        color: #d6d6d6;
      }
      .fee-month {
        color: rgba(214, 214, 214, 0.58);
      }
    }
  }

  .plan-detail-section {
    padding-top: 20px;

    .plan-detail-item {
      display: flex;
      align-items: center;
      padding-bottom: 10px;

      svg {
        fill: @success-color;
      }

      .item-text {
        font-family: 'Poppins';
        padding-left: 15px;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: #bcbcbc;
      }
    }
  }

  .change-plan-btn {
    .button-background();
    font-family: 'Poppins';
    margin-top: 25px;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    box-sizing: border-box;
    text-align: center;
  }

  .basic-change-plan-btn{
    .gradient-border();
    font-family: 'Poppins';
  background: transparent;
    margin-top: 25px;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    box-sizing: border-box;
    text-align: center;
    color: white;
  }
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;