@import "../../styleConstant.less";

@cmn-padding: 30px;
@side-nav-width: 330px;
@header-height: 10%;
@side-nav-header-height: 15%;
@logout-height: 15%;
@upgrade-height: 0px;
@version-height: 0px;
@sidebar-width:100px;
// @side-height: 30px;

.ant-spin.loader {
  max-height: 100% !important;
}

.disclaimer-text {
  color: white;
  font-size: 20px;
  text-align: right;
  padding-top: 20px;
  animation: slide-left 10s linear infinite;
  z-index: 0;
}
@keyframes slide-left {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.developer-app-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000000;
  /* font-family: @font-family; */
  font-family: 'Poppins' ;

  .side-nav-container-new {
    width: @side-nav-width;
    background-color: #000000;
    box-sizing: border-box;
    z-index: 10;
    /* font-family: @font-family; */
    font-family: 'Poppins' ;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #e3e3e3;
    padding-top: 30px;

    .header-logo-container1 {
      // height: @side-nav-header-height;
      padding: 0px;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 20px;
      box-sizing: border-box;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      img {
        object-fit: contain;
        width: -webkit-fill-available;
      }
    }

    .menu-container {
      padding: 20px 0px 20px 0px;
      display: flex;
      flex-direction: column;
      /* height: 65%; */
      height: 85vh;
      overflow-y: auto;

      .new-sidenav-list{
        
      }
      .new-sidenav-list-active{
        background-color: #262626;
        .menu-text-new {
          color: #ffffff  !important;
          opacity: 100% !important;

        }
      }
      .new-sidenav-list:hover{
        background-color: #262626;
        .menu-text-new {
          color: #ffffff ;
          // opacity: 100%;

        }
      }

      .menu-item {
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 30px;
        padding-right: 0px;
        margin-bottom: 0px;
        display: flex;
        align-items: center;

                    .menu-text-new {
           color: #ffffff ;
           opacity: 50%;
           font-family: 'Poppins';
          font-size: 20px;
 //              font-weight: bold;
              line-height: 35px;
           text-align: left;

         }


        .menu-Icon{
          margin-left: 10px;
        }
      }
      .menu-item svg{
        margin-left: 20px;
      }
      .subMenuName{
        margin-left: 2.2vw;
        color:#e3e3e3;
        margin-bottom: 10px;
      }

      .active-menu-item {
        position: relative;
        background: #353535;
        // display: flex;
        // align-items: center;
        // justify-content: space-around;
        /* .gradient-border(); */

        // .menu-text {
        //   padding: 0;
        // }
      }
      .active-menu-item svg{
        margin-left: 20px;
      }
               .sub-menu-text-new {
          color: #ffffff ;
          opacity: 50%;
          font-family: 'Poppins';
          font-size: 20px;
//           font-weight: 700;
          line-height: 35px;
          text-align: left;
       }

      .sub-menu-text-new:hover{
          // color: #ffffff ;
                   opacity: 100%;

      }
      .active-menu-item1 {
        position: relative;
        // border-radius: 5px;
        color: #d3d3d3;
        background-color: #262626;
        .sub-menu-text-new {
          color: #ffffff ;
          opacity: 100%;
      
       }

      }
      .inactive-menu-item1 {
        position: relative;
        // border-radius: 5px;
        color: #d3d3d3;
        margin-left: 40px;
        margin-bottom: 10px;
      }
    }

    .menu-container.premium-menu-container {
      height: calc(100% - (@header-height + @version-height + @logout-height));
    }

    .upgrade-container {
      height: @upgrade-height;
      padding: 35px 30px;
      box-sizing: border-box;
      .upgrade-section {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background: #0d1164;
        border-radius: 4.89655px;
        padding: 5px 10px;
        box-sizing: border-box;

        .upgrade-text {
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #e7e8ff;
        }

        .upgrade-btn {
          width: 114.99px;
          height: 30.12px;
          background: @success-color;
          border-radius: 6px;
          color: #0e114e;
          font-weight: 600;
          font-size: 10px;
        }
      }
    }

    .logout-container {
      height: @logout-height;
      display: flex;
      padding: 0px @cmn-padding;
      align-items: center;

      .logout-text {
        padding-left: 10px;
        cursor: pointer;
      }
    }

    .down-menu-container {
         /*  height: @logout-height;
          display: flex;
          flex-direction: column;
          padding: 20px;
          align-items: left; */
          bottom: 10px;
          position: absolute;

          .down-menu-item {
                 padding: 10px;
                 /* margin-bottom: 10px; */
                 display: flex;
                 align-items: center;

                 .down-menu-text {
                   padding-left: 10px;
                   color: #e3e3e3;
                    cursor: pointer;
                 }
                 svg{height: 18px;}

               }
               .down-menu-item svg{
                    margin-left: 20px;
               }
        }

    .version-container {
      height: @version-height;
      font-size: 10px;
      text-align: center;

      .logout-text {
        padding-left: 10px;
        cursor: pointer;
      }
    }
  }

.site-layout {
      width: calc(100% + @sidebar-width);

}
  .site-layout1-new {
    /* width: calc(100% - (@side-nav-width + @sidebar-width)); */
    width: calc(100% - (@side-nav-width));
    background-color: #262626;
    padding-left: 40px;
    padding-right: 40px;


    .header-container {
      display: flex;
      align-items: center;
      height: @header-height;
      padding: 20px 0px;
      padding-bottom: 0px;
      box-sizing: border-box;
      justify-content: space-between;
      margin-bottom: 10px;
      border-bottom: 1px solid #FFFFFF;

      .header-text {
        font-family: 'Poppins';
      font-size: 32px;
      font-weight: 700;
      line-height: 35px;
      text-align: left;
        color: #efefef;
        cursor: pointer;
      }
      .btn-ai-button{
        font-family: 'Poppins';
        display: flex;
        align-items: center;
        border-radius: 15px;
        padding-right: 25px;
        padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
        border: none;
        background-color:#FFFFFF ;
        font-size: 20px;
        font-weight: 400;
        line-height: 35px;
        text-align: left;
        .btn-ai-button-span{
          background: linear-gradient(270deg, #E91D1D 0%, #4C59E3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
        
      }
      .krypc-mini-logo{
        height: auto;
        width: auto;
        margin-left: 30px;
      }

      .profile-section {
      margin-top: -18px;
        display: inline-flex;
        align-items: center;

        .upgrade-btn {
          box-sizing: border-box;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 123px;
          height: 40px;
          border: 1.67px solid @success-color;
          border-radius: 8px;
          font-size: 14px;
          background-color: transparent;
          color: @success-color;
          margin-right: 30px;

          .btn-text {
            padding-left: 10px;
          }
        }

        .notification {
          padding-top: 5px;
          margin-right: 30px;

          .ant-badge {
            .ant-badge-count {
              font-size: 10px;
              min-width: 15px;
              height: 15px;
              line-height: unset;
            }
          }
        }
        .notification2 {
                  padding-top: 5px;
                  margin-right: 30px;

                  .ant-badge {
                    .ant-badge-count {
                      font-size: 10px;
                      min-width: 15px;
                      height: 15px;
                      line-height: unset;
                    }
                  }
                }

        .user-name {
          padding-left: 10px;
          font-size: 14px;
          line-height: 16px;
          color: #d3d3d3;
        }
      }
      @media (min-width: 820px) {
        .header-text {
          font-family: 'Poppins';
        font-size: 30px;
        font-weight: 400;
        line-height: 35px;
        text-align: left;
          color: #efefef;
                width: -webkit-fill-available;
                width: -moz-available; /* For Firefox */

              }
      .profile-section .notification {
                 margin-right: 10px;
                 }
       .user-icon-container img{
       width: 75%;
           height: 75%;

       }
       .profile-section .user-name {
                 font-size: 14px;
                     width: max-content;
               }

        }
    }

    .main-page-container {
      padding: @cmn-padding 0px;
      box-sizing: border-box;
      height: calc(100vh - (@header-height + 5%));
      overflow: auto;
      scrollbar-width: none;

    }
    .main-page-container::-webkit-scrollbar {
      width: 0; /* Hide the scrollbar for Chrome, Safari, and newer Edge */
      height: 0;
      display: none;
    }

    /* Style the scrollbar thumb */
    .main-page-container::-webkit-scrollbar-thumb {
      background-color: #000; /* Customize the scrollbar thumb color */
    }

    /* Style the scrollbar track */
    .main-page-container::-webkit-scrollbar-track {
      background-color: #000; /* Customize the scrollbar track color */
    }

  }

}

.breadcrumb-container {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: #efefef;
  font-family: 'Poppins' ;

  .main-page {
    color: rgba(239, 239, 239, 0.34);
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    text-align: left;
  }

  .separator {
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    padding-top: 5px;
    text-align: left;

  }
         .second-page{
    font-family: 'Poppins';
    font-size: 27px;
    font-weight: 400;
    line-height: 35px;
    text-align: left;
    color: white;
    
  }
}

.ant-popover {
 /*  width: 500px;
  position: fixed !important;
  top:30% !important;
left: 50% !important;
transform:translateX(-50%) !important;
white-space: nowrap !important; */

div & {
    top: 0;
    position: fixed;
    left: 0;
    width: 35%;
    z-index: 9999;
  }

   .ant-popover-inner {
    background-color: #1c1b1c !important;
  }

  .ant-popover-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.85) !important;
    fontWeight:bold;
    color: #efefef !important;
    background-color: #2e2e2e;
  }

  .ant-popover-inner-content {
    fontWeight:bold;
    color: #efefef !important;
    background-color: #1c1b1c;
    border-radius:0px !important;
     width:auto !important;
  }
  .ant-popover-message-title{
    color: #fff !important;
  }

  .notification-list {
    display:flex;
    flex-wrap:wrap;
    gap:1rem;
    .notification {
      fontWeight:bold;
      padding: 3px 10px 3px 10px !important;
      cursor: pointer;
      background-color: blueviolet;
      box-shadow: 0px 1.76599px 27.3729px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      .gradient-border();
      &:focus {
           outline: none;
      }
    }
  }

   .notification-list {
      .notification {
        padding: 10px 0px;
        cursor: pointer;
      }
    }

  .navbar-submenu-list::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
  }
}

 .dropdown-filters {
    width: 200px;
    margin-right: 10px;
    height: 47px;
    outline: none;
    border: 1.65582px solid #464646;
    border-radius: 8px;
    color: #f8f2ff;
    background: #2e2e2e;
    padding: 5px;
  }

.notification2 .ant-popover{
    width: 100% !important;
    left: 0px !important;
    top: 45px !important;
}

  .common-dropdown{
   padding-left:40px;
  }

  /* .dropdown {
    float: left;
    overflow: hidden;
  }
  .dropdown .dropbtn:hover{
    background: transparent !important;
  }

  .dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font: inherit;
    margin: 0;
  }

  .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: #e1e1e1;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #e1e1e1;
    width: 100%;
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content .header {
    background: #e1e1e1;
    padding: 16px;
    color: white;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

   *//* Create three equal columns that floats next to each other *//*
  .column {
    float: left;
    width: 33.33%;
    padding: 10px;
    background-color: #ccc;
    height: 250px;
  }

  .column a {
    float: none;
    color: black;
    padding: 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .column a:hover {
    background-color: #ddd;
  }

   *//* Clear floats after the columns *//*
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

   *//* Responsive layout - makes the three columns stack on top of each other instead of next to each other *//*
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      height: auto;
    }
  } */

 .menu-dropdown {
//     width: 300px;
//     margin-right: 10px;
    height: 47px;
    outline: none !important;
    border: 1.65582px solid #464646;
    border-radius: 8px;
    color: #fff !important;
    background: black !important;
//     padding: 5px;
    margin-top:0px !important;
    border:none !important;
  }
  .ant-menu-title-content{
  color:#f8f2ff!important;
  padding-left:15px!important;
  font-size:large;
  }
.ant-card-head-title .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow{
color:#f8f2ff!important;
}
// .ant-menu-submenu-popup > .ant-menu{
//  background: #2e2e2e!important;
// }
.mega-menu-select{
width:470px
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
background:transparent!important;
}
// .ant-menu-submenu, .ant-menu-submenu-inline{
// border-radius:8px;
// // left:250px!important;
// width:"fit-content";
// }
// .ant-menu{
// text-align:left;
// }
.ant-menu-sub.ant-menu-inline{
background:black!important;
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
width:fit-content !important;
background:black!important;
}
.mega-menu-input{
 color: @color-d5;
    font-size: 16px;
    width: 600px;
    background: #414141;
    padding: 10px;
    border: 1px solid #414141;
    border-radius: 8px;
    margin-top:50px;
    &::placeholder {
      color: rgba(163, 227, 202, 0.3);
    }
}
.betabadge {
    box-sizing: border-box;
    display: inline-block;
    background-color: #2c3e50;
    color: #ffffff;
    border-radius: 3rem; // As longe as this is bigger than the fontsize
    text-align: center;

    font-size: 1.6rem;
    font-weight: 400;
    padding: .05rem .8rem .1rem;
    line-height: inherit;
}
.betabadge--small {
    font-size: 14px !important;
    padding: .1rem .65rem .2rem !important;
}
.betabadge--warning {
    background-color: #f1c40f;
}


.my-badge {
  position: absolute;
  border-bottom: 25px solid #4d9c40;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  height: 0;
  width: 120px;
  text-align: center;
  transform: rotate(-45deg);
  left: -40px;
  top: 5px;
  color: #fff;
}

.my-badge::before {
  content: "";
  border-bottom: 11px solid #2d6824;
  border-left: 11px solid transparent;
  transform: rotate(45deg);
  left: -27px;
  top: 24px;
  position: absolute;
}
.my-badge::after {
  content: "";
  border-bottom: 11px solid #2d6824;
  border-left: 11px solid transparent;
  transform: rotate(45deg);
  left: 76px;
  top: 24px;
  position: absolute;
}
.new-badge-container{
  position: relative;
  /* top:150px; */
  top:20px;
  margin-left:10px;
  height: 24px;
  margin-top: 50px;
  margin-bottom: 50px;

  img{
    height: 24px;
  }
}
.new-badge{
     position: absolute;
     top: -20px;
     left: 85px;
     display: inline-block;
     padding: 1px 2px;
     border-radius: 4px;
     background-color: #007BFF;
     color: #FFFFFF;
     font-size: 12px;
     font-weight: bold;
}
.ai-menu-text {
    padding-left: 10px;
    color: #e3e3e3;
    cursor: pointer;
    font-size:14px;
    font-weight: 600;
}

.css-view-175oi2r {
z-index:1 !important;
}
@img-height-sidebar:142.5%;
.sidebar {
   width: @sidebar-width;
   padding: 20px;
     position: absolute;
     bottom:0;
     right: 0;
     height: 95%;
     /* box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1); */
     background: url('../../../assets/images/curly.avif');
     background-size: 100% @img-height-sidebar;
     background-repeat: no-repeat;

}
.sidebar-content {
  text-align: center;
  position: absolute;
  top: calc(100vh/2 - (50px));
  left: 0;
  right: 40px;
  .circle-button {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: linear-gradient(90deg, #ab500f -4.52%, #4d4139 120.27%);
    color: white;
    text-align: center;
    line-height: 80px;
    font-size: 11px;
    cursor: pointer;
    font-weight: 600;
    margin-left:-20px;
    border:none
  }
}
.ant-alert-message{
font-size:12px;
}
.ant-alert-info{
padding:5px;
}

.api-sandbox-layout{
   width: 100%;
   height: 35px;
   top: 390px;
//    left: 465px;
   gap: 13px;
//    opacity: 0px;
   font-family: 'Poppins';
   font-size: 32px;
   font-weight: 700;
   line-height: 35px;
   text-align: left;
   color: #FFFFFF;
   display: flex;
   flex-direction: column;
   margin-top: 67px;
}


.api-sandbox-line{
    width: 100%;
    height: 0px;
    top: 45px;
    left: 0px;
    gap: 0px;
    border: 1px 0px 0px 0px;
//     opacity: 0px;
    angle: 0 deg;
    border: 1px solid #FFFFFF;
    margin-bottom: 0px;
    line-height: 104px;
}

.dapp-details-select-items{
        display: flex;
        box-sizing: border-box;
        margin-top: 70px;
        margin-bottom:25px;
}

.dapp-details-select-items1{
//     position: absolute;
    top:480px;
    display:flex;
    box-sizing:border-box;
}

.dapp-details-custom-select{
   font-family: 'Poppins';  
    width: 318px;
    height: 61px;
    font-size: 16px;
    border: 2px solid #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    margin-bottom: 10px;
    margin-right:30px;
}

.dapp-details-custom-select option {
    border-radius: 20px;
     appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
    /* Add any additional styling for options here */
}


.form-layout{
     width: 668px;
     height: auto;
     top: 550px;
     left: 340px;
     gap: 0px;
     border-radius: 20px 20px 20px 19px;
//      opacity: 0px;
     background: #383838;

     .ant-form{
       margin: 25px 10px 10px 10px;
     }

     .ant-form-item-label > label{
           font-family: 'Poppins';
           font-size: 14px;
           font-weight: 900;
           line-height: 20px;
           text-align: left;
           color: #FFFFFF;
           white-space: normal;
           word-wrap: break-word;
       }

      .ant-input{
            width: 412px;
            height: 50px;
            left:50px;
            gap: 0px;
            border-radius: 20px 20px 20px 20px;
//             opacity: 0px;
            background: #D9D9D9;
            margin-bottom:10px;
      }


      .ant-switch{
         left:50px;
         gap: 0px;
         background: #D9D9D9;
      }



     .ant-btn{
        margin-left: 500px;
        border-radius: 20px 20px 20px 20px;
        font-weight:600;
     }


}

.custom-file-upload {
    width: 412px;
    height: 50px;
    left:50px;
    gap: 0px;
    border-radius: 20px 20px 20px 20px;
//     opacity: 0px;
    background: #000000;
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 1000;
    line-height: 45px;
    text-align: center;
    left: 50px;
    top: 0px;

     position: relative;
     display: inline-block;
}

.custom-file-upload span {
  display: inline-block;
  margin-right: 10px; /* Adjust the spacing between the button and filename */
}

.custom-file-upload .filename {
  display: inline-block;
  color: white; /* Adjust filename color */
}

.submenu-style{
     display: flex;
     /* align-items: center; */
     flex-direction: column-reverse;
     flex-wrap: wrap;
     padding: 10px;
     align-content: center;
}

.pr-35{
padding-right:35px
}

.connectmeta{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding-right: 25px;
    padding-left: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    background-color: #FFFFFF;
    font-size: 20px;
    font-weight: 700;
    line-height: 35px;
    text-align: left;
    width: 211px;
    height: 49px;
    margin-right:20px;
    }

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;