@import "../../../styleConstant.less";

.content-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;

  .prev-next-btn-section {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 20px;

    .next-btn {
      /* background: transparent;
      box-shadow: 0px 2px 14px rgba(167, 167, 167, 0.25);
      border-radius: 6px;
      font-family: @font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: white;
      width: 95px;
      height: 40px;
      .gradient-border(); */
      font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: white !important;
            width: 150px;
            height: 47px;
            box-sizing: border-box;
            color: white;
            background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
            border-radius: 6px;
            border: 0.5px solid black;
      /* &:active {
        color: white;
        border-color: #d9d9d9;
        background: transparent;
        text-shadow: none;
        box-shadow: none;
      }
      &:hover {
        color: white;
        border-color: #d9d9d9;
        background: transparent;
        text-shadow: none;
        box-shadow: none;
      } */
    }

    .prev-btn {
     font-weight: 600;
           font-size: 14px;
           line-height: 17px;
           text-align: center;
           color: white !important;
           width: 150px;
           height: 47px;
           box-sizing: border-box;
           color: white;
           background: #303030;
           border-radius: 6px;
           border: 0.5px solid black;

    }

  }

  .column-container {
    font-family: @font-family;
    font-style: normal;
    font-weight: 500;
    line-height: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .label-text {
      letter-spacing: 0.02em;
      font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #f2ecfb;

      .anticon {
        margin-left: 15px;
      }
    }
    .label-text-22{
        font-size: 22px !important;
    }

    .input-container {
      color: @color-d5;
      font-size: 16px;
      width: 100%;
      max-width: 750px;
      border: 1px;
      border-style: solid;
      border-radius: 6px;
      padding: 10px;
      background: #02020f;
      border: 1px solid #676767;
      border-radius: 6px;
      &::placeholder {
        color: #e0caef82;
      }
    }
    .ant-radio-wrapper {
        font-family: 'Poppins' ;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: white !important;
    }
  }

  .row-container{
    .card:first-child {
            border: 3px solid #095FDB;
            background: transparent;
            align-items: center;
        }
  }

  .row-container,
  .three-row-container {
    display: grid;


    .card {
      position: relative;
      border: 0.636608px solid #222222;
      border-radius: 4px;
      background-color: #222222;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      padding-top: 20px;
      min-height: 182px;
      text-align: center;

      .endpoint-name1{
        color: #fff;
        opacity: 0.8;
          padding-top: 1rem;
          margin-bottom: -5px;
          text-transform: Capitalize;
      }
      .endpoint-name2{
              color: #fff;
                opacity: 0.5;
            }

      .text-container {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: @color-e7;
        padding: 30px 0px;
      }

      .header-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #b0b0b0;
        padding: 5px 0px;
        text-align: left;
      }

      .label-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: @color-e7;
        text-align: left;
      }

      .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .column {
          display: flex;
          align-items: center;
          padding-top: 10px;

          .text-container {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 17px;
            color: #d9d9db;
            padding-left: 10px;
            padding-right: 20px;
            box-sizing: border-box;
          }
        }
      }

      .ellipsis {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 10px;
      }
    }

    .card.active-card {
      /* background: #a54fed12;
      border: 0.636608px solid @success-color;
      border-radius: 4px; */
    }

    .disabled-card {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  .row-container {
    grid-gap: 25px;
    grid-template-columns: repeat(auto-fill, 144px);
    .plus-btn{
        border-radius: 50%;
          height: 40px;
          width: 40px;
          background: #222;
    }
    .add-plus-text{color:#fff;opacity: 0.8;margin-top:20px;}
  }

  .three-row-container {
    grid-gap: 30px;
    grid-template-columns: repeat(3, minmax(150px, 1fr));

    .card {
      align-items: flex-start;
      justify-content: flex-start;
      padding: 20px;
      border: 1px solid #676767;
      filter: drop-shadow(4.64122px 4.64122px 14.5038px rgba(0, 0, 0, 0.25));
      backdrop-filter: blur(2.90076px);
      border-radius: 6px;
//       width: 240px;
      height: auto;

      .card-svg-div {
        width: 40px;
        height: 40px;
        background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .row {
        margin-top: 15px !important;
        overflow: auto;

        .column {
          .text-container {
            padding: 0px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

.slider-layout {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo {
    position: absolute;
    top: 20px;
  }

  .timeline-container {
    padding-top: 20px;
    padding-left: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    max-height: calc(100% - 125px);
    height: 100%;

    .timeline-item1 {
      font-family: @font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      display: flex;
      align-items: center;
      color: @color-side-text;
      letter-spacing: @letter-space;

      .ant-timeline-item-content {
        margin: 0 0 0 50px;
      }
      

      .row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        margin-left: 0px;

        .column {
          display: inline-flex;
          align-items: center;
          padding-top: 5px;

          .placeholder-text {
            padding-left: 5px;
            padding-right: 10px;
          }
        }
      }

      .placeholder-text {
        font-family: @font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        color: @placeholder-color;
        opacity: 0.9;
      }

      .placeholder-text.ellipsis {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .timeline-item:last-child {
    .ant-timeline-item-tail{
       border-left: 0px;
    }
    }
    .timeline-item {
      font-family: @font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      display: flex;
      align-items: center;
      color: @color-side-text;
      letter-spacing: @letter-space;
      scroll-snap-align: end;
     scroll-margin-bottom: 500px;

     


      .ant-timeline-item-content {
        margin: 0 0 0 50px;
      }
      

      .row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        margin-left: 0px;

        .column {
          display: inline-flex;
          align-items: center;
          padding-top: 5px;

          .placeholder-text {
            padding-left: 5px;
            padding-right: 10px;
          }
        }
      }

      .placeholder-text {
        font-family: @font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        color: @placeholder-color;
        opacity: 0.9;
      }

      .placeholder-text.ellipsis {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .badge-icon {
      .ant-badge-count {
        font-family: @font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 15.2034px;
        display: flex;
        align-items: center;
        width: 35px;
        height: 35px;
        justify-content: center;
        border-radius: 50%;
      }
    }
    .ant-timeline-item-head {
      background: unset !important;
    }

    .ant-timeline-item-tail {
      border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
    .timeline-item1:last-child {
        .ant-timeline-item-tail{
           border-left: 0px;
        }
    }
  
  }

  .timeline-container.full-timeline-container {
    max-height: 100%;
  }

  .icon-container {
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    position: absolute;
    bottom: 25px;

    svg {
      margin-right: 20px;
      width: 22px;
    }

    &:last-child {
      padding-right: 0[x];
    }
  }

}

.content-header-text {
        font-family: @font-family;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 0px;
        color: #b0b0b0;
        padding: 5px 0px;
        text-align: left;
      }
 .content-text {
        font-family: @font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        word-wrap: break-word;
        line-height: 24px;
        height: auto;
        color: @color-e7;
        text-align: left;
        padding-left:20px;
      }
 .new-container{
    padding-top:10px;
    padding-bottom:10px;
 }
  .summary-border-bottom{
     border-bottom: 1px solid rgb(35, 60, 128);
  }
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;