.card-container {
    display: grid;
    grid-gap: 15px;
   .card-section-container {
      min-width: calc(100% / 4);
      height:90px;
    }
}
.ant-modal {
  width: 75% !important;
}
.ant-modal-body {
 background: black;
 min-height: auto;
 width: 600px;
}
.ant-modal-body button {
  background: #4C59E3;
  border-radius: 20px;
  font-weight: 400;
  font-size: 16px;
}

.template-container{
   display: grid;
   grid-gap: 20px;
   grid-template-columns: repeat(3, 1fr);
   margin-top: 40px;
   width: 60%;
   grid-column-gap: 0px;
}
.template-header{
   font-family: 'Poppins';
   font-size: 24px;
   font-weight: 400;
   line-height: 35px;
   text-align: left;
   color: #FFFFFF;
}
.template-box{
   background: linear-gradient(180deg, #262626 0%, #373737 100%);
   border: 1px solid #7F7F7F;
   width: 200px;
   height: 130px;
   border-radius: 15px;
   cursor: pointer;
}

.self-managed-text{
   font-family: 'Poppins';
   font-size: 18px;
   font-weight: 700;
   line-height: 35px;
   text-align: left;
   color: #FFFFFF;
}

.self-managed-label{
//    width: 175px !important;
   height: 35px;
//    top: 390px;
//    left: 463px;
//    gap: 0px;
//    opacity: 0px;
   font-family: 'Poppins';
   font-size: 24px !important;
   font-weight: 700;
   line-height: 35px;
   text-align: left;
   color: #FFFFFF;
   padding-right: 20px;
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;