@import "../../styleConstant.less";

.dashboard-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;

  .name-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

    .name-section {
      display: flex;
      flex-direction: column;

      .name {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        color: #d9d9d9;
      }

      .sub-text {
        font-family: 'Poppins' ;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #efefef;
      }
    }

    .create-project-section {
      .background-grid {
        .gradient-border();
        width: 150px;
        height: 40px;
        border-radius: 7.94305px;
        font-size: 14px;
        font-weight: 600;
        color: white;
        background: black;
        &:focus {
          outline: none;
        }
      }
      .create-project-btn {
        .button-background();
        width: 150px;
        height: 40px;
        border-radius: 7.94305px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .heading {
    display: inline-flex;
    align-items: center;
    margin-bottom: 15px;
    .text {
      font-weight: 600;
      font-size: 20px;
      color: #d9d9d9;
      padding-right: 10px;
    }
  }
  .card-container {
    display: grid;
    grid-gap: 30px;
    
    .card-section-container {
      min-width: calc(100% / 3);
    }
  }

  .grid-repeat-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .container-text {
    display: inline-flex;
    align-items: center;

    .text {
      font-weight: 600;
      font-size: 20px;
      color: #d9d9d9;
      padding-right: 10px;
    }
  }

  .active-tag,
  .inactive-tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0px 11px;
    background: rgba(53, 176, 139, 0.1);
    border-radius: 51.4121px;
  }

  .active-tag {
    border: @border;
  }

  .inactive-tag {
    color: #646464;
    border: 1.02824px solid #646464;
  }

  .table-cell-data {
    display: inline-flex;
    align-items: center;

    .total-count {
      color: @success-color;
      padding-left: 5px;
    }
  }

  .api-key {
    cursor: pointer;
  }

  .row-chart {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 70px;
    height: 70px;
    right: 0;
    top: 248px;
   /*  background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%); */
   background: none !important;
    filter: blur(50px);
  }
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 5px !important;
}

.tooltip {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
  opacity: 1 !important;
  position: relative !important;

  .tooltip-text {
    padding-right: 5px;
  }
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: transparent !important;
}

// developers style
.developerWrapper {
  display: flex;
  column-gap: 25px;
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    .gradient-border();
    overflow: hidden;
    background: none;
    .avatar-holder {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .initial {
        color: black;
        font-weight: 700;
        font-size: 22px;
      }
    }
    .name {
      text-align: center;
      a {
        color: #d6d6d6;
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
        &:hover {
          text-decoration: underline;
          color: rgba(32, 205, 137, 0.5);
        }
      }
    }
    .button {
      text-align: center;
      outline: none;
      a {
        border: @border;
        background: rgba(53, 176, 139, 0.1);
        border-radius: 51.4121px;
        padding: 5px 20px;
        color: #d6d6d6;
        letter-spacing: 0.05em;
        text-decoration: none;
        font-size: 10px;
        transition: all 1s;
        &:hover {
          color: #d6d6d6;
          background: rgba(32, 205, 137, 0.5);
        }
      }
    }
  }
}

@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;