@import "../../styleConstant.less";

.table_button_revoke{
    border:1px solid #9933ff;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius:20px
}
.view-details12 {

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: white;
    width: auto;
    background-color: black;
    .gradient-border();
    outline: none;
    height: auto;
    cursor: pointer;
  }
.view-details1 {
  
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: white;
    width: calc((100% - 10px) / 2);
    background-color: black;
    .gradient-border();
    outline: none;
    height: 39px;
    cursor: pointer;
  }

  .wallet_Validation{
    margin-top: -20px;
    margin-right: 15px;
    color: red;
  }

   .createBtn {
    font-family: 'Poppins' !important;
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #090a2c;
      width: 150px;
      height: 47px;
      box-sizing: border-box;
      .button-background();
      &:focus {
        outline: none;
      }
    }
 .input-container {
    color: @color-d5;
    font-size: 16px;
    width: 100%;
    max-width: 750px;
    border: 1px;
    border-style: solid;
    border-radius: 6px;
    border-color: #464646;
    background: #02020f;
    padding: 10px;
    &::placeholder {
      color: rgba(183, 183, 183, 0.65);
    }
  }
.card-box-container {
  position: relative;
  background: #222222;
  border: 1px solid #464646;
  box-shadow: 0px 3.49493px 8.73734px rgb(0 0 0 / 10%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  /* width: 96%; */
  height: 500px;
  justify-content: space-evenly;
  align-content: center;
  margin-top: 16px;

  .project-name{
  line-height:25px;
  }
}
.empty-card-box-container {
  position: relative;
  background: #222222;
  border: 1px solid #464646;
  box-shadow: 0px 3.49493px 8.73734px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  justify-content: space-evenly;
  align-content: center;
  margin-top: 16px;

  .view-details{
    border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #090a2c;
      width: 150px;
      height: 47px;
      box-sizing: border-box;
      color: white;
      background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
      border-radius: 6px;
      border: 0.5px solid black;
  }
  .name-section{
    color: #fff;
    opacity: 0.8;
  }
  .project-name{
    font-family: 'Poppins' ;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 20px;
      line-height: inherit;
      padding-bottom: 5px;
  }
}
.ant-modal{
width:550px !important;
}
.table{
 border:none !important;
 height:0px;
}
.wallet-footer-container{
 margin-top: 30px;
 margin-bottom: 50px;
 width:auto;
 overflow:hidden;
 .project-footer-container{
         max-width: 100%;
         overflow-x: auto;
         /* display: flex; */
         z-index:1;
         flex-direction: row;
          //gap: 10px;
          .project-footer-col{
           width: 25%;
          }
  }
 .tiles {
     display: grid;
     grid-template-columns: repeat(14, 1fr);
     -moz-column-gap: 1rem;
          column-gap: 1rem;
     row-gap: 1rem;
     margin-top: 1.25rem;

     padding: 10px;
   }
   @media (max-width: 700px) {
     .tiles {
       grid-template-columns: repeat(1, 1fr);
     }
   }

   .tile {
     padding: 1rem;
     border-radius: 8px;
     /* background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%); */
     background: transparent;
     color: #000000;
     min-height: 200px;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     position: relative;
     transition: 0.25s ease;
     &:hover {
             a .icon-button {
                 transform: rotateZ(-30deg);
             }
         }
   }
   .tile:hover {
     transform: translateY(-5px);
   }

   .tile:focus-within {
     box-shadow: 0 0 0 2px #1f1f1f, 0 0 0 4px #e3ffa8;
   }
   .tile:nth-child(2) {
     background-color: #45ffbc;
   }
   .tile:nth-child(2):focus-within {
     box-shadow: 0 0 0 2px #1f1f1f, 0 0 0 4px #45ffbc;
   }
   .tile:nth-child(3) {
     background-color: #bdbbb7;
   }
   .tile:nth-child(3):focus-within {
     box-shadow: 0 0 0 2px #1f1f1f, 0 0 0 4px #bdbbb7;
   }
   .tile a {
     text-decoration: none;
     display: flex;
     align-items: center;
     justify-content: center;
     font-weight: 600;
   }
   .tile a .icon-button {
     color: inherit;
     border-color: inherit;
   }
   .tile a span svg,img {
        height: 35px;
   }

   .tile a .icon-button:hover, .tile a .icon-button:focus {
     background-color: transparent;
   }
   .tile a .icon-button:hover i, .tile a .icon-button:focus i {
     transform: none;
   }
   .tile a:focus {
     box-shadow: none;
   }
   .tile a:after {
     content: "";
     display: block;
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
   }

   .tile-header {
     display: none;
     align-items: center;
     justify-content: center;
     color: #fff;
   }
   .tile-header i {
     font-size: 2.5em;
   }
   .tile-header span {
        font-size: 11px;
      }
   .tile-header h3 {
     display: flex;
     flex-direction: column;
     line-height: 1.375;
     margin-left: 0.5rem;
   }
   .tile-header h3 span:first-child {
     font-weight: 600;
   }
   .tile-header h3 span:last-child {
     font-size: 0.825em;
     font-weight: 200;
   }
   .tile-small {
        min-height: 30px !important;
   }
   .tile_div{
        background: linear-gradient(90deg, #b447eb -4.52%, #2797ff 120.27%);
        border-radius: 8px;
   }
   .tile_div:hover {
        transform: translateY(-5px);
   }
   }

.circle-container {
    /* width: 100px;
    height: 100px; */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-color: lightgray;
  }

  .circle-image {
    width: 100%;
    height: 40px !important;
    /* height: 100px !important; */
    object-fit: cover;
  }

.marquee-container-left {
  overflow: hidden;
}

.marquee-content-left {
  display: flex;
  animation: marquee-left 50s linear infinite;
}

@keyframes marquee-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee-content-left:hover {
  -moz-animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
 }

.protocol-data {

  &:hover {

              .tile-header{
                    display: flex;
                    justify-content:left !important;
               }
               .tile{
                    border-radius: 6px;
                    border: 2px solid #b447eb;
               }
           }
}
.tile:hover {
border: 1px solid #e1e1e1;
}
@primary-color: #233C80;@font-family: 'Inter', sans-serif;@font-size: 1.5rem;@layout-header-background: #233C80;